export const StepperOrderNumber = {
  ChurchMinistry: 0,
  GideonCard: 1,
  FaithFund: 2,
  AuxiliaryScripture: 3,
  CampDesignated: 4,
  Placements: 5,
  PersonalWorkersTestaments: 6,
  Membership: 7
}
