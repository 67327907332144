/* eslint-disable */
export const SCRIPTURE_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/scripture`

export const SCRIPTURE_API_CONFIG = {
  addLocation: (ind_key, cmp_key) => `${SCRIPTURE_API_ACTIVE_ROUTE}/location/add/${ind_key}/${cmp_key}`,
  addLocationContact: () => `${SCRIPTURE_API_ACTIVE_ROUTE}/location/contact/add`,
  addPlacementDate: ind_key => `${SCRIPTURE_API_ACTIVE_ROUTE}/placementdate/add/${ind_key}`,
  addScriptureLocation: ind_key => `${SCRIPTURE_API_ACTIVE_ROUTE}/location/church/add/${ind_key}`,
  deletePlacementDate: (mtg_key, mtg_user) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/placementdate/delete/${mtg_key}/${mtg_user}`,
  deleteLocation: (loc_key, user) => `${SCRIPTURE_API_ACTIVE_ROUTE}/location/delete/${loc_key}/${user}`,
  editPlacementItem: ind_key => `${SCRIPTURE_API_ACTIVE_ROUTE}/placementitems/edit/${ind_key}`,
  getAllPlacementDatesByLocation: (org_key, lng_key) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/location/allplacementdates/${org_key}/${lng_key}`,
  getLocationCommunications: (entity_key, lng_key) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/location/communication/${entity_key}/${lng_key}`,
  getLocationContacts: (org_key, lng_key) => `${SCRIPTURE_API_ACTIVE_ROUTE}/location/contacts/${org_key}/${lng_key}`,
  getLocationTypeOptions: (lku_description_code, lng_key) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/location/GetTypeFormOptions_tc3/${lku_description_code}/${lng_key}`,
  getLocationVisitOptions: (lku_description_code, lng_key, cmp_key, mpt_key) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/location/GetVisitFormOptions_tc3/${lku_description_code}/${lng_key}/${cmp_key}/${mpt_key}`,
  getLocationVisits: (entity_key, lng_key) => `${SCRIPTURE_API_ACTIVE_ROUTE}/location/visits/${entity_key}/${lng_key}`,
  getLocationsDetails: (org_key, lng_key, org_type_key) => `${SCRIPTURE_API_ACTIVE_ROUTE}/location/profile/${org_key}/${lng_key}/${org_type_key}`,
  getLocationsDetailsNoType: (org_key, lng_key) => `${SCRIPTURE_API_ACTIVE_ROUTE}/location/profile/${org_key}/${lng_key}`,
  getPlacementDatesByCampLast12: (camp_key, lng_key) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/GetPlacementDatesByCampLast12/${camp_key}/${lng_key}`,
  getPlacementFormOptions: (camp_key, lng_key, mbt_key) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/location/GetPlacementFormOptions_tc3/${camp_key}/${lng_key}/${mbt_key}`,
  getPlacementItems: (org_key, lng_key) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/location/placementitems/${org_key}/${lng_key}`,
  getPlacementLocationsByCamp: (camp_key, lng_key, mpt_key) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/GetPlacementLocationsByCamp/${camp_key}/${lng_key}/${mpt_key}`,
  getPlacementLocationsByCampForAuxiliary: (camp_key, lng_key, mpt_key) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/GetPlacementLocationsByCampForAuxiliary/${camp_key}/${lng_key}/${mpt_key}`,
  getScriptureDistributionWidgetData: (camp_key, lng_key, fiscal_year, limit) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/GetScriptureDistributionWidgetData/${camp_key}/${lng_key}/${fiscal_year}/${limit}`,
  getSinglePlacementDetails: (mtg_key, lng_key) =>
    `${SCRIPTURE_API_ACTIVE_ROUTE}/getSinglePlacementDetails/${mtg_key}/${lng_key}`,
  upsertLocationPhoto: org_key => `${SCRIPTURE_API_ACTIVE_ROUTE}/photoupsert_tc3/${org_key}`
}
