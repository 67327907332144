export const GoalShortCodes = {
  ChurchMinistry: 'CM',
  ChurchPresentation: 'CP',
  FaithFund: 'FF',
  AuxiliaryScripture: 'AS',
  CampDesignated: 'DC',
  BarnabasFund: 'BF',
  GideonPlacements: 'PL',
  AuxiliaryPlacements: 'PA',
  GideonPWTs: 'GP',
  AuxiliaryPWTs: 'AP',
  GideonMembership: 'MG',
  AuxiliaryMembership: 'MA',
  GideonCard: 'GC',
  GideonCardPresentation: 'GV',
  GideonCardCollection: 'TC',
  Bible: 'BP',
  Hospital: 'HP',
  College: 'CL',
  Youth: 'YP',
  SidewalkYouth: 'SY',
  Service: 'SV',
  SoftCoverBible: 'SC',
  GideonFacilities: 'FG',
  Medical: 'MD',
  AuxiliaryFacilities: 'FA',
  AuxiliaryFacilities9: 'A9',
  Receipts: 'RC'
}
