/*eslint-disable*/
import axiosErrorHandler from '../axiosErrorHandler.js'
import { GET_GOAL_API_ENDPOINT } from '@/config/goals.api.config.js'

export default class CampDesignatedService {
  static async getGoals(CAMP_KEY, LANG_KEY) {
    const URL = GET_GOAL_API_ENDPOINT('GoalsGet[CampDesignated]')(CAMP_KEY, LANG_KEY)
    const response = await axiosErrorHandler({
      endpoint: URL,
      apiType: 'get'
    })
    return response
  }
  static async updateGoals({ goalsData }) {
    const URL = GET_GOAL_API_ENDPOINT('GoalsUpdate[CampDesignated]')()
    const response = await axiosErrorHandler({
      endpoint: URL,
      apiType: 'post',
      payload: goalsData,
      successMessage: 'You have successfully updated camp designated goals.'
    })
    return response
  }
}
