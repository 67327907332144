/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'
import constantData from '@/json/data.json'

export const state = () => ({
  account: {},
  accountTypes: []
});

export const getters = {
  currentAccount: state => {
    return state.account;
  }
};

export const SET_CURRENT_ACCOUNT = "SET_CURRENT_ACCOUNT";

export const mutations = {
  [SET_CURRENT_ACCOUNT](state, data) {
    data.bnk_account_type =
      data.bnk_bkt_key === "28f00c92-b2ef-49c2-82a2-937478c1750e"
        ? "Business Checking"
        : data.bnk_bkt_key === "c2cb43d9-4118-495b-9fdb-31f2a211ce2c"
          ? "Business Savings"
          : "Other";
    state.account = data;
  }
};

export const actions = {
  async setCurrentAccount({ commit }, acct) {
    commit("SET_CURRENT_ACCOUNT", acct);
    return true;
  },
  async updateAccount({ commit, rootGetters }, acctData) {
    try {
      const account = {
        bnk_org_key: acctData.stateOrCamp === 's' ? rootGetters["campModule/stateUndist"].cmp_org_key : rootGetters["campModule/homeCamp"].key,
        bnk_account_number: acctData.account,
        bnk_routing_number: acctData.routing,
        bnk_bkt_key: acctData.type,
        bnk_nag_key: constantData.default_nag_key,
        bnk_add_user: null,
        bnk_add_date: null,
        bnk_change_user: rootGetters["user/userId"],
        bnk_change_date: new Date().toISOString(),
        bnk_delete_flag: false
      };
      const response = await apiCalls.updateAccount(account);
      if (response && response.status === 200) {
        commit("SET_CURRENT_ACCOUNT", response.data[0]);
        return true;
      }
    } catch (error) {
      // TODO: handle calendar get failure
      return false;
    }
  },
  async getAccount({ commit }, orgid) {
    try {
      if (orgid.length > 0) {
        const response = await apiCalls.getAccount(orgid);
        if (response && response.status === 200) {
          commit("SET_CURRENT_ACCOUNT", response.data[0]);
          return true;
        }
      }
    } catch (error) {
      // TODO: handle calendar get failure
      return false;
    }
  }
};

const apiCalls = {
  // eslint-disable-next-line camelcase
  getAccount(org_key) {
    const route = API_CONFIG.getBankAccount(org_key);
    return axios.get(route);
  },
  updateAccount(data) {
    const route = API_CONFIG.updateBankAccount();
    return axios.post(route, data);
  }
};

export const accountModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
