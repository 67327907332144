/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'
import constantData from '@/json/data.json'

export const GIDEON_COMMITTEE_MEMBER_LIST = 'GIDEON_COMMITTEE_MEMBER_LIST'
export const AUXILIARY_COMMITTEE_MEMBER_LIST = 'AUXILIARY_COMMITTEE_MEMBER_LIST'
export const GIDEON_MEMBER_LIST = 'GIDEON_MEMBER_LIST'
export const AUXILIARY_MEMBER_LIST = 'AUXILIARY_MEMBER_LIST'
export const COMMITTEE = 'COMMITTEE'
export const SET_AUXILIARY_MEMBER_TYPE = 'SET_AUXILIARY_MEMBER_TYPE'
export const SET_GIDEON_MEMBER_TYPE = 'SET_GIDEON_MEMBER_TYPE'
export const UPSERT_COMMITTEE_MEMBER = 'UPSERT_COMMITTEE_MEMBER'
export const UPDATE_COMMITTEE = 'UPDATE_COMMITTEE'

const apiCalls = {
  getElectionCommitteeMembers(org_key, lng_key) {
    const route = API_CONFIG.getElectionCommitteeMembers(org_key, lng_key)
    return axios.get(route)
  },
  getElectionGideonMembers(org_key, lng_key) {
    const route = API_CONFIG.getElectionGideonMembers(org_key, lng_key)
    return axios.get(route)
  },
  getElectionAuxiliaryMembers(org_key, lng_key) {
    const route = API_CONFIG.getElectionAuxiliaryMembers(org_key, lng_key)
    return axios.get(route)
  },
  getElectionCommittee(org_key, lng_key) {
    const route = API_CONFIG.getElectionCommittee(org_key, lng_key)
    return axios.get(route)
  },
  upsertElectionCommitteeMember(data) {
    const route = API_CONFIG.upsertElectionCommitteeMember()
    return axios.post(route, data)
  },
  updateElectionCommittee(data) {
    const route = API_CONFIG.updateElectionCommittee()
    return axios.post(route, data)
  }
}
const actions = {
  async getElectionCommitteeMembers({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getElectionCommitteeMembers(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('GIDEON_COMMITTEE_MEMBER_LIST', response.data)
        await commit('AUXILIARY_COMMITTEE_MEMBER_LIST', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getElectionCommittee({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getElectionCommittee(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('COMMITTEE', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getElectionGideonMembers({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getElectionGideonMembers(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('GIDEON_MEMBER_LIST', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getElectionAuxiliaryMembers({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getElectionAuxiliaryMembers(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('AUXILIARY_MEMBER_LIST', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async setMemberType({ commit }, newData, memberType) {
    try {
      if (memberType === 'gideon') await commit('SET_GIDEON_MEMBER_TYPE', newData)
      else if (memberType === 'auxiliary') await commit('SET_AUXILIARY_MEMBER_TYPE', newData)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async upsertElectionCommitteeMember({ }, data) {
    try {
      const response = await apiCalls.upsertElectionCommitteeMember(data)
      if (response && response.status === 200) {
        return response.data
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async updateElectionCommittee({ }, data2) {
    try {
      const response = await apiCalls.updateElectionCommittee(data2)
      if (response && response.status === 200) {
        return response.data
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

export const state = () => ({
  gideonMemberDetailSelect: {},
  auxiliaryMemberDetailSelect: {},
  gideonMembersSelect: [],
  auxiliaryMembersSelect: [],
  committeeDetailSelect: {},
  upsertCommitteeMemberSelect: {},
  updateCommitteeSelect: {}
})

export const getters = {
  gideonMemberDetailSelect: state => {
    return state.gideonMemberDetailSelect
  },
  auxiliaryMemberDetailSelect: state => {
    return state.auxiliaryMemberDetailSelect
  },
  gideonMembersList: state => {
    return state.gideonMembersSelect.map(gm => {
      return { text: gm.text, value: gm.value }
    })
  },
  gideonMembersSelect: state => {
    return state.gideonMembersSelect
  },
  auxiliaryMembersSelect: state => {
    return state.auxiliaryMembersSelect
  },
  committeeDetailSelect: state => {
    return state.committeeDetailSelect
  },
  upsertCommitteeMemberSelect: state => {
    return state.upsertCommitteeMemberSelect
  },
  updateCommitteeSelect: state => {
    return state.updateCommitteeSelect
  }
}
export const mutations = {
  [GIDEON_COMMITTEE_MEMBER_LIST](state, data) {
    state.gideonMemberDetailSelect = data.filter(gm => !gm.office.includes('Aux'))
    state.gideonMemberDetailSelect.forEach(gm => {
      gm.mbt_met_key = constantData.member_types.find((mt) => mt.text === 'Gideon').value
    })
  },
  [AUXILIARY_COMMITTEE_MEMBER_LIST](state, data) {
    state.auxiliaryMemberDetailSelect = data.filter(gm => gm.office.includes('Aux'))
    state.auxiliaryMemberDetailSelect.forEach(gm => {
      gm.mbt_met_key = constantData.member_types.find((mt) => mt.text === 'Auxiliary').value
    })
  },
  [GIDEON_MEMBER_LIST](state, data) {
    state.gideonMembersSelect = data
  },
  [AUXILIARY_MEMBER_LIST](state, data) {
    state.auxiliaryMembersSelect = data
  },
  [COMMITTEE](state, data) {
    state.committeeDetailSelect = data
  },
  [SET_AUXILIARY_MEMBER_TYPE](state, data) {
    state.auxiliaryMembersSelect = data
  },
  [SET_GIDEON_MEMBER_TYPE](state, data) {
    state.gideonMembersSelect = data
  },
  [UPSERT_COMMITTEE_MEMBER](state, data) {
    state.upsertCommitteeMemberSelect = data
  },
  [UPDATE_COMMITTEE](state, data) {
    state.updateCommitteeSelect = data
  }
}

export const election = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
