/* eslint-disable */
export const CALENDAR_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/Calendar`

export const CALENDAR_API_CONFIG = {
  AddNewEvent: () => `${CALENDAR_API_ACTIVE_ROUTE}/AddUpdateEvent`,
  AddNewStateEvent: () => `${CALENDAR_API_ACTIVE_ROUTE}/AddUpdateStateEvent`,
  campCalendarEventTypesTranslations: languageCode =>
    `${CALENDAR_API_ACTIVE_ROUTE}/campCalendarEventTypesTranslations/${languageCode}`,
  deleteStateMeeting: (mtr_key, delete_all) =>
    `${CALENDAR_API_ACTIVE_ROUTE}/DeleteStateMeeting/${mtr_key}/${delete_all}`,
  DownloadICS: mtg_key => `${CALENDAR_API_ACTIVE_ROUTE}/DownloadICS/${mtg_key}`,
  getEventCalendarFormSettings: (org_key, event_type) =>
    `${CALENDAR_API_ACTIVE_ROUTE}/GetEventFormParamerters/${org_key}/${event_type}`,
  getEventLocation: query => `${CALENDAR_API_ACTIVE_ROUTE}/GetLocation/${query}`,
  getMeetingRecurrence: mtr_key => `${CALENDAR_API_ACTIVE_ROUTE}/GetMeetingRecurrence/${mtr_key}`,
  getMeetings: (org_key, start_date, end_date) =>
    `${CALENDAR_API_ACTIVE_ROUTE}/GetMeetings/${org_key}/${start_date}/${end_date}`,
  getMeetingSingle: (org_key, mtg_key) => `${CALENDAR_API_ACTIVE_ROUTE}/GetMeetingSingle/${org_key}/${mtg_key}`,
  GetOrganisationHierarchy: (t_parent_org_key, org_key, lng_key) =>
    `${CALENDAR_API_ACTIVE_ROUTE}/OrganisationHierarchy/${t_parent_org_key}/${org_key}/${lng_key}`,
  getStateMeetings: (org_key, start_date, end_date) =>
    `${CALENDAR_API_ACTIVE_ROUTE}/GetStateMeetings/${org_key}/${start_date}/${end_date}`,
  getStateMeetingsRowSort: (state_org_key, start_date, end_date, rows, sort_asc) =>
    `${CALENDAR_API_ACTIVE_ROUTE}/GetStateMeetings/${state_org_key}/${start_date}/${end_date}/${rows}/${sort_asc}`,
  getStates: country_key => `${CALENDAR_API_ACTIVE_ROUTE}/GetStates/${country_key}`,
  meetingDeleteByRecurrence: (mtr_key, mtg_user) =>
    `${CALENDAR_API_ACTIVE_ROUTE}/DeleteMeetingByRecurrence/${mtr_key}/${mtg_user}`,
  meetingSoftDelete: (mtg_key, mtg_user) => `${CALENDAR_API_ACTIVE_ROUTE}/MeetingSofteDelete/${mtg_key}/${mtg_user}`,
  meetingToBeSchedule: org_key => `${CALENDAR_API_ACTIVE_ROUTE}/MeetingToBeSchedule/${org_key}`,
  searchEventLocation: (query, city, cnt_key) =>
    `${CALENDAR_API_ACTIVE_ROUTE}/GetLocation?query=${query || ''}&city=${city || ''}&cnt_key=${cnt_key}`,
  searchEventLocationVirtual: (query, user_camp_key) =>
    `${CALENDAR_API_ACTIVE_ROUTE}/GetLocationVirtual?query=${query || ''}&user_camp_key=${user_camp_key || ''}`,
  upsertAddress: () => `${CALENDAR_API_ACTIVE_ROUTE}/UpsertAddress`,
  upsertVirtualLocation: () => `${CALENDAR_API_ACTIVE_ROUTE}/UpsertVirtualLocation`,
  campCalenderEventsDropdownList: languageCode =>
    `${CALENDAR_API_ACTIVE_ROUTE}/campCalenderEventDropdown/${languageCode}`
}
