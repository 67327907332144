/* eslint-disable */
import axios from 'axios'
import { SCRIPTURE_API_CONFIG } from '../config/scripture.api.config.js'
import { REPORT_CONFIG } from '../config/report.config.js'
import constantData from '@/json/data.json'

export const state = () => ({
  widgetData: {},
  recent_placements: [],
  upcoming_placements: [],
  all_placements: [],
  show_primary: true,
  placement_locations: [],
  placement_locations_auxiliary: [],
  locationListCSVUrl: '',
  locationListPDFUrl: '',
  detailedLocationReportUrl: '',
  locationDetail: {
    plf_org_key: null,
    org_name: '',
    org_type_key: null,
    org_type_name: '',
    camp_assigned_number: '',
    camp_org_key: null,
    where_we_should_be_flag: false,
    gideoncard_display_number: '',
    last_gideon_card_video_showing: '',
    last_gideon_card_video_showing_mtg_key: null,
    url: '',
    note: '',
    address_type_key: null,
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    state: '',
    state_key: null,
    zip: '',
    cnt_key: null,
    phone_number: '',
    phn_cnt_key: null,
    phone_number_formatted: '',
    email: '',
    location_image: null,
    last_placement_plc_key: null,
    last_placement_date: '',
    last_placement_start_time: '',
    last_placement_mtg_key: null,
    last_placement_mtg_coordinator_key: null,
    last_placement_mtg_coordinator: '',
    last_placement_weekday: '',
    last_placement_month: '',
    last_placement_day: '',
    last_placement_year: '',
    next_placement_plc_key: null,
    next_placement_date: '',
    next_placement_mtg_key: null,
    next_placement_mtg_coordinator_key: null,
    next_placement_mtg_coordinator: '',
    next_placement_weekday: '',
    next_placement_month: '',
    next_placement_day: '',
    next_placement_year: '',
    member_participation_type_key: null,
    member_participation_type: '',
    members_required: 0,
    number_of_rooms: 0,
    contact: {
      key: null,
      first_name: '',
      last_name: '',
      name: '',
      title: '',
      gender_key: null,
      gender: '',
      relationship_key: null,
      relationship: '',
      email: '',
      phone: ''
    }
  },
  emptyLocationDetail: {
    plf_org_key: null,
    org_name: '',
    org_type_key: null,
    org_type_name: '',
    camp_assigned_number: '',
    camp_org_key: null,
    where_we_should_be_flag: false,
    gideoncard_display_number: '',
    last_gideon_card_video_showing: '',
    last_gideon_card_video_showing_mtg_key: null,
    url: '',
    note: '',
    address_type_key: null,
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    state: '',
    state_key: null,
    zip: '',
    cnt_key: null,
    phone_number: '',
    phn_cnt_key: null,
    phone_number_formatted: '',
    email: '',
    location_image: null,
    last_placement_plc_key: null,
    last_placement_date: '',
    last_placement_start_time: '',
    last_placement_mtg_key: null,
    last_placement_mtg_coordinator_key: null,
    last_placement_mtg_coordinator: '',
    last_placement_weekday: '',
    last_placement_month: '',
    last_placement_day: '',
    last_placement_year: '',
    next_placement_plc_key: null,
    next_placement_date: '',
    next_placement_mtg_key: null,
    next_placement_mtg_coordinator_key: null,
    next_placement_mtg_coordinator: '',
    next_placement_weekday: '',
    next_placement_month: '',
    next_placement_day: '',
    next_placement_year: '',
    member_participation_type_key: null,
    member_participation_type: '',
    members_required: 0,
    number_of_rooms: 0,
    contact: {
      key: null,
      first_name: '',
      last_name: '',
      name: '',
      title: '',
      gender_key: null,
      gender: '',
      relationship_key: null,
      relationship: '',
      email: '',
      phone: ''
    }
  },
  locationCommunicationsData: {
    addresses: [],
    emails: [],
    phones: []
  },
  locationContacts: [],
  locationVisits: [],
  locationVisitFormOptions: {
    noteTypes: [],
    members: [],
    countriesByCamp: {
      value: constantData.USA_country,
      display_text: 'United States',
      use_state_table_flag: true
    }
  },
  locationTypeFormOptions: {
    types: []
  },
  contactRelationshipTypeFormOptions: {
    types: []
  },
  placementDateDetails: {
    placement_key: null,
    location_key: null,
    loc_name: '',
    loc_adr_key: null,
    loc_address_1: '',
    loc_address_2: '',
    loc_city: '',
    loc_state: '',
    loc_postal_code: '',
    loc_country_name: '',
    loc_country_key: '',
    coordinator_ind_key: null,
    coordinator_name: '',
    coordinator_phone: '',
    coordinator_email: '',
    member_participation_type: '',
    member_participation_type_key: null,
    members_required: 0,
    number_of_rooms: 0,
    camp_key: null,
    meeting_key: null,
    mtg_start_date: '',
    mtg_end_date: '',
    mtg_start_time: '',
    mtg_end_time: '',
    status: '',
    other_info_note: '',
    plc_participants: [],
    plc_products: [],
    delete_participants: [],
    delete_products: []
  },
  emptyPlacementDateDetails: {
    placement_key: null,
    location_key: null,
    loc_name: '',
    loc_adr_key: null,
    loc_address_1: '',
    loc_address_2: '',
    loc_city: '',
    loc_state: '',
    loc_postal_code: '',
    loc_country_name: '',
    loc_country_key: '',
    coordinator_ind_key: null,
    coordinator_name: '',
    coordinator_phone: '',
    coordinator_email: '',
    member_participation_type: '',
    member_participation_type_key: null,
    members_required: 0,
    number_of_rooms: 0,
    camp_key: null,
    meeting_key: null,
    mtg_start_date: '',
    mtg_end_date: '',
    mtg_start_time: '',
    mtg_end_time: '',
    status: '',
    other_info_note: '',
    plc_participants: [],
    plc_products: [],
    delete_participants: [],
    delete_products: []
  },
  placementFormOptions: {
    members: [],
    products: [],
    countriesByCamp: {
      value: constantData.USA_country,
      display_text: 'United States',
      use_state_table_flag: true
    }
  },
  placementItems: [],
  pastPlacementDatesAsCSVUrl: '',
  pastPlacementDatesAsPDFUrl: '',
  upcomingPlacementDatesAsCSVUrl: '',
  upcomingPlacementDatesAsPDFUrl: ''
})

export const getters = {
  topWidgetData: state => {
    return [
      {
        num: state.widgetData.ScriptureEventsLast12Mos,
        title: 'Scripture events by your camp in the past 12 months',
        subtitle: ''
      },
      {
        num: state.widgetData.MembersLast12Mos,
        title: 'Camp members participating in scripture events past 12 months',
        subtitle: ''
      }
    ]
  },
  bottomWidgetData: state => {
    return [
      {
        num: state.widgetData.ScripturesFiscalYear,
        title: 'Camp scriptures ordered this fiscal year',
        subtitle: ''
      },
      {
        num: state.widgetData.TestamentsFiscalYear,
        title: 'Camp personal workers testaments ordered fiscal year',
        subtitle: ''
      }
    ]
  },
  recent_placements: state => {
    return state.recent_placements
  },
  showPrimary: state => {
    return state.show_primary
  },
  upcoming_placements: state => {
    return state.upcoming_placements
      .map(upl => {
        if (['VBS (Educational)', 'Food Distribution'].includes(upl.institution_type)) {
          upl.facility_name += ' - ' + upl.institution_type.substring(0, 4)
        }
        return upl
      })
  },
  all_placements: state => {
    return state.all_placements
  },
  placement_locations: state => {
    return state.placement_locations
  },
  placement_locations_auxiliary: state => {
    return state.placement_locations_auxiliary
  },
  locationListCSVUrl: state => {
    return state.locationListCSVUrl
  },
  locationListPDFUrl: state => {
    return state.locationListPDFUrl
  },
  detailedLocationReportUrl: state => {
    return state.detailedLocationReportUrl
  },
  locationDetail: state => {
    let ld = state.locationDetail
    if (['VBS (Educational)', 'Food Distribution'].includes(state.locationDetail.org_type_name)) {
      ld.org_name += ' - ' + state.locationDetail.org_type_name.substring(0, 4)
    }
    return ld
  },
  emptyLocationDetail: state => {
    return state.emptyLocationDetail
  },
  locationsForSelection: state => {
    let locationSelection = state.placement_locations
      .map(spl => {
        if (['VBS (Educational)', 'Food Distribution'].includes(spl.institution_type)) {
          spl.facility_name += ' - ' + spl.institution_type.substring(0, 4)
        }
        return spl
      })
      .sort((a, b) => {
        let alpha = a.facility_name.toUpperCase()
        let beta = b.facility_name.toUpperCase()
        return alpha < beta ? -1 : alpha > beta ? 1 : 0
      })
      .map(tr => {
        return { text: tr.facility_name, value: tr.plf_org_key + '|' + tr.institution_type_key }
      })
    return locationSelection
  },
  locationCommunicationsData: state => {
    return state.locationCommunicationsData
  },
  locationContacts: state => {
    return state.locationContacts
  },
  locationVisits: state => {
    return state.locationVisits
  },
  locationVisitFormOptions: state => {
    return state.locationVisitFormOptions
  },
  convertedLocationVisitFormOptions: state => {
    let newFormOptions = {}
    newFormOptions.noteTypes = state.locationVisitFormOptions.noteTypes.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.members = state.locationVisitFormOptions.members.map(x => {
      return {
        text: x.name,
        value: x.ind_key
      }
    })
    newFormOptions.campCountry = {
      text: state.locationVisitFormOptions.countriesByCamp.display_text,
      value: state.locationVisitFormOptions.countriesByCamp.value
    }
    return newFormOptions
  },
  locationTypeFormOptions: state => {
    return state.locationTypeFormOptions
  },
  convertedLocationTypeFormOptions: state => {
    let newFormOptions = []
    newFormOptions.types = state.locationTypeFormOptions.types.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    return newFormOptions
  },
  contactRelationshipTypeFormOptions: state => {
    return state.contactRelationshipTypeFormOptions
  },
  convertedContactRelationshipTypeFormOptions: state => {
    let newFormOptions = []
    newFormOptions.types = state.contactRelationshipTypeFormOptions.types.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    return newFormOptions
  },
  placementDateDetails: state => {
    let pdd = state.placementDateDetails
    if (['VBS (Educational)', 'Food Distribution'].includes(pdd.loc_org_type_name)) {
      pdd.loc_name += ' - ' + pdd.loc_org_type_name.substring(0, 4)
    }
    return pdd
  },
  emptyPlacementDateDetails: state => {
    return state.emptyPlacementDateDetails
  },
  placementFormOptions: state => {
    return state.placementFormOptions
  },
  convertedPlacementFormOptions: state => {
    let newFormOptions = []
    newFormOptions.members = state.placementFormOptions.members.map(x => {
      return {
        text: x.name,
        value: x.ind_key
      }
    })
    newFormOptions.products = state.placementFormOptions.products.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.campCountry = {
      text: state.placementFormOptions.countriesByCamp.display_text,
      value: state.placementFormOptions.countriesByCamp.value
    }
    return newFormOptions
  },
  placementItems: state => {
    return state.placementItems
  },
  pastPlacementDatesAsCSVUrl: state => {
    return state.pastPlacementDatesAsCSVUrl
  },
  pastPlacementDatesAsPDFUrl: state => {
    return state.pastPlacementDatesAsPDFUrl
  },
  upcomingPlacementDatesAsPDFUrl: state => {
    return state.upcomingPlacementDatesAsPDFUrl
  },
  upcomingPlacementDatesAsCSVUrl: state => {
    return state.upcomingPlacementDatesAsCSVUrl
  }
}

export const WIDGET_LIMIT = 3
export const SET_WIDGET_DATA = 'SET_WIDGET_DATA'
export const SET_RECENT_PLACEMENTS = 'SET_RECENT_PLACEMENTS'
export const SET_UPCOMING_PLACEMENTS = 'SET_UPCOMING_PLACEMENTS'
export const SET_PLACEMENT_LOCATIONS = 'SET_PLACEMENT_LOCATIONS'
export const SET_PLACEMENT_LOCATIONS_AUXILIARY = 'SET_PLACEMENT_LOCATIONS_AUXILIARY'
export const SET_PLACEMENT_DATES = 'SET_PLACEMENT_DATES'
export const SET_ALL_PLACEMENT_DATES = 'SET_ALL_PLACEMENT_DATES'
export const SET_PLACEMENT_DATE_DETAILS = 'SET_PLACEMENT_DATE_DETAILS'
export const SET_PLACEMENT_FORM_OPTIONS = 'SET_PLACEMENT_FORM_OPTIONS'
export const SET_PLACEMENT_ITEMS = 'SET_PLACEMENT_ITEMS'
export const SET_LOCATION_DETAILS = 'SET_LOCATION_DETAILS'
export const CLEAR_LOCATION_DETAILS = 'CLEAR_LOCATION_DETAILS'
export const CLEAR_PLACEMENT_DATE_DETAILS = 'CLEAR_PLACEMENT_DATE_DETAILS'
export const SET_LOCATION_COMMUNICATIONS_DATA = 'SET_LOCATION_COMMUNICATIONS_DATA'
export const SET_LOCATION_CONTACTS_DATA = 'SET_LOCATION_CONTACTS_DATA'
export const SET_LOCATION_VISITS = 'SET_LOCATION_VISITS'
export const SET_LOCATION_VISIT_FORM_OPTIONS = 'SET_LOCATION_VISIT_FORM_OPTIONS'
export const SET_LOCATION_TYPE_FORM_OPTIONS = 'SET_LOCATION_TYPE_FORM_OPTIONS'
export const SET_CONTACT_RELATIONSHIP_TYPE_FORM_OPTIONS = 'SET_CONTACT_RELATIONSHIP_TYPE_FORM_OPTIONS'
export const SET_LOCATION_LIST_CSV_URL = 'SET_LOCATION_LIST_CSV_URL'
export const SET_LOCATION_LIST_PDF_URL = 'SET_LOCATION_LIST_PDF_URL'
export const SET_DETAILED_LOCATION_REPORT_URL = 'SET_DETAILED_LOCATION_REPORT_URL'
export const SET_PAST_PLACEMENT_DATES_CSV_URL = 'SET_PAST_PLACEMENT_DATES_CSV_URL'
export const SET_PAST_PLACEMENT_DATES_PDF_URL = 'SET_PAST_PLACEMENT_DATES_PDF_URL'
export const SET_SHOW_PRIMARY = 'SET_SHOW_PRIMARY'
export const SET_UPCOMING_PLACEMENT_DATES_CSV_URL = 'SET_UPCOMING_PLACEMENT_DATES_CSV_URL'
export const SET_UPCOMING_PLACEMENT_DATES_PDF_URL = 'SET_UPCOMING_PLACEMENT_DATES_PDF_URL'

export const mutations = {
  [SET_CONTACT_RELATIONSHIP_TYPE_FORM_OPTIONS](state, data) {
    state.contactRelationshipTypeFormOptions.types = data.noteTypes
  },
  [SET_WIDGET_DATA](state, data) {
    state.widgetData = data
  },
  [SET_RECENT_PLACEMENTS](state, data) {
    state.recent_placements = data
  },
  [SET_UPCOMING_PLACEMENTS](state, data) {
    state.upcoming_placements = data
  },
  [SET_PLACEMENT_LOCATIONS](state, data) {
    state.placement_locations = data
  },
  [SET_PLACEMENT_LOCATIONS_AUXILIARY](state, data) {
    state.placement_locations_auxiliary = data
  },
  [SET_LOCATION_DETAILS](state, data) {
    let state_postal_code = data.state_postal_code ? data.state_postal_code.split(' ') : ['', '']

    const ignoreFields = ({
      state_postal_code,
      org_ind_key,
      contact_name,
      contact_relationship,
      contact_email,
      contact_phone,
      image_url,
      ...rest
    }) => rest

    state.locationDetail = {
      ...ignoreFields(data),
      location_image: data.image_url,
      state: state_postal_code[0],
      zip: state_postal_code[1],
      contact: {
        key: data.org_ind_key,
        name: data.contact_name,
        relationship: data.contact_relationship,
        email: data.contact_email,
        phone: data.contact_phone
      }
    }
  },
  [CLEAR_LOCATION_DETAILS](state, data) {
    state.locationDetail = {
      plf_org_key: null,
      org_name: '',
      org_type_key: null,
      org_type_name: '',
      camp_assigned_number: '',
      where_we_should_be_flag: false,
      gideoncard_display_number: '',
      last_gideon_card_video_showing: '',
      last_gideon_card_video_showing_mtg_key: null,
      url: '',
      note: '',
      address_type_key: null,
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      city: '',
      state: '',
      state_key: null,
      zip: '',
      cnt_key: null,
      phone_number: '',
      phn_cnt_key: null,
      phone_number_formatted: '',
      email: '',
      location_image: null,
      last_placement_plc_key: null,
      last_placement_date: '',
      last_placement_start_time: '',
      last_placement_mtg_key: null,
      last_placement_mtg_coordinator_key: null,
      last_placement_mtg_coordinator: '',
      last_placement_weekday: '',
      last_placement_month: '',
      last_placement_day: '',
      last_placement_year: '',
      next_placement_plc_key: null,
      next_placement_date: '',
      next_placement_mtg_key: null,
      next_placement_mtg_coordinator_key: null,
      next_placement_mtg_coordinator: '',
      next_placement_weekday: '',
      next_placement_month: '',
      next_placement_day: '',
      next_placement_year: '',
      member_participation_type_key: null,
      member_participation_type: '',
      members_required: 0,
      number_of_rooms: 0,
      contact: {
        key: null,
        first_name: '',
        last_name: '',
        name: '',
        title: '',
        gender_key: null,
        gender: '',
        relationship_key: null,
        relationship: '',
        email: '',
        phone: ''
      }
    }
  },
  [CLEAR_PLACEMENT_DATE_DETAILS](state, data) {
    state.placementDateDetails = {
      placement_key: null,
      location_key: null,
      loc_name: '',
      loc_adr_key: null,
      loc_address_1: '',
      loc_address_2: '',
      loc_city: '',
      loc_state: '',
      loc_postal_code: '',
      loc_country_name: '',
      loc_org_type_name: '',
      loc_country_key: '',
      coordinator_ind_key: null,
      coordinator_name: '',
      coordinator_phone: '',
      coordinator_email: '',
      member_participation_type: '',
      member_participation_type_key: null,
      members_required: 0,
      number_of_rooms: 0,
      camp_key: null,
      meeting_key: null,
      mtg_start_date: '',
      mtg_end_date: '',
      mtg_start_time: '',
      mtg_end_time: '',
      status: '',
      other_info_note: '',
      plc_participants: [],
      plc_products: [],
      delete_participants: [],
      delete_products: []
    }
  },
  [SET_LOCATION_COMMUNICATIONS_DATA](state, data) {
    state.locationCommunicationsData = data
    // item_description is for anything of the ServiceCard type
    state.locationCommunicationsData.addresses.map(ad => {
      ad.item_description = 'address'
    })
    state.locationCommunicationsData.phones.map(ph => {
      ph.item_description = 'phone number'
    })
    state.locationCommunicationsData.emails.map(ph => {
      ph.item_description = 'email address'
    })
  },
  [SET_LOCATION_CONTACTS_DATA](state, data) {
    state.locationContacts = []
    if (!!data && data.length > 0) {
      state.locationContacts = data
      state.locationContacts.map(c => {
        c.item_description = 'contact'
      })
    }
  },
  [SET_SHOW_PRIMARY](state, data) {
    state.show_primary = data
  },
  [SET_LOCATION_VISITS](state, data) {
    state.locationVisits = data
  },
  [SET_LOCATION_VISIT_FORM_OPTIONS](state, data) {
    state.locationVisitFormOptions = data
  },
  [SET_LOCATION_TYPE_FORM_OPTIONS](state, data) {
    state.locationTypeFormOptions.types = data.noteTypes
  },
  [SET_PLACEMENT_DATES](state, data) {
    ; (state.recent_placements = data.recentPlacements), (state.upcoming_placements = data.upcomingPlacements)
  },
  [SET_ALL_PLACEMENT_DATES](state, data) {
    state.all_placements = data
  },
  [SET_PLACEMENT_DATE_DETAILS](state, data) {
    state.placementDateDetails = data
  },
  [SET_PLACEMENT_FORM_OPTIONS](state, data) {
    state.placementFormOptions = data
  },
  [SET_PLACEMENT_ITEMS](state, data) {
    state.placementItems = data
  },
  [SET_LOCATION_LIST_CSV_URL](state, data) {
    state.locationListCSVUrl = data
  },
  [SET_DETAILED_LOCATION_REPORT_URL](state, data) {
    state.detailedLocationReportUrl = data
  },
  [SET_LOCATION_LIST_PDF_URL](state, data) {
    state.locationListPDFUrl = data
  },
  [SET_PAST_PLACEMENT_DATES_CSV_URL](state, data) {
    state.pastPlacementDatesAsCSVUrl = data
  },
  [SET_PAST_PLACEMENT_DATES_PDF_URL](state, data) {
    state.pastPlacementDatesAsPDFUrl = data
  },
  [SET_UPCOMING_PLACEMENT_DATES_CSV_URL](state, data) {
    state.upcomingPlacementDatesAsCSVUrl = data
  },
  [SET_UPCOMING_PLACEMENT_DATES_PDF_URL](state, data) {
    state.upcomingPlacementDatesAsPDFUrl = data
  }
}

export const actions = {
  async loadWidgetData({ commit, rootGetters }) {
    try {
      let d = new Date()
      let year = d.getFullYear()
      let fiscal_year = d.getMonth() <= 5 ? year : year + 1
      let response = await apiCalls.loadWidgetData(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey'],
        fiscal_year,
        WIDGET_LIMIT
      )
      if (response && response.status === 200) {
        await commit('SET_WIDGET_DATA', response.data.counts)
        await commit('SET_RECENT_PLACEMENTS', response.data.recentPlacements)
        await commit('SET_UPCOMING_PLACEMENTS', response.data.upcomingPlacements)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPlacementLocationsByCamp({ commit, rootGetters }, memberTypeKey) {
    try {
      let response = await apiCalls.getPlacementLocationsByCamp(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey'],
        memberTypeKey
      )
      if (response && response.status === 200) {
        await commit('SET_PLACEMENT_LOCATIONS', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPlacementLocationsByCampForAuxiliary({ commit, rootGetters }, memberTypeKey) {
    try {
      let response = await apiCalls.getPlacementLocationsByCampForAuxiliary(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey'],
        memberTypeKey
      )
      if (response && response.status === 200) {
        await commit('SET_PLACEMENT_LOCATIONS_AUXILIARY', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPlacementLocationsByCampAsCSVUrl({ commit, rootGetters }, memberTypeKey) {
    try {
      let csvUrl = reportCalls.getLocationListReportAsCSVUrl(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey'],
        memberTypeKey
      )
      await Promise.all([commit('SET_LOCATION_LIST_CSV_URL', csvUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },

  async loadPlacementDetailedLocationReportUrl({ commit, rootGetters }, org_key) {
    try {
      let pdfUrl = reportCalls.getDetailedLocationReportUrl(org_key, rootGetters['user/userLanguageKey'])
      await Promise.all([commit('SET_DETAILED_LOCATION_REPORT_URL', pdfUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPlacementLocationsByCampAsPDFUrl({ commit, rootGetters }, memberTypeKey) {
    try {
      let pdfUrl = reportCalls.getLocationListReportAsPDFUrl(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey'],
        memberTypeKey
      )
      await Promise.all([commit('SET_LOCATION_LIST_PDF_URL', pdfUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPlacementDatesByCampLast12({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getPlacementDatesByCampLast12(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_PLACEMENT_DATES', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadAllPlacementDatesByLocation({ commit, rootGetters }, loc_key) {
    try {
      let response = await apiCalls.getAllPlacementDatesByLocation(loc_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_ALL_PLACEMENT_DATES', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPlacementFormOptions({ commit, rootGetters }, memberTypeKey) {
    try {
      let response = await apiCalls.getPlacementFormOptions(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey'],
        memberTypeKey
      )
      if (response && response.status === 200) {
        await commit('SET_PLACEMENT_FORM_OPTIONS', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPlacementItems({ commit, rootGetters }, loc_key) {
    try {
      let response = await apiCalls.getPlacementItems(loc_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_PLACEMENT_ITEMS', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadUpcomingPlacementDatesPDFUrl({ commit, rootGetters }) {
    try {
      let pdfUrl = reportCalls.getUpcomingPlacementDatesAsPDFUrl(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      await Promise.all([commit('SET_UPCOMING_PLACEMENT_DATES_PDF_URL', pdfUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadUpcomingPlacementDatesCSVUrl({ commit, rootGetters }) {
    try {
      let csvUrl = reportCalls.getUpcomingPlacementDatesAsCSVUrl(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      await Promise.all([commit('SET_UPCOMING_PLACEMENT_DATES_CSV_URL', csvUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPastPlacementDatesPDFUrl({ commit, rootGetters }) {
    try {
      let pdfUrl = reportCalls.getPastPlacementDatesAsPDFUrl(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      await Promise.all([commit('SET_PAST_PLACEMENT_DATES_PDF_URL', pdfUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPastPlacementDatesCSVUrl({ commit, rootGetters }) {
    try {
      let csvUrl = reportCalls.getPastPlacementDatesAsCSVUrl(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      await Promise.all([commit('SET_PAST_PLACEMENT_DATES_CSV_URL', csvUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadLocationDetails({ commit, rootGetters }, pkg) {
    try {
      let response = !!pkg.org_type_key ?
        await apiCalls.getLocationsDetails(pkg.org_key, rootGetters['user/userLanguageKey'], pkg.org_type_key) :
        await apiCalls.getLocationsDetailsNoType(pkg.org_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_LOCATION_DETAILS', response.data)
      } else {
        throw 'bad response from loadLocationDetails call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  clearLocationDetails: ({ commit }) => {
    commit('CLEAR_LOCATION_DETAILS')
  },
  async getLocationCommunications({ commit, rootGetters }, entity_key) {
    try {
      let response = await apiCalls.getLocationCommunications(entity_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_LOCATION_COMMUNICATIONS_DATA', response.data)
      } else {
        throw 'bad response from getLocationCommunications call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadLocationContacts({ commit, rootGetters }, org_key) {
    try {
      let response = await apiCalls.getLocationContacts(org_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_LOCATION_CONTACTS_DATA', response.data)
      } else {
        throw 'bad response from getLocationContacts call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadLocationVisits({ commit, rootGetters }, entity_key) {
    try {
      let response = await apiCalls.getLocationVisits(entity_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_LOCATION_VISITS', response.data)
      } else {
        throw 'bad response from getLocationVisits call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadLocationVisitOptions({ commit, rootGetters }, camp_mpt_keys) {
    try {
      let mpt_key = camp_mpt_keys.mpt_key === '80a3afcf-4def-4ccc-85b2-96284f978027' ? 'null' : camp_mpt_keys.mpt_key // check if the type is "both", if so just pass 'null'
      let response = await apiCalls.loadLocationVisitOptions(
        'PlacementFacilityNoteType',
        rootGetters['user/userLanguageKey'],
        camp_mpt_keys.camp_key,
        mpt_key
      )
      if (response && response.status === 200) {
        await commit('SET_LOCATION_VISIT_FORM_OPTIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadLocationTypeOptions({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadLocationTypeOptions(
        'PlacementFacilityType',
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_LOCATION_TYPE_FORM_OPTIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPlacementContactRelationshipTypeOptions({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadLocationTypeOptions(
        'PlacementContactRelationship',
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_CONTACT_RELATIONSHIP_TYPE_FORM_OPTIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPlacementDateDetails({ commit, rootGetters }, mtg_key) {
    try {
      let response = await apiCalls.loadPlacementDateDetails(mtg_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_PLACEMENT_DATE_DETAILS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addLocation({ rootGetters }, location) {
    try {
      let response = await apiCalls.addLocation(rootGetters['user/userId'], rootGetters['user/userCampKey'], location)
      if (response && response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addLocationContact({ }, indixo) {
    try {
      let response = await apiCalls.addLocationContact(indixo)
      return response && response.status === 200
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addPlacementDate({ rootGetters }, payload) {
    try {
      let response = await apiCalls.addPlacementDate(rootGetters['user/userId'], payload)
      if (response && response.status === 200) {
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addScriptureLocation({ rootGetters }, location) {
    try {
      let response = await apiCalls.addScriptureLocation(rootGetters['user/userId'], location)
      if (response && response.status === 200) {
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deletePlacementDate({ }, payload) {
    try {
      let response = await apiCalls.deletePlacementDate(payload.mtg_key, payload.mtg_user)
      if (response && response.status === 200) {
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deleteLocation({ }, payload) {
    try {
      let response = await apiCalls.deleteLocation(payload.loc_key, payload.user)
      if (response && response.status === 200) {
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async clearPlacementDateDetails({ commit }) {
    await commit('CLEAR_PLACEMENT_DATE_DETAILS')
  },
  async editPlacementItems({ rootGetters }, payload) {
    try {
      let response = await apiCalls.editPlacementItem(rootGetters['user/userId'], payload)
      if (response && response.status === 200) {
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async savePhoto({ }, photoInfo) {
    try {
      let response = await apiCalls.savePhoto(photoInfo)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
        return false
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadUpcomingAuxiliaryPlacementDatesPDFUrl({ commit, rootGetters }) {
    try {
      let pdfUrl = reportCalls.getUpcomingAuxiliaryPlacementDatesAsPDFUrl(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      await Promise.all([commit('SET_UPCOMING_PLACEMENT_DATES_PDF_URL', pdfUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadUpcomingAuxiliaryPlacementDatesCSVUrl({ commit, rootGetters }) {
    try {
      let csvUrl = reportCalls.getUpcomingAuxiliaryPlacementDatesAsCSVUrl(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      await Promise.all([commit('SET_UPCOMING_PLACEMENT_DATES_CSV_URL', csvUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  setShowPrimary({ commit }, val) {
    try {
      commit('SET_SHOW_PRIMARY', val)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

const apiCalls = {
  loadWidgetData(campKey, languageKey, fiscal_year, limit) {
    const route = SCRIPTURE_API_CONFIG.getScriptureDistributionWidgetData(campKey, languageKey, fiscal_year, limit)
    return axios.get(route)
  },
  getPlacementLocationsByCamp(campKey, languageKey, memberTypeKey) {
    const route = SCRIPTURE_API_CONFIG.getPlacementLocationsByCamp(campKey, languageKey, memberTypeKey)
    return axios.get(route)
  },
  getPlacementLocationsByCampForAuxiliary(campKey, languageKey, memberTypeKey) {
    const route = SCRIPTURE_API_CONFIG.getPlacementLocationsByCampForAuxiliary(campKey, languageKey, memberTypeKey)
    return axios.get(route)
  },
  getLocationsDetails(orgKey, languageKey, orgTypeKey) {
    const route = SCRIPTURE_API_CONFIG.getLocationsDetails(orgKey, languageKey, orgTypeKey)
    return axios.get(route)
  },
  getLocationsDetailsNoType(orgKey, languageKey) {
    const route = SCRIPTURE_API_CONFIG.getLocationsDetailsNoType(orgKey, languageKey)
    return axios.get(route)
  },
  getLocationCommunications(entity_key, lng_key) {
    const route = SCRIPTURE_API_CONFIG.getLocationCommunications(entity_key, lng_key)
    return axios.get(route)
  },
  getLocationContacts(org_key, lng_key) {
    const route = SCRIPTURE_API_CONFIG.getLocationContacts(org_key, lng_key)
    return axios.get(route)
  },
  getLocationVisits(entity_key, lng_key) {
    const route = SCRIPTURE_API_CONFIG.getLocationVisits(entity_key, lng_key)
    return axios.get(route)
  },
  getPlacementDatesByCampLast12(campKey, languageKey) {
    const route = SCRIPTURE_API_CONFIG.getPlacementDatesByCampLast12(campKey, languageKey)
    return axios.get(route)
  },
  getAllPlacementDatesByLocation(org_key, languageKey) {
    const route = SCRIPTURE_API_CONFIG.getAllPlacementDatesByLocation(org_key, languageKey)
    return axios.get(route)
  },
  getPlacementFormOptions(campKey, languageKey, memberTypeKey) {
    const route = SCRIPTURE_API_CONFIG.getPlacementFormOptions(campKey, languageKey, memberTypeKey)
    return axios.get(route)
  },
  getPlacementItems(org_key, languageKey) {
    const route = SCRIPTURE_API_CONFIG.getPlacementItems(org_key, languageKey)
    return axios.get(route)
  },
  loadLocationVisitOptions(entity_key, lng_key, cmp_key, mpt_key) {
    const route = SCRIPTURE_API_CONFIG.getLocationVisitOptions(entity_key, lng_key, cmp_key, mpt_key)
    return axios.get(route)
  },
  loadLocationTypeOptions(entity_key, lng_key) {
    const route = SCRIPTURE_API_CONFIG.getLocationTypeOptions(entity_key, lng_key)
    return axios.get(route)
  },
  loadPlacementDateDetails(mtg_key, lng_key) {
    const route = SCRIPTURE_API_CONFIG.getSinglePlacementDetails(mtg_key, lng_key)
    return axios.get(route)
  },
  addLocation(ind_key, cmp_key, location) {
    let formData = new FormData()
    for (var itm in location) {
      if (location[itm] !== null) {
        formData.append(itm, location[itm])
      }
    }
    const route = SCRIPTURE_API_CONFIG.addLocation(ind_key, cmp_key)
    return axios.post(route, formData)
  },
  addLocationContact(indixo) {
    const route = SCRIPTURE_API_CONFIG.addLocationContact()
    return axios.post(route, indixo)
  },
  addPlacementDate(ind_key, plc) {
    const route = SCRIPTURE_API_CONFIG.addPlacementDate(ind_key)
    return axios.post(route, plc)
  },
  addScriptureLocation(ind_key, location) {
    const route = SCRIPTURE_API_CONFIG.addScriptureLocation(ind_key)
    return axios.post(route, location)
  },
  deletePlacementDate(mtg_key, mtg_user) {
    const route = SCRIPTURE_API_CONFIG.deletePlacementDate(mtg_key, mtg_user)
    return axios.post(route)
  },
  deleteLocation(loc_key, user) {
    const route = SCRIPTURE_API_CONFIG.deleteLocation(loc_key, user)
    return axios.post(route)
  },
  editPlacementItem(ind_key, items) {
    const route = SCRIPTURE_API_CONFIG.editPlacementItem(ind_key)
    return axios.post(route, items)
  },
  savePhoto(photoInfo) {
    let formData = new FormData()
    for (var itm in photoInfo) {
      if (photoInfo[itm] !== null) {
        formData.append(itm, photoInfo[itm])
      }
    }
    const route = SCRIPTURE_API_CONFIG.upsertLocationPhoto(photoInfo.iim_org_key)
    return axios.post(route, formData)
  }
}

const reportCalls = {
  getDetailedLocationReportUrl(org_key, lng_key) {
    const route = REPORT_CONFIG.placementLocationDetailReport(org_key, lng_key, 'pdf')
    return route
  },
  getLocationListReportAsCSVUrl(camp_key, lng_key, mpt_key) {
    const route = REPORT_CONFIG.placementLocationsReport(camp_key, lng_key, mpt_key, 'excel')
    return route
  },
  getLocationListReportAsPDFUrl(camp_key, lng_key, mpt_key) {
    const route = REPORT_CONFIG.placementLocationsReport(camp_key, lng_key, mpt_key, 'pdf')
    return route
  },
  getDetaledLocationReportUrl(camp_key, lng_key, mpt_key) {
    const route = REPORT_CONFIG.placementLocationsReport(camp_key, lng_key, mpt_key, 'pdf')
    return route
  },
  getPastPlacementDatesAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.pastPlacementDates(camp_key, lng_key, 'excel')
    return route
  },
  getPastPlacementDatesAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.pastPlacementDates(camp_key, lng_key, 'pdf')
    return route
  },
  getUpcomingPlacementDatesAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.upcomingPlacementDates(camp_key, lng_key, 'excel')
    return route
  },
  getUpcomingPlacementDatesAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.upcomingPlacementDates(camp_key, lng_key, 'pdf')
    return route
  },
  getUpcomingAuxiliaryPlacementDatesAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.upcomingAuxiliaryPlacementDates(camp_key, lng_key, 'excel')
    return route
  },
  getUpcomingAuxiliaryPlacementDatesAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.upcomingAuxiliaryPlacementDates(camp_key, lng_key, 'pdf')
    return route
  }
}

export const scriptureDistribution = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
