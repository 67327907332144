/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'
import { REPORT_CONFIG } from '../config/report.config.js'

export const state = () => ({
  returnedRows: 6,
  searchTerm: '',
  selectedTestimonyKey: '0',
  testimonies: [],
  testimonyReportURL: '',
  testimonySummary: [],
  testimonyTypes: []
})

export const getters = {
  testimonies: state => {
    return state.testimonies
  },
  testimonySummary: state => {
    return state.testimonySummary
  },
  testimonyKey: state => {
    return state.selectedTestimonyKey
  },
  testimonyTypes: state => {
    return state.testimonyTypes.map(tt => {
      return { text: tt.display_text, value: tt.tst_key }
    })
  },
  testimonyUrl: state => {
    return state.testimonyReportURL
  },
  searchTerm: state => state.searchTerm,
  returnedRows: state => state.returnedRows
}

export const SET_RETURNED_ROWS = 'SET_RETURNED_ROWS'
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const SET_SELECTED_TESTIMONY_KEY = 'SET_SELECTED_TESTIMONY_KEY'
export const SET_TESTIMONIES = 'SET_TESTIMONIES'
export const SET_TESTIMONIES_FROM_SEARCH = 'SET_TESTIMONIES_FROM_SEARCH'
export const SET_TESTIMONY_SUMMARY = 'SET_TESTIMONY_SUMMARY'
export const SET_TESTIMONY_TYPES = 'SET_TESTIMONY_TYPES'
export const SET_TESTIMONY_URL = 'SET_TESTIMONY_URL'

export const mutations = {
  [SET_RETURNED_ROWS](state, data) {
    state.returnedRows = data
  },
  [SET_SEARCH_TERM](state, data) {
    state.searchTerm = data
  },
  [SET_SELECTED_TESTIMONY_KEY](state, data) {
    state.selectedTestimonyKey = data
  },
  async [SET_TESTIMONIES](state, data) {
    if (!!data.article) {
      state.testimonies = data.article.map(ss => {
        const dend = ss.description.length > 247 ? '...' : ''
        ss.desc = ss.description.padEnd(250).substr(0, 247) + dend
        const tend = ss.title.length > 62 ? '...' : ''
        ss.ttl = ss.title.padEnd(62).substr(0, 62) + tend
        return ss
      })
    }
  },
  [SET_TESTIMONIES_FROM_SEARCH](state, data) {
    if (!!data) {
      state.testimonies = data.map(ss => {
        const dend = ss.description.length > 247 ? '...' : ''
        ss.desc = ss.description.padEnd(250).substr(0, 247) + dend
        const tend = ss.title.length > 62 ? '...' : ''
        ss.ttl = ss.title.padEnd(62).substr(0, 62) + tend
        return ss
      })
    }
  },
  [SET_TESTIMONY_SUMMARY](state, data) {
    state.testimonySummary = data
  },
  [SET_TESTIMONY_TYPES](state, data) {
    state.testimonyTypes = data
  },
  [SET_TESTIMONY_URL](state, data) {
    state.testimonyReportURL = data
  },
}

export const actions = {
  async getTestimonies({ commit }, pkg) {
    try {
      let response = !!pkg.selected_key
        ? await apiCalls.getTestimonies('en', pkg.rows, pkg.selected_key)
        : await apiCalls.getTestimoniesNoKey('en', pkg.rows)
      if (response && response.status === 200) {
        await Promise.all([commit('SET_TESTIMONIES', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - testimonies were not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getSpecificTestimony({ commit }, art_key) {
    try {
      let response = await apiCalls.getTestimonies('en', 4, art_key)
      if (response && response.status === 200) {
        await Promise.all([commit('SET_TESTIMONIES', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - testimonies were not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getFilteredTestimonies({ commit }, pkg) {
    try {
      let response = await apiCalls.getFilteredTestimonies(
        'en',
        pkg.rows,
        pkg.search,
        pkg.filter
      )
      if (response && response.status === 200) {
        await Promise.all([
          commit('SET_TESTIMONIES', !!response.data.article ? response.data : { article: response.data })
        ]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - testimonies were not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getMoreFilteredTestimonies({ commit }, key_rows) {
    try {
      let response = await apiCalls.getFilteredTestimonies(
        'en',
        key_rows.rows,
        key_rows.search,
        key_rows.filter
      )
      if (response && response.status === 200) {
        await Promise.all([commit('SET_TESTIMONIES', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - testimonies were not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getTestimonyTypes({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getTestimonyTypes(rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await Promise.all([commit('SET_TESTIMONY_TYPES', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - testimonies were not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getTestimonyUrl({ commit, rootGetters }, art_key) {
    let doc = reportCalls.getTestimonyUrl(art_key, rootGetters['user/userLanguageKey'])
    await Promise.all([
      commit('SET_TESTIMONY_URL', doc)
    ])
    return true
  },
  async saveTestimony({ }, payload) {
    try {
      let response = await apiCalls.saveTestimony(payload)
      if (response && response.status === 200) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async searchArticles({ commit }, searchCriteria) {
    try {
      let response = await apiCalls.searchArticles(searchCriteria)
      if (response && response.status === 200) {
        await Promise.all([commit('SET_TESTIMONIES_FROM_SEARCH', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - testimonies were not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async setSelectedTestimonyKey({ commit }, key) {
    await commit('SET_SELECTED_TESTIMONY_KEY', key)
  },
  setSearchTerm({ commit }, q) {
    commit(SET_SEARCH_TERM, q)
  },
  setReturnedRows({ commit }, q) {
    commit(SET_RETURNED_ROWS, q)
  }
}

const apiCalls = {
  getTestimonies(lng_key, rows, key) {
    const route = API_CONFIG.getTestimonies(lng_key, rows, key)
    return axios.get(route)
  },
  getTestimoniesNoKey(lng_key, rows) {
    const route = API_CONFIG.getTestimoniesNoKey(lng_key, rows)
    return axios.get(route)
  },
  getFilteredTestimonies(lang, rows, search, filter) {
    const route = API_CONFIG.getFilteredTestimonies(lang, rows, search || 'null', filter)
    return axios.get(route)
  },
  getTestimonyWithKey(art_key, lng_key) {
    const route = API_CONFIG.getTestimonyWithKey(art_key, lng_key)
    return axios.get(route)
  },
  getTestimonyTypes(lng_key) {
    const route = API_CONFIG.getTestimonyTypes(lng_key)
    return axios.get(route)
  },
  saveTestimony(payload) {
    const route = API_CONFIG.saveTestimony()
    return axios.post(route, payload)
  },
  searchArticles(search_criteria) {
    const route = API_CONFIG.searchArticles(
      search_criteria.search_for,
      search_criteria.art_type,
      search_criteria.rows,
      search_criteria.lng_key
    )
    return axios.get(route)
  }
}

const reportCalls = {
  getTestimonyUrl() {
    return REPORT_CONFIG.getTestimonyUrl()
  }
}

export const testimony = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
