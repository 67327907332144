/* eslint-disable */
import axios from 'axios'

const API_ACTIVE_ROUTE = process.env.VUE_APP_MEMBERSHIP_APP
const CANDIDATE_API_CONFIG = {
  createCandidateInfo: () => `${API_ACTIVE_ROUTE}/candidate/information/create`
}

export const CREATE_CANDIDATE_INFO_HTTP = 'CREATE_CANDIDATE_INFO_HTTP'
export const SET_CANDIDATE_THANKYOU_NAME = 'SET_CANDIDATE_THANKYOU_NAME'

const apiCalls = {
  createCandidateInfo(data) {
    const route = CANDIDATE_API_CONFIG.createCandidateInfo(data)
    return axios.post(route, data)
  }
}

const actions = {
  async createCandidateInfo({ commit }, mCandidateInfo) {
    try {
      const response = await apiCalls.createCandidateInfo(mCandidateInfo)
      if (response && response.status === 200) {
        await commit('SET_CANDIDATE_THANKYOU_NAME', mCandidateInfo.firstName + ' ' + mCandidateInfo.lastName)
        await commit('CREATE_CANDIDATE_INFO_HTTP', mCandidateInfo)
        return true
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

export const state = () => ({
  CandidateInfo: {},
  validatedCandidate: [],
  pdfFile: {},
  status: {},
  httpMessage: {},
  token: '',
  CandidateAll: {},
  CandidateThankYouName: ''
})

export const getters = {
  CandidateInfo: state => {
    return state.CandidateInfo
  },
  CandidateThankYouName: state => {
    return state.CandidateThankYouName
  }
}
export const mutations = {
  [CREATE_CANDIDATE_INFO_HTTP](state, data) {
    state.CandidateInfo = data
  },
  [SET_CANDIDATE_THANKYOU_NAME](state, data) {
    state.CandidateThankYouName = data
  }
}

export const candidateModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
