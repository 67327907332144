/* eslint-disable */
export const CONVERSATIONS_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/conversations`
export const CHURCH_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/church`

export const CONVERSATIONS_API_CONFIG = {
  addInstructorEU: () => `${CHURCH_API_ACTIVE_ROUTE}/presenter/add/edit`,
  addNonMember: () => `${CONVERSATIONS_API_ACTIVE_ROUTE}/UpsertNonMember`,
  deleteConversation: (cvs_key, mtg_user) =>
    `${CONVERSATIONS_API_ACTIVE_ROUTE}/delete/${cvs_key}/${mtg_user}`,
  exportConversationHistory: org_key => `${CONVERSATIONS_API_ACTIVE_ROUTE}/exporthistory/${org_key}`,
  getCountryByOFTB: org_key => `${CONVERSATIONS_API_ACTIVE_ROUTE}/CountryByOFTB/${org_key}`,
  getConversationDetails: cvs_key => `${CONVERSATIONS_API_ACTIVE_ROUTE}/details/${cvs_key}`,
  getConversationDetailsByMeetingKey: mtg_key => `${CONVERSATIONS_API_ACTIVE_ROUTE}/detailsbymeeting/${mtg_key}`,
  getConversationsHistory: (ind_key) => `${CONVERSATIONS_API_ACTIVE_ROUTE}/individualhistory/${ind_key}`,
  getConversationsList: (org_key, start_date, end_date) => `${CONVERSATIONS_API_ACTIVE_ROUTE}/listbyorg/${org_key}?start_date=${start_date}&end_date=${end_date}`,
  getConversationWidgetData: (org_key, row_count) => `${CONVERSATIONS_API_ACTIVE_ROUTE}/GetConversationWidgetData/${org_key}/${row_count}`,
  getEducationalUnitsByState: (state_key, lng_key) =>
    `${CONVERSATIONS_API_ACTIVE_ROUTE}/eu/all/${state_key}/${lng_key}`,
  getInstructorsByState: (state_key, lng_key) =>
    `${CONVERSATIONS_API_ACTIVE_ROUTE}/instructors/all/${state_key}/${lng_key}`,
  getMeetingTypes: (type, lng_key) => `${CONVERSATIONS_API_ACTIVE_ROUTE}/meeting-types/${type}/${lng_key}`,
  getMembersByStateAndType: (state_key, lng_key, membertype_key) =>
    `${CONVERSATIONS_API_ACTIVE_ROUTE}/MembersListByType/${state_key}/${lng_key}/${membertype_key}`,
  saveConversation: ind_key => `${CONVERSATIONS_API_ACTIVE_ROUTE}/save/${ind_key}`
}
