/* eslint-disable */
import axios from 'axios'
import { USA_BLITZ_API_CONFIG } from '../config/usascriptureblitz.api.config.js'

export const state = () => ({
  usa_blitzes: [],
  usa_blitz_info: {},

})

export const getters = {
  usa_blitzes: state => {
    return state.usa_blitzes
  },
  usa_blitz_info: state => {
    return state.usa_blitz_info
  },

}

export const SET_BLITZ_INFO = 'SET_BLITZ_INFO'
export const SET_BLITZES = 'SET_BLITZES'

export const mutations = {
  [SET_BLITZES](state, data) {
    state.usa_blitzes = data
  },
  [SET_BLITZ_INFO](state, data) {
    state.usa_blitz_info = data
  }

}

export const actions = {
  async loadAllBlitzes({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadAllBlitzes()
      if (response && response.status === 200) {
        await commit('SET_BLITZES', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadBlitzInfo({ commit, rootGetters }, blz_key) {
    try {
      let response = await apiCalls.loadBlitzInfo(blz_key)
      if (response && response.status === 200) {
        await commit('SET_BLITZ_INFO', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addBlitz({ rootGetters }, blitz) {
    try {
      let response = await apiCalls.addBlitz(rootGetters['user/userId'], blitz)
      if (response && response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deleteBlitz({}, blitz) {
    try {
      let response = await apiCalls.deleteBlitz(blitz)
      if (response && response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
}

const apiCalls = {
  loadBlitzInfo(blz_key) {
    const route = USA_BLITZ_API_CONFIG.getBlitzInfo(blz_key)
    return axios.get(route)
  },
  loadAllBlitzes() {
    const route = USA_BLITZ_API_CONFIG.getAllBlitzes()
    return axios.get(route)
  },
  addBlitz(ind_key, blitz) {
    let formData = new FormData()
    for (var itm in blitz) {
      if (blitz[itm] !== null) {
        formData.append(itm, blitz[itm])
      }
    }
    const route = USA_BLITZ_API_CONFIG.addBlitz(ind_key)
    return axios.post(route, formData)
  },
  deleteBlitz(blitz) {
    const route = USA_BLITZ_API_CONFIG.deleteBlitz()
    return axios.post(route, blitz)
  },
}

export const usaScriptureBlitz = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
