import axios from 'axios'
import store from './store'
import Vue from 'vue'
import router from './router'
import commonTranslations from '@/json/commonTranslations.json'

let originalResponse = true

/* eslint-disable */
export default function setup() {
  const translations = { 'JS-intercept-api-traffic': {} }
  const userLang = handleLanguageChoice()

  // Now, update the `translations` object from the JSON
  translations['JS-intercept-api-traffic'] = commonTranslations['logInLogOut'][userLang]

  axios.interceptors.request.use(
    config => {
      let token = store.getters['user/accessToken']
      let authenticated = store.getters['user/authenticated']
      let bypass = config.url.toLowerCase().includes('twofactor')
      if (token && (authenticated || bypass)) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (!!error.response) {
        if (error.response.status === 401) {
          store.dispatch('menu/setLoadingStatus', false)
          let authenticated = store.getters['user/authenticated']
          if (originalResponse && authenticated) {
            const { tcExtendSession, tcOk, tcRedirectToLogin, tcSessionExpired } = translations['JS-intercept-api-traffic']
            originalResponse = false
            Vue.swal({
              backdrop: true,
              title: tcSessionExpired,
              text: tcRedirectToLogin,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: tcOk,
              cancelButtonText: tcExtendSession,
              showCancelButton: true,
              allowOutsideClick: false
            }).then(result => {
              originalResponse = true
              if ( result.dismiss && result.dismiss === 'cancel') {
                store.dispatch('menu/setLoadingStatus', true)
                store.dispatch('user/extendUserLogin')
                  .then((response) => {
                    if (response) {
                      location.reload();
                    } else {
                      store.dispatch('user/setAuthenticated', false)
                      router.push('/login')
                    }
                    store.dispatch('menu/setLoadingStatus', false)
                  })
              } else {
                store.dispatch('user/setAuthenticated', false)
                router.push('/login')
              }
            })
          }
        } else {
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
    }
  )
}

/**
 * Get language choice based on the 1) Vuex Store, 2) browser language choice, or 3) default to English
 *  - We currently make no distinctions between languages. `US English` is the same as `UK English`
 * @return {string} An string containing the 4 letter language code
 * @example
 *    handleLanguageChoice() returns `'en-US'` if user has English selected (also default)
 *    handleLanguageChoice() returns `'es-ES'` if user selected Spanish, or browser defaults to Spanish
 */
function handleLanguageChoice () {
  let usersLanguage = store.getters['user/userPreferredCulture'] || navigator.language || 'en-US'

  if (usersLanguage.indexOf('es') !== -1) {        // Spanish
    usersLanguage = 'es-ES'
  } else if (usersLanguage.indexOf('fr') !== -1) { // French
    usersLanguage = 'fr-FR'
  } else if (usersLanguage.indexOf('ru') !== -1) { // Russian
    usersLanguage = 'ru-RU'
  } else {
    usersLanguage = 'en-US' // English (default/catch-all)
  }

  if (!commonTranslations['logInLogOut'].hasOwnProperty(usersLanguage)) {
    usersLanguage = 'en-US' // user's preference is not English, but their language choice is invalid
  }
  return usersLanguage
}
