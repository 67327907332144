/* eslint-disable */
export const MRP_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/mrp`

export const MRP_API_CONFIG = {
  getMRPLandingPage: (lang, landingpage) => `${MRP_API_ACTIVE_ROUTE}/gettc3mrplandingpage/${lang}/${landingpage}`,
  getMRPProgramPage: (lang, restype, programid) => `${MRP_API_ACTIVE_ROUTE}/gettc3mrpprogrampage/${lang}/${restype}/${programid}`,
  getMRPTopicPage: (lang, topicid) => `${MRP_API_ACTIVE_ROUTE}/gettc3mrptopicdetails/${lang}/${topicid}`,
  getMRPProgramSearch: (lang, programname, term) =>
    `${MRP_API_ACTIVE_ROUTE}/programbasicsearch/${lang}/${programname}/${term}`
}
