export const YearRange = year => {
  // INPUT: 2024
  // OUTPUT: 2023-24
  const getLastTwoDigits = number => {
    const tempVal = number % 100

    if ((tempVal < 10) & (tempVal !== 0)) {
      return '0' + tempVal
    } else if (tempVal === 0) {
      return number
    } else {
      return tempVal
    }
  }
  return `${parseInt(year) - 1}-${getLastTwoDigits(parseInt(year))}`
}
