import axios from 'axios'
import { API_CONFIG } from '@/config/api.config'
import { MEMBER_API_CONFIG } from '@/config/member.api.config'
import store from '../store'
import constantData from '@/json/data.json'
import USStateData from '@/json/states.json'

/* eslint-disable */
export const state = () => ({
  allowedControls: [],
  authQuestions: [],
  authenticated: false,
  control_permissions: [],
  demograph: {},
  from_page: '',
  internationalKey: 'a0000000-b000-4000-c000-d00000000000',
  loginRedirectPath: '',
  meeting: {},
  membersAuthQuestionsAndAnswers: {},
  memberPopupShown: false,
  newNonMemberTestimonySpeaker: null,
  officer_toolbar: {
    active: false,
    camps: [],
    countries_states: [],
    mode: '',
    regions_zones: []
  },
  officer_toolbar_selected: {
    camp: null,
    camp_name: '',
    country_state: null,
    country_state_name: null,
    domain_change: true,
    mode: null,
    region_zone: null,
    userid: null,
    SPSP: false
  },
  otb_snapshot: {},
  page_error: {},
  PaymentInstruments: [],
  selectedAddress: {},
  selectedAnnouncementKey: '',
  selectedArticleKey: '',
  selectedBlitzKey: '',
  selectedCampType: '',
  selectedAccountOnFile: '',
  selectedCardOnFile: '',
  selectedChurchKey: '',
  selectedContact: {},
  selectedConventionKey: '',
  selectedConversationKey: '',
  selectedEmail: {},
  selectedFacilityKey: '',
  selectedFax: {},
  selectedIndividualKey: '',
  selectedLocationContact: {},
  selectedLocationKey: '',
  selectedMeetingKey: '',
  selectedNote: {},
  selectedOrgTypeKey: '',
  selectedPhone: {},
  selectedPlacementDateKey: '',
  user: {},
  userImpersonation: false,
  userLogin: {},
  userRouterBackLink: '',
  userStaffLogin: {},
  userVerification: {}
})

export const uniqueByPropertyName = prop => arr =>
  Array.from(
    arr
      .reduce(
        (acc, item) => (item && item[prop] && acc.set(item[prop], item), acc), // using map (preserves ordering)
        new Map()
      )
      .values()
  )

export const getters = {
  accessToken: state => {
    return state.userLogin.access_token
  },
  accessTokenExpiresIn: state => {
    return state.userLogin['.expires']
  },
  authQuestions: state => {
    return state.authQuestions
  },
  authenticated: state => {
    return state.authenticated
  },
  controlPermissions: state => {
    return state.control_permissions
  },
  convertedAuthQuestions: state => {
    return {
      questions: state.authQuestions.map(c => {
        return {
          text: c.question,
          value: c.questionid
        }
      })
    }
  },
  cpositions: state => {
    return state.userLogin.cpositions
  },
  demograph: state => {
    return state.demograph
  },
  domainChange: state => {
    return state.officer_toolbar_selected.domain_change
  },
  fromPage: state => {
    return state.from_page
  },
  donorCardsOnFile: state => {
    return state.PaymentInstruments.filter(pb => pb.pit_brand > 0)
  },
  donorChecksOnFile: state => {
    return state.PaymentInstruments.filter(pb => pb.pit_brand === 0)
  },
  internationalKey: state => {
    return state.internationalKey
  },
  iCanSee: state => ctl_key => {
    if (!!ctl_key) {
      return !!state.allowedControls ? state.allowedControls.filter(item => item.toUpperCase() === ctl_key.toUpperCase()).length > 0 : false
    }
    return false
  },
  iCanSeeAny: state => (ctl_keys, ignore) => {
    if (!!ignore && ignore === true) {
      return true
    }
    if (!!ctl_keys) {
      return Object.values(ctl_keys).some(ac => {
        return state.allowedControls.filter(item => item.toUpperCase() === ac.toUpperCase()).length > 0
      })
    } else {
      return false
    }
  },
  lastLogin: state => {
    return state.userLogin.usr_last_web_login_date
  },
  mbr_installpay: state => {
    return state.userLogin.mbr_installpay
  },
  mbr_pending_drop_date: state => {
    return state.userLogin.mbr_pending_drop_date
  },
  loginRedirectPath: state => {
    return state.loginRedirectPath
  },
  membersAuthQuestionsAndAnswers: state => {
    return state.membersAuthQuestionsAndAnswers
  },
  memberPopupShown: state => {
    return state.memberPopupShown
  },
  newNonMemberTestimonySpeaker: state => {
    return state.newNonMemberTestimonySpeaker
  },
  officerToolbarActive: state => {
    return state.officer_toolbar.active
  },
  officerToolbarCamps: state => {
    return state.officer_toolbar.camps.map(rz => {
      return {
        text: rz.org_name,
        value: rz.org_key
      }
    })
  },
  officerToolbarCountriesStates: state => {
    return state.officer_toolbar.countries_states.map(rz => {
      return {
        text: rz.org_name,
        value: rz.org_key
      }
    })
  },
  officerToolbarRegionsZones: state => {
    return state.officer_toolbar.regions_zones.map(rz => {
      return {
        text: rz.org_name,
        value: rz.org_key
      }
    })
  },
  officerToolbarUSAZones: state => {
    return state.officer_toolbar.regions_zones
      .filter(rf => {
        return rf.org_name.includes('USA Zone')
      })
      .map(rz => {
        return {
          text: rz.org_name,
          value: rz.org_key
        }
      })
  },
  officerToolbarNonUSAZones: state => {
    return state.officer_toolbar.regions_zones
      .filter(rf => {
        return !rf.org_name.includes('USA Zone')
      })
      .map(rz => {
        return {
          text: rz.org_name,
          value: rz.org_key
        }
      })
  },
  okToEditProfile: state => {
    return (
      state.selectedIndividualKey === state.demograph.ind_key ||
      state.selectedIndividualKey === state.demograph.spouse_ind_key
    )
  },
  officerToolbarSelected: state => {
    return state.officer_toolbar_selected
  },
  okToCreateAssignments: state => {
    let ava = constantData.create_assignment_acronyms.map(v => v.text)
    let avv = state.userLogin.cpositions.map(v => v.office_acronym)
    return ava.some(r => avv.indexOf(r) >= 0)
  },
  okToViewAssignments: state => {
    let bva = constantData.view_assignment_acronyms.map(v => v.text)
    let bvv = state.userLogin.cpositions.map(v => v.office_acronym)
    return bva.some(r => bvv.indexOf(r) >= 0)
  },
  okToViewCampVisitationAdmin: state => {
    let cva = constantData.camp_visitation_admin.map(v => v.text)
    let cvv = state.userLogin.cpositions.map(v => v.office_acronym)
    return cva.some(r => cvv.indexOf(r) >= 0)
  },
  otbSnapshot: state => {
    return state.otb_snapshot
  },
  pageError: state => {
    return state.page_error
  },
  userAvatar(state) {
    /* return state.user.avatar */
    return 'chris-basescu.png'
  },
  selectedCardOnFile: state => {
    return state.selectedCardOnFile
  },
  userCamp: state => {
    return {
      number: state.demograph.camp_number,
      name: state.demograph.camp_name,
      key: state.user.camp_key
    }
  },
  userCampKey: state => {
    return state.user.camp_key
  },
  userCampNumber: state => {
    return state.user.id
  },
  userCommitteeCount: state => {
    return state.userLogin.cpositions.length
  },
  userCommittees: state => {
    const uniqueByProperty = uniqueByPropertyName('committee_key')
    const uniqueCommittees = uniqueByProperty(state.userLogin.cpositions)
    let user_committees = {
      key: constantData.empty_guid,
      title: 'Committees',
      areas: []
    }
    if (uniqueCommittees.length > 0) {
      user_committees.areas = [
        ...[
          {
            selected: false,
            text: 'All Committees',
            value: constantData.empty_guid,
            code: 'ALL'
          }
        ],
        ...uniqueCommittees.map(xy => {
          return {
            selected: false,
            text: xy.committee_name,
            value: xy.committee_key,
            code: xy.committee_acronym
          }
        })
      ]
    }
    return user_committees
  },
  userCountryObject: state => {
    return {
      key: state.demograph.country_key,
      name: state.demograph.country_name,
      type:
        !state.demograph.country_key || state.demograph.country_key.toLowerCase() === USStateData.country
          ? 'domestic'
          : 'foreign'
    }
  },
  userId: state => {
    return state.user.ind_key
  },
  userImpersonation: state => {
    return state.userImpersonation
  },
  userLanguageKey: state => {
    return state.user.lng_key
  },
  userPreferredCulture: state => {
    return state.userLogin.pref_culture
  },
  userPreferredLanguage: state => {
    return state.userLogin.pref_language
  },
  userLocation: state => {
    return state.user.location
  },
  userLogin: state => {
    return state.userLogin
  },
  userMemberTypeKey: state => {
    return !state.demograph.member_type_key
      ? 'abda920a-f939-4de0-bad5-0ab91f476b5e'
      : [
        '8123452c-58be-4fdb-8ad7-2a6568bb22bc',
        '397a5b31-9b02-4013-90b7-97649cf9282e',
        '057b00df-0548-4d34-bb8d-a833b64f4bf3',
        '678cfa9f-3ced-4880-ab74-6bad634aaf74'
      ].includes(state.demograph.member_type_key.toLowerCase())
        ? '678cfa9f-3ced-4880-ab74-6bad634aaf74'
        : 'abda920a-f939-4de0-bad5-0ab91f476b5e'
  },
  userMenuKey: state => {
    return state.user.mnu_key
  },
  userCountryKey: state => {
    return state.demograph.country_key
  },
  userName: state => {
    return state.user.name
  },
  userNotificationsCount: state => {
    return 2
  },
  userOrgKey: state => {
    return state.user.org_key
  },
  userRouterBackLink: state => {
    return state.userRouterBackLink
  },
  userSelectedAddress: state => {
    return state.selectedAddress
  },
  userSelectedAnnouncementKey: state => {
    return state.selectedAnnouncementKey
  },
  userSelectedArticleKey: state => {
    return state.selectedArticleKey
  },
  userSelectedBlitzKey: state => {
    return state.selectedBlitzKey
  },
  userSelectedCampType: state => {
    return state.selectedCampType
  },
  userSelectedChurchContact: state => {
    return state.selectedContact
  },
  userSelectedChurchKey: state => {
    return state.selectedChurchKey
  },
  userSelectedConventionKey: state => {
    return state.selectedConventionKey
  },
  userSelectedConversationKey: state => {
    return state.selectedConversationKey
  },
  userSelectedEmail: state => {
    return state.selectedEmail
  },
  userSelectedFacilityKey: state => {
    return state.selectedFacilityKey
  },
  userSelectedFax: state => {
    return state.selectedFax
  },
  userSelectedIndividualKey: state => {
    return state.selectedIndividualKey
  },
  userSelectedLocationContact: state => {
    return state.selectedLocationContact
  },
  userSelectedLocationKey: state => {
    return state.selectedLocationKey
  },
  userSelectedMeetingKey: state => {
    return state.selectedMeetingKey
  },
  userSelectedNote: state => {
    return state.selectedNote
  },
  userSelectedOrgTypeKey: state => {
    return state.selectedOrgTypeKey
  },
  userSelectedPhone: state => {
    return state.selectedPhone
  },
  userSelectedPlacementDateKey: state => {
    return state.selectedPlacementDateKey
  },
  userStaffLogin: state => {
    return state.userStaffLogin
  },
  userStateKey: state => {
    return state.userLogin.statekey
  },
  userStateName: state => {
    let sn = USStateData.states.find(uss => uss.code === state.demograph.camp_number.substr(0, 3))
    return !!sn ? sn.name : ''
  },
  userVerification: state => {
    return state.userVerification
  }
}

export const APPEND_OFFICER_TOOLBAR_COUNTRIES_STATES = 'APPEND_OFFICER_TOOLBAR_COUNTRIES_STATES'
export const CLEAR_NEW_NON_MEMBER_TESTIMONY_SPEAKER = 'CLEAR_NEW_NON_MEMBER_TESTIMONY_SPEAKER'
export const CLEAR_OFFICER_TOOLBAR_STATES_CAMPS = 'CLEAR_OFFICER_TOOLBAR_STATES_CAMPS'
export const RESET_OFFICER_TOOLBAR_DOMAIN_CHANGE = 'RESET_OFFICER_TOOLBAR_DOMAIN_CHANGE'
export const REVERT_OFFICER_TOOLBAR_CHANGES = 'REVERT_OFFICER_TOOLBAR_CHANGES'
export const SAVE_OFFICER_TOOLBAR_CAMPS_SELECTION = 'SAVE_OFFICER_TOOLBAR_CAMPS_SELECTION'
export const SAVE_OFFICER_TOOLBAR_CAMP_NAME = 'SAVE_OFFICER_TOOLBAR_CAMP_NAME'
export const SAVE_OFFICER_TOOLBAR_COUNTRIES_STATES_SELECTION = 'SAVE_OFFICER_TOOLBAR_COUNTRIES_STATES_SELECTION'
export const SAVE_OFFICER_TOOLBAR_DOMAIN_SELECTION = 'SAVE_OFFICER_TOOLBAR_DOMAIN_SELECTION'
export const SAVE_OFFICER_TOOLBAR_REGIONS_ZONES_SELECTION = 'SAVE_OFFICER_TOOLBAR_REGIONS_ZONES_SELECTION'
export const SET_ALLOWED_CONTROLS = 'SET_ALLOWED_CONTROLS'
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_AUTHQUESTIONS = 'SET_AUTHQUESTIONS'
export const SET_CAMP_FROM_OFFICER_TOOLBAR = 'SET_CAMP_FROM_OFFICER_TOOLBAR'
export const SET_CONTROL_PERMISSIONS = 'SET_CONTROL_PERMISSIONS'
export const SET_DEMOGRAPH = 'SET_DEMOGRAPH'
export const SET_DEMOGRAPH_CHURCH_VISITS = 'SET_DEMOGRAPH_CHURCH_VISITS'
export const SET_DONOR_CARDS_ON_FILE = 'SET_DONOR_CARDS_ON_FILE'
export const SET_FROM_PAGE = 'SET_FROM_PAGE'
export const SET_LOGIN_REDIRECT_PATH = 'SET_LOGIN_REDIRECT_PATH'
export const SET_MEMBERAUTHQUESTIONSANDANSWERS = 'SET_MEMBERAUTHQUESTIONSANDANSWERS'
export const SET_MEMBERPOPUPSHOWN = 'SET_MEMBERPOPUPSHOWN'
export const SET_NEW_NON_MEMBER_TESTIMONY_SPEAKER = 'SET_NEW_NON_MEMBER_TESTIMONY_SPEAKER'
export const SET_OFFICER_TOOLBAR_CAMPS = 'SET_OFFICER_TOOLBAR_CAMPS'
export const SET_OFFICER_TOOLBAR_COUNTRIES_STATES = 'SET_OFFICER_TOOLBAR_COUNTRIES_STATES'
export const SET_OFFICER_TOOLBAR_REGIONS_ZONES = 'SET_OFFICER_TOOLBAR_REGIONS_ZONES'
export const SET_OFFICER_TOOLBAR_STATUS = 'SET_OFFICER_TOOLBAR_STATUS'
export const SET_OFFICER_TOOLBAR_USER = 'SET_OFFICER_TOOLBAR_USER'
export const SET_OTB_SNAPSHOT = 'SET_OTB_SNAPSHOT'
export const SET_PAGE_ERROR = 'SET_PAGE_ERROR'
export const SET_ROUTER_BACK_LINK = 'SET_ROUTER_BACK_LINK'
export const SET_SELECTED_ACCOUNT_ON_FILE = 'SET_SELECTED_ACCOUNT_ON_FILE'
export const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS'
export const SET_SELECTED_ANNOUNCEMENT_KEY = 'SET_SELECTED_ANNOUNCEMENT_KEY'
export const SET_SELECTED_ARTICLE_KEY = 'SET_SELECTED_ARTICLE_KEY'
export const SET_SELECTED_BLITZ_KEY = 'SET_SELECTED_BLITZ_KEY'
export const SET_SELECTED_CAMP_TYPE = 'SET_SELECTED_CAMP_TYPE'
export const SET_SELECTED_CARD_ON_FILE = 'SET_SELECTED_CARD_ON_FILE'
export const SET_SELECTED_CHURCH_CONTACT = 'SET_SELECTED_CHURCH_CONTACT'
export const SET_SELECTED_CHURCH_KEY = 'SET_SELECTED_CHURCH_KEY'
export const SET_SELECTED_CONVENTION_KEY = 'SET_SELECTED_CONVENTION_KEY'
export const SET_SELECTED_CONVERSATION_KEY = 'SET_SELECTED_CONVERSATION_KEY'
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL'
export const SET_SELECTED_FACILITY_KEY = 'SET_SELECTED_FACILITY_KEY'
export const SET_SELECTED_FAX = 'SET_SELECTED_FAX'
export const SET_SELECTED_INDIVIDUAL_KEY = 'SET_SELECTED_INDIVIDUAL_KEY'
export const SET_SELECTED_LOCATION_CONTACT = 'SET_SELECTED_LOCATION_CONTACT'
export const SET_SELECTED_LOCATION_KEY = 'SET_SELECTED_LOCATION_KEY'
export const SET_SELECTED_MEETING_KEY = 'SET_SELECTED_MEETING_KEY'
export const SET_SELECTED_NOTE = 'SET_SELECTED_NOTE'
export const SET_SELECTED_ORG_TYPE_KEY = 'SET_SELECTED_ORG_TYPE_KEY'
export const SET_SELECTED_PHONE = 'SET_SELECTED_PHONE'
export const SET_SELECTED_PLACEMENT_DATE_KEY = 'SET_SELECTED_PLACEMENT_DATE_KEY'
export const SET_USER = 'SET_USER'
export const SET_USERIMPERSONATION = 'SET_USERIMPERSONATION'
export const SET_USERLOGIN = 'SET_USERLOGIN'
export const SET_USERLOGIN_PREFERRED_CULTURE = 'SET_USERLOGIN_PREFERRED_CULTURE'
export const SET_USERLOGIN_PREFERRED_LANGUAGE = 'SET_USERLOGIN_PREFERRED_LANGUAGE'
export const SET_USERSTAFFLOGIN = 'SET_USERSTAFFLOGIN'
export const SET_USERVERIFICATION = 'SET_USERVERIFICATION'
export const SET_USER_LANGUAGE_KEY = 'SET_USER_LANGUAGE_KEY'
export const SET_USER_USA_DUES_SEASON = 'SET_USER_USA_DUES_SEASON'
export const SET_USER_IOC_DUES_SEASON = 'SET_USER_IOC_DUES_SEASON'

export const mutations = {
  [SET_USERLOGIN](state, data) {
    state.userLogin = data
    state.userLogin.statekey = data.statekey
  },
  [SET_USERLOGIN_PREFERRED_CULTURE](state, data) {
    state.userLogin.pref_culture = data
  },
  [SET_USERLOGIN_PREFERRED_LANGUAGE](state, data) {
    state.userLogin.pref_language = data
  },
  [SET_USERSTAFFLOGIN](state, data) {
    state.userStaffLogin = data
    //Checking to confirm data exists by access_token
    if (!!data.access_token) {
      state.userStaffLogin.spouse_formal_name = state.demograph.spouse_formal_name || null
      state.userStaffLogin.spouse_ind_key = state.demograph.spouse_ind_key || null
      state.userStaffLogin.spouse_member_number = state.demograph.spouse_member_number || null
    }
  },
  [SET_USERIMPERSONATION](state, data) {
    state.userImpersonation = data
  },
  [SET_ALLOWED_CONTROLS](state, data) {
    state.allowedControls = data
  },
  [SET_USERVERIFICATION](state, data) {
    state.userVerification = data
  },
  [SET_AUTHQUESTIONS](state, data) {
    state.authQuestions = data
  },
  [SET_MEMBERAUTHQUESTIONSANDANSWERS](state, data) {
    state.membersAuthQuestionsAndAnswers = data
  },
  [SET_AUTHENTICATED](state, data) {
    state.authenticated = data
  },
  [SET_DEMOGRAPH](state, data) {
    state.demograph = data
    state.demograph.state_key = state.userLogin.statekey
  },
  [SET_DEMOGRAPH_CHURCH_VISITS](state, data) {
    if (!!data) {
      state.demograph.last_visit = data.last === null ? null : data.last.toString()
      state.demograph.next_visit = data.next === null ? null : data.next.toString()
    }
  },
  [SET_DONOR_CARDS_ON_FILE](state, data) {
    state.PaymentInstruments = data
  },
  [SET_USER](state, data) {
    state.user = data
  },
  [SET_OTB_SNAPSHOT](state, data) {
    state.otb_snapshot = data
  },
  [REVERT_OFFICER_TOOLBAR_CHANGES](state, proceed) {
    if (proceed) {
      state.userLogin.statekey = state.demograph.state_key
      state.user.camp_key = state.demograph.camp_key
    }
  },
  [SET_CAMP_FROM_OFFICER_TOOLBAR](state, data) {
    state.user.state_key = data.statekey
    state.user.camp_key = data.campkey
    state.userLogin.statekey = data.statekey
  },
  [SET_MEMBERPOPUPSHOWN](state, data) {
    state.memberPopupShown = data
  },
  [SET_NEW_NON_MEMBER_TESTIMONY_SPEAKER](state, data) {
    state.newNonMemberTestimonySpeaker = data
  },
  [SET_OFFICER_TOOLBAR_REGIONS_ZONES](state, data) {
    state.officer_toolbar.regions_zones = data
  },
  [SET_OFFICER_TOOLBAR_COUNTRIES_STATES](state, data) {
    state.officer_toolbar.countries_states = data
  },
  [APPEND_OFFICER_TOOLBAR_COUNTRIES_STATES](state, data) {
    state.officer_toolbar.countries_states.push(data)
  },
  [SET_OFFICER_TOOLBAR_CAMPS](state, data) {
    state.officer_toolbar.camps = data
  },
  [SET_OFFICER_TOOLBAR_USER](state, data) {
    state.officer_toolbar_selected.userid = data
  },
  [SET_OFFICER_TOOLBAR_STATUS](state, data) {
    state.officer_toolbar.active =
      state.userLogin.statekey !== state.officer_toolbar_selected.country_state ||
      data !== state.officer_toolbar_selected.country_state_name ||
      state.user.location !== state.officer_toolbar_selected.camp_name ||
      state.user.camp_key === state.officer_toolbar_selected.camp
  },
  [SAVE_OFFICER_TOOLBAR_COUNTRIES_STATES_SELECTION](state, data) {
    state.officer_toolbar_selected.country_state = data
    if (
      state.officer_toolbar.countries_states.length > 0 &&
      state.officer_toolbar.countries_states.find(sf => sf.org_key === data)
    ) {
      state.officer_toolbar_selected.country_state_name = state.officer_toolbar.countries_states.find(
        sf => sf.org_key === data
      ).org_name
    }
  },
  [SAVE_OFFICER_TOOLBAR_CAMPS_SELECTION](state, data) {
    state.officer_toolbar_selected.camp = data
    let cnumber = state.officer_toolbar.camps.find(cmp => cmp.org_key === data)
    state.officer_toolbar_selected.camp_number = !!cnumber ? cnumber.cmp_number : state.user.id
  },
  [SAVE_OFFICER_TOOLBAR_CAMP_NAME](state, data) {
    if (!!data) {
      state.officer_toolbar_selected.camp_name = data
    }
  },
  [RESET_OFFICER_TOOLBAR_DOMAIN_CHANGE](state, data) {
    state.officer_toolbar_selected.domain_change = data
  },
  [SAVE_OFFICER_TOOLBAR_DOMAIN_SELECTION](state, data) {
    state.officer_toolbar_selected.domain_change = !!data.domainchanged ? data.domainchanged : false
    let officersection = ['committee', 'committeeofficers', 'countryofficers', 'officers']
    let cntrystate = ['country', 'state']
    let cntrystateExt = ['reports', 'events', 'connected', 'prayer']
    let bToggleCntryState = cntrystate.includes(data.to) && cntrystate.includes(data.from)
    let bExtFromCntryState = cntrystateExt.includes(data.to) && cntrystate.includes(data.from)
    if (data.domainchanged && !officersection.includes(data.from) && !bToggleCntryState && !bExtFromCntryState) {
      state.officer_toolbar_selected.region_zone = state.demograph.region_zone
      state.officer_toolbar_selected.country_state = state.demograph.state_key
      state.officer_toolbar_selected.camp = state.demograph.camp_key
      state.officer_toolbar_selected.camp_number = state.demograph.camp_number
      state.officer_toolbar_selected.camp_name = state.demograph.camp_name
      state.user.camp_key = state.demograph.camp_key
      state.user.state_key = state.demograph.state_key
    }
  },
  [SAVE_OFFICER_TOOLBAR_REGIONS_ZONES_SELECTION](state, data) {
    state.officer_toolbar_selected.region_zone = data
    state.officer_toolbar_selected.country_state = null
  },
  [CLEAR_NEW_NON_MEMBER_TESTIMONY_SPEAKER](state) {
    state.newNonMemberTestimonySpeaker = null
  },
  [CLEAR_OFFICER_TOOLBAR_STATES_CAMPS](state) {
    state.officer_toolbar.camps = []
    state.officer_toolbar.countries_states = []
  },
  [SET_SELECTED_BLITZ_KEY](state, data) {
    state.selectedBlitzKey = data
  },
  [SET_SELECTED_CHURCH_KEY](state, data) {
    state.selectedChurchKey = data
  },
  [SET_SELECTED_CONVENTION_KEY](state, data) {
    state.selectedConventionKey = data
  },
  [SET_SELECTED_CONVERSATION_KEY](state, data) {
    state.selectedConversationKey = data
  },
  [SET_SELECTED_INDIVIDUAL_KEY](state, data) {
    state.selectedIndividualKey = data
  },
  [SET_SELECTED_CARD_ON_FILE](state, data) {
    state.selectedCardOnFile = data
  },
  [SET_SELECTED_ACCOUNT_ON_FILE](state, data) {
    state.selectedAccountOnFile = data
  },
  [SET_SELECTED_MEETING_KEY](state, data) {
    state.selectedMeetingKey = data
  },
  [SET_SELECTED_PLACEMENT_DATE_KEY](state, data) {
    state.selectedPlacementDateKey = data
  },
  [SET_SELECTED_ANNOUNCEMENT_KEY](state, data) {
    state.selectedAnnouncementKey = data
  },
  [SET_SELECTED_ARTICLE_KEY](state, data) {
    state.selectedArticleKey = data
  },
  [SET_SELECTED_CAMP_TYPE](state, data) {
    state.selectedCampType = data
  },
  [SET_SELECTED_ADDRESS](state, data) {
    state.selectedAddress = data
  },
  [SET_SELECTED_EMAIL](state, data) {
    state.selectedEmail = data
  },
  [SET_SELECTED_PHONE](state, data) {
    state.selectedPhone = data
  },
  [SET_SELECTED_FAX](state, data) {
    state.selectedFax = data
  },
  [SET_SELECTED_NOTE](state, data) {
    state.selectedNote = data
  },
  [SET_SELECTED_ORG_TYPE_KEY](state, data) {
    state.selectedOrgTypeKey = data
  },
  [SET_SELECTED_CHURCH_CONTACT](state, data) {
    state.selectedContact = data
  },
  [SET_SELECTED_LOCATION_KEY](state, data) {
    state.selectedLocationKey = data
  },
  [SET_SELECTED_FACILITY_KEY](state, data) {
    state.selectedFacilityKey = data
  },
  [SET_SELECTED_LOCATION_CONTACT](state, data) {
    state.selectedLocationContact = data
  },
  [SET_LOGIN_REDIRECT_PATH](state, data) {
    state.loginRedirectPath = data
  },
  [SET_ROUTER_BACK_LINK](state, data) {
    state.userRouterBackLink = data
  },
  [SET_PAGE_ERROR](state, data) {
    state.page_error = data
  },
  [SET_FROM_PAGE](state, data) {
    state.from_page = data
  },
  [SET_CONTROL_PERMISSIONS](state, data) {
    state.control_permissions = data
  },
  [SET_USER_USA_DUES_SEASON](state, data) {
    state.userLogin.usa_dues_season = data
  },
  [SET_USER_IOC_DUES_SEASON](state, data) {
    state.userLogin.ioc_dues_season = data
  }
}

export const actions = {
  async getUserLogin({ commit }, userdata) {
    try {
      let response = await apiCalls.loadUserLogin(userdata)

      if (response && response.status === 200) {
        await commit('SET_USERLOGIN', response.data)
        return true
      } else {
        throw `error during user login retrieval; response.status = ${response.status}`
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async extendUserLogin({ commit }) {
    try {
      let response = await apiCalls.extendUserLogin()

      if (response && response.status === 200) {
        await commit('SET_USERLOGIN', response.data)
        return true
      } else {
        throw `error during extension process; response.status = ${response.status}`
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAllowedControls({ commit, state }) {
    try {
      await commit('SET_ALLOWED_CONTROLS', [])
      let response = await apiCalls.loadAllowedControls({
        ind_key: state.userLogin.indkey,
        account_id: state.userLogin.acountid,
        nag_key: constantData.default_nag_key
      })
      if (response && response.status === 200) {
        await commit(
          'SET_ALLOWED_CONTROLS',
          response.data.map(ac => ac.ctl_key)
        )
        return true
      } else {
        await commit('SET_ALLOWED_CONTROLS', [])
        throw `error during allowed control data retrieval; response.status = ${response.status}`
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadDonorCardsOnFile({ commit }, { ind_key, origin, check } = payload) {
    try {
      let response = await apiCalls.loadDonorCardsOnFile(ind_key, origin, check)
      if (response && response.status === 200) {
        await commit('SET_DONOR_CARDS_ON_FILE', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async setPreferredCulture({ commit }, userdata) {
    await commit('SET_USERLOGIN_PREFERRED_CULTURE', userdata)
  },
  async setPreferredLanguage({ commit }, userdata) {
    await commit('SET_USERLOGIN_PREFERRED_LANGUAGE', userdata)
  },
  async setUSADuesSeason({ commit }, userdata) {
    await commit('SET_USER_USA_DUES_SEASON', userdata)
  },
  async setIOCDuesSeason({ commit }, userdata) {
    await commit('SET_USER_IOC_DUES_SEASON', userdata)
  },
  async getUserImpersonation({ commit, rootGetters }, userdata) {
    try {
      let staffLogin = rootGetters['user/userStaffLogin']
      if (Object.keys(staffLogin).length > 0) {
        await commit('SET_USERLOGIN', Object.assign({}, staffLogin))
      }
      let response = await apiCalls.loadUserImpersonation(userdata)
      if (response && response.status === 200) {
        await commit('SET_USERSTAFFLOGIN', Object.assign({}, rootGetters['user/userLogin']))
        await commit('SET_USERLOGIN', response.data)
        await commit('SET_USERIMPERSONATION', true)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getUserImpersonationStop({ commit, rootGetters }) {
    try {
      let staffLogin = rootGetters['user/userStaffLogin']
      if (Object.keys(staffLogin).length > 0) {
        await commit('SET_USERLOGIN', Object.assign({}, staffLogin))
        await commit('SET_USERSTAFFLOGIN', Object.assign({}, {}))
        await commit('SET_USERIMPERSONATION', false)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getUserVerification({ commit }, userdata) {
    try {
      let response = await apiCalls.loadUserVerification(userdata)
      if (response && response.status === 200) {
        await commit('SET_USERVERIFICATION', response.data)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAuthQuestions({ commit }) {
    try {
      let response = await apiCalls.loadAuthQuestions()
      if (response && response.status === 200) {
        await commit('SET_AUTHQUESTIONS', response.data)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getMembersAuthQuestionsAndAnswers({ commit }, userdata) {
    try {
      let response = await apiCalls.loadMembersAuthQuestionsAndAnswers(userdata)
      if (response && response.status === 200) {
        await commit('SET_MEMBERAUTHQUESTIONSANDANSWERS', response.data)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getUserLoginTwoFactor({ commit }, userdata) {
    try {
      let response = await apiCalls.loadUserLoginTwoFactor(userdata)
      if (response && response.status === 200) {
        await commit('SET_USERLOGIN', response.data)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getUserRegistered({ commit }, userdata) {
    try {
      let response = await apiCalls.loadUserRegistration(userdata)
      if (response && response.status === 200) {
        await commit('SET_USERLOGIN', response.data)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getUserPasswordReset({ commit }, userdata) {
    try {
      let response = await apiCalls.loadUserPasswordReset(userdata)
      if (response && response.status === 200) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getUserVerifyQuestionAnswer({ commit }, userdata) {
    try {
      let response = await apiCalls.loadVerifyAuthQuestionAndAnswer(userdata)
      if (response && response.status === 200) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getOfficerToolbarRegionsZones({ commit, rootGetters }, SPSP) {
    try {
      let ind_key = rootGetters['user/userId']
      let response = await apiCalls.getOfficerToolbarRegionsZones(ind_key, SPSP)
      if (response && response.status === 200) {
        await commit('SET_OFFICER_TOOLBAR_REGIONS_ZONES', response.data)
        return true
      } else {
        throw `error during officer toolbar data retrieval; response.status = ${response.status}`
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getOfficerToolbarCountriesStates({ commit, rootGetters }, userdata) {
    try {
      let ind_key = rootGetters['user/userId']
      let response = await apiCalls.getOfficerToolbarCountriesStates(userdata.zone, ind_key, userdata.SPSP)
      if (response && response.status === 200) {
        await commit('SET_OFFICER_TOOLBAR_COUNTRIES_STATES', response.data)
        return true
      } else {
        throw `error during officer toolbar data retrieval; response.status = ${response.status}`
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async appendOfficerToolbarCountriesStates({ commit }, statedata) {
    try {
      await commit('APPEND_OFFICER_TOOLBAR_COUNTRIES_STATES', statedata)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getOfficerToolbarCamps({ commit, rootGetters }, state_country) {
    try {
      let ind_key = rootGetters['user/userId']
      let response = await apiCalls.getOfficerToolbarCamps(state_country, ind_key)
      if (response && response.status === 200) {
        await commit('SET_OFFICER_TOOLBAR_CAMPS', response.data)
        return true
      } else {
        throw `error during officer toolbar data retrieval; response.status = ${response.status}`
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getControlPermissions({ commit, state }, ctl_key) {
    try {
      let response = await apiCalls.getControlPermissions(state.user.lng_key, ctl_key)
      if (response && response.status === 200) {
        await commit('SET_CONTROL_PERMISSIONS', response.data)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getControlPermissionsFromList({ commit, state }, ctl_key_list) {
    try {
      let response = await apiCalls.getControlPermissionsFromList({
        lng_key: state.user.lng_key,
        ctl_key_list: JSON.stringify(ctl_key_list)
      })
      if (response && response.status === 200) {
        await commit('SET_CONTROL_PERMISSIONS', response.data)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async resetOfficerToolbarDomainChange({ commit }, val) {
    await commit('RESET_OFFICER_TOOLBAR_DOMAIN_CHANGE', val)
  },
  async sendFeedback({ commit }, parcel) {
    try {
      let response = await apiCalls.recordFeedback(parcel)
      if (response && response.status === 200) {
        return true
      } else {
        throw `error during sendFeedback data post; response.status = ${response.status}`
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  setAuthenticated: ({ commit }, payload) => {
    commit('SET_AUTHENTICATED', payload)
    if (payload == false) {
      store.reset()
    }
  },
  setLoginRedirectPath: ({ commit }, payload) => {
    commit('SET_LOGIN_REDIRECT_PATH', payload)
  },
  setMemberPopupShown: ({ commit }, payload) => {
    commit('SET_MEMBERPOPUPSHOWN', payload)
  },
  setNewNonMemberTestimonySpeaker: ({ commit }, payload) => {
    commit('SET_NEW_NON_MEMBER_TESTIMONY_SPEAKER', payload)
  },
  setUser: ({ commit }, payload) => {
    commit('SET_USER', payload)
  },
  setSelectedFacilityKey: ({ commit }, payload) => {
    commit('SET_SELECTED_FACILITY_KEY', payload)
  },
  setSelectedUSABlitzKey: ({ commit }, payload) => {
    commit('SET_SELECTED_BLITZ_KEY', payload)
  },
  setSelectedChurchKey: ({ commit }, payload) => {
    commit('SET_SELECTED_CHURCH_KEY', payload)
  },
  setSelectedChurchContact: ({ commit }, payload) => {
    commit('SET_SELECTED_CHURCH_CONTACT', payload || {})
  },
  setSelectedConventionKey: ({ commit }, payload) => {
    commit('SET_SELECTED_CONVENTION_KEY', payload)
  },
  async setSelectedConversationKey({ commit }, payload) {
    commit('SET_SELECTED_CONVERSATION_KEY', payload)
  },
  setSelectedIndividualKey: ({ commit }, payload) => {
    commit('SET_SELECTED_INDIVIDUAL_KEY', payload)
  },
  setSelectedCardOnFile: ({ commit }, payload) => {
    commit('SET_SELECTED_CARD_ON_FILE', payload)
  },
  setSelectedAccountOnFile: ({ commit }, payload) => {
    commit('SET_SELECTED_ACCOUNT_ON_FILE', payload)
  },
  async setSelectedAnnouncementKey({ commit }, payload) {
    commit('SET_SELECTED_ANNOUNCEMENT_KEY', payload)
  },
  async setSelectedArticleKey({ commit }, payload) {
    commit('SET_SELECTED_ARTICLE_KEY', payload.toLowerCase())
  },
  async clearNewNonMemberTestimonySpeaker({ commit }) {
    commit('CLEAR_NEW_NON_MEMBER_TESTIMONY_SPEAKER')
  },
  async clearOfficerToolbarStatesCamps({ commit }) {
    commit('CLEAR_OFFICER_TOOLBAR_STATES_CAMPS')
  },
  async revertOfficerToolbarChanges({ commit }, payload) {
    commit('REVERT_OFFICER_TOOLBAR_CHANGES', payload)
  },
  async setCampFromOfficerToolbar({ commit }, payload) {
    commit('SET_CAMP_FROM_OFFICER_TOOLBAR', payload)
  },
  setSelectedMeetingKey: ({ commit }, payload) => {
    commit('SET_SELECTED_MEETING_KEY', payload)
  },
  setSelectedPlacementDateKey: ({ commit }, payload) => {
    commit('SET_SELECTED_PLACEMENT_DATE_KEY', payload)
  },
  setOTBSnapshot: ({ commit }, payload) => {
    commit('SET_OTB_SNAPSHOT', payload)
  },
  async loadSelectedCampType({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadSelectedCampType(rootGetters['user/userCampKey'])
      if (response && response.status === 200) {
        await commit('SET_SELECTED_CAMP_TYPE', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  setOfficerToolbarUser: async ({ commit }, payload) => {
    commit('SET_OFFICER_TOOLBAR_USER', payload)
  },
  setOfficerToolbarStatus: async ({ commit }, payload) => {
    commit('SET_OFFICER_TOOLBAR_STATUS', payload)
  },
  setFromPage: async ({ commit }, payload) => {
    commit('SET_FROM_PAGE', payload)
  },
  setPageError: async ({ commit }, payload) => {
    commit('SET_PAGE_ERROR', payload)
  },
  setSelectedAddress: ({ commit }, payload) => {
    commit('SET_SELECTED_ADDRESS', payload)
  },
  setSelectedEmail: ({ commit }, payload) => {
    commit('SET_SELECTED_EMAIL', payload)
  },
  setSelectedPhone: ({ commit }, payload) => {
    commit('SET_SELECTED_PHONE', payload)
  },
  setSelectedFax: ({ commit }, payload) => {
    commit('SET_SELECTED_FAX', payload)
  },
  setSelectedNote: ({ commit }, payload) => {
    commit('SET_SELECTED_NOTE', payload)
  },
  setSelectedLocationKey: ({ commit }, payload) => {
    commit('SET_SELECTED_LOCATION_KEY', payload)
  },
  setSelectedLocationContact: ({ commit }, payload) => {
    commit('SET_SELECTED_LOCATION_CONTACT', payload || {})
  },
  setSelectedOrgTypeKey: ({ commit }, payload) => {
    commit('SET_SELECTED_ORG_TYPE_KEY', payload)
  },
  setRouterBackLink: ({ commit }, payload) => {
    commit('SET_ROUTER_BACK_LINK', payload)
  },
  async saveOfficerToolbarCountriesStatesSelection({ commit }, payload) {
    commit('SAVE_OFFICER_TOOLBAR_COUNTRIES_STATES_SELECTION', payload)
  },
  async saveOfficerToolbarCampName({ commit }, payload) {
    commit('SAVE_OFFICER_TOOLBAR_CAMP_NAME', payload)
  },
  async saveOfficerToolbarCampsSelection({ commit }, payload) {
    commit('SAVE_OFFICER_TOOLBAR_CAMPS_SELECTION', payload)
  },
  async saveOfficerToolbarRegionsZonesSelection({ commit }, payload) {
    commit('SAVE_OFFICER_TOOLBAR_REGIONS_ZONES_SELECTION', payload)
  },
  async saveOfficerToolbarDomainSelection({ commit }, payload) {
    commit('SAVE_OFFICER_TOOLBAR_DOMAIN_SELECTION', payload)
  }
}

export const apiCalls = {
  extendUserLogin() {
    const route = API_CONFIG.extendUserLogin()
    return axios.post(route)
  },
  loadAllowedControls(data) {
    const route = API_CONFIG.getAllowedControls(data.ind_key, data.account_id, data.nag_key)
    return axios.get(route)
  },
  loadDonorCardsOnFile(ind_key, origin, check) {
    const route = API_CONFIG.getDonorCardsOnFile(ind_key, origin, check)
    return axios.get(route)
  },
  loadSelectedCampType(campKey) {
    const route = MEMBER_API_CONFIG.getCampType(campKey)
    return axios.get(route)
  },
  loadUserLogin(data) {
    const route = API_CONFIG.userLogin()
    return axios.post(route, data)
  },
  loadUserImpersonation(data) {
    const route = API_CONFIG.userImpersonation(data.memberno)
    return axios.get(route)
  },
  loadUserLoginTwoFactor(data) {
    const route = API_CONFIG.userLoginTwoFactor(data.rndnum)
    return axios.post(route, data)
  },
  loadUserVerification(data) {
    const route = API_CONFIG.verifyAccountExists(data.memberno, data.campno)
    return axios.get(route)
  },
  loadAuthQuestions() {
    const route = API_CONFIG.getAuthQuestions()
    return axios.get(route)
  },
  loadUserRegistration(data) {
    const route = API_CONFIG.registerUser()
    return axios.post(route, data)
  },
  loadUserPasswordReset(data) {
    const route = API_CONFIG.resetUserPassword()
    return axios.post(route, data)
  },
  loadMembersAuthQuestionsAndAnswers(data) {
    const route = API_CONFIG.getMembersAuthQuestionsAndAnswers(data.memberno)
    return axios.get(route)
  },
  loadVerifyAuthQuestionAndAnswer(data) {
    const route = API_CONFIG.verifyAuthQuestionAndAnswer()
    return axios.post(route, data)
  },
  recordFeedback(data) {
    const route = API_CONFIG.recordFeedback(data.key)
    return axios.post(route, data.msg)
  },
  getOfficerToolbarRegionsZones(ind_key, SPSP) {
    const route = API_CONFIG.getOfficerToolbarRegionsZones(ind_key, SPSP)
    return axios.get(route)
  },
  getOfficerToolbarCountriesStates(zone, ind_key, SPSP) {
    const route = API_CONFIG.getOfficerToolbarCountriesStates(zone, ind_key, SPSP)
    return axios.get(route)
  },
  getOfficerToolbarCamps(state_country, ind_key) {
    const route = API_CONFIG.getOfficerToolbarCamps(state_country, ind_key)
    return axios.get(route)
  },
  getControlPermissions(lng_key, ctl_key) {
    const route = API_CONFIG.getControlPermissions(lng_key, ctl_key)
    return axios.get(route)
  },
  getControlPermissionsFromList(data) {
    const route = API_CONFIG.getControlPermissionsFromList()
    return axios.post(route, data)
  }
}

export const user = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
