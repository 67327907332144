/* eslint-disable */
export const CONVENTION_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/conventions`

export const CONVENTION_API_CONFIG = {
  addConvention: (ind_key, cmp_key) => `${CONVENTION_API_ACTIVE_ROUTE}/add/${ind_key}/${cmp_key}`,
  deleteConvention: () => `${CONVENTION_API_ACTIVE_ROUTE}/delete`,
  editConventionPhoto: ind_key => `${CONVENTION_API_ACTIVE_ROUTE}/editphoto/${ind_key}`,
  editConventionDocument: ind_key => `${CONVENTION_API_ACTIVE_ROUTE}/editdocuments/${ind_key}`,
  editConventionLink: ind_key => `${CONVENTION_API_ACTIVE_ROUTE}/editlink/${ind_key}`,
  getConventionInfo: event_key => `${CONVENTION_API_ACTIVE_ROUTE}/GetConventionInfo/${event_key}`,
  getConventionsByCamp: cmp_key => `${CONVENTION_API_ACTIVE_ROUTE}/GetConventionsByCamp/${cmp_key}`,
  toggleActiveConvention: cmp_key => `${CONVENTION_API_ACTIVE_ROUTE}/activetoggle/${cmp_key}`,
}
