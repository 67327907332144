/* eslint-disable */
import axios from 'axios'
import { MEMBER_API_CONFIG } from '../config/member.api.config.js'

export const state = () => ({
  membershipbreakdown: [],
  otherkeymeasures: [],
  yearataglance: [],
  receiptstotals: [],
  receipts: [],
  placementstotals: [],
  placements: [],
  pwts: [],
  chartreceipts: [],
  chartplacements: [],
  friendcounts: []
})

export const getters = {
  membershipbreakdown: state => {
    return state.membershipbreakdown
  },
  otherkeymeasures: state => {
    return state.otherkeymeasures
  },
  yearataglance: state => {
    return state.yearataglance
  },
  receiptstotals: state => {
    return state.receiptstotals
  },
  receipts: state => {
    return state.receipts
  },
  placementstotals: state => {
    return state.placementstotals
  },
  placements: state => {
    return state.placements
  },
  pwts: state => {
    return state.pwts
  },
  chartreceipts: state => {
    return state.chartreceipts
  },
  chartplacements: state => {
    return state.chartplacements
  },
  friendcounts: state => {
    return state.friendcounts
  }
}

export const SET_CHARTPLACEMENTS = 'SET_CHARTPLACEMENTS'
export const SET_CHARTRECEIPTS = 'SET_CHARTRECEIPTS'
export const SET_FRIENDCOUNTS = 'SET_FRIENDCOUNTS'
export const SET_MEMBERSHIPBREAKDOWN = 'SET_MEMBERSHIPBREAKDOWN'
export const SET_OTHERKEYMEASURES = 'SET_OTHERKEYMEASURES'
export const SET_PLACEMENTS = 'SET_PLACEMENTS'
export const SET_PLACEMENTSTOTALS = 'SET_PLACEMENTSTOTALS'
export const SET_PWTS = 'SET_PWTS'
export const SET_RECEIPTS = 'SET_RECEIPTS'
export const SET_RECEIPTSTOTALS = 'SET_RECEIPTSTOTALS'
export const SET_YEARATAGLANCE = 'SET_YEARATAGLANCE'

export const mutations = {
  [SET_MEMBERSHIPBREAKDOWN](state, data) {
    state.membershipbreakdown = data
  },
  [SET_OTHERKEYMEASURES](state, data) {
    state.otherkeymeasures = data
  },
  [SET_YEARATAGLANCE](state, data) {
    state.yearataglance = data
  },
  [SET_RECEIPTSTOTALS](state, data) {
    state.receiptstotals = data
  },
  [SET_RECEIPTS](state, data) {
    state.receipts = data
  },
  [SET_PLACEMENTSTOTALS](state, data) {
    state.placementstotals = data
  },
  [SET_PLACEMENTS](state, data) {
    state.placements = data.filter(pl => pl.TokenValue.search('PWT') < 0)
  },
  [SET_PWTS](state, data) {
    state.pwts = data.filter(pl => pl.TokenValue.search('PWT') > -1)
  },
  [SET_CHARTRECEIPTS](state, data) {
    state.chartreceipts = data
  },
  [SET_CHARTPLACEMENTS](state, data) {
    state.chartplacements = data
  },
  [SET_FRIENDCOUNTS](state, data) {
    state.friendcounts = data
  }
}

export const actions = {
  async loadDashboard({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadCampDashboardData(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_MEMBERSHIPBREAKDOWN', response.data.membershipbreakdown)
        await commit('SET_OTHERKEYMEASURES', response.data.otherkeymeasures)
        await commit('SET_YEARATAGLANCE', response.data.yearataglance)
        await commit('SET_RECEIPTSTOTALS', response.data.receiptstotals)
        await commit('SET_RECEIPTS', response.data.receipts)
        await commit('SET_PLACEMENTSTOTALS', response.data.placementstotals)
        await commit('SET_PLACEMENTS', response.data.placements)
        await commit('SET_PWTS', response.data.placements)
        await commit('SET_CHARTRECEIPTS', response.data.chartreceipts)
        await commit('SET_CHARTPLACEMENTS', response.data.chartplacements)
        await commit('SET_FRIENDCOUNTS', response.data.friendcounts)
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStateDashboard({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadStateDashboardData(
        rootGetters['user/userStateKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_YEARATAGLANCE', response.data.yearataglance)
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

const apiCalls = {
  loadCampDashboardData(campKey, languageKey) {
    const route = MEMBER_API_CONFIG.getMembersCampDashboard(campKey, languageKey)
    return axios.get(route)
  },
  loadStateDashboardData(stateKey, languageKey) {
    const route = MEMBER_API_CONFIG.getMembersStateDashboard(stateKey, languageKey)
    return axios.get(route)
  }
}

export const dashboard = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
