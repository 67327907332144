import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import breadcrumbService from './services/breadcrumbService'
import { error } from 'jquery'
Vue.use(Router)

/* eslint-disable */
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // ******************************************************************
    // Home
    // ******************************************************************
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/Home.vue'),
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcHome') || 'Home'
          }
        },
        domain: 'home'
      }
    },
    {
      path: '*',
      meta: {},
      component: () => import('./views/help/NotFound.vue')
    },
    // ******************************************************************
    // Login
    // ******************************************************************
    {
      path: '/login',
      name: 'login',
      meta: {
        breadcrumb() {
          return { label: breadcrumbService.getTranslation('tcLogin') || 'Login' }
        },
        domain: 'home'
      },
      component: () => import('./views/Login.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {},
      component: () => import('./views/Logout.vue')
    },
    // ******************************************************************
    // Church Ministry
    // ******************************************************************
    {
      path: '/programs/cm/church-ministry/:m?',
      name: 'church-ministry',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchMinistry') || 'Church Ministry',
            parent: 'Home'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/ChurchMinistry.vue')
    },
    {
      path: '/programs/cm/church-list/:m?',
      name: 'church-list',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchList') || 'Church List',
            parent: 'church-ministry'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/ChurchList.vue')
    },
    {
      path: '/programs/cm/presenters/:m?',
      name: 'presenters',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchPresenters') || 'Church Presenters',
            parent: 'church-ministry'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/Presenters.vue')
    },
    {
      path: '/programs/cm/presenter-history/:m?',
      name: 'presenter-history',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPresenterHistory') || 'Presenter History',
            parent: 'presenters'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/PresenterHistory.vue')
    },
    {
      path: '/programs/cm/presentations/:m?',
      name: 'presentations',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchPresentations') || 'Church Presentations',
            parent: 'church-ministry'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/Presentations.vue')
    },
    {
      path: '/programs/cm/presentations/1/:m?',
      name: 'presentations-1',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchPresentations') || 'Church Presentations',
            parent: 'church-profile'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/Presentations.vue')
    },
    {
      path: '/programs/cm/church-visits/:m?',
      name: 'church-visits',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchVisits') || 'Church Visits',
            parent: 'church-ministry'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/ChurchVisitsByCamp.vue')
    },
    {
      path: '/programs/cm/church-visits-card',
      name: 'church-visits-sc',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchVisits') || 'Church Visits',
            parent: 'church-profile'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/ChurchVisits.vue')
    },
    {
      path: '/reports/report-center/cm/:m?',
      name: 'report-center-cm',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcReports') || 'Reports',
            parent: 'church-ministry'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ReportCenter.vue')
    },
    {
      path: '/programs/cm/churchProfile/view-church-visit/:m?',
      name: 'view-church-visit',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchVisit') || 'Church Visit',
            parent: 'church-ministry'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/ViewChurchVisit.vue')
    },
    {
      path: '/programs/cm/churchProfile/add-church-visit/:m?',
      name: 'add-church-visit',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddChurchVisit') || 'Add Church Visit',
            parent: 'church-visits'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/AddChurchVisit.vue')
    },
    {
      path: '/programs/cm/churchProfile/view-church-presentation/:m?',
      name: 'view-church-presentation',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchPresentation') || 'Church Presentation',
            parent: 'church-ministry'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/ViewChurchPresentation.vue')
    },
    {
      path: '/programs/cm/churchProfile/add-church-presentation/:m?',
      name: 'add-church-presentation',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddChurchPresentation') || 'Add Church Presentation',
            parent: 'presentations'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/AddChurchPresentation.vue')
    },
    {
      path: '/programs/cm/church-profile/:m?',
      name: 'church-profile',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchProfile') || 'Church Profile',
            parent: 'church-list'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/ChurchProfile.vue')
    },
    {
      path: '/programs/cm/church-contacts/:m?',
      name: 'church-contacts',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchContacts') || 'Church Contacts',
            parent: 'church-profile'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/ChurchContacts.vue')
    },
    {
      path: '/programs/cm/churchProfile/add-church-contact/:p/:m?',
      name: 'add-church-contact',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditChurchContact') || 'Add/Edit Church Contact',
            parent: 'church-contacts'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/AddChurchContact.vue')
    },
    {
      path: '/admin/church-ministry/church-communications/:m?',
      name: 'church-communications',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchCommunications') || 'Church Communications',
            parent: 'church-profile'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/admin/church-ministry/ChurchCommunications.vue')
    },
    {
      path: '/programs/cm/church-details/:m?',
      name: 'church-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchDetails') || 'Church Details',
            parent: 'church-profile'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/ChurchDetails.vue')
    },
    {
      path: '/programs/cm/churchProfile/edit-church/:m?',
      name: 'edit-church',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditChurch') || 'Edit Church',
            parent: 'church-details'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/AddChurch.vue')
    },
    {
      path: '/admin/church-ministry/service-times-presentations/:m?',
      name: 'service-times-presentations',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcServiceTimesPresentations') || 'Service Times / Presentations',
            parent: 'church-profile'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/admin/church-ministry/ServiceTimesPresentations.vue')
    },
    {
      path: '/admin/church-ministry/church-members-friends/:m?',
      name: 'church-members-friends',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchMembersFriends') || 'Church Members / Friends',
            parent: 'church-profile'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/admin/church-ministry/ChurchMembersFriends.vue')
    },
    {
      path: '/programs/cm/churchProfile/add-church/:m?',
      name: 'add-church',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddChurch') || 'Add Church',
            parent: 'church-list'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/AddChurch.vue')
    },
    {
      path: '/programs/cm/churchProfile/add-church-presenter/:m?',
      name: 'add-church-presenter',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddChurchPresenter') || 'Add Church Presenter',
            parent: 'presenters'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/AddChurchPresenter.vue')
    },
    {
      path: '/programs/cm/churchProfile/add-edit-church-address/:m?',
      name: 'add-edit-church-address',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddChurchAddress') || 'Add Church Address',
            parent: 'church-communications'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/AddChurchAddress.vue')
    },
    {
      path: '/programs/cm/churchProfile/add-edit-church-phone-fax/:m?',
      name: 'add-edit-church-phone-fax',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddChurchPhoneFax') || 'Add Church Phone/Fax',
            parent: 'church-communications'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/AddChurchPhoneFax.vue')
    },
    {
      path: '/programs/cm/churchProfile/add-edit-church-email/:m?',
      name: 'add-edit-church-email',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddChurchEmail') || 'Add Church Email',
            parent: 'church-communications'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/cm/churchProfile/AddChurchEmail.vue')
    },
    // ******************************************************************
    // Scripture Distribution
    // ******************************************************************
    {
      path: '/programs/sd/scripture-distribution/:m?',
      name: 'scripture-distribution',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcScriptureDistribution') || 'Scripture Distribution',
            parent: 'Home'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/ScriptureDistribution.vue')
    },
    {
      path: '/programs/sd/placement-locations/:m?',
      name: 'placement-locations',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPlacementLocations') || 'Placement Locations',
            parent: 'scripture-distribution'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/PlacementLocations.vue')
    },
    {
      path: '/programs/sd/placement-dates/:m?',
      name: 'placement-dates',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPlacementDates') || 'Placement Dates',
            parent: 'scripture-distribution'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/PlacementDates.vue')
    },
    {
      path: '/programs/sd/placement-details/:m?',
      name: 'placement-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPlacementDetails') || 'Placement Details',
            parent: 'scripture-distribution'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/PlacementDetails.vue')
    },
    {
      path: '/programs/sd/add-placement-date/:m?',
      name: 'add-placement-date',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddPlacement') || 'Add Placement',
            parent: 'scripture-distribution'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/AddPlacementDate.vue')
    },
    {
      path: '/programs/sd/location-profile/:m?',
      name: 'location-profile',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcLocationProfile') || 'Location Profile',
            parent: 'placement-locations'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/LocationProfile.vue')
    },
    {
      path: '/programs/sd/placement-items/:m?',
      name: 'placement-items',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPlacementItems') || 'Placement Items',
            parent: 'location-profile'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/PlacementItems.vue')
    },
    {
      path: '/programs/sd/edit-placement-items/:m?',
      name: 'edit-placement-items',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditPlacementItems') || 'Edit Placement Items',
            parent: 'placement-items'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/EditPlacementItems.vue')
    },
    {
      path: '/programs/sd/location-details/:m?',
      name: 'location-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcLocationDetails') || 'Location Details',
            parent: 'location-profile'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/LocationDetails.vue')
    },
    {
      path: '/programs/sd/location-communications/:m?',
      name: 'location-communications',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcLocationCommunications') || 'Location Communications',
            parent: 'location-profile'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/LocationCommunications.vue')
    },
    {
      path: '/programs/sd/location-contacts/:m?',
      name: 'location-contacts',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcLocationContacts') || 'Location Contacts',
            parent: 'location-profile'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/LocationContacts.vue')
    },
    {
      path: '/programs/sd/location-add-contact/:m?',
      name: 'location-add-contact',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddLocationContacts') || 'Add Location Contacts',
            parent: 'location-contacts'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/AddLocationContact.vue')
    },
    {
      path: '/programs/sd/location-profile/add-edit-location-address/:m?',
      name: 'add-edit-location-address',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddLocationAddress') || 'Add Location Address',
            parent: 'location-communications'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/AddLocationAddress.vue')
    },
    {
      path: '/programs/sd/location-profile/add-edit-location-email/:m?',
      name: 'add-location-email',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddLocationEmail') || 'Add Location Email',
            parent: 'location-communications'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/AddLocationEmail.vue')
    },
    {
      path: '/programs/sd/location-profile/add-edit-location-phone-fax/:m?',
      name: 'add-location-phone-fax',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddLocationPhoneFax') || 'Add Location Phone/Fax',
            parent: 'location-communications'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/AddLocationPhoneFax.vue')
    },
    {
      path: '/programs/sd/location-visits/:m?',
      name: 'location-visits',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcLocationVisitsNotes') || 'Location Visits/Notes',
            parent: 'location-profile'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/LocationVisits.vue')
    },
    {
      path: '/programs/sd/location-visit-details/:m?',
      name: 'location-visit-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcVisitNoteDetails') || 'Visit/Note Details',
            parent: 'location-visits'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/LocationVisitDetails.vue')
    },
    {
      path: '/programs/sd/location-add-visit/:m?',
      name: 'location-add-visit',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddVisitNote') || 'Add Visit/Note',
            parent: 'location-visits'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/LocationAddVisit.vue')
    },
    {
      path: '/programs/sd/location-profile/add-location/:m?',
      name: 'add-location',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddLocation') || 'Add Location',
            parent: 'placement-locations'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/AddLocation.vue')
    },
    {
      path: '/programs/sd/all-placement-dates/:m?',
      name: 'all-placement-dates',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPlacementDates') || 'Placement Dates',
            parent: 'location-profile'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/location-profile/AllPlacementDates.vue')
    },
    {
      path: '/reports/report-center/sd/:m?',
      name: 'report-center-sd',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcReports') || 'Reports',
            parent: 'scripture-distribution'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ReportCenter.vue')
    },
    {
      path: '/reports/report-center/cr/:m?',
      name: 'report-center-cr',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcReports') || 'Reports',
            parent: 'committee-landing-page'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ReportCenter.vue')
    },
    // ******************************************************************
    // Membership
    // ******************************************************************
    {
      path: '/programs/mb/membership/:m?',
      name: 'membership',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMembership') || 'Membership',
            parent: 'Home'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/Membership.vue')
    },
    {
      path: '/programs/mb/member-list/:m?',
      name: 'member-list',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberList') || 'Member List',
            parent: 'membership'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },
    {
      path: '/programs/mb/member-list/new/:m?',
      name: 'member-list-new',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNewMembersList') || 'New Members List',
            parent: 'membership'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },
    {
      path: '/programs/mb/member-list/cof/:m?',
      name: 'member-list-cof',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampOfficersList') || 'Camp Officers List',
            parent: 'membership'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },
    {
      path: '/programs/mb/member-profile/:m?',
      name: 'member-profile',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberProfile') || 'Member Profile',
            parent: 'member-list'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/MemberProfile.vue')
    },
    {
      path: '/programs/mb/member-profile-cm/:m?',
      name: 'member-profile-cm',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberProfile') || 'Member Profile',
            parent: 'church-profile'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/mb/MemberProfile.vue')
    },
    {
      path: '/programs/mb/member-details/:m?',
      name: 'member-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberDetails') || 'Member Details',
            parent: 'member-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/MemberDetails.vue')
    },
    {
      path: '/programs/mb/individual-details-edit/:m?',
      name: 'individual-details-edit',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditMemberDetails') || 'Edit Member Details',
            parent: 'member-details'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/IndividualDetailsEdit.vue')
    },

    {
      path: '/programs/mb/add-spouse/:m?',
      name: 'add-spouse',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddSpouse') || 'Add Spouse',
            parent: 'member-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddSpouse.vue')
    },
    {
      path: '/programs/mb/user-preferences/:m?',
      name: 'user-preferences',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcUserPreferences') || 'User Preferences',
            parent: 'member-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/MemberCommunicationPreferences.vue')
    },
    {
      path: '/programs/mb/edit-preferred-email/:m?',
      name: 'edit-member-preferred-email',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditPreferredEmail') || 'Edit Preferred Email',
            parent: 'user-preferences'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/EditMemberPreferredEmail.vue')
    },
    {
      path: '/programs/mb/edit-login-email/:m?',
      name: 'edit-member-login-email',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditLoginEmail') || 'Edit Login Email',
            parent: 'user-preferences'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/EditMemberLoginEmail.vue')
    },
    {
      path: '/programs/mb/edit-preferred-language/:m?',
      name: 'edit-preferred-language',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChangeWebsiteLanguage') || 'Change Website Language',
            parent: 'user-preferences'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/EditMemberPreferredLanguage.vue')
    },
    {
      path: '/programs/mb/communications/member/:m?',
      name: 'communications',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberCommunications') || 'Member Communications',
            parent: 'member-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/MemberCommunications.vue')
    },
    {
      path: '/programs/mb/communications/nonmember/:m?',
      name: 'nonmember-communications',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNonMemberCommunications') || 'Non-Member Communications',
            parent: 'non-member-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/MemberCommunications.vue')
    },
    {
      path: '/programs/mb/member-payment-center/:m?',
      name: 'member-payment-center',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberPaymentCenter') || 'Member Payment Center',
            parent: 'member-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/MemberPaymentCenter.vue')
    },
    {
      path: '/programs/mb/autopay-form/:m?',
      name: 'member-autopay-form',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAutoPayForm') || 'Edit Payment',
            parent: 'member-payment-center'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/MemberAutopayForm.vue')
    },
    {
      path: '/programs/mb/member-dues/:m?',
      name: 'member-dues',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberDues') || 'Member Dues',
            parent: 'member-payment-center'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/MemberDues.vue')
    },
    {
      path: '/programs/mb/member-renewal/:m?',
      name: 'member-renewal',
      meta: {
        breadcrumb() {
          return {
            label: 'Membership Renewal',
            parent: 'member-payment-center'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/MemberDues.vue')
    },
    {
      path: '/programs/mb/member-roles/:m?',
      name: 'member-roles',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberRoles') || 'Member Roles',
            parent: 'member-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/MemberRoles.vue')
    },
    {
      path: '/programs/mb/non-member-profile-cm/:m?',
      name: 'non-member-profile-cm',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNonMemberProfile') || 'Non-Member Profile',
            parent: 'church-profile'
          }
        },
        domain: 'churchministry'
      },
      component: () => import('./views/programs/mb/NonMemberProfile.vue')
    },
    {
      path: '/programs/mb/non-member-profile-sd/:m?',
      name: 'non-member-profile-sd',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNonMemberProfile') || 'Non-Member Profile',
            parent: 'location-profile'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/mb/NonMemberProfile.vue')
    },
    {
      path: '/programs/ff/non-member-profile-ff/:m?',
      name: 'non-member-profile-ff',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNonMemberProfile') || 'Non-Member Profile',
            parent: 'friends-of-gideons'
          }
        },
        domain: 'faithfund'
      },
      component: () => import('./views/programs/mb/NonMemberProfile.vue')
    },
    {
      path: '/programs/mb/non-member-profile-gc/:m?',
      name: 'non-member-profile-gc',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNonMemberProfile') || 'Non-Member Profile',
            parent: 'card_profile'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/mb/NonMemberProfile.vue')
    },
    {
      path: '/programs/mb/non-member-profile-mb/:m?',
      name: 'non-member-profile',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNonMemberProfile') || 'Non-Member Profile',
            parent: 'membership'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/NonMemberProfile.vue')
    },
    {
      path: '/programs/mb/non-member-details/:m?',
      name: 'non-member-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNonMemberDetails') || 'Non-Member Details',
            parent: 'non-member-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/nonmember-profile/NonMemberDetails.vue')
    },
    {
      path: '/programs/mb/prospects/:m?',
      name: 'prospects',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCandidates') || 'Candidates',
            parent: 'membership'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/Prospects.vue')
    },
    {
      path: '/programs/mb/add-prospect/:m?',
      name: 'add-prospect',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddCandidate') || 'Add Candidate',
            parent: 'prospects'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/prospect-profile/AddProspect.vue')
    },
    {
      path: '/programs/mb/prospect-profile/:m?',
      name: 'prospect-profile',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCandidateProfile') || 'Candidate Profile',
            parent: 'prospects'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/ProspectProfile.vue')
    },
    {
      path: '/programs/prospect-details/:m?',
      name: 'prospect-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCandidateDetails') || 'Candidate Details',
            parent: 'prospect-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/prospect-profile/ProspectDetails.vue')
    },
    {
      path: '/programs/mb/prospect-contact-notes/:m?',
      name: 'prospect-contact-notes',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcContactNotes') || 'Contact Notes',
            parent: 'prospect-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/prospect-profile/ProspectContactNotes.vue')
    },
    {
      path: '/programs/mb/view-prospect-note/:m?',
      name: 'prospect-note-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNoteDetails') || 'Note Details',
            parent: 'prospect-contact-notes'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/prospect-profile/ProspectNoteDetails.vue')
    },
    {
      path: '/programs/mb/prospect-add-note/:m?',
      name: 'prospect-add-note',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddNote') || 'Add Note',
            parent: 'prospect-contact-notes'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/prospect-profile/ProspectAddNote.vue')
    },
    {
      path: '/programs/mb/communications/prospect/:m?',
      name: 'prospect-communications',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCandidateCommunications') || 'Candidate Communications',
            parent: 'prospect-profile'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/member-profile/MemberCommunications.vue')
    },
    {
      path: '/programs/mb/address/member/:m?',
      name: 'add-member-address',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddAddress') || 'Add Address',
            parent: 'communications'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddIndividualAddress.vue')
    },
    {
      path: '/programs/mb/address/member/:m?',
      name: 'add-member-checkout-address',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddAddress') || 'Add Address',
            parent: 'communications'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddIndividualAddress.vue')
    },
    {
      path: '/programs/mb/address/nonmember/:m?',
      name: 'add-nonmember-address',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddAddress') || 'Add Address',
            parent: 'nonmember-communications'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddIndividualAddress.vue')
    },
    {
      path: '/programs/mb/address/candidate/:m?',
      name: 'add-candidate-address',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddAddress') || 'Add Address',
            parent: 'prospect-communications'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddIndividualAddress.vue')
    },
    {
      path: '/programs/mb/phone/member/:m?',
      name: 'add-member-phone',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddPhoneFax') || 'Add Phone/Fax',
            parent: 'communications'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddIndividualPhone.vue')
    },
    {
      path: '/programs/mb/phone/nonmember/:m?',
      name: 'add-nonmember-phone',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddPhoneFax') || 'Add Phone/Fax',
            parent: 'nonmember-communications'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddIndividualPhone.vue')
    },
    {
      path: '/programs/mb/phone/candidate/:m?',
      name: 'add-candidate-phone',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddPhoneFax') || 'Add Phone/Fax',
            parent: 'prospect-communications'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddIndividualPhone.vue')
    },
    {
      path: '/programs/mb/email/member/:m?',
      name: 'add-member-email',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEmail') || 'Add Email',
            parent: 'communications'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddIndividualEmail.vue')
    },
    {
      path: '/programs/mb/email/nonmember/:m?',
      name: 'add-nonmember-email',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEmail') || 'Add Email',
            parent: 'nonmember-communications'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddIndividualEmail.vue')
    },
    {
      path: '/programs/mb/email/candidate/:m?',
      name: 'add-candidate-email',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEmail') || 'Add Email',
            parent: 'prospect-communications'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AddIndividualEmail.vue')
    },
    {
      path: '/programs/mb/sponsorcandidate2/:m?',
      name: 'sponsorcandidate2',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSponsorCandidate') || 'Sponsor Candidate',
            parent: 'membership'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/SponsorCandidate.vue')
    },
    {
      path: '/reports/report-center/mb/:m?',
      name: 'report-center-mb',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcReports') || 'Reports',
            parent: 'membership'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ReportCenter.vue')
    },
    // ******************************************************************
    // GideonCard
    // ******************************************************************
    {
      path: '/programs/gc/gideon-card/:m?',
      name: 'gideon-card',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcGideonCard') || 'GideonCard',
            parent: 'Home'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/GideonCard.vue')
    },
    {
      path: '/programs/gc/gideon-card-facilities/:m?',
      name: 'gideon-card-facilities',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcGideonCardFacilities') || 'GideonCard Facilities',
            parent: 'gideon-card'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/GideonCardFacilities.vue')
    },
    {
      path: '/programs/gc/video-showings/:m?',
      name: 'video-showings',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcVideoShowings') || 'Video Showings',
            parent: 'gideon-card'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/VideoShowings.vue')
    },
    {
      path: '/programs/gc/restocking-visits/:m?',
      name: 'restocking-visits',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcRestockingVisits') || 'Restocking Visits',
            parent: 'gideon-card'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/RestockingVisits.vue')
    },
    {
      path: '/programs/gc/profile/summary/card_profile/:m?',
      name: 'card_profile',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcGideonCardProfile') || 'GideonCard Profile',
            parent: 'gideon-card-facilities'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/GideonCardProfile.vue')
    },
    {
      path: '/programs/gc/profile/card-video-visits-list/:m?',
      name: 'card-video-visits-list',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcVideoPresentations') || 'Video Presentations',
            parent: 'card_profile'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/gc-profile/VideoVisitList.vue')
    },
    {
      path: '/programs/gc/profile/card_communication/:m?',
      name: 'card_communication',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcGideonCardCommunication') || 'GideonCard Communication',
            parent: 'card_profile'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/gc-profile/GideonCardCommunication.vue')
    },
    {
      path: '/programs/gc/profile/card_contacts/:m?',
      name: 'card_contacts',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcGideonCardContacts') || 'GideonCard Contacts',
            parent: 'card_profile'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/gc-profile/GideonCardContact.vue')
    },
    {
      path: '/programs/gc/profile/card_details/:m?',
      name: 'card_details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcFacilityDetails') || 'Facility Details',
            parent: 'card_profile'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/gc-profile/GideonCardDetails.vue')
    },
    {
      path: '/programs/gc/profile/card_notes/:m?',
      name: 'card_notes',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcVisitsNotes') || 'Visits/Notes',
            parent: 'card_profile'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/gc-profile/GideonCardNote.vue')
    },
    {
      path: '/programs/gc/profile/card_note_details/:m?',
      name: 'card_facility_note_details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcVisitNoteDetails') || 'Visit/Note Details',
            parent: 'card_notes'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/events/GideonCardNoteDetails.vue')
    },
    {
      path: '/programs/gc/profile/card_restocking_visits_list/:m?',
      name: 'card_restocking_visits_list',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcRestockingVisitList') || 'Restocking Visit List',
            parent: 'card_profile'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/gc-profile/RestockingVisitList.vue')
    },
    {
      path: '/programs/gc/profile/restocking-visit-details/:m?',
      name: 'restocking-visit-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcRestockingVisitDetails') || 'Restocking Visit Details',
            parent: 'card_restocking_visits_list'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/gc-profile/RestockingVisitDetails.vue')
    },
    {
      path: '/programs/gc/events/card_restocking_visit_add/:m?',
      name: 'card_restocking_visit_add',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddRestockingVisit') || 'Add Restocking Visit',
            parent: 'card_restocking_visits_list'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/events/AddRestockingVisit.vue')
    },
    {
      path: '/programs/gc/profile/video-visit/:m?',
      name: 'video-visit',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcVideoPresentations') || 'Video Presentations',
            parent: 'card-video-visits-list'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/gc-profile/VideoPresentationDetails.vue')
    },
    {
      path: '/programs/gc/events/card_video_presentation_add/:m?',
      name: 'card_video_presentation_add',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddVideoVisit') || 'Add Video Visit',
            parent: 'card-video-visits-list'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/events/AddVideoPresentation.vue')
    },
    {
      path: '/programs/gc/card_facility_add/:m?',
      name: 'card_facility_add',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddFacility') || 'Add Facility',
            parent: 'gideon-card-facilities'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/AddGideonCardFacility.vue')
    },
    {
      path: '/programs/gc/card_facility_edit_facility/:m?',
      name: 'card_facility_edit_facility',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditCardFacility') || 'Add/Edit Card Facility',
            parent: 'card_profile'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/events/EditGideonCardFacility.vue')
    },
    {
      path: '/programs/gc/card_facility_edit_address/:m?',
      name: 'card_facility_edit_address',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditAddress') || 'Add/Edit Address',
            parent: 'card_communication'
          }
        }
      },
      component: () => import('./views/programs/gc/events/AddGideonCardAddress.vue')
    },
    {
      path: '/programs/gc/card_facility_edit_email/:m?',
      name: 'card_facility_edit_email',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditEmail') || 'Add/Edit Email',
            parent: 'card_communication'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/events/EditGideonCardEmail.vue')
    },
    {
      path: '/programs/gc/card_facility_edit_phone/:m?',
      name: 'card_facility_edit_phone',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditPhoneFax') || 'Add/Edit Phone/Fax',
            parent: 'card_communication'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/events/EditGideonCardPhone.vue')
    },
    {
      path: '/programs/gc/card_facility_edit_contact/:m?',
      name: 'card_facility_edit_contact',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditContact') || 'Add/Edit Contact',
            parent: 'card_contacts'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/events/EditGideonCardContact.vue')
    },
    {
      path: '/programs/gc/card_facility_edit_note/:m?',
      name: 'card_facility_edit_note',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddCardVisitNote') || 'Add Card Visit/Note',
            parent: 'card_notes'
          }
        },
        domain: 'gideoncard'
      },
      component: () => import('./views/programs/gc/events/EditGideonCardNote.vue')
    },
    {
      path: '/reports/report-center/gc/:m?',
      name: 'report-center-gc',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcReports') || 'Reports',
            parent: 'gideon-card'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ReportCenter.vue')
    },
    // ******************************************************************
    // Faith Fund
    // ******************************************************************
    {
      path: '/programs/ff/faith-fund/:m?',
      name: 'faith-fund',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcFaithFund') || 'Faith Fund',
            parent: 'Home'
          }
        },
        domain: 'faithfund'
      },
      component: () => import('./views/programs/ff/FaithFund.vue')
    },
    {
      path: '/programs/ff/friends-of-gideons/:m?',
      name: 'friends-of-gideons',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcFriendsOfGideons') || 'Friends of Gideons',
            parent: 'Home'
          }
        },
        domain: 'faithfund'
      },
      component: () => import('./views/programs/ff/FriendsOfGideons.vue')
    },
    {
      path: '/programs/ff/friends-of-gideons/non-member-profile/:m?',
      name: 'friend-non-member-profile',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNonMemberProfile') || 'Non-Member Profile',
            parent: 'friends-of-gideons'
          }
        },
        domain: 'faithfund'
      },
      component: () => import('./views/programs/mb/NonMemberProfile.vue')
    },
    // ******************************************************************
    // Auxiliary
    // ******************************************************************
    {
      path: '/auxiliary/auxiliary/:m?',
      name: 'auxiliary',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAuxiliary') || 'Auxiliary',
            parent: 'Home'
          }
        },
        domain: 'auxiliary'
      },
      component: () => import('./views/auxiliary/Auxiliary.vue')
    },
    {
      path: '/auxiliary/camp/validate-membership-applications',
      name: 'auxiliary-validate-membership-applications-cm',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcValidateMembershipApplications') ||
              'Validate Membership Applications',
            parent: 'auxiliary'
          }
        },
        domain: 'auxiliary'
      },
      component: () => import('./views/auxiliary/ValidateMembershipApplications.vue')
    },
    {
      path: '/programs/mb/member-list/acof/:m?',
      name: 'member-list-acof',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampOfficers') || 'Camp Officers',
            parent: 'auxiliary'
          }
        },
        domain: 'auxiliary'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },
    {
      path: '/programs/mb/member-list/aux/:m?',
      name: 'member-list-aux',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMembersList') || 'Members List',
            parent: 'auxiliary'
          }
        },
        domain: 'auxiliary'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },
    {
      path: '/programs/mb/member-list/naux/:m?',
      name: 'member-list-naux',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMembersList') || 'Members List',
            parent: 'auxiliary'
          }
        },
        domain: 'auxiliary'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },
    {
      path: '/auxiliary/auxiliarycandidates/:m?',
      name: 'auxiliary-candidates',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAuxiliaryCandidates') || 'Auxiliary Candidates',
            parent: 'auxiliary'
          }
        },
        domain: 'auxiliary'
      },
      component: () => import('./views/auxiliary/AuxiliaryCandidates.vue')
    },
    {
      path: '/programs/sd/auxiliary-placement-locations/:m?',
      name: 'auxiliary-placement-locations',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAuxiliaryPlacementLocations') || 'Auxiliary Placement Locations',
            parent: 'auxiliary'
          }
        },
        domain: 'scripturedistribution'
      },
      component: () => import('./views/programs/sd/AuxiliaryPlacementLocations.vue')
    },
    {
      path: '/programs/sd/auxiliary-placement-dates/:m?',
      name: 'auxiliary-placement-dates',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAuxiliaryPlacementDates') || 'Auxiliary Placement Dates',
            parent: 'scripture-distribution'
          }
        },
        domain: 'auxiliary'
      },
      component: () => import('./views/programs/sd/AuxiliaryPlacementDates.vue')
    },
    {
      path: '/programs/pw/prayer-center-daily/aux/:m?',
      name: 'prayer-center-daily-aux',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPrayerCenter') || 'Prayer Center',
            parent: 'auxiliary'
          }
        },
        domain: 'auxiliary'
      },
      component: () => import('./views/programs/pw/PrayerCenterDaily.vue')
    },
    {
      path: '/calendar/events/view-events/ax/:m?',
      name: 'view-events-ax',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEvents') || 'Events',
            parent: 'auxiliary'
          }
        },
        domain: 'auxiliary'
      },
      component: () => import('./views/calendar/events/ViewEvents.vue')
    },
    // ******************************************************************
    // Resources
    // ******************************************************************
    // NOTE: These routes are in a specific order. Please preserve this order.
    // and make sure the programs root landing page is at the bottom of the list.
    {
      path: '/resources/media-portal/program/topic-detail/:m?',
      name: 'mrp-topic-detail',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcTopicDetail') || 'Topic Detail',
            parent: 'mrp-topics'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/mrp/TopicDetail.vue')
    },
    {
      path: '/resources/media-portal/program/resources/:m?',
      name: 'mrp-resources',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcResources') || 'Resources',
            parent: 'mrp-program'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/mrp/Resources.vue')
    },
    {
      path: '/resources/media-portal/program/videos/:m?',
      name: 'mrp-videos',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcVideos') || 'Videos',
            parent: 'mrp-program'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/mrp/Videos.vue')
    },
    {
      path: '/resources/media-portal/program/links/:m?',
      name: 'mrp-links',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcLinks') || 'Links',
            parent: 'mrp-program'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/mrp/Links.vue')
    },
    {
      path: '/resources/media-portal/program/search/:m?',
      name: 'mrp-search',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSearch') || 'Search',
            parent: 'mrp-program'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/mrp/Search.vue')
    },
    {
      path: '/resources/media-portal/program/topics/:m?',
      name: 'mrp-topics',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcTopics') || 'Topics',
            parent: 'mrp-program'
          }
        }
      },
      component: () => import('./views/resources/mrp/Topics.vue')
    },
    {
      path: '/resources/media-portal/program/:m?',
      name: 'mrp-program',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcProgram') || 'Program',
            parent: 'media-resource-portal'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/mrp/Program.vue')
    },
    {
      path: '/resources/media-portal/:m?',
      name: 'media-resource-portal',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMediaPortal') || 'Media Portal',
            parent: 'Home'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/mrp/MediaResourcePortal.vue')
    },
    // ******************************************************************
    // Reports
    // ******************************************************************
    {
      path: '/officers/camp/camp-dashboard/:m?',
      name: 'camp-dashboard',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampDashboard') || 'Camp Dashboard',
            parent: 'Home'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/officers/camp/CampDashboard.vue')
    },
    {
      path: '/officers/camp/application-upload-file/:m?',
      name: 'application-upload-file',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcIOApplicationUpload') || 'IO Application Upload',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/ApplicationFileUpload.vue')
    },
    {
      path: '/reports/report-center/:m?',
      name: 'report-center',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcReports') || 'Reports',
            parent: 'Home'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ReportCenter.vue')
    },
    {
      path: '/reports/report-center/sr/:m?',
      name: 'report-center-secure',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSecureReports') || 'Secure Reports',
            parent: 'Home'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ReportCenter.vue')
    },
    {
      path: '/reports/report-parameters/:m?',
      name: 'report-parameters',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcReportParameters') || 'Report Parameters',
            parent: 'report-center'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ReportParameters.vue')
    },
    {
      path: '/reports/state-association-summaries/:m?',
      name: 'state-association-summaries',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateAssociationummaries') || 'State Association Summaries',
            parent: 'report-center'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/StateAssociationSummaries.vue')
    },
    {
      path: '/reports/zone-trustee-summaries/:m?',
      name: 'zone-trustee-summaries',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcZoneTrusteeSummaries') || 'Zone Trustee Summaries',
            parent: 'report-center'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ZoneTrusteeSummaries.vue')
    },
    {
      path: '/reports/national-association-summaries/:m?',
      name: 'national-association-summaries',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcNationalAssociationSummaries') || 'National Association Summaries',
            parent: 'report-center'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/NationalAssociationSummaries.vue')
    },
    {
      path: '/reports/ioc-summaries/:m?',
      name: 'ioc-summaries',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcIOCSummaries') || 'IOC Summaries',
            parent: 'report-center'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/IOCSummaries.vue')
    },
    {
      path: '/reports/world-summaries/:m?',
      name: 'world-summaries',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcWorldSummaries') || 'World Summaries',
            parent: 'report-center'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/WorldSummaries.vue')
    },
    {
      path: '/reports/monthly-camp-reports/:m?',
      name: 'monthly-camp-reports',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMonthlyCampReports') || 'Monthly Camp Reports',
            parent: 'report-center'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/MonthlyCampReports.vue')
    },
    {
      path: '/reports/monthly-camp-reports-current-camp/:m?',
      name: 'monthly-camp-reports-current-camp',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMonthlyCampReports') || 'Monthly Camp Reports',
            parent: 'report-center'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/MonthlyCampReportCurrentCamp.vue')
    },
    {
      path: '/reports/ioc-national-conferences/:m?',
      name: 'ioc-national-conferences',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcIOCNationalConferences') || 'IOC National Conferences',
            parent: 'report-center'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/IOCNationalConferences.vue')
    },
    {
      path: '/reports/ioc-camp-visits/:m?',
      name: 'ioc-camp-visits',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcIOCCampVisits') || 'IOC Camp Visits',
            parent: 'report-center'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/IOCCampVisits.vue')
    },
    // ******************************************************************
    // Events
    // ******************************************************************
    {
      path: '/calendar/state/event/event-detail/:id?/:m?',
      name: 'state-event-detail',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateCountryEventDetail') || 'State/Country Event Detail',
            parent: 'Home'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/state-calendar/StateEventDetails.vue')
    },
    {
      path: '/calendar/events/international-convention/:m?',
      name: 'international_convention',
      meta: {
        breadcrumb() {
          return {
            label: '',
            parent: ''
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/InternationalConvention.vue')
    },
    {
      path: '/calendar/events/tgi-fall-conference/:m?',
      name: 'tgi-fall-conference',
      meta: {
        breadcrumb() {
          return {
            label: '',
            parent: ''
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/TGIFallConference.vue')
    },
    {
      path: '/calendar/event-select/select-add-event/:m?',
      name: 'select-add-event',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddStateCountryEvent') || 'Add State/Country Event',
            parent: 'calendar-month'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/event-select/SelectAddEvent.vue')
    },
    {
      path: '/calendar/state/event/geographic-dist/:m?',
      name: 'state-event-geographic-dist',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddStateCountryEventDetail') || 'Add State/Country Event Detail',
            parent: 'select-add-event'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/state-calendar/StateEventGeographicDist.vue')
    },
    {
      path: '/calendar/calendar-month/:m?',
      name: 'calendar-month',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCalendar') || 'Calendar',
            parent: 'Home'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/CalendarMonth.vue')
    },
    {
      path: '/calendar/camp-calendar/add-event/:aid?/:m?',
      name: 'add-event',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEvent') || 'Add Event',
            parent: 'calendar-month'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/camp-calendar/CalAddEvent.vue')
    },
    {
      path: '/calendar/camp-calendar/event-detail/:id?/:m?',
      name: 'cal-event-detail',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEventDetail') || 'Event Detail',
            parent: 'calendar-month'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/camp-calendar/EventDetail.vue')
    },
    {
      path: '/calendar/camp-calendar/edit-event/:type/:id/:mtg_key?/:m?',
      name: 'edit-event',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditEvent') || 'Edit Event',
            parent: 'cal-event-detail'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/camp-calendar/CalAddEvent.vue')
    },
    {
      path: '/calendar/events/view-events/:m?',
      name: 'view-events',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEvents') || 'Events',
            parent: 'Home'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/ViewEvents.vue')
    },
    {
      path: '/calendar/events/view-events/ic/:m?',
      name: 'view-events-ic',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcInternationalConvention') || 'International Convention',
            parent: 'Home'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/ViewEvents.vue')
    },
    {
      path: '/calendar/events/view-events/ub/:m?',
      name: 'view-events-ub',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcUpcomingBlitzes') || 'Upcoming Blitzes',
            parent: 'Home'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/ViewEvents.vue')
    },
    {
      path: '/calendar/events/state-conventions/:m?',
      name: 'state-conventions',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateConventionsEvents') || 'State Conventions/Events',
            parent: 'Home'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/StateConventionsEvents.vue')
    },
    {
      path: '/calendar/events/manage-events/:m?',
      name: 'manage-events',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcManageEvents') || 'Manage Events',
            parent: 'state-conventions'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/ManageEvents.vue')
    },
    {
      path: '/calendar/events/convention-promo/:m?',
      name: 'convention-promo',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcConventionPromo') || 'Convention Promo',
            parent: 'state-conventions'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/StateConPromoPage.vue')
    },
    {
      path: '/calendar/events/add-convention/:m?',
      name: 'add-convention',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditConvention') || 'Add/Edit Convention',
            parent: 'manage-events'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/AddConvention.vue')
    },
    {
      path: '/calendar/events/usa-scripture-blitzes/:m?',
      name: 'usa-scripture-blitzes',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcUSAScriptureBlitzes') || 'USA Scripture Blitzes',
            parent: 'Home'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/usa-scripture-blitzes/USAScriptureBlitzes.vue')
    },
    {
      path: '/calendar/events/manage-blitzes/:m?',
      name: 'manage-blitzes',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcManageBlitzes') || 'Manage Blitzes',
            parent: 'usa-scripture-blitzes'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/usa-scripture-blitzes/ManageBlitzes.vue')
    },
    {
      path: '/calendar/events/add-blitz/:m?',
      name: 'add-blitz',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditBlitz') || 'Add/Edit Blitz',
            parent: 'manage-blitzes'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/events/usa-scripture-blitzes/AddBlitz.vue')
    },
    // ******************************************************************
    // Get Connected
    // ******************************************************************
    {
      path: '/get-connected/stay-informed/announcements/:m?',
      name: 'announcements',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAnnouncements') || 'Announcements',
            parent: 'Home'
          }
        },
        domain: 'connected'
      },
      component: () => import('./views/get-connected/stay-informed/Announcements.vue')
    },
    {
      path: '/calendar/calendar-month/ev/:m?',
      name: 'calendar-month-ev',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEvents') || 'Events',
            parent: 'Home'
          }
        },
        domain: 'connected'
      },
      component: () => import('./views/calendar/events/ViewEvents.vue')
    },
    {
      path: '/programs/pw/prayer-center-daily/inf/:m?',
      name: 'prayer-center-daily-inf',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPrayerCenter') || 'Prayer Center',
            parent: 'Home'
          }
        },
        domain: 'stayinformed'
      },
      component: () => import('./views/programs/pw/PrayerCenterDaily.vue')
    },
    {
      path: '/get-connected/stay-informed/announcements/add/add-announcement/:m?',
      name: 'add-announcement',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddAnnouncement') || 'Add Announcement',
            parent: 'announcements'
          }
        },
        domain: 'stayinformed'
      },
      component: () => import('./views/admin/announcements/AddAnnouncement.vue')
    },
    {
      path: '/admin/announcements/article/:article/:m?',
      name: 'banner-to-article',
      meta: {
        breadcrumb() {
          return {
            label: '',
            parent: ''
          }
        },
        domain: 'banner'
      },
      component: () => import('./views/admin/announcements/BannerToArticle.vue')
    },
    {
      path: '/get-connected/stay-informed/announcements/add/add-committee-announcement/:m?',
      name: 'add-committee-announcement',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddCommitteeAnnouncement') || 'Add Committee Announcement',
            parent: 'add-announcement'
          }
        },
        domain: 'stayinformed'
      },
      component: () => import('./views/admin/announcements/AddCommitteeAnnouncement.vue')
    },
    {
      path: '/get-connected/stay-informed/announcements/add/add-camp-announcement/:m?',
      name: 'add-camp-announcement',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddCampAnnouncement') || 'Add Camp Announcement',
            parent: 'add-announcement'
          }
        },
        domain: 'stayinformed'
      },
      component: () => import('./views/admin/announcements/AddCampAnnouncement.vue')
    },
    {
      path: '/get-connected/stay-informed/announcements/add/add-state-announcement/:m?',
      name: 'add-state-announcement',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcAddStateCountryAnnouncement') || 'Add State/Country Announcement',
            parent: 'add-announcement'
          }
        },
        domain: 'stayinformed'
      },
      component: () => import('./views/admin/announcements/AddStateAnnouncement.vue')
    },
    {
      path: '/get-connected/stay-informed/announcements/add/add-regional-committee-announcement/:m?',
      name: 'add-regional-committee-announcement',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcAddRegionalCommitteeAnnouncement') ||
              'Add Regional Committee Announcement',
            parent: 'add-announcement'
          }
        },
        domain: 'stayinformed'
      },
      component: () => import('./views/admin/announcements/AddRegionalCommitteeAnnouncement.vue')
    },
    {
      path: '/admin/announcements/manage-announcements/:m?',
      name: 'manage-announcements',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcManageAnnouncements') || 'Manage Announcements',
            parent: 'announcements'
          }
        },
        domain: 'stayinformed'
      },
      component: () => import('./views/admin/announcements/ManageAnnouncements.vue')
    },
    {
      path: '/admin/announcements/announcementDetail/:m?',
      name: 'announcement-detail',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAnnouncementDetail') || 'Announcement Detail',
            parent: 'announcements'
          }
        },
        domain: 'stayinformed'
      },
      component: () => import('./views/admin/announcements/AnnouncementDetail.vue')
    },
    {
      path: '/admin/announcements/edit-announcement/:m?',
      name: 'edit-announcement',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditAnnouncement') || 'Edit Announcement',
            parent: 'manage-announcements'
          }
        },
        domain: 'stayinformed'
      },
      component: () => import('./views/admin/announcements/AnnouncementEdit.vue')
    },
    {
      path: '/get-connected/stay-informed/testimonies/:m?',
      name: 'testimonies',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcTestimonies') || 'Testimonies',
            parent: 'Home'
          }
        },
        domain: 'connected'
      },
      component: () => import('./views/get-connected/stay-informed/Testimonies.vue')
    },
    {
      path: '/get-connected/stay-informed/testimony/:m?',
      name: 'testimony',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcTestimony') || 'Testimony',
            parent: 'testimonies'
          }
        },
        domain: 'connected'
      },
      component: () => import('./views/get-connected/stay-informed/Testimony.vue')
    },
    {
      path: '/get-connected/stay-informed/submit-testimony/:m?',
      name: 'submit-testimony',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSubmitTestimony') || 'Submit Testimony',
            parent: 'testimonies'
          }
        },
        domain: 'connected'
      },
      component: () => import('./views/get-connected/stay-informed/SubmitTestimony.vue')
    },
    {
      path: '/get-connected/stay-informed/success-stories/:m?',
      name: 'success-stories',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSuccessStories') || 'Success Stories',
            parent: 'Home'
          }
        },
        domain: 'connected'
      },
      component: () => import('./views/get-connected/stay-informed/SuccessStories.vue')
    },
    {
      path: '/get-connected/stay-informed/success-story/:m?',
      name: 'success-story',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSuccessStory') || 'Success Story',
            parent: 'success-stories'
          }
        },
        domain: 'connected'
      },
      component: () => import('./views/get-connected/stay-informed/SuccessStory.vue')
    },
    {
      path: '/get-connected/stay-informed/submit-success-story/:m?',
      name: 'submit-success-story',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSubmitSuccessStory') || 'Submit Success Story',
            parent: 'success-stories'
          }
        },
        domain: 'connected'
      },
      component: () => import('./views/get-connected/stay-informed/SubmitTestimony.vue')
    },
    // ******************************************************************
    // State
    // ******************************************************************
    {
      path: '/reports/report-center/st/:m?',
      name: 'report-center-st',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcReports') || 'Reports',
            parent: 'Home'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ReportCenter.vue')
    },
    {
      path: '/officers/page/state-landing-page/:m?',
      name: 'state-officers-home',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcState') || 'State',
            parent: 'Home'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/landing-page/StateLandingPage.vue')
    },
    {
      path: '/officers/camp/treasury-tools/s/:m?',
      name: 'treasury-tools-st',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateTreasuryTools') || 'State Treasury Tools',
            parent: 'state-officers-home'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/camp/TreasuryTools.vue')
    },
    {
      path: '/officers/camp/treasury-tools/c/:m?',
      name: 'treasury-tools-camp',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampTreasuryTools') || 'Camp Treasury Tools',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/TreasuryTools.vue')
    },
    {
      path: '/officers/camp/goal-planner/st/:m?',
      name: 'goal-planner-st',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateGoalPlanner') || 'State Goal Planner',
            parent: 'state-officers-home'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/GoalPlanner.vue')
    },

    {
      path: '/get-connected/stay-informed/announcements/st/:m?',
      name: 'announcements-st',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateAnnouncements') || 'State Announcements',
            parent: 'Home'
          }
        },
        domain: 'connected'
      },
      component: () => import('./views/get-connected/stay-informed/Announcements.vue')
    },
    {
      path: '/programs/cm/church-list/st/:m?',
      name: 'church-list-st',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateChurchList') || 'State Church List',
            parent: 'state-officers-home'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/programs/cm/ChurchList.vue')
    },
    {
      path: '/programs/pw/prayer-center-daily/st/:m?',
      name: 'prayer-center-daily-st',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStatePrayerCenter') || 'State Prayer Center',
            parent: 'state-officers-home'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/programs/pw/PrayerCenterDaily.vue')
    },
    {
      path: '/programs/pw/prayer-center-monthly/st/:m?',
      name: 'prayer-center-monthly-st',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStatePrayerCenter') || 'State Prayer Center',
            parent: 'state-officers-home'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/programs/pw/PrayerCenterMonthly.vue')
    },
    {
      path: '/programs/mb/member-list/st/:m?',
      name: 'member-list-st',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateMemberList') || 'State Member List',
            parent: 'state-officers-home'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },

    // ******************************************************************
    // Prayer & Personal Witnessing
    // ******************************************************************
    {
      path: '/programs/pw/prayer-and-personal-witnessing/:m?',
      name: 'prayer-and-personal-witnessing',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPrayerPersonalWitnessing') || 'Prayer & Personal Witnessing',
            parent: 'Home'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/PrayerPersonalWitnessing.vue')
    },
    {
      path: '/programs/pw/personal-witnessing-encounters/:m?',
      name: 'personal-witnessing-encounters',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcPersonalWitnessingEncounters') || 'Personal Witnessing Encounters',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/PersonalWitnessingEncounters.vue')
    },
    {
      path: '/programs/pw/add-witnessing-encounter/:m?',
      name: 'add-witnessing-encounter',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddWitnessingEncounter') || 'Add Witnessing Encounter',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/AddWitnessingEncounter.vue')
    },
    {
      path: '/programs/pw/view-witnessing-encounter/:m?',
      name: 'view-witnessing-encounter',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcViewWitnessingEncounter') || 'View Witnessing Encounter',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/ViewWitnessingEncounter.vue')
    },
    {
      path: '/programs/pw/qualified-personal-witnessing-trainers/:m?',
      name: 'qualified-personal-witnessing-trainers',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcQualifiedPersonalWitnessingTrainers') ||
              'Qualified Personal Witnessing Trainers',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/Trainers.vue')
    },
    // {
    //   path: '/programs/pw/qualified-church-speakers/:m?',,
    //   name: 'qualified-church-speakers',
    //   meta: {
    //     breadcrumb: {
    //       label: 'Qualified Church Speakers',
    //       parent: 'prayer-and-personal-witnessing'
    //     },
    //     domain: 'prayer'
    //   },
    //   component: () => import('./views/programs/pw/Speakers.vue')
    // },
    {
      path: '/calendar/events/view-events/ce/:m?',
      name: 'view-events-ce',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchEngagementEvents') || 'Church Engagement Events',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/calendar/events/ViewEvents.vue')
    },
    {
      path: '/programs/pw/prayer-center-daily/:m?',
      name: 'prayer-center-daily',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPrayerCenterDaily') || 'Prayer Center Daily',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/PrayerCenterDaily.vue')
    },
    {
      path: '/programs/pc/prayer-center-monthly/:m?',
      name: 'prayer-center-monthly',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPrayerCenterMonthly') || 'Prayer Center Monthly',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/PrayerCenterMonthly.vue')
    },
    {
      path: '/programs/pc/prayer-details/:m?',
      name: 'prayer-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPrayerDetails') || 'Prayer Details',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/PrayerDetails.vue')
    },
    {
      path: '/programs/pc/prayer-request/details/:m?',
      name: 'prayer-request-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPrayerRequestDetails') || 'Prayer Request Details',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/PrayerRequestDetails.vue')
    },
    {
      path: '/programs/pc/add-edit-calendar/:m?',
      name: 'prayer-add-edit-calendar',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditCalendar') || 'Add Edit Calendar',
            parent: 'prayer-center-daily'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/AddEditCalendar.vue')
    },
    {
      path: '/programs/pc/upload-prayer-calendar/:m?',
      name: 'prayer-calendar-upload',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcUploadPrayerCalendar') || 'Upload Prayer Calendar',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/events/UploadPrayerCalendar.vue')
    },
    {
      path: '/programs/pc/edit-calendar/:m?',
      name: 'prayer-edit-calendar',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditCalendar') || 'Edit Calendar',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/events/EditCalendar.vue')
    },
    {
      path: '/programs/pc/edit-prayer-request/:m?',
      name: 'prayer-edit-request',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditPrayerRequest') || 'Edit Prayer Request',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/events/EditPrayerRequest.vue')
    },
    {
      path: '/programs/pc/edit-calendar-item/:m?',
      name: 'prayer-edit-calendar-item',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditCalendarItem') || 'Add/Edit Calendar Item',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/events/EditCalendarItem.vue')
    },
    {
      path: '/programs/pc/add-edit-prayer-requests/:m?',
      name: 'prayer-add-edit-request',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEditPrayerRequest') || 'Add/Edit Prayer Request',
            parent: 'prayer-center-daily'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/AddEditPrayerRequest.vue')
    },
    {
      path: '/programs/pc/manage-calendar/:m?',
      name: 'prayer-manage-calendar',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcManageCalendar') || 'Manage Calendar',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/ManageCalendar.vue')
    },
    {
      path: '/get-connected/onevision/one-vision/:m?',
      name: 'one-vision',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcOneVision') || 'One Vision',
            parent: 'Home'
          }
        },
        domain: 'onevision'
      },
      component: () => import('./views/get-connected/onevision/OneVision.vue')
    },
    // ******************************************************************
    // Directory
    // ******************************************************************
    {
      path: '/programs/mb/member-list/dir/:m?',
      name: 'member-list-dir',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampOfficers') || 'Camp Officers',
            parent: 'Home'
          }
        },
        domain: 'directory'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },
    {
      path: '/programs/mb/member-list/sco/:m?',
      name: 'member-list-sco',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateOfficers') || 'State Officers',
            parent: 'Home'
          }
        },
        domain: 'directory'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },
    {
      path: '/programs/mb/member-list/cmb/:m?',
      name: 'member-list-cmb',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampMembers') || 'Camp Members',
            parent: 'Home'
          }
        },
        domain: 'directory'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },
    {
      path: '/programs/mb/sponsorcandidate/:m?',
      name: 'sponsorcandidate',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCandidateInfo') || 'Candidate Info',
            parent: 'membership'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/CandidateInfo.vue')
    },
    {
      path: '/programs/mb/sponsorauxiliarycandidate/:m?',
      name: 'sponsorauxiliarycandidate',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCandidateInfo') || 'Auxiliary Candidate Info',
            parent: 'membership'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/AuxiliaryCandidateInfo.vue')
    },
    {
      path: '/programs/mb/success/:m?',
      name: 'success',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSuccess') || 'Success',
            parent: 'membership'
          }
        },
        domain: 'membership'
      },
      component: () => import('./views/programs/mb/Success.vue')
    },
    {
      path: '/programs/magazine/home/:m?',
      name: 'magazine-home',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMagazine') || 'Magazine',
            parent: 'media-resource-portal'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/magazine/Home.vue')
    },
    {
      path: '/programs/magazine/admin/:m?',
      name: 'magazine-admin',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAdmin') || 'Admin',
            parent: 'magazine-home'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/magazine/Admin.vue')
    },
    {
      path: '/programs/magazine/pdfpreview/:m?',
      name: 'pdfpreview',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPdfPreview') || 'PdfPreview',
            parent: 'magazine-home'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/magazine/PdfPreview.vue')
    },
    {
      path: '/programs/mb/member-list/scm/:m?',
      name: 'member-list-scm',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateMembers') || 'State Members',
            parent: 'Home'
          }
        },
        domain: 'directory'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },
    {
      path: '/programs/mb/member-list/oth/:m?',
      name: 'member-list-oth',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcOtherMembers') || 'Other Members',
            parent: 'Home'
          }
        },
        domain: 'directory'
      },
      component: () => import('./views/programs/mb/MemberList.vue')
    },

    // ******************************************************************
    // Camp
    // ******************************************************************
    {
      path: '/officers/camp/camp-officers-home/:m?',
      name: 'camp-officers-home',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCamp') || 'Camp',
            parent: 'Home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/CampOfficers.vue')
    },
    {
      path: '/officers/camp/camp-member-churches/:m?',
      name: 'camp-member-churches',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCamp') || 'Camp',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/CampMemberChurches.vue')
    },
    {
      path: '/officers/camp/treasury-tools/:m?',
      name: 'treasury-tools',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampTreasuryTools') || 'Camp Treasury Tools',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/TreasuryTools.vue')
    },
    {
      path: '/officers/camp/election/view-camp-election/:m?',
      name: 'view-camp-election',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampElection') || 'Camp Election',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/election/ViewCampElection.vue')
    },
    {
      path: '/officers/camp/election/edit-camp-election/:m?',
      name: 'edit-camp-election',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditCampElection') || 'Edit Camp Election',
            parent: 'view-camp-election'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/election/EditCampElection.vue')
    },
    {
      path: '/officers/camp/goal-planner/cm/:campGoalName?',
      name: 'goal-planner-cm',
      props: true,
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcGoalPlanner') || 'Goal Planner',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/GoalPlanner.vue')
    },
    {
      path: '/officers/camp/validate-membership-applications/cm/:m?',
      name: 'validate-membership-applications-cm',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcValidateMembershipApplications') ||
              'Validate Membership Applications',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/ValidateMembershipApplications.vue')
    },
    {
      path: '/officers/camp/digital-membership-candidate/',
      name: 'digital-membership-candidate',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('digitalMembershipCandidate') || 'Digital Membership Candidate',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/DigitalMemberDetailsView.vue')
    },
    {
      path: '/officers/camp/camp-profile/cm/:m?',
      name: 'camp-profile-cm',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampProfile') || 'Camp Profile',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/CampProfile.vue')
    },
    {
      path: '/officers/camp/camp-profile/edit/cm/:m?',
      name: 'edit-camp-profile-cm',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEditCampProfile') || 'Edit Camp Profile',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/EditCampProfile.vue')
    },
    {
      path: '/officers/camp/website-security-manager/cm/:m?',
      name: 'website-security-manager-cm',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcWebsiteSecurityManager') || 'Website Security Manager',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/officers/camp/WebsiteSecurityManager.vue')
    },
    {
      path: '/officers/state/state-directory/:m?',
      name: 'state-directory',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcOtherStateLists') || 'Other State Lists',
            parent: 'state-landing-page'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/StateDirectory.vue')
    },
    {
      path: '/officers/state/country-directory/:m?',
      name: 'country-directory-new',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCountryDirectory') || 'Country Directory',
            parent: 'country-landing-page'
          }
        },
        domain: 'country'
      },
      component: () => import('./views/officers/state/CountryDirectory.vue')
    },
    {
      path: '/reports/report-center/cn/:m?',
      name: 'report-center-cn',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcReports') || 'Reports',
            parent: 'country-directory-new'
          }
        },
        domain: 'reports'
      },
      component: () => import('./views/reports/ReportCenter.vue')
    },
    {
      path: '/officers/directory/membership-dinner-speakers/:m?',
      name: 'membership-dinner-speakers',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcAnnualMembershipDinnerSpeakers') ||
              'Annual Membership Dinner Speakers',
            parent: 'state-directory'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/MembershipDinnerSpeakers.vue')
    },
    {
      path: '/officers/directory/country/membership-dinner-speakers/:m?',
      name: 'membership-dinner-speakers-country',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcAnnualMembershipDinnerSpeakers') ||
              'Annual Membership Dinner Speakers',
            parent: 'country-directory-new'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/MembershipDinnerSpeakers.vue')
    },
    {
      path: '/officers/directory/membership-dinner-speakers/upsert/:m?',
      name: 'membership-dinner-speakers-upsert',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcAnnualDinnerMembershipSpeakersAddEdit') ||
              'Annual Dinner Membership Speakers Add/Edit',
            parent: 'membership-dinner-speakers'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/events/UpsertAMDinnerSpeakers.vue')
    },
    {
      path: '/officers/directory/country/membership-dinner-speakers/upsert/:m?',
      name: 'membership-dinner-speakers-upsert-country',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcDinnerMembershipSpeakersAddEdit') ||
              'Dinner Membership Speakers Add/Edit',
            parent: 'membership-dinner-speakers-country'
          }
        },
        domain: 'country'
      },
      component: () => import('./views/officers/state/events/UpsertAMDinnerSpeakers.vue')
    },
    {
      path: '/officers/directory/pastor-banquet-speakers/:m?',
      name: 'pastors-banquet-speakers',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPastorBanquetSpeakers') || 'Pastor Banquet Speakers',
            parent: 'state-directory'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/AnnualPastorsEventSpeakers.vue')
    },
    {
      path: '/officers/directory/country/pastor-banquet-speakers/:m?',
      name: 'pastors-banquet-speakers-country',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPastorBanquetSpeakers') || 'Pastor Banquet Speakers',
            parent: 'country-directory-new'
          }
        },
        domain: 'country'
      },
      component: () => import('./views/officers/state/AnnualPastorsEventSpeakers.vue')
    },
    {
      path: '/officers/directory/pastor-banquet-speakers/upsert/:m?',
      name: 'pastors-banquet-speakers-upsert',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcPastorsBanquetSpeakersAddEdit') ||
              'Pastors Banquet Speakers Add/Edit',
            parent: 'pastors-banquet-speakers'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/events/UpsertPastorBanquetSpeakers.vue')
    },
    {
      path: '/officers/directory/country/pastor-banquet-speakers/upsert/:m?',
      name: 'pastors-banquet-speakers-upsert-country',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcPastorsBanquetSpeakersAddEdit') ||
              'Pastors Banquet Speakers Add/Edit',
            parent: 'pastors-banquet-speakers-country'
          }
        },
        domain: 'country'
      },
      component: () => import('./views/officers/state/events/UpsertPastorBanquetSpeakers.vue')
    },
    {
      path: '/officers/directory/qualified-speakers/:m?',
      name: 'qualified-speakers-state',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcQualifiedSpeakers') || 'Qualified Speakers',
            parent: 'state-directory'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/QualifiedSpeakers.vue')
    },
    {
      path: '/officers/directory/country/qualified-speakers/:m?',
      name: 'qualified-speakers-country',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcQualifiedSpeakers') || 'Qualified Speakers',
            parent: 'country-directory-new'
          }
        },
        domain: 'country'
      },
      component: () => import('./views/officers/state/QualifiedSpeakers.vue')
    },
    {
      path: '/officers/directory/camp-officer-selections/:m?',
      name: 'camp-officer-selections',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampOfficerSelections') || 'Camp Officer Selections',
            parent: 'state-directory'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/CampOfficerSelections.vue')
    },
    {
      path: '/officers/directory/country/camp-officer-selections/:m?',
      name: 'camp-officer-selections-country',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampOfficerSelections') || 'Camp Officer Selections',
            parent: 'country-directory-new'
          }
        },
        domain: 'country'
      },
      component: () => import('./views/officers/state/CampOfficerSelections.vue')
    },
    {
      path: '/officers/directory/scripture-blitz-speakers/:m?',
      name: 'scripture-blitz-speakers',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcScriptureBlitzParticipants') || 'Scripture Blitz Participants',
            parent: 'state-directory'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/ScriptureBlitzSpeakers.vue')
    },
    {
      path: '/officers/directory/directory-camp/:m?',
      name: 'directory-camp',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCamp') || 'Camp',
            parent: 'state-directory'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/StateCamp.vue')
    },
    {
      path: '/officers/directory/country/directory-camp/:m?',
      name: 'directory-camp-country',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCamp') || 'Camp',
            parent: 'country-directory-new'
          }
        },
        domain: 'country'
      },
      component: () => import('./views/officers/state/StateCamp.vue')
    },
    {
      path: '/officers/directory/members-list/:m?',
      name: 'members-list-state',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMembersList') || 'Members List',
            parent: 'state-landing-page'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/MembersList.vue')
    },
    {
      path: '/officers/country/directory/members-list/:m?',
      name: 'members-list',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMembersList') || 'Members List',
            parent: 'country-landing-page'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/MembersList.vue')
    },
    {
      path: '/officers/directory/country/members-list/:m?',
      name: 'members-list-country',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMembersList') || 'Members List',
            parent: 'country-directory-new'
          }
        },
        domain: 'country'
      },
      component: () => import('./views/officers/state/MembersList.vue')
    },
    {
      path: '/officers/directory/testimony-speakers/:m?',
      name: 'testimony-speakers',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcTestimonySpeakers') || 'Testimony Speakers',
            parent: 'state-directory'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/TestimonySpeakers.vue')
    },
    {
      path: '/officers/directory/testimony-speaker/add/:m?',
      name: 'add-testimony-speaker',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcTestimonySpeaker') || 'Testimony Speaker',
            parent: 'testimony-speakers'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/events/AddTestimonySpeaker.vue')
    },
    {
      path: '/officers/directory/testimony-speaker/create-non-member/:m?',
      name: 'testimony-speaker-create-non-member',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAdd') || 'Add',
            parent: 'add-testimony-speaker'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/events/CreateNonMember.vue')
    },
    {
      path: '/officers/directory/widows-list/:m?',
      name: 'widows-list',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcWidowsList') || 'Widows List',
            parent: 'state-directory'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/state/WidowsList.vue')
    },
    {
      path: '/officers/state/websecurity/add-member/:m?',
      name: 'add-member-to-security',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddStateWebSecurity') || 'Add State Web Security',
            parent: 'officers-security-manager'
          }
        },
        domain: 'state'
      },
      component: () => import('./views/officers/landing-page/event/AddMemberToSecurity.vue')
    },
    {
      path: '/programs/cm/church-list-camp/:m?',
      name: 'church-list-camp',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcChurchList') || 'Church List',
            parent: 'camp-officers-home'
          }
        },
        domain: 'camp'
      },
      component: () => import('./views/programs/cm/ChurchList.vue')
    },
    {
      path: '/officers/country/camp-visitation/:m?',
      name: 'camp-visitation',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampVisitation') || 'Camp Visitation',
            parent: 'Home'
          }
        },
        domain: 'countryofficers'
      },
      component: () => import('./views/officers/country/909Information.vue')
    },
    {
      path: '/officers/committees/committee/fr/3B140DC4-70C6-4343-A8E8-9C971FB0843F/:m?',
      name: 'committee-fr',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCommittee') || 'Committee',
            parent: 'Home'
          }
        },
        domain: 'committeeofficers'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/apcx/753CB8F1-3279-45DE-AD92-919802C7690B/:m?',
      name: 'committee-apcx',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCommittee') || 'Committee',
            parent: 'Home'
          }
        },
        domain: 'committeeofficers'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/apcc/B1CCCD1E-810C-4B80-BCF3-601E1AC52F08/:m?',
      name: 'committee-apcc',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCommittee') || 'Committee',
            parent: 'Home'
          }
        },
        domain: 'committeeofficers'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ipc/9BA561FE-DC86-429B-8E7F-06FBC6BA26D1/:m?',
      name: 'committee-ipc',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCommittee') || 'Committee',
            parent: 'Home'
          }
        },
        domain: 'committeeofficers'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/hq/2BF4BCED-28DB-4A4A-9E47-B0721C4A0EDE/:m?',
      name: 'committee-hq',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcHeadquartersCommittee') || 'Headquarters Committee',
            parent: 'Home'
          }
        },
        domain: 'committeeofficers'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/d8c5492a-32eb-4e75-b63a-a123fd6b895b/:m?',
      name: 'committee-ioc-d8c5492a-32eb-4e75-b63a-a123fd6b895b',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcAfricaNorthandMiddleEastRegion') ||
              'Africa North and Middle East Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/bb28a640-2841-409e-968b-4356939e391d/:m?',
      name: 'committee-ioc-bb28a640-2841-409e-968b-4356939e391d',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAfricaSouthRegion') || 'Africa South Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/893b59f6-b0fc-4e26-9369-a649741f0469/:m?',
      name: 'committee-ioc-893b59f6-b0fc-4e26-9369-a649741f0469',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAfricaWestRegion') || 'Africa West Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/cd9bd4a5-d0bc-44a0-89e6-dd09e37c0fd1/:m?',
      name: 'committee-ioc-cd9bd4a5-d0bc-44a0-89e6-dd09e37c0fd1',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAsiaRegion') || 'Asia Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/a38d3a32-70e4-4ea8-8b89-96a242e7de59/:m?',
      name: 'committee-ioc-a38d3a32-70e4-4ea8-8b89-96a242e7de59',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEuropeCentralRegion') || 'Europe Central Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/b3b0541f-7ec5-48b7-8aba-b00b86b6c408/:m?',
      name: 'committee-ioc-b3b0541f-7ec5-48b7-8aba-b00b86b6c408',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEuropeEastRegion') || 'Europe East Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/33ac1e1b-e801-4deb-a8fb-8bf3f9f9541a/:m?',
      name: 'committee-ioc-33ac1e1b-e801-4deb-a8fb-8bf3f9f9541a',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEuropeWestRegion') || 'Europe West Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/1988b39c-8d2a-4247-98f0-33e638aa41b3/:m?',
      name: 'committee-ioc-1988b39c-8d2a-4247-98f0-33e638aa41b3',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcLatinAmericaCentralRegion') || 'Latin America Central Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/a26c719f-f526-484f-ba59-1a3e180934eb/:m?',
      name: 'committee-ioc-a26c719f-f526-484f-ba59-1a3e180934eb',
      meta: {
        breadcrumb() {
          return {
            label:
              breadcrumbService.getTranslation('tcLatinAmericaNorthandCaribbeanRegion') ||
              'Latin America North and Caribbean Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/f9cb99b8-15da-4e32-8b01-2251ac285c24/:m?',
      name: 'committee-ioc-f9cb99b8-15da-4e32-8b01-2251ac285c24',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcLatinAmericaSouthRegion') || 'Latin America South Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/79a7d2a6-fced-4ccf-a635-b38c443e834f/:m?',
      name: 'committee-ioc-79a7d2a6-fced-4ccf-a635-b38c443e834f',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcPacificRimRegion') || 'Pacific Rim Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ioc/0c9709f9-7aec-46ef-8862-a9a12eb32747/:m?',
      name: 'committee-ioc-0c9709f9-7aec-46ef-8862-a9a12eb32747',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcInternationalOutreachRegion') || 'International Outreach Region',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ic/A73CFBC5-2A89-4118-B978-222B61CDAB5E/:m?',
      name: 'committee-ic',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCommittee') || 'Committee',
            parent: 'Home'
          }
        },
        domain: 'committeeofficers'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/ifc/35D9E86B-DC68-4F06-9226-CAC1FACA7B2C/:m?',
      name: 'committee-ifc',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCommittee') || 'Committee',
            parent: 'Home'
          }
        },
        domain: 'committeeofficers'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/iac/FF50FB70-3906-423B-B688-09BFCEC11096/:m?',
      name: 'committee-iac',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCommittee') || 'Committee',
            parent: 'Home'
          }
        },
        domain: 'committeeofficers'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/committees/committee/iec/A2B0496C-C968-4BBF-A5AF-8CB44347C7DE/:m?',
      name: 'committee-iec',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCommittee') || 'Committee',
            parent: 'Home'
          }
        },
        domain: 'committeeofficers'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/help/site-help/:m?',
      name: 'site-help',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcHelp') || 'Help'
          }
        },
        domain: 'help'
      },
      component: () => import('./views/help/SiteHelp.vue')
    },
    {
      path: '/help/faqs/:m?',
      name: 'faqs',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcFAQs') || 'FAQs',
            parent: 'site-help'
          }
        },
        domain: 'help'
      },
      component: () => import('./views/help/FAQ.vue')
    },
    {
      path: '/help/contact-us/:m?',
      name: 'contact-us',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcContactUs') || 'Contact Us',
            parent: 'site-help'
          }
        },
        domain: 'help'
      },
      component: () => import('./views/help/ContactUs.vue')
    },
    {
      path: '/help/not-found/:m?',
      name: 'not-found',
      meta: {
        breadCrumb() {
          return {
            label: breadcrumbService.getTranslation('tcNotFound') || 'Not Found',
            parent: 'Home'
          }
        },
        domain: 'help'
      },
      component: () => import('./views/help/NotFound.vue')
    },
    {
      path: '/programs/mb/member-profile/:m?',
      name: 'user-profile',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberProfile') || 'Member Profile',
            parent: 'Home'
          }
        },
        domain: 'user'
      },
      component: () => import('./views/programs/mb/MemberProfile.vue')
    },
    {
      path: '/user/view-roles/:m?',
      name: 'view-roles',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcViewMemberRoles') || 'View Member Roles',
            parent: 'user-profile'
          }
        },
        domain: 'user'
      },
      component: () => import('./views/user/ViewMemberRoles.vue')
    },
    {
      path: '/user/renew-membership/:m?',
      name: 'renew-membership',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcRenewMembership') || 'Renew Membership',
            parent: 'user-profile'
          }
        },
        domain: 'user'
      },
      component: () => import('./views/user/RenewMembership.vue')
    },
    {
      path: '/search/search-site/:m?',
      name: 'search-site',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSearchSite') || 'Search Site',
            parent: 'Home'
          }
        },
        domain: 'search'
      },
      component: () => import('./views/search/Search.vue')
    },
    {
      path: '/calendar/state/event/state-event-view/:m?',
      name: 'state-event-grid',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateCalendarEventGrid') || 'State Calendar Event Grid',
            parent: 'calendar-month'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/state-calendar/StateCalendarEvent.vue')
    },
    {
      path: '/calendar/country/event/state-event-view/:m?',
      name: 'country-event-grid',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCountryCalendarEventGrid') || 'Country Calendar Event Grid',
            parent: 'calendar-month'
          }
        },
        domain: 'events'
      },
      component: () => import('./views/calendar/state-calendar/StateCalendarEvent.vue')
    },
    // {
    //   path: '/officers/treasury-tools/membershipRenewal',
    //   name: 'membershipRenewal',
    //   meta: {
    //     breadcrumb() {
    //       return {
    //         label: 'Membership Renewal',
    //         parent: 'treasury-tools-camp'
    //       }
    //     },
    //     domain: 'officers'
    //   },
    //   component: () => import('./views/officers/camp/treasury-tools/MembershipRenewal.vue')
    // },
    {
      path: '/officers/treasury-tools/E503View/c/:m?',
      name: 'e503-view-camp',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcE503Funds') || 'E503 Funds',
            parent: 'treasury-tools-camp'
          }
        },
        domain: 'officers'
      },
      component: () => import('./views/officers/camp/treasury-tools/E503View.vue')
    },
    {
      path: '/officers/treasury-tools/E503View/s/:m?',
      name: 'e503-view-state',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcE503Funds') || 'E503 Funds',
            parent: 'treasury-tools-st'
          }
        },
        domain: 'officers'
      },
      component: () => import('./views/officers/camp/treasury-tools/E503View.vue')
    },
    {
      path: '/officers/treasury-tools/E503M/c/:m?',
      name: 'e503M-view-camp',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcE503MFunds') || 'E503M Funds',
            parent: 'treasury-tools-camp'
          }
        },
        domain: 'officers'
      },
      component: () => import('./views/officers/camp/treasury-tools/E503M.vue')
    },
    {
      path: '/officers/treasury-tools/E503M/s/:m?',
      name: 'e503M-view-state',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcE503MFunds') || 'E503M Funds',
            parent: 'treasury-tools-st'
          }
        },
        domain: 'officers'
      },
      component: () => import('./views/officers/camp/treasury-tools/E503M.vue')
    },
    {
      path: '/officers/camp/treasury-tools/PendingRemittance/:showTable/:m?',
      name: 'pending-remittance-camp',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCampRemittance') || 'Camp Remittance',
            parent: 'treasury-tools-camp'
          }
        },
        domain: 'officers'
      },
      component: () => import('./views/officers/camp/treasury-tools/PendingRemittance.vue')
    },
    {
      path: '/officers/state/treasury-tools/PendingRemittance/:showTable/:m?',
      name: 'pending-remittance-state',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateRemittance') || 'State Remittance',
            parent: 'treasury-tools-st'
          }
        },
        domain: 'officers'
      },
      component: () => import('./views/officers/camp/treasury-tools/PendingRemittance.vue')
    },
    {
      path: '/officers/state/treasury-tools/Activity/:m?',
      name: 'activity-st',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcActivity') || 'Activity',
            parent: 'treasury-tools-st'
          }
        },
        domain: 'officers'
      },
      component: () => import('./views/officers/camp/treasury-tools/Activity.vue')
    },
    {
      path: '/officers/state/treasury-tools/BankAccount/:m?',
      name: 'bank-account-st',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcBankAccount') || 'Bank Account',
            parent: 'treasury-tools-st'
          }
        },
        domain: 'officers'
      },
      component: () => import('./views/officers/camp/treasury-tools/BankAccount.vue')
    },
    {
      path: '/officers/camp/treasury-tools/BankAccount/:m?',
      name: 'bank-account',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcBankAccount') || 'Bank Account',
            parent: 'treasury-tools-camp'
          }
        },
        domain: 'officers'
      },
      component: () => import('./views/officers/camp/treasury-tools/BankAccount.vue')
    },
    {
      path: '/officers/camp/treasury-tools/Activity/:m?',
      name: 'activity',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcActivity') || 'Activity',
            parent: 'treasury-tools-camp'
          }
        },
        domain: 'officers'
      },
      component: () => import('./views/officers/camp/treasury-tools/Activity.vue')
    },
    // ******************************************************************
    // Gideon Store
    // ******************************************************************
    // NOTE: These routes are in a specific order. Please preserve this order.
    // and make sure the programs root landing page is at the bottom of the list.
    {
      path: '/resources/store/cart/checkout/:m?',
      name: 'store-cart-checkout',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCheckout') || 'Checkout',
            parent: 'store-cart'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/store/CartCheckout.vue')
    },
    {
      path: '/resources/store/products/:subcat_key?/:m?',
      name: 'store-category-products',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCategoryProducts') || 'Category Products',
            parent: 'store-home'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/store/CategoryProducts.vue')
    },
    {
      path: '/resources/store/products', // NOTE: The optional menu variable (:m?) is not needed here.
      name: 'store-products',
      meta: {
        breadcrumb: {
          label: breadcrumbService.getTranslation('tcCategoryProducts') || 'Category Products',
          parent: 'store-home'
        },
        domain: 'media'
      },
      component: () => import('./views/resources/store/CategoryProducts.vue')
    },
    {
      path: '/resources/store/product-details/:prd_key?/:m?',
      name: 'store-product-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcProductDetails') || 'Product Details',
            parent: 'store-category-products'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/store/ProductDetail.vue')
    },
    {
      path: '/resources/store/order-confirmation/:m?',
      name: 'store-confirmation',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcOrderConfirmation') || 'Order Confirmation',
            parent: 'store-home'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/store/OrderConfirmation.vue')
    },
    {
      path: '/resources/store/orders/:m?',
      name: 'store-orders',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcOrders') || 'Orders',
            parent: 'store-home'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/store/Orders.vue')
    },
    {
      path: '/resources/store/search/:m?',
      name: 'store-search',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSearch') || 'Search',
            parent: 'store-home'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/store/StoreSearch.vue')
    },
    {
      path: '/resources/store/favorites/:m?',
      name: 'store-favorites',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcFavorites') || 'Favorites',
            parent: 'store-home'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/store/Favorites.vue')
    },
    {
      path: '/resources/store/cart/:m?',
      name: 'store-cart',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCart') || 'Cart',
            parent: 'store-home'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/store/Cart.vue')
    },
    {
      path: '/resources/store/:m?',
      name: 'store-home',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStore') || 'Store',
            parent: 'Home'
          }
        },
        domain: 'media'
      },
      component: () => import('./views/resources/store/GideonStore.vue')
    },
    // ********************************
    //  Donation Page
    // *******************************

    {
      path: '/resources/donations/',
      name: 'donations',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcDonation') || 'Donations',
            parent: 'Home'
          }
        },
        domain: 'donations'
      },
      component: () => import('./views/resources/Donations.vue')
    },

    // ********************************
    //  Officers landing pages
    // *******************************
    {
      path: '/officers/page/state-landing-page/:m?',
      name: 'state-landing-page',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateHome') || 'State Home',
            parent: 'Home'
          }
        },
        domain: 'officer'
      },
      component: () => import('./views/officers/landing-page/StateLandingPage.vue')
    },
    {
      path: '/officers/page/country-landing-page/:m?',
      name: 'country-landing-page',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCountryHome') || 'Country Home',
            parent: 'Home'
          }
        },
        domain: 'country'
      },
      component: () => import('./views/officers/landing-page/CountryLandingPage.vue')
    },
    {
      path: '/officers/page/committee-landing-page/:m?',
      name: 'committee-landing-page',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCommitteeHome') || 'Committee Home',
            parent: 'Home'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/CommitteeLandingPage.vue')
    },
    {
      path: '/officers/page/officers-security-manager/:m?',
      name: 'officers-security-manager',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcSecurityManager') || 'Security Manager',
            parent: 'state-landing-page'
          }
        },
        domain: 'officer'
      },
      component: () => import('./views/officers/landing-page/OfficerSecurityManager.vue')
    },
    {
      path: '/officers/page/state-member-list/:m?',
      name: 'state-member-list',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberList') || 'Member List',
            parent: 'state-landing-page'
          }
        },
        domain: 'officer'
      },
      component: () => import('./views/officers/landing-page/StateMemberList.vue')
    },
    {
      path: '/officers/page/country-member-list/:m?',
      name: 'country-member-list',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcMemberList') || 'Member List',
            parent: 'country-landing-page'
          }
        },
        domain: 'officer'
      },
      component: () => import('./views/officers/landing-page/CountryMemberList.vue')
    },
    {
      path: '/officers/page/officers-committe-position/:m?',
      name: 'officers-committe-position',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCommitteePositions') || 'Committee Positions',
            parent: 'committee-landing-page'
          }
        },
        domain: 'committee'
      },
      component: () => import('./views/officers/landing-page/OfficerCommitteePosition.vue')
    },
    {
      path: '/officers/state/leadership-management-team/:m?',
      name: 'leadership-management-team-state',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcStateManagementTeam') || 'State Management Team',
            parent: 'state-landing-page'
          }
        },
        domain: 'officer'
      },
      component: () => import('./views/officers/landing-page/LeadershipMangementTeam.vue')
    },
    {
      path: '/officers/country/leadership-management-team/:m?',
      name: 'leadership-management-team-country',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcCountryManagementTeam') || 'Country Management Team',
            parent: 'country-landing-page'
          }
        },
        domain: 'officer'
      },
      component: () => import('./views/officers/landing-page/LeadershipMangementTeam.vue')
    },

    // ******************************************************************
    // Conversations
    // ******************************************************************
    {
      path: '/programs/pw/conversations/instructors',
      name: 'conversations-instructors',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcInstructors') || 'Instructors',
            parent: 'conversations-landing-page'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/Instructors.vue')
    },
    {
      path: '/programs/pw/conversations/instructor-history',
      name: 'conversations-instructors-history',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcHistory') || 'History',
            parent: 'conversations-instructors'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/InstructorHistory.vue')
    },
    {
      path: '/programs/pw/conversations/instructors/add',
      name: 'conversations-instructors-addedit',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAdd') || 'Add Instructor',
            parent: 'conversations-instructors'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/AddEditInstructor.vue')
    },
    {
      path: '/programs/pw/conversations/educational-units',
      name: 'conversations-educational-units',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcEducationalUnits') || 'Educational Units',
            parent: 'conversations-landing-page'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/EducationalUnits.vue')
    },
    {
      path: '/programs/pw/conversations/educational-unit-history',
      name: 'conversations-educational-unit-history',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcHistory') || 'History',
            parent: 'conversations-educational-units'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/EducationalUnitHistory.vue')
    },
    {
      path: '/programs/pw/conversations/individual-history',
      name: 'conversations-individual-history',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcHistory') || 'History',
            parent: 'conversations-landing-page'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/IndividualHistory.vue')
    },
    {
      path: '/programs/pw/conversations/educational-unit/add',
      name: 'conversations-educational-unit-addedit',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAdd') || 'Add Educational Unit',
            parent: 'conversations-educational-units'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/AddEditEducationalUnit.vue')
    },
    {
      path: '/programs/pw/conversations/add',
      name: 'conversations-add',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAdd') || 'Add',
            parent: 'conversations-landing-page'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/AddConversationDist.vue')
    },
    {
      path: '/programs/pw/conversations/add/event-details',
      name: 'conversations-add-event-details',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddEventDetails') || 'Add Event Details',
            parent: 'conversations-landing-page'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/AddConversationEventDetails.vue')
    },
    {
      path: '/programs/pw/conversations/add/attendees',
      name: 'conversations-add-attendees',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddAttendees') || 'Add Attendees',
            parent: 'conversations-landing-page'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/AddConversationAttendees.vue')
    },
    {
      path: '/programs/pw/conversations/add/review',
      name: 'conversations-add-review',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcAddReview') || 'Review',
            parent: 'conversations-landing-page'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/AddConversationReview.vue')
    },
    {
      path: '/programs/pw/conversations/info',
      name: 'conversation-info',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcInfo') || 'Info',
            parent: 'conversations-landing-page'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/ConversationInfo.vue')
    },
    {
      path: '/programs/pw/conversations/list',
      name: 'conversation-list',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcConversationsList') || 'Conversations Listx',
            parent: 'conversations-landing-page'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/ConversationsList.vue')
    },
    {
      path: '/programs/pw/conversations/',
      name: 'conversations-landing-page',
      meta: {
        breadcrumb() {
          return {
            label: breadcrumbService.getTranslation('tcConversations') || 'Conversations',
            parent: 'prayer-and-personal-witnessing'
          }
        },
        domain: 'prayer'
      },
      component: () => import('./views/programs/pw/conversations/Conversations.vue')
    },
    // ******************************************************************
    // National Association Goal Planning
    // ******************************************************************
    {
      path: '/national-association-goal-planning',
      name: 'national-association-goal-planning',
      meta: {
        breadcrumb() {
          return {
            label: 'NAGP',
            parent: 'country-landing-page'
          }
        },
      },
      component: () => import('./views/nagp/NAGP.vue')
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  //Only allow access to login if not authorized
  if (to.name === 'login') {
    let authTest = store.getters['user/authenticated']
    if (authTest) {
      next('/')
    }
  }

  if (!!to.path && (to.path.includes('menu') || to.path === '/')) {
    await store.dispatch('user/saveOfficerToolbarDomainSelection', {
      domainchanged: from.meta.domain !== to.meta.domain,
      to: to.meta.domain,
      from: from.meta.domain
    })
  } else {
    to.params.m = ''
  }
  // This section is for if you need to run some code before going
  // to a section of the connection
  // 1) Put the area you want to run code in the pathFragments array
  // 2) Add a function name to the fragmentFunctions object
  // 3) Place the actual code in the functions section below
  // The functions could be refactored out to their own file later
  // if too many end up being used
  let pathFragments = ['/resources/program-resources/', '/programs/pw/conversations']

  let fragmentFunctions = {
    '/resources/program-resources/': () => programResources(to, next),
    '/programs/pw/conversations': () => conversations()
  }

  let foundFragment = pathFragments.find(fragment => to.path.includes(fragment))
  if (foundFragment) {
    await fragmentFunctions[foundFragment]()
  }
  next()
})

//Functions called from the pathFragments portion above
async function programResources(to, next) {
  let urlCategoryCode = to.path.split('/')[3]
  await store
    .dispatch('mrp/loadMRPPrograms', {
      lang: 'en',
      landingpage: 'MRP_Landing'
    })
    .then(() => {
      try {
        let pgmId = store.getters['mrp/mrpPrograms'].find(mp => mp.CategoryCode === urlCategoryCode).ProgramId
          ; (async () => {
            await store.dispatch('mrp/setMRPProgramId', pgmId)
            await store.dispatch('mrp/setMRPSelectedCategory', urlCategoryCode)
            next({
              path: '/resources/media-portal/program'
            })
          })()
      } catch (e) {
        next({
          path: '/resources/media-portal'
        })
      }
    })
}

async function conversations() {
  let instructors = store.getters['conversations/instructors']
  let educational_units = store.getters['conversations/educational_units']
  if (instructors.length === 0 || educational_units.length === 0) {
    try {
      await store.dispatch('menu/setLoadingStatus', true)
      let otf = store.getters['user/officerToolbarSelected']
      let userLanguageKey = store.getters['user/userLanguageKey']

      const payload = {
        org_key: otf.country_state,
        lang_key: userLanguageKey
      }
      await Promise.all([
        store.dispatch('conversations/loadInstructors', payload),
        store.dispatch('conversations/loadEducationalUnits', payload)
      ])
    } catch (e) {
      console.error('Error in loading instructors and EUs', e)
    } finally {
      await store.dispatch('menu/setLoadingStatus', false)
    }
  }
}

export default router
