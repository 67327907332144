/* eslint-disable */

export const REPORT_CONFIG = {
  generateReportPDF: params => {
    var apiEndpoint = ''
    var queryString = '?'
    for (const property in params) {
      if (property == 'api_endpoint') {
        apiEndpoint = params[property]
      } else {
        queryString += `${property}=${params[property]}&`
      }
    }
    // get rid of the last "&"
    queryString = queryString.substring(0, queryString.length - 1)
    var downloadUrl = `${apiEndpoint}${queryString}`
    return downloadUrl
  },
  candidateDetailReport: (ind_key, lng_key, format) =>
    `CandidateDetailReport?ind_key=${ind_key}&lng_key=${lng_key}&format=${format}`,
  churchReport: (chu_key, lng_key) =>
    `ChurchReport?org_key=${chu_key}&lng_key=${lng_key}&format=pdf`,
  churchReport202: (chu_key, nag_key, lng_key, format) =>
    `PresentationReport202?cst_key=${chu_key}&nag_key=${nag_key}&lng_key=${lng_key}&format=${format}`,
  churchFriendsReport: (org_key, lng_key, format) =>
    `ChurchFriendsReport?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  churchListReport: (camp_key, lng_key, format, hidden, ineligible) =>
    `ChurchListByCamp?org_key=${camp_key}&lng_key=${lng_key}&format=${format}&include_hidden=${hidden}&include_ineligible=${ineligible}`,
  churchVisitsByCamp: (camp_key, lng_key, format) =>
    `ChurchVisitsByCamp?org_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  churchPresentersByCamp: (camp_key, lng_key, format) =>
    `ChurchPresentersByCamp?org_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  churchPresenterHistory: (ind_key, lng_key, format) =>
    `ChurchPresenterHistory?ind_key=${ind_key}&lng_key=${lng_key}&format=${format}`,
  churchPastPresentationsByCamp: (camp_key, lng_key, format) =>
    `PastChurchPresentationsByCamp?org_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  churchFuturePresentationsByCamp: (camp_key, lng_key, format) =>
    `ExportChurchPresentationReport?org_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  conversationDetails: (mtg_key, format) => `ConversationDetails?cvs_key=&mtg_key=${mtg_key}&format=${format}`,
  conversationHistory: (org_key, format) => `ConversationsListByOrg?org_key=${org_key}&format=${format}`,
  downloadPrayerCalendarItems: (cpr_key, format) =>
    `DownloadCalendarItems?cpr_key=${cpr_key}&format=${format}`,
  friendsReport: (org_key, lng_key, format) =>
    `FriendsOfGideonsReport?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  gideonCardFacilitiesReport: (camp_key, lng_key, format) =>
    `GideonCardFacilitiesReport?org_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  gideonCardFacilityDetailsReport: (camp_key, lng_key, format) =>
    `GideonCardFacilityReport?org_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  getCampEventsReport: (CampNumber, CampName, FiscalYear, CampOrgKey, Format) =>
    `CampEventPlanner?CampNumber=${CampNumber}&CampName=${CampName}&FiscalYear=${FiscalYear}&CampOrgKey=${CampOrgKey}&Format=${FileSystemormat}`,
  getVideoShowingsReport: (org_key, lng_key, start_date, end_date, format) =>
    `GideonCardVideoShowings?org_key=${org_key}&lng_key=${lng_key}&start_date=${start_date}&end_date=${end_date}&format=${format}`,
  getRestockingVisitReport: (org_key, lng_key, format) =>
    `GideonCardRestockingVisits?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  getAuxiliaryCandidates: (org_key, lng_key, format) =>
    `AuxiliaryCandidatesByCamp?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  getChurchMembers: (org_key, lng_key, format) =>
    `ChurchMembers?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  getCampMembersChurches: (org_key, lng_key, format) =>
    `CampMemberChurchReport?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  getDailyPrayerCalendar: (org_key, year, day, lang, org_type, format) =>
    `NewDailyPrayerCalendarReport?org_key=${org_key}&year=${year}&day=${day}&lang=${lang}&org_type=${org_type}&format=${format}`,
  getWidowListReport: (org_key, lng_key, ind_key, format) =>
    `widowslistreport?org_key=${org_key}&lng_key=${lng_key}&ind_key=${ind_key}&format=${format}`,
  getWitnessingOpportunitiesReport: (camp_key, lng_key, format) =>
    `PersonalWitnessingOpportunities?cmp_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  getDinnerSpeakersReportUrl: (org_key, lng_key, acr_type_key, nag_key, format) =>
    `AnnualMembershipDinnerSpeakersReport?org_key=${org_key}&lng_key=${lng_key}&accreditation_type=${acr_type_key}&nag_key=${nag_key}&format=${format}`,
  getPastorBanquetSpeakersReportUrl: (org_key, lng_key, acr_type_key, nag_key, format) =>
    `AnnualPastorEventSpeakersReport?org_key=${org_key}&lng_key=${lng_key}&accreditation_type=${acr_type_key}&nag_key=${nag_key}&format=${format}`,
  getCampOfficerSelectionsReportUrl: (org_key, ctp_key, lng_key, format) =>
    `StateCampOfficerSelectionsReport?org_key=${org_key}&lng_key=${lng_key}&ctp_key=${ctp_key}&format=${format}`,
  getQualifiedChurchSpeakersReportUrl: (org_key, lng_key, format) =>
    `QualifiedChurchSpeakersExport?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  getScriptureBlitzSpeakersReportUrl: (org_key, lng_key, format) =>
    `ScriptureBlitzParticipantsExportReport?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  getStateCampsReportUrl: (org_key, lng_key, format) =>
    `StateCampsExportReport?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  getTestimonySpeakersReportUrl: (org_key, lng_key, format) =>
    `TestimonySpeakersExportReport?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  getTestimonyUrl: () => `SingleTestimonyReport`,
  memberDetailReport: (ind_key, lng_key, format) =>
    `MemberDetailReport?ind_key=${ind_key}&lng_key=${lng_key}&format=${format}`,
  membersListReport: (camp_key, lng_key, format) =>
    `MembersListByCamp?org_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  stateMembersListReport: (state_key, lng_key, format) =>
    `StateMembersListReport?org_key=${state_key}&lng_key=${lng_key}&format=${format}`,
  newMembersListReport: (camp_key, lng_key, format) =>
    `NewMembersListByCamp?org_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  number10Envelope: (mtg_key, format) =>
    `Number10Envelope?mtg_key=${mtg_key}&format=${format}`,
  officersListReport: (camp_key, lng_key, format) =>
    `OfficersListByCamp?org_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  pastPlacementDates: (org_key, lng_key, format) =>
    `PastPlacementDates?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  placeholder: () => `Placeholder`,
  placementLocationDetailReport: (org_key, lng_key, format) =>
    `PlacementLocationDetailReport?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  placementLocationsReport: (camp_key, lng_key, mpt_key, format) =>
    `PlacementLocationsByCamp?org_key=${camp_key}&lng_key=${lng_key}&mpt_key=${mpt_key}&format=${format}`,
  presentationEnvelope: (mtg_key, format) =>
    `PresentationEnvelope?mtg_key=${mtg_key}&format=${format}`,
  presentationEvaluation: (mtg_key, format) =>
    `PresentationEvaluation?mtg_key=${mtg_key}&format=${format}`,
  presentationThankYouLetter: (mtg_key, format) =>
    `PresentationThankYouLetter?mtg_key=${mtg_key}&format=${format}`,
  prospectsListReport: (camp_key, lng_key, format) =>
    `ProspectsListByCamp?org_key=${camp_key}&lng_key=${lng_key}&format=${format}`,
  upcomingAuxiliaryPlacementDates: (org_key, lng_key, format) =>
    `UpcomingAuxiliaryPlacementDates?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
  upcomingPlacementDates: (org_key, lng_key, format) =>
    `UpcomingPlacementDates?org_key=${org_key}&lng_key=${lng_key}&format=${format}`,
}
