/* eslint-disable */
export const CARD_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/Card`

export const CARD_API_CONFIG = {
  addFacility: () => `${CARD_API_ACTIVE_ROUTE}/AddFacility`,
  editFacility: () => `${CARD_API_ACTIVE_ROUTE}/EditFacility`,
  deleteVideoPresentation: (mtg_key, ind_key) =>
    `${CARD_API_ACTIVE_ROUTE}/DeleteVideoPresentation/${mtg_key}/${ind_key}`,
  getContactRelationshipTypes: () => `${CARD_API_ACTIVE_ROUTE}/ContactRelationshipTypes`,
  getFacilities: org_key => `${CARD_API_ACTIVE_ROUTE}/Facilities/${org_key}`,
  getFacilitiesCSVUrl: gcf_org_key => `${CARD_API_ACTIVE_ROUTE}/Facilities/Download/${gcf_org_key}`,
  getFacilityAddresses: gcf_org_key => `${CARD_API_ACTIVE_ROUTE}/Facility/Details/Addresses/${gcf_org_key}`,
  getFacilityContactInfo: gcf_org_key => `${CARD_API_ACTIVE_ROUTE}/Facility/Details/ContactInfo/${gcf_org_key}`,
  getFacilityContacts: gcf_org_key => `${CARD_API_ACTIVE_ROUTE}/Facility/Details/Contacts/${gcf_org_key}`,
  getFacilityNotes: gcf_org_key => `${CARD_API_ACTIVE_ROUTE}/Facility/Details/Notes/${gcf_org_key}`,
  getFacilityRestockingVisitList: gcf_org_key =>
    `${CARD_API_ACTIVE_ROUTE}/Facility/Details/RestockingVisit/List/${gcf_org_key}`,
  getFacilitySummary: gcf_org_key => `${CARD_API_ACTIVE_ROUTE}/Facility/Summary/${gcf_org_key}`,
  getFacilityTypes: () => `${CARD_API_ACTIVE_ROUTE}/FacilityTypes`,
  getFacilityVideoVisitList: gcf_org_key => `${CARD_API_ACTIVE_ROUTE}/Facility/Details/VideoVisit/List/${gcf_org_key}`,
  getHomeStats: org_key => `${CARD_API_ACTIVE_ROUTE}/HomeStats/${org_key}`,
  getMeetingTypes: () => `${CARD_API_ACTIVE_ROUTE}/MeetingTypes`,
  getNoteTypes: () => `${CARD_API_ACTIVE_ROUTE}/NoteTypes`,
  getRestockingVisitCSVUrl: gcf_org_key => `${CARD_API_ACTIVE_ROUTE}/RestockingVisits/Download/${gcf_org_key}`,
  getRestockingVisitDetails: (mtg_key, isNew) =>
    `${CARD_API_ACTIVE_ROUTE}/Facility/Details/RestockingVisitDetail/${mtg_key}/${isNew}`,
  getRestockingVisits: org_key => `${CARD_API_ACTIVE_ROUTE}/RestockingVisits/${org_key}`,
  getVideoCSVUrl: (gcf_org_key, start_date, end_date) =>
    `${CARD_API_ACTIVE_ROUTE}/VideoVisits/Download/${gcf_org_key}/${start_date}/${end_date}`,
  getVideoShowings: (org_key, start_date, end_date) =>
    `${CARD_API_ACTIVE_ROUTE}/VideoVisits/${org_key}/${start_date}/${end_date}`,
  getVideoVisitDetails: mtg_key => `${CARD_API_ACTIVE_ROUTE}/Facility/Details/VideoVisitDetail/${mtg_key}`,
  upsertFacility: () => `${CARD_API_ACTIVE_ROUTE}/UpsertFacility`,
  upsertCardAddress: () => `${CARD_API_ACTIVE_ROUTE}/UpsertAddress`,
  upsertCardContact: () => `${CARD_API_ACTIVE_ROUTE}/UpsertContact`,
  upsertCardEmail: () => `${CARD_API_ACTIVE_ROUTE}/UpsertEmail`,
  upsertCardNote: () => `${CARD_API_ACTIVE_ROUTE}/UpsertNote`,
  upsertCardPhone: () => `${CARD_API_ACTIVE_ROUTE}/UpsertPhone`,
  upsertRestockingVisit: () => `${CARD_API_ACTIVE_ROUTE}/UpsertRestockingVisit`,
  upsertVideoVisit: () => `${CARD_API_ACTIVE_ROUTE}/UpsertVideoPresentation`
}
