import { API_CONFIG } from '@/config/api.config'
import { REPORT_CONFIG } from '../config/report.config.js'
import axios from 'axios'

/* eslint-disable */
export const state = () => ({
  stats: [
    {
      data: {
        before: '',
        current: -1,
        full: 0,
        after: ''
      },
      title: 'Scripture Orders',
      href: '#'
    },
    {
      data: {
        before: '',
        current: -1,
        full: 0,
        after: ''
      },
      title: 'New Members',
      href: '#'
    },
    {
      data: {
        before: '$',
        current: -1,
        full: 0,
        after: ''
      },
      title: 'Scripture Receipts',
      href: '#'
    }
  ],
  recent_members: [],
  prospects: [],
  recent_placements: [],
  upcoming_placements: [],
  auxiliaryCandidatesPDFUrl: '',
  auxiliaryCandidatesCSVUrl: '',
  presentations: {
    restockingCount: 0
  }
})

export const getters = {
  auxiliaryStats: state => {
    return state.stats
  },
  recent_members: state => {
    return state.recent_members
  },
  recent_members_all: state => {
    return state.recent_members_all
  },
  prospects: state => {
    return state.prospects
  },
  recent_placements: state => {
    return state.recent_placements
  },
  upcoming_placements: state => {
    return state.upcoming_placements
  },
  presentations: state => {
    return state.presentations
  },
  auxiliaryCandidatesPDFUrl: state => {
    return state.auxiliaryCandidatesPDFUrl
  },
  auxiliaryCandidatesCSVUrl: state => {
    return state.auxiliaryCandidatesCSVUrl
  }
}

export const SET_STATS = 'SET_STATS'
export const SET_RECENT_AUXILIARY_MEMBERS = 'SET_RECENT_AUXILIARY_MEMBERS'
export const SET_RECENT_AUXILIARY_MEMBERS_ALL = 'SET_RECENT_AUXILIARY_MEMBERS_ALL'
export const SET_AUXILIARY_CANDIDATES = 'SET_AUXILIARY_CANDIDATES'
export const SET_AUXILIARY_PLACEMENT_DATES = 'SET_AUXILIARY_PLACEMENT_DATES'
export const SET_AUXILIARY_PRESENTATIONS = 'SET_AUXILIARY_PRESENTATIONS'
export const SET_AUXILIARY_CANDIDATES_PDF_URL = 'SET_AUXILIARY_CANDIDATES_PDF_URL'
export const SET_AUXILIARY_CANDIDATES_CSV_URL = 'SET_AUXILIARY_CANDIDATES_CSV_URL'

export const mutations = {
  [SET_STATS](state, data) {
    state.stats[0].data.current = data.placements
    state.stats[0].data.full = data.placements_goal
    state.stats[1].data.current = data.member_count
    state.stats[2].data.current = !!data.to_date ? data.to_date.toFixed(0) : 0
    state.stats[2].data.full = data.goal
  },
  [SET_RECENT_AUXILIARY_MEMBERS](state, data) {
    state.recent_members = data
  },
  [SET_RECENT_AUXILIARY_MEMBERS_ALL](state, data) {
    state.recent_members_all = data
  },
  [SET_AUXILIARY_CANDIDATES](state, data) {
    state.prospects = data
  },
  [SET_AUXILIARY_PLACEMENT_DATES](state, data) {
    state.recent_placements = data.recentPlacements
    state.upcoming_placements = data.upcomingPlacements
  },
  [SET_AUXILIARY_PRESENTATIONS](state, data) {
    state.presentations = data
  },
  [SET_AUXILIARY_CANDIDATES_PDF_URL](state, data) {
    state.auxiliaryCandidatesPDFUrl = data
  },
  [SET_AUXILIARY_CANDIDATES_CSV_URL](state, data) {
    state.auxiliaryCandidatesCSVUrl = data
  }
}

export const actions = {
  async setAuxiliaryPageWidgetData({ commit, rootGetters }, payload) {
    try {
      if (!payload) {
        return false
      }
      const response = await apiCalls.setAuxiliaryPageWidgetData(payload)
      if (response && response.status === 200) {
        await Promise.all([await commit('SET_STATS', response.data)])
        return true
      } else {
        throw 'bad response from setAuxiliaryPageWidgetData call'
      }
    } catch (e) {
      return false
    }
  },
  async getAuxiliaryMembers({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getAuxiliaryMembers(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_RECENT_AUXILIARY_MEMBERS', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAuxiliaryMembersAll({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getAuxiliaryMembersAll(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_RECENT_AUXILIARY_MEMBERS_ALL', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadAuxiliaryCandidates({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadAuxiliaryCandidatesByCamp(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_AUXILIARY_CANDIDATES', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadAuxiliaryCandidatesReportUrls({ commit, rootGetters }) {
    try {
      let orgKey = rootGetters['user/userCampKey']
      let lngKey = rootGetters['user/userLanguageKey']
      const pdfUrl = REPORT_CONFIG.getAuxiliaryCandidates(orgKey, lngKey, 'pdf')
      const csvUrl = REPORT_CONFIG.getAuxiliaryCandidates(orgKey, lngKey, 'excel')
      commit('SET_AUXILIARY_CANDIDATES_PDF_URL', pdfUrl)
      commit('SET_AUXILIARY_CANDIDATES_CSV_URL', csvUrl)
    } catch (error) {
      console.error(error)
    }
  },
  async loadAuxiliaryPlacementDatesByCampLast12({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getAuxiliaryPlacementDatesByCampLast12(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_AUXILIARY_PLACEMENT_DATES', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAuxiliaryPresentations({ commit, rootGetters }) {
    const response = await apiCalls.getAuxiliaryPresentations(rootGetters['user/userCampKey'])
    if (response && response.status === 200) {
      await commit('SET_AUXILIARY_PRESENTATIONS', response.data)
    }
  }
}

export const apiCalls = {
  setAuxiliaryPageWidgetData(payload) {
    const route = API_CONFIG.getAuxiliaryPageWidgetData(payload.org_key)
    return axios.get(route)
  },
  getAuxiliaryMembers(campKey, languageKey) {
    const route = API_CONFIG.getAuxiliaryMembers(campKey, languageKey)
    return axios.get(route)
  },
  getAuxiliaryMembersAll(campKey, languageKey) {
    const route = API_CONFIG.getAuxiliaryMembersAll(campKey, languageKey)
    return axios.get(route)
  },
  loadAuxiliaryCandidatesByCamp(campKey, languageKey) {
    const route = API_CONFIG.getAuxiliaryCandidates(campKey, languageKey)
    return axios.get(route)
  },
  getAuxiliaryPlacementDatesByCampLast12(campKey, languageKey) {
    const route = API_CONFIG.getAuxiliaryPlacementDatesByCampLast12(campKey, languageKey)
    return axios.get(route)
  },
  getAuxiliaryPresentations(orgKey) {
    const route = API_CONFIG.getAuxiliaryPresentations(orgKey)
    return axios.get(route)
  }
}

export const auxiliary = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
