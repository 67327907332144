/* eslint-disable */
export const OFFICERS_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/Officers`

export const OFFICERS_API_CONFIG = {
  getWebSecurityManagers: (org_key, lng_key) =>
    `${OFFICERS_API_ACTIVE_ROUTE}/GetCampSecurityManagers/${org_key}/${lng_key}`,
  getWebStateSecurityManagers: (org_key, lng_key) =>
    `${OFFICERS_API_ACTIVE_ROUTE}/GetStateSecurityManagers/${org_key}/${lng_key}`,
  getMemberList: (org_key, lng_key) => `${OFFICERS_API_ACTIVE_ROUTE}/GetMembersList/${org_key}/${lng_key}`,
  getCommitteMembersByOrganisation: (org_key, lng_key, ctp_key) =>
    `${OFFICERS_API_ACTIVE_ROUTE}/GetCommitteMembersByOrganisation/${org_key}/${lng_key}/${ctp_key}`,
  getCommittePositions: (org_key, lng_key) => `${OFFICERS_API_ACTIVE_ROUTE}/GetCommittePositions/${org_key}/${lng_key}`,
  getCommitteMembers: (org_key, lng_key) => `${OFFICERS_API_ACTIVE_ROUTE}/GetCommittePositions/${org_key}/${lng_key}`,
  updateStateWebSecuirty: () => `${OFFICERS_API_ACTIVE_ROUTE}/UpdateStateWebSecurityManagers`,
  updateWebSecuirty: () => `${OFFICERS_API_ACTIVE_ROUTE}/UpdateWebSecurityManagers`,
  getManagementTeam: (org_key, lng_key) =>
    `${OFFICERS_API_ACTIVE_ROUTE}/GetCountryStateLeadershipTeam/${org_key}/${lng_key}`,
  getChurchVisited: org_key => `${OFFICERS_API_ACTIVE_ROUTE}/GetChurchVisited/${org_key}`,
  getCommitteMembersList: (cmt_key, lng_key) =>
    `${OFFICERS_API_ACTIVE_ROUTE}/GetCommitteMembersByMember/${cmt_key}/${lng_key}`,
  saveOrUpdateSecurityManagers: () => `${OFFICERS_API_ACTIVE_ROUTE}/SaveOrUpdateWebSecurityManagers`,
  upsertIOApplicantInfo: () => `${OFFICERS_API_ACTIVE_ROUTE}/ioApplicant/add/`
}
