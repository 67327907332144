/* eslint-disable */
import axios from 'axios'
import { CALENDAR_API_CONFIG } from '../config/calendar.api.config.js'

export const state = () => ({
  areas: [],
  areasOption: [],
  campOfficerGroup: [],
  editType: {},
  meetings: {},
  meetingTypeList: [],
  memberTypeList: [],
  selectedMeeting: {},
  stateMeetings: [],
})

export const getters = {
  areasOption: state => state.areasOption,
  campOfficerGroup: state => state.campOfficerGroup,
  meetingSelected: state => {
    return state.selectedMeeting
  },
  meetings: state => {
    return state.meetings
  },
  meetingTypeList: state => {
    return state.meetingTypeList
  },
  memberTypeList: state => {
    return state.memberTypeList
  },
  stateMeetings: state => {
    return state.stateMeetings
  },
  top3StateMeetings: state => {
    let today = new Date()
    return state.stateMeetings
      .filter(smf => new Date(smf.mtg_start_date) > today)
      .sort((a, b) => a.mtg_start_date - b.mtg_start_date)
      .slice(0, 3)
  },
  getAreas: state => {
    return state.areas
  },
  getEditType: state => {
    return state.editType
  }
}

export const CLEAR_SELECTED = 'CLEAR_SELECTED'
export const EDIT_TYPE = 'EDIT_TYPE'
export const SET_AREAS = 'SET_AREAS'
export const SET_AREAS_OPTION = 'SET_AREAS_OPTION'
export const SET_CAMP_OFFICER_GROUP = 'SET_CAMP_OFFICER_GROUP'
export const SET_MEETINGS = 'SET_MEETINGS'
export const SET_MEETING_TYPE_LIST = 'SET_MEETING_TYPE_LIST'
export const SET_MEMBER_TYPE_LIST = 'SET_MEMBER_TYPE_LIST'
export const SET_SELECTED_MEETING = 'SET_SELECTED_MEETING'
export const SET_STATE_MEETINGS = 'SET_STATE_MEETINGS'

export const mutations = {
  [SET_AREAS_OPTION](state, data) {
    state.areasOption = data
  },
  [SET_CAMP_OFFICER_GROUP](state, data) {
    state.campOfficerGroup = data
  },
  [SET_SELECTED_MEETING](state, data) {
    state.selectedMeeting = data
  },
  [SET_MEETINGS](state, data) {
    state.meetings = data
  },
  [SET_MEETING_TYPE_LIST](state, data) {
    state.meetingTypeList = data
  },
  [SET_MEMBER_TYPE_LIST](state, data) {
    state.memberTypeList = data
  },
  [SET_STATE_MEETINGS](state, data) {
    state.stateMeetings = data.meetings
  },
  [SET_AREAS](state, data) {
    state.areas = data
  },
  [EDIT_TYPE](state, data) {
    state.editType = data
  }
}

export const actions = {
  async setStateMeetings({ commit }, data) {
    await commit('SET_STATE_MEETINGS', data)
  },
  async setSelectedMeeting({ commit }, data) {
    await commit('SET_SELECTED_MEETING', data)
  },
  async clearSelected({ commit }) {
    await commit('SET_SELECTED_MEETING', null)
  },
  async getAreas({ commit }, data) {
    await commit('SET_AREAS', data)
  },
  async setEditType({ commit }, data) {
    await commit('EDIT_TYPE', data)
  },
  async loadStateMeetingEvents({ commit }, data) {
    try {
      let response = await apiCalls.loadStateMeetingEvents(
        data.state_org_key,
        data.start_date,
        data.end_date,
        data.rows,
        data.sort_asc
      )
      if (response && response.status === 200) {
        await commit('SET_STATE_MEETINGS', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  // PORTED FROM CALENDARSERVICE
  async getEventCalenderFormSettings({ commit }, data) {
    try {
      let response = await apiCalls.getEventCalenderFormSettings(data.org_key, data.event_type)
      if (response && response.status === 200) {
        commit('SET_MEETING_TYPE_LIST', response.data.MeetingTypeList)
        commit('SET_MEMBER_TYPE_LIST', response.data.MemberTypeList)
        return true
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getOrganisationHierarchy({ commit }, data) {
    try {
      let response = await apiCalls.getOrganisationHierarchy(data.t_parent_org_key, data.org_key, data.lng_key)

      if (response && response.status === 200) {
        commit('SET_AREAS_OPTION', response.data.sub_areas)
        commit('SET_CAMP_OFFICER_GROUP', response.data.audience)
        return true
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addUpdateStateEvent({ commit }, event_obj) {
    try {
      let response = await apiCalls.addUpdateStateEvent(event_obj)
      if (response && response.status === 200) {
        return response
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

const apiCalls = {
  loadStateMeetingEvents(state_org_key, start_date, end_date, rows, sort_asc) {
    const route = CALENDAR_API_CONFIG.getStateMeetingsRowSort(state_org_key, start_date, end_date, rows, sort_asc)
    return axios.get(route)
  },
  getEventCalenderFormSettings(org_key, event_type) {
    const route = CALENDAR_API_CONFIG.getEventCalendarFormSettings(org_key, event_type)
    return axios.get(route)
  },
  getOrganisationHierarchy(t_parent_org_key, org_key, lng_key) {
    const route = CALENDAR_API_CONFIG.GetOrganisationHierarchy(t_parent_org_key, org_key, lng_key)
    return axios.get(route)
  },
  addUpdateStateEvent(event_obj) {
    const route = CALENDAR_API_CONFIG.AddNewStateEvent()
    return axios.post(route, event_obj)
  }
}
export const eventCalendar = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
