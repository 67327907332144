/* eslint-disable */
import axios from 'axios'
import { CHURCH_API_CONFIG } from '../config/church.api.config.js'
import { REPORT_CONFIG } from '../config/report.config.js'
import date_data from '@/json/date.json'
import constantData from '@/json/data.json'

export const state = () => ({
  all_presentations: [],
  all_presentations_by_camp: [],
  all_presenters: [],
  all_auxiliary_presenters: [],
  all_gideon_presenters: [],
  all_visits_by_camp: [],
  all_visits_by_church: [],
  campMembersChurchesUrl: '',
  churchContacts: [],
  churchContactTypes: [
    { index: 0, type: 'pastor', active: false },
    { index: 1, type: 'rep', active: false },
    { index: 2, type: 'contact', active: false }
  ],
  churchCommunicationsData: {},
  churchDetail: {
    name: '',
    location: {
      address: '',
      city: '',
      state: '',
      zip: ''
    },
    phone: '',
    email: '',
    church_image: '',
    church_image_key: '',
    services: [],
    presentations: [],
    support: '',
    rank: '',
    hidden: false,
    gideonCardServiced: '',
    other: '',
    view: '#',
    contact: {
      key: '',
      name: '',
      email: '',
      phone: '',
      relationship: '',
      spouse: {
        text: '',
        key: '#'
      },
      gideonRep: {
        text: '',
        key: '#'
      }
    }
  },
  churchDetailSelect: {},
  churchReportUrl: '',
  churchReport202Url: '',
  churchVisitContactTypes: [],
  church_friends: [],
  church_members: [],
  church_resource_list: null,
  churches: [],
  datalist1CSVUrl: '',
  datalist2CSVUrl: '',
  datalist1PDFUrl: '',
  datalist2PDFUrl: '',
  display_presentation: {},
  empty_address: {
    adr_line1: null,
    adr_line2: null,
    adr_line3: null,
    adr_city: null,
    adr_state: null,
    adr_postal_code: null,
    adr_cnt_key: null,
    adr_business_name: null,
    adr_business_attn: null,
    adr_nag_key: null,
    adr_add_user: null,
    adr_add_date: null,
    adr_change_user: null,
    adr_change_date: null,
    adr_delete_flag: false,
    adr_usps_checked_flag: false,
    adr_usps_valid_flag: false,
    adr_usps_corrected_flag: false,
    adr_usps_uncorrected: null
  },
  empty_church: {
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    advocate: '',
    camp_assigned_church_number: '',
    camp_assigned_number: '',
    chu_chl_key: null,
    chu_chr_key: null,
    chu_crt_key: null,
    chu_lnt_key: null,
    chu_org_key: null,
    church_country_key: constantData.USA_country,
    church_image: null,
    church_image_key: null,
    church_lang_type: '',
    church_level_support: '',
    church_name: '',
    church_ranking: '',
    church_relationship_strength: '',
    church_state: '',
    church_state_key: null,
    church_url: '',
    city: '',
    contact_email: '',
    contact_name: '',
    contact_phone: '',
    contact_relationship: '',
    denomination: '',
    email: '',
    estimated_worship_attendance: null,
    gideoncard_display_number: null,
    gideons_church_rep: '',
    gideons_church_rep_key: null,
    in_church_budget: null,
    last_gideon_card_video_showing: '',
    last_gideon_card_video_showing_mtg_key: null,
    last_presentation_date: '',
    last_presentation_mtg_key: null,
    life_book: null,
    mega_church_flag: null,
    next_presentation_date: '',
    next_presentation_mtg_key: null,
    note: '',
    org_den_key: null,
    org_ind_key: null,
    org_name: '',
    pastor_bi_vocational_flag: null,
    phn_cnt_key: null,
    phone_number: '',
    phone_number_formatted: '',
    postal_code: '',
    radio_television_broadcast_flag: null,
    show_online_flag: true,
    state_postal_code: '',
    where_we_should_be_flag: null
  },
  empty_email: {
    member_no: null,
    category: null,
    phn_key: null,
    phn_number: null,
    nag_key: null,
    phn_user: null,
    phn_delete_flag: null,
    phn_cnt_key: null,
    pxe_key: null,
    pxe_extension: null,
    pxe_entity_key: null,
    pxe_pht_key: null,
    pxe_unlisted_flag: null,
    pxe_user: null,
    primary_phone_flag: null,
    allow_confirm_primary_procedure_run: true
  },
  emptyIndividualIXO: {
    ind_account_id: null,
    ind_pfx_key: null,
    ind_first_name: null,
    ind_last_name: null,
    ind_mid_name: null,
    ind_sfx_key: null,
    ind_pref_name: null,
    ind_deceased_flag: 0,
    ind_informal_name: null,
    ind_deceased_date: null,
    ind_birthdate: null,
    ind_widow_flag: 0,
    ind_gnd_key: null,
    ind_spouse_ind_key: null,
    ind_donor_camp_org_key: null,
    ind_allow_email_flag: 0,
    ind_comment: null,
    ind_lng_key: null,
    ind_edu_key: null,
    ind_occ_key: null,
    ind_eml_key: null,
    ind_phn_key: null,
    ind_fax_key: null,
    ind_adr_key: null,
    ind_den_key: null,
    ind_rcf_key: null,
    ind_nag_key: null,
    ind_user: null,
    ind_delete_flag: 0,
    ind_key: null,
    ixo_key: null,
    ixo_ind_key: null,
    ixo_org_key: null,
    ixo_irt_key: null,
    ixo_start_date: null,
    ixo_end_date: null,
    ixo_title: null,
    ixo_nag_key: null,
    ixo_add_user: null,
    ixo_add_date: null,
    ixo_change_user: null,
    ixo_change_date: null,
    ixo_delete_flag: null,
    ixo_user: null,
    contact_type: null,
    spouse_first_name: null,
    spouse_last_name: null,
    phone_number: null,
    email: null,
    primary: 0
  },
  empty_phone_fax: {
    member_no: null,
    category: null,
    phn_key: '',
    pxe_key: '',
    pht_key: '',
    phone_type: '',
    phone_number: '',
    extension: '',
    unlisted_flag: false,
    primary_phone_flag: 0,
    unlisted_word: '',
    primary_phone_word: '',
    number_masked: '',
    IsPhone: true,
    IsFax: false,
    number_masked_extension: ''
  },
  widgetData: {},
  datalist1CSVUrl: '',
  datalist2CSVUrl: '',
  datalist1PDFUrl: '',
  datalist2PDFUrl: '',
  churchFriendsPDFUrl: '',
  churchFriendsCSVUrl: '',
  churchMembersPDFUrl: '',
  churchMembersCSVUrl: '',
  churchContactTypes: [
    { index: 0, type: 'pastor', active: false },
    { index: 1, type: 'rep', active: false },
    { index: 2, type: 'contact', active: false }
  ],
  churchReportUrl: '',
  churchReport202Url: '',
  churchDetailSelect: {},
  held_meetings_by_camp: [],
  held_visits_by_camp: [],
  new_church_org_key: null,
  number_10_envelope_url: '',
  optionsDenominations: [],
  optionsLanguages: [],
  optionsRank: [],
  optionsRelationshipStrength: [],
  optionsRelationships: [],
  optionsSupport: [],
  presentation_date_edit: null,
  presentation_edit_key: null,
  presentation_envelope_url: '',
  presentation_media_types: [],
  presentation_time_data: [],
  presentations: [],
  presentations_scheduled_by_camp: [],
  presenters: [],
  presenter_history: [],
  query_limit: 3,
  selected_visit_key: null,
  single_presentation: {},
  single_visit: {},
  speaker_evaluation_url: '',
  thank_you_letter_url: '',
  visits_for_widget: [],
  widgetData: {}
})

export const uniqueByProperty_map = prop => arr =>
  Array.from(
    arr
      .reduce(
        (acc, item) => (item && item[prop] && acc.set(item[prop], item), acc), // using map (preserves ordering)
        new Map()
      )
      .values()
  )

export const getters = {
  campMembersChurchesUrl: state => {
    return state.campMembersChurchesUrl
  },
  newChurchOrgKey: state => {
    return state.new_church_org_key
  },
  number_10_envelope_url: state => {
    return state.number_10_envelope_url
  },
  presentation_envelope_url: state => {
    return state.presentation_envelope_url
  },
  empty_address: state => {
    return state.empty_address
  },
  empty_individual_ixo: state => {
    return state.emptyIndividualIXO
  },
  empty_email: state => {
    return state.empty_email
  },
  empty_phone_fax: state => {
    return state.empty_phone_fax
  },
  empty_church: state => {
    return state.empty_church
  },
  thank_you_letter_url: state => {
    return state.thank_you_letter_url
  },
  speaker_evaluation_url: state => {
    return state.speaker_evaluation_url
  },
  active_church_contact_type: state => {
    return state.churchContactTypes.find(cct => cct.active === true)
  },
  church_friends: state => {
    return state.church_friends.map(fr => {
      return {
        ind_key: fr.ind_key,
        friend_name: fr.name,
        camp_name: fr.camp_name
      }
    })
  },
  church_members: state => {
    return state.church_members.map(mb => {
      return {
        ind_key: mb.ind_key,
        member_name: mb.name,
        camp_name: mb.camp_name
      }
    })
  },
  churchVisitContactTypes: state => {
    return state.churchVisitContactTypes.map(cp => {
      return {
        value: cp.value,
        text: cp.display_text,
        is_auxiliary_type: cp.is_auxiliary_type === 1
      }
    })
  },
  optionsDenominations: state => {
    return state.optionsDenominations.map(dn => {
      return { text: dn.display_text, value: dn.value }
    })
  },
  optionsRank: state => {
    return state.optionsRank.map(or => {
      return { text: or.ranking_type, value: or.crt_key }
    })
  },
  optionsSupport: state => {
    return state.optionsSupport.map(ls => {
      return { value: ls.chl_key, text: ls.level_of_support }
    })
  },
  optionsLanguages: state => {
    return state.optionsLanguages.map(ls => {
      return { value: ls.lng_key, text: ls.lng_description }
    })
  },
  optionsRelationshipStrength: state => {
    return state.optionsRelationshipStrength.map(rs => {
      return { text: rs.vchr_trn_txt, value: rs.vchr_chr_key }
    })
  },
  optionsRelationships: state => {
    return state.optionsRelationships
  },
  presenters: state => {
    return state.presenters
  },
  presenterHistory: state => {
    return state.presenter_history
  },
  allAuxiliaryPresentersList: state => {
    return state.all_auxiliary_presenters.map(pr => {
      return { text: pr.name, value: pr.ind_key }
    })
  },
  allPresentersList: state => {
    return state.all_presenters.map(pr => {
      return { text: pr.name, value: pr.ind_key }
    })
  },
  presenterList: state => {
    return state.presenters.map(pr => {
      return { text: pr.name, value: pr.ind_key }
    })
  },
  held_meetings_by_camp: state => {
    return state.held_meetings_by_camp
  },
  held_visits_by_camp: state => {
    return state.held_visits_by_camp
  },
  presentation_edit_key: state => {
    return state.presentation_edit_key
  },
  presentation_date_edit: state => {
    return state.presentation_date_edit
  },
  presentations_widget: state => {
    return state.presentations
  },
  presentation_time_data: state => {
    return state.presentation_time_data
  },
  presentation_media_types: state => {
    return state.presentation_media_types.map(e => {
      return { text: e.vmia_trn_txt, value: e.vmia_mia_key }
    })
  },
  presentations_scheduled_by_camp: state => {
    return state.presentations_scheduled_by_camp
  },
  presentations_scheduled_by_church: state => {
    return state.presentations
  },
  single_presentation: state => {
    return state.single_presentation
  },
  single_presentation_for_display: state => {
    return state.display_presentation
  },
  singleVisit: state => {
    return state.single_visit
  },
  selected_visit_key: state => {
    return state.selected_visit_key
  },
  all_visits_by_camp: state => {
    return state.all_visits_by_camp
  },
  all_visits_by_church: state => {
    return state.all_visits_by_church
  },
  all_presentations_by_camp: state => {
    return state.all_presentations_by_camp
  },
  all_presentations: state => {
    return state.all_presentations
  },
  all_presenters: state => {
    return state.all_presenters
  },
  all_auxiliary_presenters: state => {
    return state.all_auxiliary_presenters
  },
  all_gideon_presenters: state => {
    return state.all_gideon_presenters
  },
  churches: state => {
    return state.churches
  },
  churchCommunicationsData: state => {
    return state.churchCommunicationsData
  },
  churchServiceAttireList: state => {
    let aList = state.churchDetail.services.map(s => {
      return {
        text: s.attire,
        value: s.attire
      }
    })
    const uniqueById = uniqueByProperty_map('text')
    return uniqueById(aList)
  },
  churchServiceTypeList: state => {
    let tList = state.churchDetail.services.map(s => {
      return {
        text: s.worship_type,
        value: s.cst_key
      }
    })
    return [...new Set(tList)]
  },
  churchServices: state => {
    return state.churchDetail.services.map(s => {
      return {
        text: s.description,
        value: s.cti_key
      }
    })
  },
  churchesForSelection: state => {
    let churchSelection = state.churches
      .sort((a, b) => {
        let alpha = a.church_name.toUpperCase()
        let beta = b.church_name.toUpperCase()
        return alpha < beta ? -1 : alpha > beta ? 1 : 0
      })
      .map(tr => {
        return { text: tr.church_name, value: tr.chu_org_key }
      })
    const uniqueByName = uniqueByProperty_map('value')
    return uniqueByName(churchSelection)
  },
  churchDetail: state => {
    return state.churchDetail
  },
  churchContacts: state => {
    return state.churchContacts
  },
  churchDetailSelect: state => {
    return state.churchDetailSelect
  },
  churchReportUrl: state => {
    return state.churchReportUrl
  },
  churchReport202Url: state => {
    return state.churchReport202Url
  },
  churchResourceList: state => {
    return state.church_resource_list
  },
  visits_for_widget: state => {
    return state.visits_for_widget
  },
  topWidgetData: state => {
    return [
      {
        num: state.widgetData.ChurchesNotVisitedIn12Months,
        title: 'Churches with no contact the last 12 mos',
        subtitle: ''
      },
      {
        num: state.widgetData.VerySupportiveChurches,
        title: 'Churches are marked extremely supportive',
        subtitle: ''
      }
    ]
  },
  bottomWidgetData: state => {
    return [
      {
        num: state.widgetData.PresentationCount + ' / ' + state.widgetData.PresentationGoal,
        title: 'Presentations',
        subtitle: 'Current Fiscal Year'
      },
      {
        num: state.widgetData.PresentationCountLast365 + ' ',
        title: 'Presentations',
        subtitle: 'Past 12 Mos'
      }
    ]
  },
  datalist1CSVUrl: state => {
    return state.datalist1CSVUrl
  },
  datalist1PDFUrl: state => {
    return state.datalist1PDFUrl
  },
  datalist2CSVUrl: state => {
    return state.datalist2CSVUrl
  },
  datalist2PDFUrl: state => {
    return state.datalist2PDFUrl
  },
  churchFriendsPDFUrl: state => {
    return state.churchFriendsPDFUrl
  },
  churchFriendsCSVUrl: state => {
    return state.churchFriendsCSVUrl
  },
  churchMembersPDFUrl: state => {
    return state.churchMembersPDFUrl
  },
  churchMembersCSVUrl: state => {
    return state.churchMembersCSVUrl
  }
}

export const CLEAR_CHURCH_DETAIL = 'CLEAR_CHURCH_DETAIL'
export const CLEAR_PRESENTATIONS = 'CLEAR_PRESENTATIONS'
export const CLEAR_PRESENTATION_TIME_DATA = 'CLEAR_PRESENTATION_TIME_DATA'
export const NUMBER_10_ENVELOPE_URL = 'NUMBER_10_ENVELOPE_URL'
export const PRESENTATION_ENVELOPE_URL = 'PRESENTATION_ENVELOPE_URL'
export const REMOVE_CHURCH_IMAGE = 'REMOVE_CHURCH_IMAGE'
export const SET_ACTIVE_CHURCH_CONTACT_TYPE = 'SET_ACTIVE_CHURCH_CONTACT_TYPE'
export const SET_ALL_AUXILIARY_PRESENTERS = 'SET_ALL_AUXILIARY_PRESENTERS'
export const SET_ALL_GIDEON_PRESENTERS = 'SET_ALL_GIDEON_PRESENTERS'
export const SET_ALL_PRESENTATIONS = 'SET_ALL_PRESENTATIONS'
export const SET_ALL_PRESENTATIONS_BY_CAMP = 'SET_ALL_PRESENTATIONS_BY_CAMP'
export const SET_ALL_PRESENTERS = 'SET_ALL_PRESENTERS'
export const SET_ALL_VISITS_BY_CAMP = 'SET_ALL_VISITS_BY_CAMP'
export const SET_ALL_VISITS_BY_CHURCH = 'SET_ALL_VISITS_BY_CHURCH'
export const SET_CAMP_KEY = 'SET_CAMP_KEY'
export const SET_CAMP_MEMBERS_CHURCHES_URL = 'SET_CAMP_MEMBERS_CHURCHES_URL'
export const SET_CHURCHES = 'SET_CHURCHES'
export const SET_CHURCH_COMMUNICATIONS_DATA = 'SET_CHURCH_COMMUNICATIONS_DATA'
export const SET_CHURCH_CONTACTS = 'SET_CHURCH_CONTACTS'
export const SET_CHURCH_DETAIL = 'SET_CHURCH_DETAIL'
export const SET_CHURCH_DETAIL_SELECT = 'SET_CHURCH_DETAIL_SELECT'
export const SET_CHURCH_FRIENDS_CSV_URL = 'SET_CHURCH_FRIENDS_CSV_URL'
export const SET_CHURCH_FRIENDS_PDF_URL = 'SET_CHURCH_FRIENDS_PDF_URL'
export const SET_CHURCH_MEMBERS_AND_FRIENDS = 'SET_CHURCH_MEMBERS_AND_FRIENDS'
export const SET_CHURCH_MEMBERS_AND_FRIENDS_CSV_URL = 'SET_CHURCH_MEMBERS_AND_FRIENDS_CSV_URL'
export const SET_CHURCH_MEMBERS_AND_FRIENDS_PDF_URL = 'SET_CHURCH_MEMBERS_AND_FRIENDS_PDF_URL'
export const SET_CHURCH_NAME = 'SET_CHURCH_NAME'
export const SET_CHURCH_RELATIONSHIP_OPTIONS = 'SET_CHURCH_RELATIONSHIP_OPTIONS'
export const SET_CHURCH_REPORT202_URL = 'SET_CHURCH_REPORT202_URL'
export const SET_CHURCH_REPORT_URL = 'SET_CHURCH_REPORT_URL'
export const SET_CHURCH_RESOURCES = 'SET_CHURCH_RESOURCES'
export const SET_CHURCH_SERVICE_TIMES = 'SET_CHURCH_SERVICE_TIMES'
export const SET_CHURCH_VISIT_CONTACT_TYPES = 'SET_CHURCH_VISIT_CONTACT_TYPES'
export const SET_DATALIST1_CSV_URL = 'SET_DATALIST1_CSV_URL'
export const SET_DATALIST1_PDF_URL = 'SET_DATALIST1_PDF_URL'
export const SET_DATALIST2_CSV_URL = 'SET_DATALIST2_CSV_URL'
export const SET_DATALIST2_PDF_URL = 'SET_DATALIST2_PDF_URL'
export const SET_DENOMINATION_OPTIONS = 'SET_DENOMINATION_OPTIONS'
export const SET_HELD_MEETINGS_BY_CAMP = 'SET_HELD_MEETINGS_BY_CAMP'
export const SET_HELD_VISITS_BY_CAMP = 'SET_HELD_VISITS_BY_CAMP'
export const SET_LANGUAGE_KEY = 'SET_LANGUAGE_KEY'
export const SET_LANGUAGE_OPTIONS = 'SET_LANGUAGE_OPTIONS'
export const SET_NEW_CHURCH_ORG_KEY = 'SET_NEW_CHURCH_ORG_KEY'
export const SET_PRESENTATIONS_SCHEDULED_BY_CAMP = 'SET_PRESENTATIONS_SCHEDULED_BY_CAMP'
export const SET_PRESENTATIONS_SCHEDULED_BY_CHURCH = 'SET_PRESENTATIONS_SCHEDULED_BY_CHURCH'
export const SET_PRESENTATION_DATE_EDIT = 'SET_PRESENTATION_DATE_EDIT'
export const SET_PRESENTATION_EDIT_KEY = 'SET_PRESENTATION_EDIT_KEY'
export const SET_PRESENTATION_MEDIA_TYPES = 'SET_PRESENTATION_MEDIA_TYPES'
export const SET_PRESENTATION_TIME_DATA = 'SET_PRESENTATION_TIME_DATA'
export const SET_PRESENTERS = 'SET_PRESENTERS'
export const SET_PRESENTER_HISTORY = 'SET_PRESENTER_HISTORY'
export const SET_QUERY_LIMIT = 'SET_QUERY_LIMIT'
export const SET_RANK_OPTIONS = 'SET_RANK_OPTIONS'
export const SET_RELATIONSHIP_STRENGTH_OPTIONS = 'SET_RELATIONSHIP_STRENGTH_OPTIONS'
export const SET_SELECTED_VISIT_KEY = 'SET_SELECTED_VISIT_KEY'
export const SET_SINGLE_PRESENTATION = 'SET_SINGLE_PRESENTATION'
export const SET_SINGLE_PRESENTATION_FOR_DISPLAY = 'SET_SINGLE_PRESENTATION_FOR_DISPLAY'
export const SET_SINGLE_VISIT = 'SET_SINGLE_VISIT'
export const SET_SUPPORT_OPTIONS = 'SET_SUPPORT_OPTIONS'
export const SET_VISITS_FOR_WIDGET_DATA = 'SET_VISITS_FOR_WIDGET_DATA'
export const SET_WIDGET_DATA = 'SET_WIDGET_DATA'
export const SPEAKER_EVALUATION_URL = 'SPEAKER_EVALUATION_URL'
export const STORE_PRESENTATION_TIME_DATA = 'STORE_PRESENTATION_TIME_DATA'
export const THANK_YOU_LETTER_URL = 'THANK_YOU_LETTER_URL'
export const UPDATE_PRESENTATION_TIME_DATA = 'UPDATE_PRESENTATION_TIME_DATA'
export const WIDGET_LIMIT = 3

export const MONTH_LIST = date_data.months.map(month => month)
// export const DAY_LIST = date_data.days.map(day => day)
export const EMPTY_PRESENTATION = {
  date: {
    month: '',
    day: '',
    year: ''
  },
  title: '',
  speaker: '',
  offer: '$',
  link: '#',
  notes: ''
}

export const mutations = {
  [CLEAR_PRESENTATIONS](state) {
    state.presentations = []
  },
  [CLEAR_CHURCH_DETAIL](state) {
    state.churchDetail.name = ''
    state.churchDetail.location = {
      address: '',
      city: '',
      state: '',
      zip: ''
    }
    state.churchDetail.phone = ''
    state.churchDetail.email = ''
    state.churchDetail.church_image = ''
    state.churchDetail.church_image_key = ''
    state.churchDetail.services = []
    state.churchDetail.presentations = []
    state.churchDetail.support = ''
    state.churchDetail.rank = ''
    state.churchDetail.hidden = false
    state.churchDetail.gideonCardServiced = ''
    state.churchDetail.other = ''
      ; (state.churchDetail.view = '#'),
        (state.churchDetail.contact = {
          key: '',
          name: '',
          email: '',
          phone: '',
          relationship: '',
          spouse: {
            text: '',
            key: '#'
          },
          gideonRep: {
            text: '',
            key: '#'
          }
        })
  },
  [SET_ACTIVE_CHURCH_CONTACT_TYPE](state, data) {
    state.churchContactTypes.map(cct => {
      cct.active = cct.index === data
    })
  },
  [SET_CHURCHES](state, data) {
    state.churches = data
  },
  [SET_NEW_CHURCH_ORG_KEY](state, data) {
    state.new_church_org_key = data
  },
  [SET_PRESENTERS](state, data) {
    state.presenters = data
  },
  [SET_PRESENTER_HISTORY](state, data) {
    state.presenter_history = data
  },
  [SET_ALL_PRESENTERS](state, data) {
    state.all_presenters = data
  },
  [SET_ALL_AUXILIARY_PRESENTERS](state, data) {
    state.all_auxiliary_presenters = data
  },
  [SET_ALL_GIDEON_PRESENTERS](state, data) {
    state.all_gideon_presenters = data
  },
  [SET_PRESENTATION_DATE_EDIT](state, data) {
    state.presentation_date_edit = data
  },
  [SET_PRESENTATIONS_SCHEDULED_BY_CAMP](state, data) {
    state.presentations_scheduled_by_camp = data
  },
  [SET_HELD_MEETINGS_BY_CAMP](state, data) {
    state.held_meetings_by_camp = data
  },
  [SET_HELD_VISITS_BY_CAMP](state, data) {
    state.held_visits_by_camp = data
  },
  [SET_ALL_VISITS_BY_CAMP](state, data) {
    state.all_visits_by_camp = data
  },
  [SET_ALL_VISITS_BY_CHURCH](state, data) {
    state.all_visits_by_church = data
  },
  [SET_ALL_PRESENTATIONS_BY_CAMP](state, data) {
    state.all_presentations_by_camp = data
  },
  [SET_ALL_PRESENTATIONS](state, data) {
    state.all_presentations = data
  },
  [SET_CHURCH_REPORT_URL](state, data) {
    state.churchReportUrl = data
  },
  [SET_CHURCH_REPORT202_URL](state, data) {
    state.churchReport202Url = data
  },
  [SET_QUERY_LIMIT](state, data) {
    state.limit = data
  },
  [SET_RANK_OPTIONS](state, data) {
    state.optionsRank = data
  },
  [SET_SUPPORT_OPTIONS](state, data) {
    state.optionsSupport = data
  },
  [SET_RELATIONSHIP_STRENGTH_OPTIONS](state, data) {
    state.optionsRelationshipStrength = data
  },
  [SET_DENOMINATION_OPTIONS](state, data) {
    state.optionsDenominations = data
  },
  [SET_LANGUAGE_OPTIONS](state, data) {
    state.optionsLanguages = data
  },
  [SET_CHURCH_RELATIONSHIP_OPTIONS](state, data) {
    state.optionsRelationships = data
  },
  [SET_WIDGET_DATA](state, data) {
    state.widgetData = data
  },
  [SET_VISITS_FOR_WIDGET_DATA](state, data) {
    state.visits_for_widget = data
  },
  [SET_DATALIST1_CSV_URL](state, data) {
    state.datalist1CSVUrl = data
  },
  [SET_DATALIST2_CSV_URL](state, data) {
    state.datalist2CSVUrl = data
  },
  [SET_DATALIST1_PDF_URL](state, data) {
    state.datalist1PDFUrl = data
  },
  [SET_DATALIST2_PDF_URL](state, data) {
    state.datalist2PDFUrl = data
  },
  [SET_CHURCH_FRIENDS_PDF_URL](state, data) {
    state.churchFriendsPDFUrl = data
  },
  [SET_CHURCH_FRIENDS_CSV_URL](state, data) {
    state.churchFriendsCSVUrl = data
  },
  [SET_CHURCH_MEMBERS_AND_FRIENDS_PDF_URL](state, data) {
    state.churchMembersPDFUrl = data
  },
  [SET_CAMP_MEMBERS_CHURCHES_URL](state, data) {
    state.campMembersChurchesUrl = data
  },
  [SET_CHURCH_MEMBERS_AND_FRIENDS_CSV_URL](state, data) {
    state.churchMembersCSVUrl = data
  },
  [SET_CHURCH_RESOURCES](state, data) {
    state.churchResourceList = data
  },
  [SET_CHURCH_MEMBERS_AND_FRIENDS](state, data) {
    state.church_members = data.church_members
    state.church_friends = data.church_friends
  },
  [SET_CHURCH_SERVICE_TIMES](state, data) {
    state.churchDetail.services = data.filter(p => !!p.weekday && p.weekday.length > 0)
    state.churchDetail.services.map(cs => (cs.item_description = 'church service'))
  },
  [SET_SELECTED_VISIT_KEY](state, data) {
    state.selected_visit_key = data
  },
  [SET_SINGLE_VISIT](state, data) {
    state.single_visit = data
  },
  [SET_PRESENTATION_EDIT_KEY](state, data) {
    state.presentation_edit_key = data
  },
  [SET_PRESENTATION_MEDIA_TYPES](state, data) {
    state.presentation_media_types = data
  },
  [SET_PRESENTATIONS_SCHEDULED_BY_CHURCH](state, data) {
    if (data.length > 0) {
      state.presentations = data.map(d => {
        const monthElement = MONTH_LIST.find(month => month.value * 1 === d.presentation_month)
        d.date = {
          month: monthElement.abbr,
          monthGuid: monthElement.guidAbbr,
          day: d.presentation_day,
          year: d.presentation_year,
          class: new Date(d.presentation_date) > new Date() ? 'presentation-date' : ''
        }
        d.offering = !d.offering_amount ? '' : `Offering: $${d.offering_amount}`
        d.length = null
        d.speaker = d.speaker === '' ? 'No speaker assigned' : d.speaker
        return d
      })
    } else {
      state.presentations = []
    }
  },
  [SET_SINGLE_PRESENTATION](state, data) {
    state.single_presentation = data
  },
  [SET_SINGLE_PRESENTATION_FOR_DISPLAY](state, data) {
    state.display_presentation = data
  },
  [SET_CHURCH_DETAIL_SELECT](state, data) {
    state.churchDetailSelect = data
    state.churchDetailSelect.item_description = 'church'
    if (!data.postal_code || data.state_postal_code.length > 0) {
      let spc = data.state_postal_code.split(' ')
      state.churchDetailSelect.postal_code = spc[1]
    }
  },
  [SET_CHURCH_VISIT_CONTACT_TYPES](state, data) {
    state.churchVisitContactTypes = data
  },
  [SET_CHURCH_NAME](state, data) {
    state.churchDetail.name = data
  },
  [SET_CHURCH_DETAIL](state, data) {
    state.presentations = []
    state.churchDetail.item_description = 'church'
    state.churchDetail.name = data.church_name
    if (data.adr_key) {
      state.churchDetail.location.address =
        data.address_line_1 + ' ' + (data.address_line_2 || '') + ' ' + (data.address_line_3 || '')
    } else {
      state.churchDetail.location.address = ''
    }
    state.churchDetail.chu_lnt_key = data.chu_lnt_key || null // Language Guid
    state.churchDetail.chu_chl_key = data.chu_chl_key || null // Support Lvl Guid
    state.churchDetail.chu_chr_key = data.chu_chr_key || null // Relationship Guid
    state.churchDetail.location.city = data.city
    let state_postal_code = data.state_postal_code.split(' ')
    state.churchDetail.location.state = state_postal_code[0] || ''
    state.churchDetail.location.zip = state_postal_code[1] || ''
    state.churchDetail.phone = data.phone_number_formatted
    state.churchDetail.email = data.email || ''
    state.churchDetail.church_image = data.church_image
    state.churchDetail.church_image_key = data.church_image_key
    state.churchDetail.support = data.church_level_support
    state.churchDetail.rank = data.church_ranking
    state.churchDetail.hidden = !data.show_online_flag
    state.churchDetail.view = data.church_url
    state.churchDetail.gideonCardServiced = data.addendum.restock_date
    state.churchDetail.other = data.note
    state.churchDetail.contact.key = data.org_ind_key || ''
    state.churchDetail.contact.email = data.contact_email || ''
    state.churchDetail.contact.phone = data.contact_phone || ''
    state.churchDetail.contact.relationship = data.contact_relationship
    state.churchDetail.contact.relationship_key = data.virt_irt_key
    state.churchDetail.contact.gideonRep.text = data.gideons_church_rep
    state.churchDetail.contact.gideonRep.key = data.gideons_church_rep_key
    state.churchDetail.contact.name = data.contact_name
    state.churchDetail.contact.spouse.text = data.addendum.spouse_name
    state.churchDetail.contact.spouse.key = data.addendum.spouse_key
    let lpd = data.last_presentation_date !== null ? new Date(data.last_presentation_date) : 0
    let monthLastPresentation = lpd === 0 ? '' : MONTH_LIST.find(month => month.value * 1 === lpd.getMonth() + 1)
    let last_presentation = {
      month: monthLastPresentation ? monthLastPresentation.abbr : '',
      monthAbbrGuid: monthLastPresentation ? monthLastPresentation.guidAbbr : '',
      day: lpd === 0 ? '' : lpd.getDate(),
      year: lpd === 0 ? '' : lpd.getFullYear(),
      fullDate: data.last_presentation_date,
      title: 'Last Presentation',
      class: !lpd || lpd <= new Date() ? '' : 'presentation-date',
      speaker:
        !data.addendum.last_presentation_speaker_name ||
          data.addendum.last_presentation_speaker_name.replace(/ /g, '').length === 0
          ? ''
          : data.addendum.last_presentation_speaker_name,
      offer: !data.addendum.last_presentation_offering ? null : '$' + (data.addendum.last_presentation_offering + 0.0),
      notes: !data.last_presentation_date
        ? 'No previous presentations are recorded'
        : !data.last_presentation_notes
          ? ''
          : data.last_presentation_notes.substr(0, 25) + '...',
      mtg_key: data.last_presentation_mtg_key
    }

    let np_date = new Date(data.next_presentation_date)
    let monthNextPresentation = MONTH_LIST.find(month => month.abbr === data.addendum.next_presentation_date_month_name)
    let next_presentation = {
      month: data.addendum.next_presentation_date_month_name || '',
      monthAbbrGuid: monthNextPresentation ? monthNextPresentation.guidAbbr : '',
      day: !data.next_presentation_date ? '' : np_date.getDate(),
      year: !data.next_presentation_date ? '' : np_date.getFullYear(),
      fullDate: data.next_presentation_date,
      title: 'Next Presentation',
      class: '',
      speaker:
        !data.addendum.next_presentation_speaker_name ||
          data.addendum.next_presentation_speaker_name.replace(/ /g, '').length === 0
          ? 'No speaker assigned'
          : data.addendum.next_presentation_speaker_name,
      offer: null,
      notes: !data.next_presentation_date
        ? 'No presentation date set'
        : !data.next_presentation_notes
          ? ''
          : data.next_presentation_notes.substr(0, 25) + '...',
      mtg_key: data.next_presentation_mtg_key
    }

    let rsd = !data.addendum.restock_date ? '' : data.addendum.restock_date.split('/')
    const lastVisitMonth = MONTH_LIST.find(mth => mth.abbr === data.addendum.last_visit_date_month_name)
    let last_visit = {
      month: data.addendum.last_visit_date_month_name || '',
      monthAbbrGuid: lastVisitMonth ? lastVisitMonth.guidAbbr : '',
      day: !!data.addendum.last_visit_date ? data.addendum.last_visit_date.substr(8, 2) : '',
      year: !!data.addendum.last_visit_date ? data.addendum.last_visit_date.substr(0, 4) : '',
      fullDate: !!data.addendum.last_visit_date ? data.addendum.last_visit_date : '',
      title: 'Last Visit',
      class: '',
      speaker: '',
      offer: null,
      notes: data.addendum.last_visit_notes ?
        data.addendum.last_visit_notes.substr(0, 25) + '...' : 'No previous visits are recorded',
      nte_key: data.addendum.last_visit_nte_key
    }
    state.presentations = [...[last_presentation], ...[next_presentation], ...[last_visit]]
  },
  [CLEAR_PRESENTATION_TIME_DATA](state) {
    state.presentation_time_data = []
  },
  [REMOVE_CHURCH_IMAGE](state) {
    state.churchDetail.church_image = null
    state.churchDetail.church_image_key = null
  },
  [SET_PRESENTATION_TIME_DATA](state, data) {
    state.presentation_time_data = data
  },
  [STORE_PRESENTATION_TIME_DATA](state, data) {
    state.presentation_time_data.push(data)
  },
  [UPDATE_PRESENTATION_TIME_DATA](state, data) {
    state.presentation_time_data[data.idx] = {
      attire: data.payload.attire,
      removeKey: data.payload.removeKey,
      cts_key: data.payload.cts_key,
      mediaTypeKey: data.payload.mediaTypeKey,
      speakerKey: data.payload.speakerKey,
      speakerMinutes: data.payload.speakerMinutes,
      serviceTimeKey: data.payload.removeKey,
      cts_delete_flag: data.payload.cts_delete_flag ? data.payload.cts_delete_flag : false
    }
  },
  [SET_CHURCH_CONTACTS](state, data) {
    state.churchContacts = data
    state.churchContacts.map(ad => {
      ad.item_description = 'contact'
    })
  },
  [SET_CHURCH_COMMUNICATIONS_DATA](state, data) {
    state.churchCommunicationsData = data
    // item_description is for anything of the ServiceCard type
    state.churchCommunicationsData.addresses.map(ad => {
      ad.item_description = 'address'
    })
    state.churchCommunicationsData.phones.map(ph => {
      ph.item_description = 'phone number'
    })
    state.churchCommunicationsData.emails.map(em => {
      em.item_description = 'email address'
    })
  },
  [NUMBER_10_ENVELOPE_URL](state, data) {
    state.number_10_envelope_url = data
  },
  [PRESENTATION_ENVELOPE_URL](state, data) {
    state.presentation_envelope_url = data
  },
  [THANK_YOU_LETTER_URL](state, data) {
    state.thank_you_letter_url = data
  },
  [SPEAKER_EVALUATION_URL](state, data) {
    state.speaker_evaluation_url = data
  }
}

export const actions = {
  async addChurch({ commit, rootGetters }, church) {
    try {
      let response = await apiCalls.addChurch(rootGetters['user/userId'], rootGetters['user/userCampKey'], church)
      if (response && response.status === 200) {
        commit('user/SET_SELECTED_CHURCH_KEY', response.data, {
          root: true
        })
        commit('SET_CHURCH_NAME', church.church_name)
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addChurchContact({ }, indixo) {
    try {
      let response = await apiCalls.addChurchContact(indixo)
      return response && response.status === 200
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addChurchPresenter({ rootGetters }, pr) {
    try {
      let ixa = {
        ixa_key: pr.ixa_key,
        ixa_ind_key: pr.ind_key,
        ixa_acr_key: '629d2e67-9cf4-40a0-837c-2133a3ad76e0', //  church presenter
        ixa_tst_key: null,
        ixa_start_date: pr.qualified_date,
        ixa_end_date: null,
        ixa_presentation_media_flag: pr.presentation_media_flag,
        ixa_note: pr.ixa_note,
        ixa_scripture_blitz_date: pr.ixa_scripture_blitz_date,
        ixa_scripture_blitz_title: pr.ixa_scripture_blitz_title,
        ixa_nag_key: null,
        ixa_add_user: rootGetters['user/userId'],
        ixa_add_date: new Date(),
        ixa_change_user: null,
        ixa_change_date: null,
        ixa_delete_flag: pr.ixa_delete_flag
      }
      let response = await apiCalls.addChurchPresenter(ixa)
      return response && response.status === 200
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addChurchGideonRep({ }, ixo) {
    try {
      let response = await apiCalls.addChurchGideonRep(ixo)
      return response && response.status === 200
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async setActiveChurchContactType({ commit }, idx) {
    await commit('SET_ACTIVE_CHURCH_CONTACT_TYPE', idx)
  },
  async getSelectionListsForChurchAdd({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getSelectionListsForChurchAdd(rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_SUPPORT_OPTIONS', response.data.support)
        await commit('SET_RANK_OPTIONS', response.data.rankings)
        await commit('SET_DENOMINATION_OPTIONS', response.data.denominations)
        await commit('SET_LANGUAGE_OPTIONS', response.data.languages)
        await commit('SET_RELATIONSHIP_STRENGTH_OPTIONS', response.data.strengths)
        await commit('SET_CHURCH_RELATIONSHIP_OPTIONS', response.data.relationships)
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getChurchVisitContactTypes({ commit, rootGetters }) {
    try {
      const lngKey = rootGetters['user/userLanguageKey']
      let response = await apiCalls.loadChurchVisitContactTypes(lngKey)
      if (response && response.status === 200) {
        await commit('SET_CHURCH_VISIT_CONTACT_TYPES', response.data)
      } else {
        throw 'No visit contact types'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadChurches({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadChurchesByCamp(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_CHURCHES', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadVisibleChurches({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadVisibleChurchesByCamp(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_CHURCHES', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveNewChurchServiceTime({ }, new_times) {
    try {
      let response = await apiCalls.saveNewChurchServiceTime(new_times)
      if (response && response.status === 200) {
        return true
      } else {
        throw 'Bad response during church service time add'
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadChurchReportUrls({ commit, dispatch, rootGetters, state }) {
    let sck = rootGetters['user/userSelectedChurchKey']
    let rURL = reportCalls.getChurchReportUrl(sck, rootGetters['user/userLanguageKey'])
    if (state.churches.length === 0) {
      await dispatch('loadChurches')
    }
    let findChurch = state.churches.find(c => c.chu_org_key === sck)
    let nagKey = !!findChurch ? findChurch.chu_nag_key : constantData.default_nag_key
    let r202URL = reportCalls.getChurchReport202Url(sck, nagKey, rootGetters['user/userLanguageKey'])
    await Promise.all([commit('SET_CHURCH_REPORT_URL', rURL), commit('SET_CHURCH_REPORT202_URL', r202URL)])
    return true
  },
  async loadPresentationDocumentUrls({ commit }, mtg_key) {
    let doc_no10 = reportCalls.getNumber10Envelope(mtg_key)
    let doc_presEnv = reportCalls.getPresentationEnvelope(mtg_key)
    let doc_thankYou = reportCalls.getThankYouLetter(mtg_key)
    let doc_evaluation = reportCalls.getSpeakerEvaluation(mtg_key)
    await Promise.all([
      commit('NUMBER_10_ENVELOPE_URL', doc_no10),
      commit('PRESENTATION_ENVELOPE_URL', doc_presEnv),
      commit('SPEAKER_EVALUATION_URL', doc_evaluation),
      commit('THANK_YOU_LETTER_URL', doc_thankYou)
    ])
    return true
  },
  async loadChurchListReportUrls({ commit, rootGetters }, h_i_flags) {
    let csvURL = reportCalls.getChurchListReportAsCSVUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey'],
      h_i_flags.hidden,
      h_i_flags.ineligible
    )
    let pdfURL = reportCalls.getChurchListReportAsPDFUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey'],
      h_i_flags.hidden,
      h_i_flags.ineligible
    )
    await Promise.all([commit('SET_DATALIST1_PDF_URL', pdfURL), commit('SET_DATALIST1_CSV_URL', csvURL)])
    return true
  },
  async loadPresentationMediaTypes({ commit }) {
    try {
      let response = await apiCalls.getChurchPresentationMediaTypes()
      if (response && response.status === 200) {
        await Promise.all([commit('SET_PRESENTATION_MEDIA_TYPES', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - church presentation media types'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async loadPresentationsAndServiceTimes({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getPresentationsAndServiceTimes(
        rootGetters['user/userSelectedChurchKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await Promise.all([
          commit('SET_CHURCH_SERVICE_TIMES', response.data.services),
          commit('SET_PRESENTATIONS_SCHEDULED_BY_CHURCH', response.data.presentations)
        ]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - church presentations and service times'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async loadChurchPresentersReportUrls({ commit, rootGetters }) {
    let csvURL = reportCalls.getChurchPresentersByCampAsCSVUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    let pdfURL = reportCalls.getChurchPresentersByCampAsPDFUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([commit('SET_DATALIST1_PDF_URL', pdfURL), commit('SET_DATALIST1_CSV_URL', csvURL)])
    return true
  },
  async loadChurchPresenterHistoryReportUrls({ commit, rootGetters }) {
    let csvURL = reportCalls.getChurchPresenterHistoryAsCSVUrl(
      rootGetters['user/userSelectedIndividualKey'],
      rootGetters['user/userLanguageKey']
    )
    let pdfURL = reportCalls.getChurchPresenterHistoryAsPDFUrl(
      rootGetters['user/userSelectedIndividualKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([commit('SET_DATALIST1_PDF_URL', pdfURL), commit('SET_DATALIST1_CSV_URL', csvURL)])
    return true
  },
  async loadChurchPresentationsReportUrls({ commit, rootGetters }) {
    let csvPastURL = reportCalls.getPastChurchPresentationsByCampAsCSVUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    let pdfPastURL = reportCalls.getPastChurchPresentationsByCampAsPDFUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    let csvFutureURL = reportCalls.getFutureChurchPresentationsByCampAsCSVUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    let pdfFutureURL = reportCalls.getFutureChurchPresentationsByCampAsPDFUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([
      commit('SET_DATALIST2_PDF_URL', pdfPastURL),
      commit('SET_DATALIST2_CSV_URL', csvPastURL),
      commit('SET_DATALIST1_PDF_URL', pdfFutureURL),
      commit('SET_DATALIST1_CSV_URL', csvFutureURL)
    ])
    return true
  },
  async loadChurchVisitsReportUrls({ commit, rootGetters }) {
    let csvURL = reportCalls.getChurchVisitsByCampAsCSVUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    let pdfURL = reportCalls.getChurchVisitsByCampAsPDFUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([commit('SET_DATALIST1_PDF_URL', pdfURL), commit('SET_DATALIST1_CSV_URL', csvURL)])
    return true
  },
  async loadWidgetData({ commit, rootGetters }) {
    try {
      let d = new Date()
      let year = d.getFullYear()
      let fiscal_year = d.getMonth() <= 4 ? year : year + 1
      let response = await apiCalls.loadWidgetData(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey'],
        fiscal_year,
        WIDGET_LIMIT
      )
      if (response && response.status === 200) {
        await commit('SET_WIDGET_DATA', response.data.counts)
        await commit('SET_PRESENTATIONS_SCHEDULED_BY_CAMP', response.data.pres)
        await commit('SET_HELD_VISITS_BY_CAMP', response.data.visits)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPresenters({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadPresenters(rootGetters['user/userCampKey'], rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_PRESENTERS', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPresenterHistory({ commit, rootGetters }, ind_key) {
    try {
      let response = await apiCalls.loadPresenterHistory(ind_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_PRESENTER_HISTORY', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadQualifiedPresenters({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadQualifiedPresenters(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_PRESENTERS', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadAllPresentationsByCamp({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadAllPresentationsByCamp(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_ALL_PRESENTATIONS_BY_CAMP', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadAllPresentations({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadAllPresentationsByCamp(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_ALL_PRESENTATIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadSinglePresentation({ commit }, mtg_key) {
    try {
      let response = await apiCalls.loadSinglePresentation(mtg_key)
      if (response && response.status === 200) {
        await commit('SET_SINGLE_PRESENTATION', response.data)
        await commit(
          'SET_PRESENTATION_TIME_DATA',
          response.data.mtg_times.map(mt => {
            return {
              cts_key: mt.cts_key,
              attire: mt.attire_key,
              mediaTypeKey: mt.media_key,
              serviceTimeKey: mt.time_key,
              speakerKey: mt.cts_speaker_ind_key,
              speakerMinutes: mt.cts_speaking_time,
              removeKey: mt.time_key,
              serviceTime: mt.service_time
            }
          })
        )
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  lowercaseAllKeys(response) {
    // if the GUIDs exist, convert all to lowercase (for SiteCore)

    return response
  },
  async loadSinglePresentationForDisplay({ commit, rootGetters }, mtg_key) {
    try {
      let response = await apiCalls.loadSinglePresentationForDisplay(mtg_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        const presKeys = {
          keyStatus: response.data.panel_one ? response.data.panel_one.status_key : '',
          keyAttire: response.data.panel_two[0] ? response.data.panel_two[0].attire_key : '',
          keyMedia: response.data.panel_two[0] ? response.data.panel_two[0].media_type_key : '',
          keyService: response.data.panel_two[0] ? response.data.panel_two[0].service_type_key : '',
          keyWeekday: response.data.panel_two[0] ? response.data.panel_two[0].weekday_key : ''
        }

        for (const [key, value] of Object.entries(presKeys)) {
          presKeys[key] = value ? value.toLowerCase() : ''
        }

        response.data.panel_one.status_key = presKeys.keyStatus

        if (response.data.panel_two.length === 0) {
          response.data.panel_two.push({})
        }

        response.data.panel_two[0].attire_key = presKeys.keyAttire
        response.data.panel_two[0].media_type_key = presKeys.keyMedia
        response.data.panel_two[0].service_type_key = presKeys.keyService
        response.data.panel_two[0].weekday_key = presKeys.keyWeekday

        await commit('SET_SINGLE_PRESENTATION_FOR_DISPLAY', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async setChurchMembersAndFriends({ commit, rootGetters }, org_key) {
    try {
      let response = await apiCalls.getChurchMembersAndFriends(org_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_CHURCH_MEMBERS_AND_FRIENDS', response.data)
      } else {
        console.error('empty response')
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getChurchFriendsPDFUrl({ commit, rootGetters }, org_key) {
    try {
      const pdfUrl = reportCalls.getChurchFriendsPDFUrl(org_key, rootGetters['user/userLanguageKey'])
      await commit(SET_CHURCH_FRIENDS_PDF_URL, pdfUrl)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getChurchFriendsCSVUrl({ commit, rootGetters }, org_key) {
    try {
      const csvUrl = reportCalls.getChurchFriendsCSVUrl(org_key, rootGetters['user/userLanguageKey'])
      await commit(SET_CHURCH_FRIENDS_CSV_URL, csvUrl)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getChurchMembersPDFUrl({ commit, rootGetters }, org_key) {
    try {
      const pdfUrl = reportCalls.getChurchMembersPDFUrl(org_key, rootGetters['user/userLanguageKey'])
      await commit(SET_CHURCH_MEMBERS_AND_FRIENDS_PDF_URL, pdfUrl)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getCampMembersChurchesUrl({ commit, rootGetters }) {
    try {
      const csvUrl = reportCalls.getCampMembersChurchesUrl(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      await commit(SET_CAMP_MEMBERS_CHURCHES_URL, csvUrl)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getChurchMembersCSVUrl({ commit, rootGetters }, org_key) {
    try {
      const csvUrl = reportCalls.getChurchMembersCSVUrl(org_key, rootGetters['user/userLanguageKey'])
      await commit(SET_CHURCH_MEMBERS_AND_FRIENDS_CSV_URL, csvUrl)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deleteChurch({ dispatch, rootGetters }, chu_org_key) {
    try {
      let response = await apiCalls.deleteChurch(chu_org_key, rootGetters['user/userId'])
      if (response && response.status === 200) {
        dispatch('user/setSelectedChurchKey', null, { root: true })
      } else {
        throw 'church delete was unsuccessful; response.status:' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deleteChurchEmailPhoneAddress({ rootGetters }, key_switch) {
    try {
      let response = await apiCalls.deleteChurchEmailPhoneAddress(
        key_switch.key,
        rootGetters['user/userId'],
        key_switch.sw
      )
      return response && response.status === 200
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deleteChurchImage({ commit, rootGetters }, img_key) {
    try {
      let response = await apiCalls.deleteChurchImage(img_key, rootGetters['user/userId'])
      if (response && response.status === 200) {
        await commit('REMOVE_CHURCH_IMAGE')
      } else {
        throw 'church image delete failed'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deleteChurchServiceTime({ rootGetters }, cti_key) {
    try {
      let response = await apiCalls.deleteChurchServiceTime(cti_key, rootGetters['user/userId'])
      return response && response.status === 200
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deletePresentation({ commit, rootGetters }, mtg_key) {
    try {
      let response = await apiCalls.deletePresentation(mtg_key, rootGetters['user/userId'])
      if (response && response.status === 200) {
        await commit('SET_SINGLE_PRESENTATION', {})
        await commit('SET_SINGLE_PRESENTATION_FOR_DISPLAY', {})
        // remove presentation from store
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addUpdateChurchVisitNote({ }, noteObject) {
    let response = await apiCalls.addUpdateChurchVisitNote(noteObject)
    return response && response.status === 200
  },
  async deleteChurchVisitNote({ }, noteObject) {
    noteObject.nte_delete_flag = 1
    let response = await apiCalls.addUpdateChurchVisitNote(noteObject)
    return response && response.status === 200
  },
  async loadHeldMeetingsByCamp({ commit, rootGetters }) {
    let response = await apiCalls.loadHeldMeetingsByCamp(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    if (response && response.status === 200) {
      await commit('SET_HELD_MEETINGS_BY_CAMP', response.data)
    } else {
      if (!response) {
        console.error('empty response')
      } else {
        console.error('unrecognized status code:' + response.status)
      }
    }
    return true
  },
  async getSingleVisit({ commit, rootGetters }, nte_key) {
    try {
      let response = await apiCalls.getSingleVisit(
        rootGetters['user/userCampKey'],
        nte_key,
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_SINGLE_VISIT', response.data[0])
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadAllVisitsByCamp({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadAllVisitsByCamp(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey'],
        payload.limit,
        payload.showall
      )
      if (response && response.status === 200) {
        if (payload.limit === 9999) {
          await commit('SET_ALL_VISITS_BY_CAMP', response.data)
        } else {
          await commit('SET_VISITS_FOR_WIDGET_DATA', response.data)
        }
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadAllVisitsByChurch({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadAllVisitsByChurch(
        payload.church_key,
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_ALL_VISITS_BY_CHURCH', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadChurchDetail({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadChurchDetail(
        rootGetters['user/userSelectedChurchKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_CHURCH_DETAIL', response.data)
      } else {
        throw 'bad response from loadChurchDetail call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadChurchDetailSelect({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadChurchDetailSelect(
        rootGetters['user/userSelectedChurchKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        if (!response.data.church_state_key || response.data.church_state.length > 0) {
          let afo = await rootGetters['membership/addressFormOptions']
          let csk = afo.states.find(af => af.state_code === response.data.church_state)
          response.data.church_state_key = !!csk ? csk.value : null
        }
        await commit('SET_CHURCH_DETAIL_SELECT', response.data)
      } else {
        throw 'bad response from loadChurchDetailSelect call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getChurchCommunications({ commit, rootGetters }, entity_key) {
    try {
      let response = await apiCalls.getChurchCommunications(entity_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_CHURCH_COMMUNICATIONS_DATA', response.data)
      } else {
        throw 'bad response from getChurchCommunications call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getChurchContacts({ commit, rootGetters }, org_key) {
    try {
      let response = await apiCalls.getChurchContacts(org_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_CHURCH_CONTACTS', response.data)
      } else {
        throw 'bad response from getChurchContacts call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAllPresentersByCamp({ commit, rootGetters }, cmp_key) {
    try {
      let response = await apiCalls.getAllPresentersByCamp(
        cmp_key || rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_ALL_PRESENTERS', response.data)

        if (!!response.data && response.data.length > 0) {
          let auxiliaryMemberType = constantData.member_types.filter(k => k.text === 'Auxiliary')[0].value
          let gideonMemberType = constantData.member_types.filter(k => k.text === 'Gideon')[0].value
          await commit(
            'SET_ALL_AUXILIARY_PRESENTERS',
            response.data.filter(p => p.met_key === auxiliaryMemberType)
          )
          await commit(
            'SET_ALL_GIDEON_PRESENTERS',
            response.data.filter(p => p.met_key === gideonMemberType)
          )
        }
      } else {
        throw 'bad response from getAllPresentersByCamp call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getResourcesForChurch({ commit, rootGetters }, cmp_key) {
    try {
      let response = await apiCalls.getResourcesForChurch(
        cmp_key || rootGetters['user/userCampKey'],
        rootGetters['user/userId'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_CHURCH_RESOURCES', response.data)
      } else {
        throw 'bad response from getResourcesForChurch call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async storePresentationTimeData({ commit, state }, payload) {
    let old = state.presentation_time_data.findIndex(q => q.serviceTimeKey === payload.serviceTimeKey)
    if (old > -1) {
      await commit('UPDATE_PRESENTATION_TIME_DATA', {
        payload: payload,
        idx: old
      })
    } else {
      await commit('STORE_PRESENTATION_TIME_DATA', payload)
    }
    return true
  },
  async savePresentation({ rootGetters }, pkg) {
    try {
      let add_date = new Date().toISOString()
      let new_presentation = {
        mti_meeting: {
          mtg_key: pkg.payload.key,
          mtg_host_org_key: rootGetters['user/userCampKey'],
          mtg_location_org_key: rootGetters['user/userSelectedChurchKey'],
          mtg_coordinator_ind_key: pkg.payload.contact,
          mtg_mtr_key: null,
          mtg_title: 'Gideons Church Presentation',
          mtg_mtt_key: '2b8182bf-118c-4a79-b571-4c1ee6b479c6', //  Gideons Church Presentation
          mtg_mpt_key: null,
          mtg_start_date: pkg.payload.start_date,
          mtg_end_date: pkg.payload.start_date,
          mtg_start_time: pkg.payload.start_time,
          mtg_end_time: null,
          mtg_assigned_date: add_date,
          mtg_mts_key: pkg.payload.status_key || '0ce62d5c-a829-482b-9db6-e95809588cd7', //  pending -- use mts key if payload.key is not null
          mtg_note: pkg.payload.note,
          mtg_church_presenter_arrival_time: pkg.payload.arrival_time,
          mtg_bulletins_required_number: pkg.payload.bulletins,
          mtg_pastor_confirmed_date: null,
          mtg_presenter_confirmed_date: null,
          mtg_offering_agreeable_flag: pkg.payload.offering_flag,
          mtg_supply_delivery_date: null,
          mtg_estimated_attendance: pkg.payload.attendance,
          mtg_offering_amount: pkg.payload.offering,
          mtg_cancellation_date: null,
          mtg_nag_key: pkg.payload.nag_key,
          mtg_add_user: rootGetters['user/userId'],
          mtg_add_date: add_date,
          mtg_delete_flag: false
        },
        mti_time_x_speaker: pkg.payload.times.map(t => {
          return {
            cts_key: t.cts_key ? t.cts_key : null,
            cts_mtg_key: pkg.payload.key,
            cts_cti_key: t.serviceTimeKey,
            cts_speaker_ind_key: t.speakerKey,
            cts_speaking_time: t.speakerMinutes,
            cts_mia_key: t.mediaTypeKey,
            cts_netforum_g05_key: pkg.payload.key,
            cts_nag_key: t.nag_key,
            cts_add_user: rootGetters['user/userId'],
            cts_add_date: add_date,
            cts_change_user: null,
            cts_change_date: null,
            cts_delete_flag: t.cts_delete_flag ? t.cts_delete_flag : false,
            cts_user: rootGetters['user/userId']
          }
        }),
        mti_mxi: []
      }
      let roles = {
        contactedBy: '3c0c1ea4-5524-4450-8e95-6d1458a30bed',
        contact: 'fffd211b-8116-45d4-98cb-89c8173a97c7',
        speaker: 'f5eb65c6-c3f6-45e7-9080-9c0858cef657',
        prayerPartner: 'f6c38fe4-d03c-4540-a986-bfbe4e5ce7d1'
      }

      if (pkg.participants.shouldContact !== null) {
        new_presentation.mti_mxi.push({
          mxi_key: null,
          mxi_mtg_key: pkg.payload.key,
          mxi_ind_key: constantData.empty_guid,
          mxi_mro_key: roles.contact,
          mxi_nag_key: null,
          mxi_user: rootGetters['user/userId'],
          mxi_add_date: add_date,
          mxi_change_user: null,
          mxi_change_date: null,
          mxi_delete_flag: false,
          mgx_speaker_name: pkg.participants.shouldContact
        })
      }

      if (pkg.participants.partner !== null) {
        new_presentation.mti_mxi.push({
          mxi_key: pkg.participants.partnerMxiKey,
          mxi_mtg_key: pkg.payload.key || null,
          mxi_ind_key: pkg.participants.partner,
          mxi_mro_key: roles.prayerPartner,
          mxi_nag_key: pkg.participants.nag_key,
          mxi_user: rootGetters['user/userId'],
          mxi_add_date: add_date,
          mxi_change_user: null,
          mxi_change_date: null,
          mxi_delete_flag: false
        })
      }

      if (pkg.participants.contactedBy !== null) {
        new_presentation.mti_mxi.push({
          mxi_key: pkg.participants.contactedByMxiKey,
          mxi_mtg_key: pkg.payload.key || null,
          mxi_ind_key: pkg.participants.contactedBy,
          mxi_mro_key: roles.contactedBy,
          mxi_nag_key: pkg.participants.nag_key,
          mxi_user: rootGetters['user/userId'],
          mxi_add_date: add_date,
          mxi_change_user: null,
          mxi_change_date: null,
          mxi_delete_flag: false
        })
      }
      let response = await apiCalls.savePresentation(new_presentation)
      if (response && response.status === 200) {
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  setQueryLimit: ({ commit, payload }) => {
    commit('SET_QUERY_LIMIT', { query_limit: payload })
  },
  setCampKey: ({ commit, payload }) => {
    commit('SET_CAMP_KEY', { campKey: payload })
  },
  setLanguageKey: ({ commit, payload }) => {
    commit('SET_LANGUAGE_KEY', { languageKey: payload })
  },
  setPresenters: ({ commit, payload }) => {
    commit('SET_PRESENTERS', { presenters: payload })
  },
  async setChurchName({ commit }, value) {
    await commit('SET_CHURCH_NAME', value)
  },
  async setPresentationEditKey({ commit }, value) {
    await commit('SET_PRESENTATION_EDIT_KEY', value)
  },
  async clearPresentationTimeData({ commit }) {
    await commit('CLEAR_PRESENTATION_TIME_DATA')
  },
  async clearSinglePresentation({ commit }) {
    await commit('SET_SINGLE_PRESENTATION', {})
    await commit('CLEAR_PRESENTATIONS')
  },
  async clearSingleVisit({ commit }) {
    await commit('SET_SINGLE_VISIT', {})
  },
  async clearSinglePresentationForDisplay({ commit }) {
    await commit('SET_SINGLE_PRESENTATION_FOR_DISPLAY', {})
  },
  async clearChurchDetail({ commit }) {
    await commit('CLEAR_CHURCH_DETAIL')
  },
  async setSelectedVisitKey({ commit }, payload) {
    await commit('SET_SELECTED_VISIT_KEY', payload)
  },
  async savePhoto({ rootGetters }, photoInfo) {
    try {
      let response = await apiCalls.savePhoto(photoInfo)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
        return false
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

const apiCalls = {
  addChurch(ind_key, cmp_key, church) {
    let formData = new FormData()
    for (var itm in church) {
      if (church[itm] !== null) {
        formData.append(itm, church[itm])
      }
    }
    const route = CHURCH_API_CONFIG.addChurch(ind_key, cmp_key)
    return axios.post(route, formData)
  },
  addChurchContact(indixo) {
    const route = CHURCH_API_CONFIG.addChurchContact(!!indixo.cnt_key ? indixo.cnt_key : null)
    return axios.post(route, indixo)
  },
  addChurchPresenter(pr) {
    const route = CHURCH_API_CONFIG.addChurchPresenter()
    return axios.post(route, pr)
  },
  addChurchGideonRep(ixo) {
    const route = CHURCH_API_CONFIG.addChurchGideonRep()
    return axios.post(route, ixo)
  },
  addUpdateChurchVisitNote(noteObject) {
    const route = CHURCH_API_CONFIG.addUpdateChurchVisitNote()
    return axios.post(route, noteObject)
  },
  deleteChurch(chu_org_key, chu_user) {
    const route = CHURCH_API_CONFIG.deleteChurch(chu_org_key, chu_user)
    return axios.delete(route)
  },
  deleteChurchEmailPhoneAddress(key, user, sw) {
    const route =
      sw === 0 ? CHURCH_API_CONFIG.deleteChurchEmail(key, user)
        : sw === 1 ? CHURCH_API_CONFIG.deleteChurchPhone(key, user)
          : CHURCH_API_CONFIG.deleteChurchAddress(key, user)
    return axios.delete(route)
  },
  deleteChurchImage(img_key, ind_key) {
    const route = CHURCH_API_CONFIG.deleteChurchImage(img_key, ind_key)
    return axios.delete(route)
  },
  deletePresentation(mtg_key, mtg_user) {
    const route = CHURCH_API_CONFIG.deletePresentation(mtg_key, mtg_user)
    return axios.delete(route)
  },
  deleteChurchServiceTime(cti_key, cti_user) {
    const route = CHURCH_API_CONFIG.deleteChurchServiceTime(cti_key, cti_user)
    return axios.delete(route)
  },
  getChurchMembersAndFriends(org_key, lng_key) {
    const route = CHURCH_API_CONFIG.getChurchMembersAndFriends(org_key, lng_key)
    return axios.get(route)
  },
  getAllPresentersByCamp(org_key, lng_key) {
    const route = CHURCH_API_CONFIG.getAllPresentersByCamp(org_key, lng_key)
    return axios.get(route)
  },
  loadWidgetData(campKey, lng_key, fiscalYear, limit) {
    const route = CHURCH_API_CONFIG.getChurchMinistryWidgetData(campKey, lng_key, fiscalYear, limit)
    return axios.get(route)
  },
  loadPresenters(campKey, languageKey) {
    const route = CHURCH_API_CONFIG.getAllPresentersByCamp(campKey, languageKey)
    return axios.get(route)
  },
  loadPresenterHistory(indKey, languageKey) {
    const route = CHURCH_API_CONFIG.getPresenterHistory(indKey, languageKey)
    return axios.get(route)
  },
  loadQualifiedPresenters(campKey, languageKey) {
    const route = CHURCH_API_CONFIG.getQualifiedPresenters(campKey, languageKey)
    return axios.get(route)
  },
  loadChurchVisitContactTypes(languageKey) {
    const route = CHURCH_API_CONFIG.getChurchVisitContactTypes(languageKey)
    return axios.get(route)
  },
  loadChurchesByCamp(campKey, languageKey) {
    const route = CHURCH_API_CONFIG.getChurches(campKey, languageKey)
    return axios.get(route)
  },
  loadVisibleChurchesByCamp(campKey, languageKey) {
    const route = CHURCH_API_CONFIG.getVisibleChurches(campKey, languageKey)
    return axios.get(route)
  },
  loadHeldMeetingsByCamp(campKey, limit) {
    const route = CHURCH_API_CONFIG.getMeetingsHeldByCamp(campKey, limit)
    return axios.get(route)
  },
  loadAllVisitsByCamp(campKey, languageKey, limit, showall) {
    const route = CHURCH_API_CONFIG.getAllVisitsByCamp(campKey, languageKey, limit, showall)
    return axios.get(route)
  },
  loadAllVisitsByChurch(churchKey, languageKey) {
    const route = CHURCH_API_CONFIG.getAllVisitsByChurch(churchKey, languageKey)
    return axios.get(route)
  },
  loadPresentationsScheduledByCamp(campKey, limit) {
    const route = CHURCH_API_CONFIG.getPresentationsScheduledByCamp(campKey, limit)
    return axios.get(route)
  },
  loadAllPresentationsByCamp(campKey, languageKey) {
    const route = CHURCH_API_CONFIG.getAllPresentationsByCamp(campKey, languageKey)
    return axios.get(route)
  },
  loadAllPresentations(organizationKey, campKey, languageKey) {
    const route = CHURCH_API_CONFIG.getAllPresentations(organizationKey, campKey, languageKey)
    return axios.get(route)
  },
  getPresentationsAndServiceTimes(churchKey, languageKey) {
    const route = CHURCH_API_CONFIG.getPresentationsAndServiceTimes(churchKey, languageKey)
    return axios.get(route)
  },
  loadChurchDetail(churchKey, languageKey) {
    const route = CHURCH_API_CONFIG.getChurchDetail(churchKey, languageKey)
    return axios.get(route)
  },
  loadChurchDetailSelect(churchKey, languageKey) {
    const route = CHURCH_API_CONFIG.getChurchDetailSelect(churchKey, languageKey)
    return axios.get(route)
  },
  loadSinglePresentationForDisplay(mtg_key, lng_key) {
    const route = CHURCH_API_CONFIG.getSinglePresentationForDisplay(mtg_key, lng_key)
    return axios.get(route)
  },
  loadSinglePresentation(mtg_key, lng_key) {
    const route = CHURCH_API_CONFIG.getSinglePresentation(mtg_key, lng_key)
    return axios.get(route)
  },
  getSingleVisit(cmp_key, nte_key, lng_key) {
    const route = CHURCH_API_CONFIG.getSingleVisit(cmp_key, nte_key, lng_key)
    return axios.get(route)
  },
  getChurchPresentationMediaTypes() {
    const route = CHURCH_API_CONFIG.getChurchPresentationMediaTypes()
    return axios.get(route)
  },
  savePresentation(data) {
    const route = CHURCH_API_CONFIG.savePresentation()
    return axios.post(route, data)
  },
  saveNewChurchServiceTime(data) {
    const route = CHURCH_API_CONFIG.addChurchServiceTime()
    return axios.post(route, data)
  },
  getChurchCommunications(entity_key, lng_key) {
    const route = CHURCH_API_CONFIG.getChurchCommunications(entity_key, lng_key)
    return axios.get(route)
  },
  getChurchContacts(org_key, lng_key) {
    const route = CHURCH_API_CONFIG.getChurchContacts(org_key, lng_key)
    return axios.get(route)
  },
  getResourcesForChurch(cmp_key, ind_key, lng_key) {
    const route = CHURCH_API_CONFIG.getResourcesForChurch(cmp_key, ind_key, lng_key)
    return axios.get(route)
  },
  getSelectionListsForChurchAdd(lng_key) {
    const route = CHURCH_API_CONFIG.getSelectionListsForChurchAdd(lng_key)
    return axios.get(route)
  },
  savePhoto(photoInfo) {
    let formData = new FormData()
    for (var itm in photoInfo) {
      if (photoInfo[itm] !== null) {
        formData.append(itm, photoInfo[itm])
      }
    }
    const route = CHURCH_API_CONFIG.upsertChurchPhoto(photoInfo.iim_org_key)
    return axios.post(route, formData)
  }
}

const reportCalls = {
  getNumber10Envelope(mtg_key) {
    return REPORT_CONFIG.number10Envelope(mtg_key, 'pdf')
  },
  getPresentationEnvelope(mtg_key) {
    return REPORT_CONFIG.presentationEnvelope(mtg_key, 'pdf')
  },
  getSpeakerEvaluation(mtg_key) {
    return REPORT_CONFIG.presentationEvaluation(mtg_key, 'pdf')
  },
  getThankYouLetter(mtg_key) {
    return `${constantData.document_prefix}Thank_You_Letter.docx`
  },
  getChurchReportUrl(chu_key, lng_key, format) {
    return REPORT_CONFIG.churchReport(chu_key, lng_key, format)
  },
  getChurchReport202Url(chu_key, nag_key, lng_key) {
    return REPORT_CONFIG.churchReport202(chu_key, nag_key, lng_key, 'pdf')
  },
  getChurchListReportAsCSVUrl(camp_key, lng_key, hidden, ineligible) {
    return REPORT_CONFIG.churchListReport(camp_key, lng_key, 'excel', hidden, ineligible)
  },
  getChurchListReportAsPDFUrl(camp_key, lng_key, hidden, ineligible) {
    return REPORT_CONFIG.churchListReport(camp_key, lng_key, 'pdf', hidden, ineligible)
  },
  getChurchVisitsByCampAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.churchVisitsByCamp(camp_key, lng_key, 'excel')
    return route
  },
  getChurchVisitsByCampAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.churchVisitsByCamp(camp_key, lng_key, 'pdf')
    return route
  },
  getChurchPresentersByCampAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.churchPresentersByCamp(camp_key, lng_key, 'excel')
    return route
  },
  getChurchPresentersByCampAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.churchPresentersByCamp(camp_key, lng_key, 'pdf')
    return route
  },
  getChurchPresenterHistoryAsCSVUrl(ind_key, lng_key) {
    const route = REPORT_CONFIG.churchPresenterHistory(ind_key, lng_key, 'excel')
    return route
  },
  getChurchPresenterHistoryAsPDFUrl(ind_key, lng_key) {
    const route = REPORT_CONFIG.churchPresenterHistory(ind_key, lng_key, 'pdf')
    return route
  },
  getPastChurchPresentationsByCampAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.churchPastPresentationsByCamp(camp_key, lng_key, 'excel')
    return route
  },
  getPastChurchPresentationsByCampAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.churchPastPresentationsByCamp(camp_key, lng_key, 'pdf')
    return route
  },
  getFutureChurchPresentationsByCampAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.churchFuturePresentationsByCamp(camp_key, lng_key, 'excel')
    return route
  },
  getFutureChurchPresentationsByCampAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.churchFuturePresentationsByCamp(camp_key, lng_key, 'pdf')
    return route
  },
  getChurchFriendsPDFUrl(org_key, lng_key) {
    const route = REPORT_CONFIG.churchFriendsReport(org_key, lng_key, 'pdf')
    return route
  },
  getChurchFriendsCSVUrl(org_key, lng_key) {
    const route = REPORT_CONFIG.churchFriendsReport(org_key, lng_key, 'excel')
    return route
  },
  getChurchMembersPDFUrl(org_key, lng_key) {
    const route = REPORT_CONFIG.getChurchMembers(org_key, lng_key, 'pdf')
    return route
  },
  getChurchMembersCSVUrl(org_key, lng_key) {
    const route = REPORT_CONFIG.getChurchMembers(org_key, lng_key, 'excel')
    return route
  },
  getCampMembersChurchesUrl(org_key, lng_key) {
    const route = REPORT_CONFIG.getCampMembersChurches(org_key, lng_key, 'excel')
    return route
  }
}

export const churchMinistry = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
