/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'
import dateData from '@/json/date.json'

/* eslint-disable */
export const state = () => ({
  activityList: [],
  availableMonths: [],
  emptyActivity: {
    date_formatted: null,
    bac_org_key: null,
    bac_key: null,
    bac_year: 0,
    bac_month: 0,
    bac_beg_balance: 0.0,
    bac_bfd_deposit: 0.0,
    bac_sfd_deposit: 0.0,
    bac_gfd_deposit: 0.0,
    bac_bfh_sent: 0.0,
    bac_bfs_stc_sent: 0.0,
    bac_sfh_503_sent: 0.0,
    bac_sfs_stc_sent: 0.0,
    bac_prh_purchase: 0.0,
    bac_gfd_expense: 0.0,
    bac_end_balance: 0.0,
    bac_status: 0, //
    bac_nag_key: 'eed25966-0ff8-4a5b-8249-aec30c173676',
    bac_add_user: null,
    bac_add_date: null,
    bac_change_user: null,
    bac_change_date: null,
    bac_delete_flag: false
  },
  fiscalYears: [],
  responseMessage: '',
  selectedActivity: {},
  selectedActivityBegBalance: 0.0,
  uploads: []
})

export const RESET_CURRENT_ACTIVITY = 'RESET_CURRENT_ACTIVITY'
export const SET_ACTIVITY_LIST = 'SET_ACTIVITY_LIST'
export const SET_ACTIVITY_STATUS = 'SET_ACTIVITY_STATUS'
export const SET_AVAILABLE_MONTHS = 'SET_AVAILABLE_MONTHS'
export const SET_CURRENT_ACTIVITY = 'SET_CURRENT_ACTIVITY'
export const SET_FISCAL_YEARS = 'SET_FISCAL_YEARS'
export const SET_RESPONSE_MESSAGE = 'SET_RESPONSE_MESSAGE'
export const SET_UPLOADED_FILES = 'SET_UPLOADED_FILES'

const apiCalls = {
  deleteUploadedFile(parms) {
    const route = API_CONFIG.deleteUploadedFile(parms.bau_key, parms.bac_year, parms.ind_key)
    return axios.get(route)
  },
  getAvailableMonths(parms) {
    const pkg = {
      org_key: parms.org_key,
      bac_key: null,
      fiscal_year: parms.fy,
      fiscal_month: 0
    }
    const route = API_CONFIG.getAvailableMonths()
    return axios.post(route, pkg)
  },
  getCampActivities(org_key) {
    const route = API_CONFIG.getCampActivities(org_key)
    return axios.get(route)
  },
  getFiscalYears() {
    const route = API_CONFIG.getFiscalYears()
    return axios.get(route)
  },
  getStateActivities(org_key) {
    const route = API_CONFIG.getStateActivities(org_key)
    return axios.get(route)
  },
  getUploads(parms) {
    const route = API_CONFIG.getUploads(parms.org_key, parms.bac_year)
    return axios.get(route)
  },
  updateBankingActivity(parms) {
    const route = API_CONFIG.updateBankingActivity()
    return axios.post(route, parms)
  },
  uploadFile(parms) {
    const data = new FormData()
    data.append('file', parms.file)
    const route = API_CONFIG.uploadFile(parms.bac_key, parms.ind_key, parms.staff_flag)
    return axios.post(route, data, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`
      }
    })
  }
}

export const actions = {
  async retrieveFiscalYears({ commit }) {
    try {
      const response = await apiCalls.getFiscalYears()
      if (response && response.status === 200) {
        commit('SET_FISCAL_YEARS', response.data)
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async setActivityList({ commit }, camp) {
    commit('SET_ACTIVITY_LIST', camp)
    return true
  },
  async resetCurrentActivity({ commit, state }) {
    await commit('RESET_CURRENT_ACTIVITY', state.emptyActivity)
    return true
  },
  async setCurrentActivity({ commit }, act) {
    commit('SET_CURRENT_ACTIVITY', act)
    return true
  },
  async updateBankingActivity({ dispatch, rootGetters }, data) {
    try {
      const ndt = new Date().toISOString()
      const userKey = rootGetters['user/userId']
      data.activity.bac_nag_key = 'eed25966-0ff8-4a5b-8249-aec30c173676'
      data.activity.bac_add_user = userKey
      data.activity.bac_add_date = ndt
      data.activity.bac_change_user = userKey
      data.activity.bac_change_date = ndt
      data.activity.bac_delete_flag = false

      const response = await apiCalls.updateBankingActivity(data.activity)
      if (response && response.status === 200) {
        await dispatch('getAvailableMonths', {
          org_key: data.activity.bac_org_key,
          fy: data.fiscalYear
        })
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async updateActivityStatusById({ dispatch }, data) {
    return await dispatch('updateBankingActivity', { activity: data })
  },
  async getStateActivities({ commit }, orgid) {
    try {
      if (orgid.length > 0) {
        const response = await apiCalls.getStateActivities(orgid)
        if (response && response.status === 200) {
          const {
            data: { camps }
          } = response
          commit('SET_ACTIVITY_LIST', camps)
          return true
        }
      }
    } catch (error) {
      // TODO: handle calendar get failure
      return false
    }
  },
  async getCampActivities({ commit }, orgid) {
    try {
      if (orgid.length > 0) {
        const response = await apiCalls.getCampActivities(orgid)
        if (response && response.status === 200) {
          const {
            data: { camps }
          } = response
          commit('SET_ACTIVITY_LIST', camps)
          return true
        }
      }
    } catch (error) {
      return false
    }
  },
  async getAvailableMonths({ commit }, parms) {
    try {
      const response = await apiCalls.getAvailableMonths(parms)
      if (response && response.status === 200) {
        commit('SET_ACTIVITY_LIST', response.data)
        commit('SET_AVAILABLE_MONTHS', response.data)
        return true
      }
    } catch (error) {
      return false
    }
  },
  async getUploadedFiles({ commit }, parms) {
    try {
      const response = await apiCalls.getUploads(parms)
      if (response && response.status === 200) {
        commit('SET_UPLOADED_FILES', response.data)
        return true
      }
    } catch (error) {
      return false
    }
  },
  async deleteUploadedFile({ commit, state }, parms) {
    try {
      const response = await apiCalls.deleteUploadedFile(parms)
      if (response && response.status === 200) {
        commit(
          'SET_UPLOADED_FILES',
          state.uploads.filter(df => {
            return df.bau_key !== parms.bau_key
          })
        )
        return true
      }
    } catch (error) {
      return false
    }
  },
  async resetResponseMessage({ commit }) {
    commit('SET_RESPONSE_MESSAGE', '')
  },
  async uploadFile({ commit, state }, parms) {
    try {
      commit('SET_RESPONSE_MESSAGE', '')
      const response = await apiCalls.uploadFile(parms)
      return response && response.status === 201
    } catch (error) {
      if (error.message.includes('204') || error.message.includes('404')) {
        commit('SET_RESPONSE_MESSAGE', 'The upload failed because no file was detected.')
      }
      if (error.message.includes('415')) {
        commit(
          'SET_RESPONSE_MESSAGE',
          'The file type you selected is not supported. Please upload a file of type .jpg,.jpeg, gif,.png,.pdf,.ppt,.doc,.docx,.csv,.xlsx,.xls,.txt,.rtf,.tif,.tiff.'
        )
      }
      if (error.message.includes('400') || error.message.includes('412')) {
        commit('SET_RESPONSE_MESSAGE', 'The upload failed for an unspecified reason. Try again or call for assistance.')
      }
      if (error.message.includes('413')) {
        commit(
          'SET_RESPONSE_MESSAGE',
          'The file you selected is too large. Please upload a file that is less than 20Mb in size.'
        )
      }
      return false
    }
  }
}

export const getters = {
  activities: state => {
    return state.activityList.map((act, idx) => {
      return {
        date_formatted: act.date_formatted,
        bac_org_key: act.bac_org_key,
        bac_key: act.bac_key,
        bac_year: act.bac_year,
        bac_month: act.bac_month,
        bac_beg_balance: parseFloat(act.bac_beg_balance || 0).toFixed(2),
        bac_bfd_deposit: parseFloat(act.bac_bfd_deposit || 0).toFixed(2),
        bac_sfd_deposit: parseFloat(act.bac_sfd_deposit || 0).toFixed(2),
        bac_gfd_deposit: parseFloat(act.bac_gfd_deposit || 0).toFixed(2),
        bac_bfh_sent: parseFloat(act.bac_bfh_sent || 0).toFixed(2),
        bac_bfs_stc_sent: parseFloat(act.bac_bfs_stc_sent || 0).toFixed(2),
        bac_sfh_503_sent: parseFloat(act.bac_sfh_503_sent || 0).toFixed(2),
        bac_sfs_stc_sent: parseFloat(act.bac_sfs_stc_sent || 0).toFixed(2),
        bac_prh_purchase: parseFloat(act.bac_prh_purchase || 0).toFixed(2),
        bac_gfd_expense: parseFloat(act.bac_gfd_expense || 0).toFixed(2),
        bac_end_balance: parseFloat(act.bac_end_balance || 0).toFixed(2),
        bac_status: act.bac_status,
        index: idx,
        prior_month_status: !!state.activityList[idx - 1] ? state.activityList[idx - 1].bac_status : -1
      }
    })
  },
  activitySelected: state => {
    return state.selectedActivity
  },
  activitySelectedBegBalance: state => {
    return state.selectedActivityBegBalance
  },
  availableMonths: state => {
    const monthArray = [...[''], ...dateData.months.map(dd => dd.text)]
    const aM = state.availableMonths
      .filter(aw => aw.bac_key != null)
      .map(av => {
        return {
          text: monthArray[av.bac_month] + ' ' + av.bac_year.toString(),
          value: av.bac_month
        }
      })
    return [{ value: null, text: 'Select a month' }, ...aM]
  },
  getEmptyActivity: state => {
    return state.emptyActivity
  },
  getFiscalYearsForDropdown: state => {
    return state.fiscalYears.map(fy => {
      return { text: fy.toString(), value: fy }
    })
  },
  getMaxFiscalYear: state => {
    return Math.max(...state.fiscalYears)
  },
  monthBacKeyArray: state => {
    return state.availableMonths.map(av => {
      return {
        month: av.bac_month,
        bac_key: av.bac_key
      }
    })
  },
  responseMessage: state => {
    return state.responseMessage
  },
  uploadedFiles: state => {
    return state.uploads
  }
}

export const mutations = {
  [RESET_CURRENT_ACTIVITY](state, data) {
    let date_hold = state.selectedActivity.date_formatted
    state.selectedActivity = data
    state.selectedActivity.date_formatted = date_hold
  },
  [SET_ACTIVITY_LIST](state, data) {
    state.activityList = data || []
  },
  [SET_ACTIVITY_STATUS](state, data) {
    let actItem = state.activityList.find(pr => {
      return pr.bac_key === data.bac_key
    })
    actItem.bac_status = data.bac_status
  },
  [SET_AVAILABLE_MONTHS](state, data) {
    state.availableMonths = data || []
  },
  [SET_CURRENT_ACTIVITY](state, data) {
    state.selectedActivityBegBalance = data.bac_beg_balance
    state.selectedActivity = data
  },
  [SET_FISCAL_YEARS](state, data) {
    state.fiscalYears =
      data.map(rfy => {
        return rfy.fiscal_year
      }) || []
  },
  [SET_RESPONSE_MESSAGE](state, data) {
    state.responseMessage = data
  },
  [SET_UPLOADED_FILES](state, data) {
    state.uploads = data || []
  }
}

export const activityModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
