/*eslint-disable*/
import axiosErrorHandler from '../axiosErrorHandler.js'
import { GET_GOAL_API_ENDPOINT } from '@/config/goals.api.config.js'
import { PermittedGoalRouteNames } from '@/constants/PermittedGoalRouteNames.js'

const getCustomizeMAEndpointName = ROUTE_CAMP_GOAL => {
  return new Map([
    [
      PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation,
      {
        GET: 'CustomizeMAGet[ChurchMinistryAndPresentations]',
        POST: 'CustomizeMAUpdate[ChurchMinistryAndPresentations]'
      }
    ],
    [PermittedGoalRouteNames.GideonCard, { GET: 'CustomizeMAGet[GideonCard]', POST: 'CustomizeMAUpdate[GideonCard]' }],
    [PermittedGoalRouteNames.FaithFund, { GET: 'CustomizeMAGet[FaithFund]', POST: 'CustomizeMAUpdate[FaithFund]' }],
    [
      PermittedGoalRouteNames.AuxiliaryScripture,
      { GET: 'CustomizeMAGet[AuxiliaryScripture]', POST: 'CustomizeMAUpdate[AuxiliaryScripture]' }
    ],
    [PermittedGoalRouteNames.CampDesignated, { GET: '', POST: '' }],
    [PermittedGoalRouteNames.Placements, { GET: 'CustomizeMAGet[Placements]', POST: 'CustomizeMAUpdate[Placements]' }],
    [
      PermittedGoalRouteNames.PersonalWorkersTestaments,
      { GET: 'CustomizeMAGet[PWTs]', POST: 'CustomizeMAUpdate[PWTs]' }
    ],
    [PermittedGoalRouteNames.Membership, { GET: '', POST: '' }]
  ]).get(ROUTE_CAMP_GOAL)
}

export default class CustomizeMonthlyAllocationService {
  static async getCustomizedMonthlyAllocationData({ routeCampGoal, campKey, langKey, shortCode = '' }) {
    const ENDPOINT_GET =
      shortCode === ''
        ? GET_GOAL_API_ENDPOINT(getCustomizeMAEndpointName(routeCampGoal).GET)(campKey, langKey)
        : GET_GOAL_API_ENDPOINT(getCustomizeMAEndpointName(routeCampGoal).GET)(campKey, langKey, shortCode)
    const response = await axiosErrorHandler({
      endpoint: ENDPOINT_GET,
      apiType: 'get'
    })
    return response
  }

  static async updateCustomizedMonthlyAllocationData({ routeCampGoal, customizedMAData }) {
    const ENDPOINT_POST = GET_GOAL_API_ENDPOINT(getCustomizeMAEndpointName(routeCampGoal).POST)()
    const response = await axiosErrorHandler({
      endpoint: ENDPOINT_POST,
      apiType: 'post',
      payload: customizedMAData,
      successMessage: 'You have successfully updated customize monthly amounts.'
    })
    return response
  }
}
