/* eslint-disable */
import { API_CONFIG } from '@/config/api.config'
import axios from 'axios'

export const state = () => ({
  sValues: '',
  user: {},
  purview: {},
  searchOptions: {}
})

export const getters = {
  getUser: state => {
    return state.user
  },
  getUserKey: state => {
    return state.user.ind_key
  },
  getUserCampKey: state => {
    return state.sValues[0]
  },
  getSValues: state => {
    return state.sValues
  },
  getSearchValues: state => {
    return state.searchOptions
  },
  isHeadquarters: state => {
    return !!state.purview.orgs
      ? state.purview.orgs.filter(pv => {
          return pv.name === 'headquarters'
        }).length > 0
      : false
  },
  isState: state => {
    return !!state.purview.orgs
      ? state.purview.orgs.filter(pv => {
          return pv.name === 'state'
        }).length > 0
      : false
  }
}

export const SET_USER = 'SET_USER'
export const SET_USER_PURVIEW = 'SET_USER_PURVIEW'
export const SET_S_VALUES = 'SET_S_VALUES'
export const SET_SEARCH_VALUES = 'SET_SEARCH_VALUES'

export const mutations = {
  [SET_USER](state, data) {
    state.user = data
  },
  [SET_USER_PURVIEW](state, data) {
    state.purview = data
  },
  [SET_S_VALUES](state, data) {
    state.sValues = data
  },
  [SET_SEARCH_VALUES](state, data) {
    state.searchOptions = data
  }
}

export const actions = {
  async setSValues({ commit }, data) {
    commit('SET_S_VALUES', data)
  },
  async setSearchValues({ commit }, data) {
    commit('SET_SEARCH_VALUES', data)
  },
  async clearSearchValues({ commit }) {
    commit('SET_SEARCH_VALUES', {})
  },
  async retrieveUserData({ commit, dispatch }, params) {
    try {
      const resp = await apiCalls.getUserPurview(params.ind_key)
      if (resp && resp.status === 200) {
        commit('SET_USER_PURVIEW', resp.data)
      }
      return true
    } catch (error) {
      return false
    }
  }
}

const apiCalls = {
  // eslint-disable-next-line camelcase
  getUserData(ind_key) {
    const route = API_CONFIG.getUserData(ind_key)
    return axios.get(route)
  },
  getUserPurview(ind_key) {
    const route = API_CONFIG.userPurview(ind_key)
    return axios.get(route)
  }
}

export const userModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
