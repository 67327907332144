/*eslint-disable*/
import axiosErrorHandler from '../axiosErrorHandler.js'
import { GET_GOAL_API_ENDPOINT } from '@/config/goals.api.config.js'
import { GoalShortCodes } from '@/constants/GoalShortCodes.js'
import { CampGoalsAllowedMaximumDigits } from '@/constants/CampGoalsMaximumBoundConstants.js'

export default class StateDashboardService {
  static async getStateDashboardDetails(stateOrgKey) {
    const dashboardEndpoint = GET_GOAL_API_ENDPOINT('StateDashboard')(stateOrgKey)
    const response = await axiosErrorHandler({
      endpoint: dashboardEndpoint,
      apiType: 'get'
    })
    if (response) return response
  }

  static async getStateCampsDetails(stateOrgKey, region, area, category) {
    const stateCampsDetailsEndpoint = GET_GOAL_API_ENDPOINT('StateCampsDetails')(stateOrgKey, region, area, category)
    const response = await axiosErrorHandler({
      endpoint: stateCampsDetailsEndpoint,
      apiType: 'get'
    })
    if (response) return response
  }

  static async lockUnlockState(stateOrgKey, payload) {
    const endpoint = GET_GOAL_API_ENDPOINT('LockUnlockState')(stateOrgKey)
    const response = await axiosErrorHandler({
      endpoint,
      apiType: 'post',
      payload,
      successMessage: `You have successfully ${payload.lockState ? 'locked' : 'unlocked'} the state.`
    })

    return response
  }

  static async lockUnlockCampGoals(payload) {
    const endpoint = GET_GOAL_API_ENDPOINT('StateLockUnlockCampGoals')()
    const response = await axiosErrorHandler({
      endpoint,
      apiType: 'post',
      payload,
      successMessage: `You have successfully ${payload.LockGoals ? 'locked' : 'unlocked'} the camp goals.`
    })
    return response
  }

  static async getStateGoalsReport(stateOrgKey, csvType, fiscalYear) {
    const stateGoalsReportEndpoint = GET_GOAL_API_ENDPOINT('StateGoalsReport')(stateOrgKey, csvType, fiscalYear)
    const response = await axiosErrorHandler({
      endpoint: stateGoalsReportEndpoint,
      apiType: 'get'
    })
    if (response) {
      const blob = new Blob([response], { type: 'application/csv' })
      const blobUrl = URL.createObjectURL(blob)
      // create temporary 'a' element
      const downloadLink = document.createElement('a')
      downloadLink.href = blobUrl
      downloadLink.download = `StateGoals_${csvType}_${fiscalYear}.csv`
      downloadLink.click()
      // Clean up the URL object after download
      URL.revokeObjectURL(blobUrl)
      downloadLink.remove()
    }
  }

  static async getCampStatusReport(stateOrgKey, fiscalYear) {
    const campStatusReportEndpoint = GET_GOAL_API_ENDPOINT('CampStatusReportForState')(stateOrgKey, fiscalYear)
    const response = await axiosErrorHandler({
      endpoint: campStatusReportEndpoint,
      apiType: 'get'
    })
    if (response) {
      const blob = new Blob([response], { type: 'application/csv' })
      const blobUrl = URL.createObjectURL(blob)
      // create temporary 'a' element
      const downloadLink = document.createElement('a')
      downloadLink.href = blobUrl
      downloadLink.download = `StateLockedReport.csv`
      downloadLink.click()
      // Clean up the URL object after download
      URL.revokeObjectURL(blobUrl)
      downloadLink.remove()
    }
  }

  static async getQuickCampGoalPlanningData(stateOrgKey, campKey) {
    const endpoint = GET_GOAL_API_ENDPOINT('GetStateQuickCampGoalPlanning')(stateOrgKey, campKey)
    const response = await axiosErrorHandler({
      endpoint,
      apiType: 'get'
    })
    return response
  }

  static async saveQuickCampGoalPlanningData(payload) {
    const endpoint = GET_GOAL_API_ENDPOINT('SaveStateQuickCampGoalPlanning')()
    const response = await axiosErrorHandler({
      endpoint,
      apiType: 'post',
      payload,
      successMessage: 'You have successfully saved the camp goals.'
    })
    return response
  }

  static async saveCampGoalByCategory(payload) {
    const endpoint = GET_GOAL_API_ENDPOINT('SaveStateCampGoalByCategory')()
    const response = await axiosErrorHandler({
      endpoint,
      apiType: 'post',
      payload,
      successMessage: 'You have successfully saved the camp goals.'
    })
    return response
  }

  static async runStateTargetGoal(payload) {
    const endpoint = GET_GOAL_API_ENDPOINT('RunStateTargetGoal')()
    const response = await axiosErrorHandler({
      endpoint,
      apiType: 'post',
      payload,
      successMessage: 'State target goal run successfully.'
    })
    return response
  }

  static getMaximumDigitsAllowed(shortCode) {
    const placementsShortCodes = [GoalShortCodes.GideonPlacements, GoalShortCodes.AuxiliaryPlacements]
    const personalWorkerTestamentsShortCodes = [GoalShortCodes.GideonPWTs, GoalShortCodes.AuxiliaryPWTs]
    const nonDollarShortCodes = [
      GoalShortCodes.ChurchPresentation,
      GoalShortCodes.GideonMembership,
      GoalShortCodes.AuxiliaryMembership,
      GoalShortCodes.GideonCardPresentation,
      GoalShortCodes.GideonCardCollection,
      GoalShortCodes.GideonMembership,
      GoalShortCodes.AuxiliaryMembership
    ]

    if (placementsShortCodes.includes(shortCode)) {
      return CampGoalsAllowedMaximumDigits.StateNonDollarTotalPlacements
    } else if (personalWorkerTestamentsShortCodes.includes(shortCode)) {
      return CampGoalsAllowedMaximumDigits.CampNonDollarSubGoalPlacementsAndPWTs
    } else if (nonDollarShortCodes.includes(shortCode)) {
      return CampGoalsAllowedMaximumDigits.CampNonDollar
    } else if (shortCode === GoalShortCodes.Receipts) {
      return CampGoalsAllowedMaximumDigits.StateAllReceiptsDollar
    } else {
      return CampGoalsAllowedMaximumDigits.CampDollar
    }
  }

  static async stateCSVUpload(stateOrgKey, indKey, file) {
    const endpoint = GET_GOAL_API_ENDPOINT('StateCSVUpload')(stateOrgKey, indKey)
    const csvFormData = new FormData()
    csvFormData.append('stateOrgKey', stateOrgKey)
    csvFormData.append('indKey', indKey)
    csvFormData.append('file', file)
    const response = await axiosErrorHandler({
      endpoint,
      apiType: 'post',
      payload: csvFormData,
      successMessage: 'You have successfully uploaded your CSV file.'
    })
    return response
  }
}
