/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'
import { MEMBER_API_CONFIG } from '../config/member.api.config.js'
import { REPORT_CONFIG } from '../config/report.config.js'

export const state = () => ({
  addressFormOptions: {
    countries: [],
    countriesByCamp: [],
    states: [],
    addressTypes: []
  },
  days_last_prospect: 0,
  emailFormOptions: {
    emailTypes: []
  },
  friends: [],
  friendsReportAsCSVUrl: '',
  friendsReportAsPDFUrl: '',
  gideon_prospects: 0,
  isIndividualProfileMembershipLifetime: null,
  individualCommunicationsData: {
    addresses: [],
    emails: [],
    phones: []
  },
  individualCommunicationPreferencesData: {
    loginEmail: {},
    memberEmails: [],
    preferredLanguage: {}
  },
  individualEditFormOptions: {
    prefixMale: [],
    suffixMale: [],
    prefixFemale: [],
    suffixFemale: [],
    occupations: [],
    denominations: [],
    languages: [],
    churches: [],
    memberTypes: [],
    referralTypes: [],
    pastorsByCamp: []
  },
  memberAvatar: {},
  individualProfile: {},
  individualProfileHeader: {},
  memberCommitteeAndRoles: {
    committee: [],
    roles: []
  },
  memberDetail: {},
  memberListReportAsCSVUrl: '',
  memberListReportAsPDFUrl: '',
  memberPopup: {
    pop_key: null,
    pop_title: '',
    pop_message: ''
  },
  memberProfileReportAsPDF: '',
  members: [],
  membertype: {
    Gideon: 'ABDA920A-F939-4DE0-BAD5-0AB91F476B5E',
    Auxiliary: '678CFA9F-3CED-4880-AB74-6BAD634AAF74'
  },
  newMemberListReportAsCSVUrl: '',
  newMemberListReportAsPDFUrl: '',
  new_auxiliary_members: 0,
  new_gideon_members: 0,
  nonmemberProfileReportAsPDF: '',
  officerListReportAsCSVUrl: '',
  officerListReportAsPDFUrl: '',
  membertypedefault: '',
  paymentInfo: {
    // Donation: [],
    // Membership: [],
    isAutoPaySetup: false,
    CampName: '',
    isPayDuesLinkSupposeToBeVisible: false,
    withdrawDate: 0,
    mbrPendingDropDate: null,
    paidFlag: false
  },
  phoneFormOptions: {
    countries: [],
    countriesByCamp: [],
    phoneTypes: [],
    faxTypes: []
  },
  preferredLanguages: [],
  prospectFormOptions: {
    prefixMale: [],
    suffixMale: [],
    prefixFemale: [],
    suffixFemale: [],
    memberTypes: [],
    countries: [],
    countriesByCamp: [],
    states: [],
    membersByCamp: [],
    pastorsByCamp: [],
    occupations: [],
    denominations: [],
    languages: [],
    churches: [],
    spouses: [],
    membersprospects: [],
    referralTypes: []
  },
  prospectListReportAsCSVUrl: '',
  prospectListReportAsPDFUrl: '',
  prospectNoteFormOptions: {
    noteTypes: [],
    membersByCamp: []
  },
  prospectNotes: [],
  prospectProfile: {},
  prospectProfileReportAsPDF: '',
  prospects: [],
  query_limit: 3,
  recent_members: [],
  recent_prospects: [],
  spouseFormOptions: {
    prefixMale: [],
    suffixMale: [],
    prefixFemale: [],
    suffixFemale: []
  },
  usStates: [],
  widgetData: {}
})

export const getters = {
  isIndividualProfileMemberLifetime: state => {
    return state.isIndividualProfileMembershipLifetime
  },
  friends: state => {
    return state.friends
  },
  fiscalYear: state => {
    let d = new Date()
    let year = d.getFullYear()
    let fiscal_year = d.getMonth() <= 5 ? year : year + 1
    return fiscal_year
  },
  recent_members: state => {
    return state.recent_members
  },
  recent_prospects: state => {
    return state.recent_prospects
  },
  topWidgetData: state => {
    return [
      {
        num: state.widgetData.ProspectsYearToDate,
        title: 'Gideon candidates identified year to date',
        subtitle: ''
      },
      {
        num: state.widgetData.DaysSinceLastProspect,
        title: 'Days since last candidate was added',
        subtitle: ''
      }
    ]
  },
  bottomWidgetData: state => {
    return [
      {
        num: state.widgetData.NewGideonMembers,
        title: 'New Gideon members fiscal year to date',
        subtitle: ''
      },
      {
        num: state.widgetData.NewAuxiliaryMember,
        title: 'New auxiliary members fiscal year to date',
        subtitle: ''
      }
    ]
  },
  memberPopup: state => {
    return state.memberPopup
  },
  members: state => {
    return state.members
  },
  membertype: state => {
    return state.membertype
  },
  prospects: state => {
    return state.prospects
  },
  friendsReportAsCSVUrl: state => {
    return state.friendsReportAsCSVUrl
  },
  friendsReportAsPDFUrl: state => {
    return state.friendsReportAsPDFUrl
  },
  memberListReportAsCSVUrl: state => {
    return state.memberListReportAsCSVUrl
  },
  memberListReportAsPDFUrl: state => {
    return state.memberListReportAsPDFUrl
  },
  newMemberListReportAsCSVUrl: state => {
    return state.newMemberListReportAsCSVUrl
  },
  newMemberListReportAsPDFUrl: state => {
    return state.newMemberListReportAsPDFUrl
  },
  officerListReportAsCSVUrl: state => {
    return state.officerListReportAsCSVUrl
  },
  officerListReportAsPDFUrl: state => {
    return state.officerListReportAsPDFUrl
  },
  prospectListReportAsCSVUrl: state => {
    return state.prospectListReportAsCSVUrl
  },
  prospectListReportAsPDFUrl: state => {
    return state.prospectListReportAsPDFUrl
  },
  memberProfileReportAsPDF: state => {
    return state.memberProfileReportAsPDF
  },
  nonmemberProfileReportAsPDF: state => {
    return state.nonmemberProfileReportAsPDF
  },
  prospectProfileReportAsPDF: state => {
    return state.prospectProfileReportAsPDF
  },
  memberTypeDefault: state => {
    return state.membertypedefault
  },
  individualProfile: state => {
    return state.individualProfile
  },
  individualProfileHeader: state => {
    return state.individualProfileHeader
  },
  individualCommunicationsData: state => {
    return state.individualCommunicationsData
  },
  individualCommunicationPreferencesData: state => {
    return state.individualCommunicationPreferencesData
  },
  individualEditFormOptions: state => {
    return state.individualEditFormOptions
  },
  convertedindividualEditFormOptions: state => {
    let newFormOptions = {}
    newFormOptions.prefixMale = state.individualEditFormOptions.prefixMale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.suffixMale = state.individualEditFormOptions.suffixMale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.prefixFemale = state.individualEditFormOptions.prefixFemale.map(x => {
      return {
        sitecore: x.sitecore,
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.suffixFemale = state.individualEditFormOptions.suffixFemale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.occupations = state.individualEditFormOptions.occupations.map(x => {
      return {
        sitecore: x.sitecore,
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.denominations = state.individualEditFormOptions.denominations.map(x => {
      return {
        sitecore: x.sitecore,
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.churches = state.individualEditFormOptions.churches
      ? state.individualEditFormOptions.churches.map(x => {
        return {
          text: x.church_name,
          value: x.chu_org_key
        }
      })
      : []
    newFormOptions.languages = state.individualEditFormOptions.languages.map(x => {
      return {
        text: x.display_text,
        value: x.value_key
      }
    })
    newFormOptions.pastorsByCamp = state.individualEditFormOptions.pastorsByCamp
      ? state.individualEditFormOptions.pastorsByCamp.map(x => {
        return {
          text: x.name,
          value: x.ind_key
        }
      })
      : []
    newFormOptions.memberTypes = [...state.individualEditFormOptions.memberTypes]
    newFormOptions.referralTypes = [...state.individualEditFormOptions.referralTypes]
    return newFormOptions
  },
  preferredLanguages: state => {
    return state.preferredLanguages
  },
  convertedPreferredLanguages: state => {
    let newFormOptions = {}
    newFormOptions.languages = state.preferredLanguages.map(x => {
      return {
        text: x.name,
        value: x.culture_code
      }
    })
    newFormOptions.suffixMale = state.individualEditFormOptions.suffixMale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.prefixFemale = state.individualEditFormOptions.prefixFemale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.suffixFemale = state.individualEditFormOptions.suffixFemale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.occupations = state.individualEditFormOptions.occupations.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.denominations = state.individualEditFormOptions.denominations.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.churches = state.individualEditFormOptions.churches.map(x => {
      return {
        text: x.church_name,
        value: x.chu_org_key
      }
    })
    newFormOptions.languages = state.individualEditFormOptions.languages.map(x => {
      return {
        text: x.display_text,
        value: x.value_key
      }
    })
    newFormOptions.pastorsByCamp = state.individualEditFormOptions.pastorsByCamp.map(x => {
      return {
        text: x.name,
        value: x.ind_key
      }
    })
    newFormOptions.memberTypes = [...state.individualEditFormOptions.memberTypes]
    newFormOptions.referralTypes = [...state.individualEditFormOptions.referralTypes]
    return newFormOptions
  },
  spouseFormOptions: state => {
    return state.spouseFormOptions
  },
  getAvatar: state => {
    return state.memberAvatar
  },
  convertedSpouseFormOptions: state => {
    let newFormOptions = {}
    newFormOptions.prefixMale = state.spouseFormOptions.prefixMale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.suffixMale = state.spouseFormOptions.suffixMale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.prefixFemale = state.spouseFormOptions.prefixFemale.map(x => {
      return {
        sitecore: x.sitecore,
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.suffixFemale = state.spouseFormOptions.suffixFemale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })

    return newFormOptions
  },
  prospectProfile: state => {
    return state.prospectProfile
  },
  prospectFormOptions: state => {
    return state.prospectFormOptions
  },
  convertedProspectFormOptions: state => {
    let newFormOptions = {}
    newFormOptions.churches = state.prospectFormOptions.churches.map(c => {
      return {
        text: c.church_name,
        value: c.chu_org_key
      }
    })
    newFormOptions.countries = state.prospectFormOptions.countries.map(c => {
      return {
        text: c.display_text,
        value: c.value
      }
    })
    newFormOptions.countriesByCamp = state.prospectFormOptions.countriesByCamp.map(c => {
      return {
        text: c.display_text,
        value: c.value
      }
    })
    newFormOptions.denominations = state.prospectFormOptions.denominations.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.languages = state.prospectFormOptions.languages.map(x => {
      return {
        text: x.display_text,
        value: x.value_key
      }
    })
    newFormOptions.memberTypes = state.prospectFormOptions.memberTypes.map(x => {
      return {
        text: x.met_description_text,
        value: x.met_key
      }
    })
    newFormOptions.membersByCamp = state.prospectFormOptions.membersByCamp.map(x => {
      return {
        text: x.name,
        value: x.ind_key
      }
    })
    newFormOptions.pastorsByCamp = state.prospectFormOptions.pastorsByCamp.map(x => {
      return {
        text: x.name.trim(),
        value: x.ind_key
      }
    })
    newFormOptions.occupations = state.prospectFormOptions.occupations.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.prefixFemale = state.prospectFormOptions.prefixFemale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.prefixMale = state.prospectFormOptions.prefixMale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.spouses = state.prospectFormOptions.spouses.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.states = state.prospectFormOptions.states.map(x => {
      return {
        text: x.display_text,
        value: x.state_code
      }
    })
    newFormOptions.suffixMale = state.prospectFormOptions.suffixMale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.suffixFemale = state.prospectFormOptions.suffixFemale.map(x => {
      return {
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.membersprospects = state.prospectFormOptions.membersprospects.map(x => {
      return {
        text: x.name,
        value: x.ind_key
      }
    })
    newFormOptions.referralTypes = [...state.prospectFormOptions.referralTypes]

    return newFormOptions
  },
  memberCommitteeAndRoles: state => {
    return state.memberCommitteeAndRoles
  },
  addressFormOptions: state => {
    return state.addressFormOptions
  },
  convertedAddressFormOptions: state => {
    let newFormOptions = {}
    newFormOptions.countries = state.addressFormOptions.countries.map(c => {
      return {
        text: c.display_text,
        value: c.value
      }
    })
    newFormOptions.countriesByCamp = state.addressFormOptions.countriesByCamp.map(c => {
      return {
        text: c.display_text,
        value: c.value
      }
    })
    newFormOptions.states = state.addressFormOptions.states
      .map(x => {
        return {
          text: x.display_text,
          value: x.state_code
        }
      })
      .sort((a, b) => {
        let aValue = a.text.toLowerCase()
        let bValue = b.text.toLowerCase()

        if (aValue < bValue) {
          return -1
        } else if (aValue > bValue) {
          return 1
        } else {
          return 0
        }
      })
    newFormOptions.states_long = state.addressFormOptions.states.map(x => {
      return {
        text: x.display_text,
        value: x.value,
        code: x.state_code
      }
    })
    newFormOptions.addressTypes = state.addressFormOptions.addressTypes.map(x => {
      return {
        text: x.display_text,
        value: x.adt_key
      }
    })
    return newFormOptions
  },
  phoneFormOptions: state => {
    return state.phoneFormOptions
  },
  convertedPhoneFormOptions: state => {
    let newFormOptions = {}
    newFormOptions.countries = state.phoneFormOptions.countries.map(c => {
      return {
        text: c.display_text,
        value: c.value
      }
    })
    newFormOptions.countriesByCamp = state.phoneFormOptions.countriesByCamp.map(c => {
      return {
        text: c.display_text,
        value: c.value
      }
    })
    newFormOptions.phoneTypes = state.phoneFormOptions.phoneTypes.map(x => {
      return {
        text: x.display_text,
        value: x.pht_key
      }
    })
    newFormOptions.faxTypes = state.phoneFormOptions.faxTypes.map(x => {
      return {
        text: x.display_text,
        value: x.fxt_key
      }
    })

    return newFormOptions
  },
  emailFormOptions: state => {
    return state.emailFormOptions
  },
  convertedEmailFormOptions: state => {
    let newFormOptions = {}
    newFormOptions.emailTypes = state.emailFormOptions.emailTypes.map(x => {
      return {
        text: x.display_text,
        value: x.emt_key
      }
    })

    return newFormOptions
  },
  prospectNotes: state => {
    return state.prospectNotes
  },
  prospectNoteFormOptions: state => {
    return state.prospectNoteFormOptions
  },
  convertedProspectNoteFormOptions: state => {
    let newFormOptions = {}
    newFormOptions.noteTypes = state.prospectNoteFormOptions.noteTypes.map(x => {
      return {
        sitecore: x.sitecore,
        text: x.display_text,
        value: x.value
      }
    })
    newFormOptions.membersByCamp = state.prospectNoteFormOptions.membersByCamp.map(x => {
      return {
        text: x.name,
        value: x.ind_key
      }
    })

    return newFormOptions
  },
  paymentInfo: state => {
    return state.paymentInfo
  },
  usStates: state => {
    return state.usStates
  },
  convertedUsStates: state => {
    let newStatesOptions = {}
    newStatesOptions = state.usStates.map(x => {
      return {
        text: x.display_text,
        value: x.state_code
      }
    })
    return newStatesOptions
  }
}

export const CLEAR_INDIVIDUAL_PROFILE = 'CLEAR_INDIVIDUAL_PROFILE'
export const CLEAR_MEMBER_TYPE_DEFAULT = 'CLEAR_MEMBER_TYPE_DEFAULT'
export const CLEAR_MEMBER_POP = 'CLEAR_MEMBER_POP'
export const CLEAR_PAYMENT_INFO = 'CLEAR_PAYMENT_INFO'
export const CLEAR_PROSPECT_PROFILE = 'CLEAR_PROSPECT_PROFILE'
export const RESET_AVATAR_URL = 'RESET_AVATAR_URL'
export const SET_ADDRESS_FORM_OPTIONS = 'SET_ADDRESS_FORM_OPTIONS'
export const SET_COMMUNICATION_PREFERENCE_DATA = 'SET_COMMUNICATION_PREFERENCE_DATA'
export const SET_DATALIST1_CSV_URL = 'SET_DATALIST1_CSV_URL'
export const SET_DATALIST1_PDF_URL = 'SET_DATALIST1_PDF_URL'
export const SET_EMAIL_FORM_OPTIONS = 'SET_EMAIL_FORM_OPTIONS'
export const SET_FRIENDS = 'SET_FRIENDS'
export const SET_FRIENDS_CSV_URL = 'SET_FRIENDS_CSV_URL'
export const SET_FRIENDS_PDF_URL = 'SET_FRIENDS_PDF_URL'
export const SET_INDIVIDUAL_COMMUNICATIONS_DATA = 'SET_INDIVIDUAL_COMMUNICATIONS_DATA'
export const SET_INDIVIDUAL_EDIT_FORM_OPTIONS = 'SET_INDIVIDUAL_EDIT_FORM_OPTIONS'
export const SET_INDIVIDUAL_PROFILE = 'SET_INDIVIDUAL_PROFILE'
export const SET_INDIVIDUAL_PROFILE_HEADER = 'SET_INDIVIDUAL_PROFILE_HEADER'
export const SET_MEMBER_AVATAR = 'SET_MEMBER_AVATAR'
export const SET_MEMBER_COMMITTEE_ROLES = 'SET_MEMBER_COMMITTEE_ROLES'
export const SET_MEMBER_LIST_CSV_URL = 'SET_MEMBER_LIST_CSV_URL'
export const SET_MEMBER_LIST_PDF_URL = 'SET_MEMBER_LIST_PDF_URL'
export const SET_MEMBER_PROFILE_PDF = 'SET_MEMBER_PROFILE_PDF'
export const SET_MEMBER_POPUP = 'SET_MEMBER_POPUP'
export const SET_MEMBER_TYPE_DEFAULT = 'SET_MEMBER_TYPE_DEFAULT'
export const SET_MEMBERS = 'SET_MEMBERS'
export const SET_NEW_MEMBER_LIST_CSV_URL = 'SET_NEW_MEMBER_LIST_CSV_URL'
export const SET_NEW_MEMBER_LIST_PDF_URL = 'SET_NEW_MEMBER_LIST_PDF_URL'
export const SET_NONMEMBER_PROFILE_PDF = 'SET_NONMEMBER_PROFILE_PDF'
export const SET_OFFICER_LIST_CSV_URL = 'SET_OFFICER_LIST_CSV_URL'
export const SET_OFFICER_LIST_PDF_URL = 'SET_OFFICER_LIST_PDF_URL'
export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO'
export const SET_PHONE_FORM_OPTIONS = 'SET_PHONE_FORM_OPTIONS'
export const SET_PREFERRED_LANGUAGES = 'SET_PREFERRED_LANGUAGES'
export const SET_PROSPECTS = 'SET_PROSPECTS'
export const SET_PROSPECT_FORM_OPTIONS = 'SET_PROSPECT_FORM_OPTIONS'
export const SET_PROSPECT_LIST_CSV_URL = 'SET_PROSPECT_LIST_CSV_URL'
export const SET_PROSPECT_LIST_PDF_URL = 'SET_PROSPECT_LIST_PDF_URL'
export const SET_PROSPECT_NOTES = 'SET_PROSPECT_NOTES'
export const SET_PROSPECT_NOTE_FORM_OPTIONS = 'SET_PROSPECT_NOTE_FORM_OPTIONS'
export const SET_PROSPECT_PROFILE = 'SET_PROSPECT_PROFILE'
export const SET_PROSPECT_PROFILE_PDF = 'SET_PROSPECT_PROFILE_PDF'
export const SET_RECENT_AUXILIARY_MEMBERS_ALL = 'SET_RECENT_AUXILIARY_MEMBERS_ALL'
export const SET_RECENT_MEMBERS = 'SET_RECENT_MEMBERS'
export const SET_RECENT_PROSPECTS = 'SET_RECENT_PROSPECTS'
export const SET_SPOUSE_FORM_OPTIONS = 'SET_SPOUSE_FORM_OPTIONS'
export const SET_US_STATES = 'SET_US_STATES'
export const SET_WIDGET_DATA = 'SET_WIDGET_DATA'
export const WIDGET_LIMIT = 3
export const SET_IS_INDIVIDUAL_PROFILE_MEMBERSHIP_LIFETIME = 'SET_IS_INDIVIDUAL_PROFILE_MEMBERSHIP_LIFETIME'

export const mutations = {
  [SET_IS_INDIVIDUAL_PROFILE_MEMBERSHIP_LIFETIME](state, data) {
    state.isIndividualProfileMembershipLifetime = data
  },
  [SET_FRIENDS](state, data) {
    state.friends = data
  },
  [SET_WIDGET_DATA](state, data) {
    state.widgetData = data
  },
  [SET_RECENT_PROSPECTS](state, data) {
    state.recent_prospects = data
  },
  [SET_RECENT_MEMBERS](state, data) {
    state.recent_members = data
  },
  [SET_MEMBERS](state, data) {
    state.members = data
  },
  [SET_MEMBER_AVATAR](state, data) {
    state.memberAvatar = data
  },
  [RESET_AVATAR_URL](state, data) {
    state.memberAvatar.iim_image_url_path = data
  },
  [SET_PROSPECTS](state, data) {
    state.prospects = data
  },
  [SET_FRIENDS_CSV_URL](state, data) {
    state.friendsReportAsCSVUrl = data
  },
  [SET_FRIENDS_PDF_URL](state, data) {
    state.friendsReportAsPDFUrl = data
  },
  [SET_MEMBER_LIST_CSV_URL](state, data) {
    state.memberListReportAsCSVUrl = data
  },
  [SET_MEMBER_LIST_PDF_URL](state, data) {
    state.memberListReportAsPDFUrl = data
  },
  [SET_NEW_MEMBER_LIST_CSV_URL](state, data) {
    state.newMemberListReportAsCSVUrl = data
  },
  [SET_NEW_MEMBER_LIST_PDF_URL](state, data) {
    state.newMemberListReportAsPDFUrl = data
  },
  [SET_MEMBER_POPUP](state, data) {
    state.memberPopup = data
  },
  [SET_OFFICER_LIST_CSV_URL](state, data) {
    state.officerListReportAsCSVUrl = data
  },
  [SET_OFFICER_LIST_PDF_URL](state, data) {
    state.officerListReportAsPDFUrl = data
  },
  [SET_PROSPECT_LIST_CSV_URL](state, data) {
    state.prospectListReportAsCSVUrl = data
  },
  [SET_PROSPECT_LIST_PDF_URL](state, data) {
    state.prospectListReportAsPDFUrl = data
  },
  [SET_MEMBER_PROFILE_PDF](state, data) {
    state.memberProfileReportAsPDF = data
  },
  [SET_NONMEMBER_PROFILE_PDF](state, data) {
    state.nonmemberProfileReportAsPDF = data
  },
  [SET_PROSPECT_PROFILE_PDF](state, data) {
    state.prospectProfileReportAsPDF = data
  },
  [SET_INDIVIDUAL_PROFILE](state, data) {
    state.individualProfile = data
  },
  [SET_INDIVIDUAL_PROFILE_HEADER](state, data) {
    state.individualProfileHeader = data
  },
  [SET_INDIVIDUAL_COMMUNICATIONS_DATA](state, data) {
    state.individualCommunicationsData = data
    // item_description is for anything of the ServiceCard type
    state.individualCommunicationsData.addresses.map(ad => {
      ad.item_description = 'address'
    })
    state.individualCommunicationsData.phones.map(ph => {
      ph.item_description = 'phone number'
    })
    state.individualCommunicationsData.emails.map(ph => {
      ph.item_description = 'email address'
    })
  },

  [SET_COMMUNICATION_PREFERENCE_DATA](state, data) {
    state.individualCommunicationPreferencesData = data
    // item_description is for anything of the ServiceCard type
    // state.individualCommunicationPreferencesData.preferredEmail.map(ph => {
    //   ph.item_description = 'email address'
    // })
  },
  [SET_PROSPECT_PROFILE](state, data) {
    state.prospectProfile = data
  },
  [SET_INDIVIDUAL_EDIT_FORM_OPTIONS](state, data) {
    state.individualEditFormOptions = data
  },
  [SET_SPOUSE_FORM_OPTIONS](state, data) {
    state.spouseFormOptions = data
  },
  [SET_PROSPECT_FORM_OPTIONS](state, data) {
    state.prospectFormOptions = data
  },
  [SET_MEMBER_COMMITTEE_ROLES](state, data) {
    state.memberCommitteeAndRoles = data
  },
  [SET_ADDRESS_FORM_OPTIONS](state, data) {
    state.addressFormOptions = data
  },
  [SET_PHONE_FORM_OPTIONS](state, data) {
    state.phoneFormOptions = data
  },
  [SET_EMAIL_FORM_OPTIONS](state, data) {
    state.emailFormOptions = data
  },
  [SET_PREFERRED_LANGUAGES](state, data) {
    state.preferredLanguages = data
  },
  [SET_PROSPECT_NOTES](state, data) {
    state.prospectNotes = data
  },
  [SET_PROSPECT_NOTE_FORM_OPTIONS](state, data) {
    state.prospectNoteFormOptions = data
  },
  [SET_PAYMENT_INFO](state, data) {
    state.paymentInfo = data
  },
  [SET_US_STATES](state, data) {
    state.usStates = data
  },
  [CLEAR_INDIVIDUAL_PROFILE](state) {
    state.individualProfile = {}
  },
  [CLEAR_PROSPECT_PROFILE](state) {
    state.prospectProfile = {}
  },
  [SET_MEMBER_TYPE_DEFAULT](state, data) {
    state.membertypedefault = data
  },
  [CLEAR_MEMBER_TYPE_DEFAULT](state) {
    state.membertypedefault = ''
  },
  [CLEAR_MEMBER_POP](state) {
    state.memberPopup = {
      pop_key: null,
      pop_title: '',
      pop_message: ''
    }
  },
  [CLEAR_PAYMENT_INFO](state) {
    state.paymentInfo = {
      isAutoPaySetup: false,
      CampName: '',
      isPayDuesLinkSupposeToBeVisible: false,
      withdrawDate: 0
    }
  }
}

export const actions = {
  async loadWidgetData({ commit, rootGetters }) {
    try {
      let d = new Date()
      let year = d.getFullYear()
      let fiscal_year = d.getMonth() <= 5 ? year : year + 1
      let response = await apiCalls.loadWidgetData(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey'],
        fiscal_year, // '2019',
        WIDGET_LIMIT
      )
      if (response && response.status === 200) {
        await commit('SET_WIDGET_DATA', response.data.counts)
        await commit('SET_RECENT_PROSPECTS', response.data.prospects)
        await commit('SET_RECENT_MEMBERS', response.data.members)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadFriends({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadFriendsByCamp(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_FRIENDS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadMemberPopup({ commit, rootGetters }, ind_key) {
    try {
      let response = await apiCalls.getMemberPopup(ind_key)
      if (response && response.status === 200) {
        await commit('SET_MEMBER_POPUP', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async removeMemberPopup({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.removeMemberPopup(payload.pop_key, payload.ind_key)
      if (response && response.status === 200) {
        // flush the pop up message
        await commit('CLEAR_MEMBER_POP')
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadMembers({ commit, rootGetters }, code) {
    try {
      // the membership page will load different data sets
      // based off of a query parameter, passing that value
      // as 'code'
      let response
      const usercampkey = rootGetters['user/userCampKey']

      switch (code) {
        case 'cof':
        case 'dir':
          // camp officers
          response = await apiCalls.loadOfficersByCamp(
            rootGetters['user/userCampKey'],
            rootGetters['user/userLanguageKey']
          )
          break
        case 'new':
          // new members
          response = await apiCalls.loadNewMembersByCamp(
            rootGetters['user/userCampKey'],
            rootGetters['user/userLanguageKey']
          )
          break
        case 'aux':
          // aux members
          response = await apiCalls.getAuxiliaryMembersAll(
            rootGetters['user/userCampKey'],
            rootGetters['user/userLanguageKey']
          )
          break
        case 'naux':
          // aux members
          response = await apiCalls.getAuxiliaryMembersNew(
            rootGetters['user/userCampKey'],
            rootGetters['user/userLanguageKey']
          )
          break
        case 'acof':
          // aux camp officers
          response = await apiCalls.loadAuxiliaryOfficersByCamp(
            rootGetters['user/userCampKey'],
            rootGetters['user/userLanguageKey']
          )
          break
        case 'scm':
        case 'sco':
        case 'cmb':
          response = await apiCalls.loadMembersByCamp(
            rootGetters['user/userCampKey'],
            rootGetters['user/userLanguageKey']
          )
          break
        case 'oth':
          break
        default:
          // just plain ole members
          if (!!usercampkey) {
            response = await apiCalls.loadMembersByCamp(
              rootGetters['user/userCampKey'],
              rootGetters['user/userLanguageKey']
            )
          }
          break
      }

      if (response && response.status === 200) {
        await commit('SET_MEMBERS', response.data)
      } else {
        if (!!usercampkey) {
          if (!response) {
            console.error('empty response')
          } else {
            console.error('unrecognized status code:' + response.status)
          }
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadMemberTypeDefault({ commit }, data) {
    await commit('SET_MEMBER_TYPE_DEFAULT', data)
  },
  async clearMemberTypeDefault({ commit }) {
    await commit('CLEAR_MEMBER_TYPE_DEFAULT')
  },
  async clearMemberPopup({ commit }) {
    await commit('CLEAR_MEMBER_POP')
  },
  async loadMembersByCamp({ commit, rootGetters }, camp_key) {
    try {
      let response = await apiCalls.loadMembersByCamp(camp_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_MEMBERS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadProspects({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadProspectsByCamp(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_PROSPECTS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadFriendsReportUrls({ commit, rootGetters }) {
    let csvURL = reportCalls.getFriendsReportAsCSVUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    await commit('SET_FRIENDS_CSV_URL', csvURL)
    let pdfURL = reportCalls.getFriendsReportAsPDFUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    await commit('SET_FRIENDS_PDF_URL', pdfURL)
    return true
  },
  async loadMemberListReportUrls({ commit, rootGetters }) {
    let csvURL = reportCalls.getMemberListReportAsCSVUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    let pdfURL = reportCalls.getMemberListReportAsPDFUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([commit('SET_MEMBER_LIST_CSV_URL', csvURL), commit('SET_MEMBER_LIST_PDF_URL', pdfURL)])
    return true
  },
  async loadNewMemberListReportUrls({ commit, rootGetters }) {
    let csvURL = reportCalls.getNewMemberListReportAsCSVUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    let pdfURL = reportCalls.getNewMemberListReportAsPDFUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([commit('SET_NEW_MEMBER_LIST_CSV_URL', csvURL), commit('SET_NEW_MEMBER_LIST_PDF_URL', pdfURL)])
    return true
  },
  async loadOfficerListReportUrls({ commit, rootGetters }) {
    let csvURL = reportCalls.getOfficerListReportAsCSVUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    let pdfURL = reportCalls.getOfficerListReportAsPDFUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([commit('SET_OFFICER_LIST_CSV_URL', csvURL), commit('SET_OFFICER_LIST_PDF_URL', pdfURL)])
    return true
  },
  async loadProspectListReportUrls({ commit, rootGetters }) {
    let csvURL = reportCalls.getProspectListReportAsCSVUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    let pdfURL = reportCalls.getProspectListReportAsPDFUrl(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([commit('SET_PROSPECT_LIST_CSV_URL', csvURL), commit('SET_PROSPECT_LIST_PDF_URL', pdfURL)])
    return true
  },
  async loadMemberProfileReportUrls({ commit, rootGetters }) {
    let pdfURL = reportCalls.getMemberProfileReportAsPDF(
      rootGetters['user/userSelectedIndividualKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([commit('SET_MEMBER_PROFILE_PDF', pdfURL)])
    return true
  },
  async loadNonMemberProfileReportUrls({ commit, rootGetters }) {
    let pdfURL = reportCalls.getNonMemberProfileReportAsPDF(
      rootGetters['user/userCampKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([commit('SET_NONMEMBER_PROFILE_PDF', pdfURL)])
    return true
  },
  async loadProspectProfileReportUrls({ commit, rootGetters }) {
    let pdfURL = reportCalls.getProspectProfileReportAsPDF(
      rootGetters['user/userSelectedIndividualKey'],
      rootGetters['user/userLanguageKey']
    )
    await Promise.all([commit('SET_PROSPECT_PROFILE_PDF', pdfURL)])
    return true
  },
  async loadIndividualProfile({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadIndividualProfile(rootGetters['user/userSelectedIndividualKey'])
      if (response && response.status === 200) {
        await commit('SET_INDIVIDUAL_PROFILE', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadIndividualProfileHeader({ commit }, ind_key) {
    try {
      let response = await apiCalls.loadIndividualProfile(ind_key)
      if (response && response.status === 200) {
        await commit('SET_INDIVIDUAL_PROFILE_HEADER', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getIndividualCommunications({ commit, rootGetters }, entity_key) {
    try {
      let response = await apiCalls.getIndividualCommunications(entity_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_INDIVIDUAL_COMMUNICATIONS_DATA', response.data)
      } else {
        throw 'bad response from getIndividualCommunications call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getMemberAvatar({ commit }, ind_key) {
    try {
      let response = await apiCalls.getMemberAvatar(ind_key)
      if (response && response.status === 200) {
        await commit('SET_MEMBER_AVATAR', response.data)
      } else {
        throw 'bad response from getMemberAvatar call'
      }
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async resetAvatar({ commit }, imgURL) {
    await commit('RESET_AVATAR_URL', imgURL)
  },
  async getIndividualCommPreferences({ commit, rootGetters }, entity_key) {
    try {
      let response = await apiCalls.getIndividualCommPreferences(entity_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_COMMUNICATION_PREFERENCE_DATA', response.data)
      } else {
        throw 'bad response from getIndividualCommPreferences call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getMemberCommitteeAndRoles({ commit, rootGetters }, ind_key) {
    try {
      let response = await apiCalls.getMemberCommitteeAndRoles(ind_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_MEMBER_COMMITTEE_ROLES', response.data)
      } else {
        throw 'bad response from getMemberCommitteeAndRoles call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadAddressFormOptions({ commit, rootGetters }, org_key) {
    try {
      const camp_key = org_key ? org_key : rootGetters['user/userCampKey']
      let response = await apiCalls.loadAddressFormOptions(camp_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_ADDRESS_FORM_OPTIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveAddress({ }, payload) {
    try {
      let response = await apiCalls.addUpdateAddress(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPhoneFormOptions({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadPhoneFormOptions(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_PHONE_FORM_OPTIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async savePhone({ }, payload) {
    try {
      let response = await apiCalls.addUpdatePhone(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveFax({ }, payload) {
    try {
      let response = await apiCalls.addUpdateFax(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadEmailFormOptions({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadEmailFormOptions(rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_EMAIL_FORM_OPTIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadIndividualEditFormOptions({ commit, rootGetters }, ind_key) {
    try {
      let response = await apiCalls.loadIndividualEditFormOptions(ind_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_INDIVIDUAL_EDIT_FORM_OPTIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadSpouseFormOptions({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadSpouseFormOptions(rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_SPOUSE_FORM_OPTIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadProspectFormOptions({ commit, rootGetters }, camp_key) {
    try {
      let response = await apiCalls.loadProspectFormOptions(camp_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_PROSPECT_FORM_OPTIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPreferredLanguages({ commit }) {
    try {
      let response = await apiCalls.loadPreferredLanguages()
      if (response && response.status === 200) {
        await commit('SET_PREFERRED_LANGUAGES', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveEmail({ }, payload) {
    try {
      let response = await apiCalls.addUpdateEmail(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async savePreferredLanguage({ }, payload) {
    try {
      let response = await apiCalls.addUpdatePreferredLanguage(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveLoginEmail({ }, payload) {
    try {
      let response = await apiCalls.addUpdateLoginEmail(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadProspectNotes({ commit, rootGetters }, entity_key) {
    try {
      let response = await apiCalls.getProspectNotes(entity_key, rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_PROSPECT_NOTES', response.data)
      } else {
        throw 'bad response from getProspectNotes call'
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadProspectNoteFormOptions({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadProspectNoteOptions(
        'UserProfileNoteType',
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_PROSPECT_NOTE_FORM_OPTIONS', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  setProspectProfile: ({ commit }, payload) => {
    commit('SET_PROSPECT_PROFILE', payload)
  },
  async saveProspectNote({ }, payload) {
    try {
      let response = await apiCalls.addUpdateProspectNote(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deleteProspect({ }, payload) {
    try {
      let response = await apiCalls.deleteProspect(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadPaymentInformation({ commit }, ind_key) {
    try {
      await commit('CLEAR_PAYMENT_INFO');
      let response = await apiCalls.getMemberPaymentInfo(ind_key)
      if (response && response.status === 200) {
        await commit('SET_PAYMENT_INFO', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadUsStates({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getUsStates()

      if (response && response.status === 200) {
        await commit('SET_US_STATES', response.data)
      } else {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveAutopay({ }, payload) {
    try {
      let response = await apiCalls.saveAutopay(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
        return false
      }
      return response
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveDues({ }, payload) {
    try {
      let response = await apiCalls.saveDues(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
        return false
      }
      return response
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveIODues({ }, payload) {
    try {
      let response = await apiCalls.saveIODues(payload)

      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
        return false
      }
      return response
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async savePhoto({ rootGetters }, photoInfo) {
    try {
      let response = await apiCalls.savePhoto(photoInfo)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
        return false
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveIndividualDetails({ }, payload) {
    try {
      let response = await apiCalls.saveIndividualDetails(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
        return false
      }
      return response
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveNonMember({ }, payload) {
    try {
      let response = await apiCalls.saveNonMember(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
        return false
      }
      return response
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveSpouse({ }, payload) {
    try {
      let response = await apiCalls.saveSpouse(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
        return false
      }
      return response
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveAddProspect({ }, payload) {
    try {
      let response = await apiCalls.saveAddProspect(payload)
      if (!response || response.status !== 200) {
        if (!response) {
          console.error('empty response')
        } else {
          console.error('unrecognized status code:' + response.status)
        }
        return false
      }
      return response
    } catch (error) {
      console.error(error)
      return false
    }
  },
  clearIndividualProfile: ({ commit }) => {
    commit('CLEAR_INDIVIDUAL_PROFILE')
  },
  clearProspectProfile: ({ commit }) => {
    commit('CLEAR_PROSPECT_PROFILE')
  },
  async getAuxiliaryMembers({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getAuxiliaryMembers(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_RECENT_AUXILIARY_MEMBERS', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAuxiliaryMembersAll({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getAuxiliaryMembersAll(
        rootGetters['user/userCampKey'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_RECENT_AUXILIARY_MEMBERS_ALL', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async turnOffMembershipAnnualAutoPay(_, turnOffMembershipPayload) {
    try {
      let response = await apiCalls.turnOffAnnualMembershipAutoPay(turnOffMembershipPayload)
      if (response && response.status === 200) {
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async setIsIndividualProfileLifeTime({ state, dispatch, commit, rootGetters }) {
    const indCampKey = Object.hasOwn(state.individualProfile, 'camp_key') && state.individualProfile.camp_key
    const indProfileIndKey = Object.hasOwn(state.individualProfile, 'ind_key') && state.individualProfile.ind_key

    const wasSuccessful = indCampKey && indProfileIndKey && (await dispatch('loadMembersByCamp', indCampKey))

    if (wasSuccessful) {
      const filteredMember = state.members.find(member => member.ind_key === indProfileIndKey)

      if (filteredMember) {
        await commit('SET_IS_INDIVIDUAL_PROFILE_MEMBERSHIP_LIFETIME', filteredMember.is_life_time_member)
      }
    }
  }
}

const apiCalls = {
  loadWidgetData(campKey, languageKey, fiscal_year, limit) {
    const route = MEMBER_API_CONFIG.getMembershipWidgetData(campKey, languageKey, fiscal_year, limit)
    return axios.get(route)
  },
  loadFriendsByCamp(campKey, languageKey) {
    const route = MEMBER_API_CONFIG.getFriends(campKey, languageKey)
    return axios.get(route)
  },
  loadMembersByCamp(campKey, languageKey) {
    const route = MEMBER_API_CONFIG.getMembers(campKey, languageKey)
    return axios.get(route)
  },
  loadNewMembersByCamp(campKey, languageKey) {
    const route = MEMBER_API_CONFIG.getNewMembers(campKey, languageKey)
    return axios.get(route)
  },
  loadOfficersByCamp(campKey, languageKey) {
    const route = MEMBER_API_CONFIG.getOfficers(campKey, languageKey)
    return axios.get(route)
  },
  loadAuxiliaryOfficersByCamp(campKey, languageKey) {
    const route = API_CONFIG.getAuxiliaryOfficers(campKey, languageKey)
    return axios.get(route)
  },
  loadAuxiliaryOfficersByCamp(campKey, languageKey) {
    const route = API_CONFIG.getAuxiliaryOfficers(campKey, languageKey)
    return axios.get(route)
  },
  loadProspectsByCamp(campKey, languageKey) {
    const route = MEMBER_API_CONFIG.getProspects(campKey, languageKey)
    return axios.get(route)
  },
  loadIndividualProfile(indKey) {
    const route = MEMBER_API_CONFIG.getProfile(indKey)
    return axios.get(route)
  },
  getIndividualCommunications(entity_key, lng_key) {
    const route = MEMBER_API_CONFIG.getIndividualCommunications(entity_key, lng_key)
    return axios.get(route)
  },
  getIndividualCommPreferences(ind_key, lng_key) {
    const route = MEMBER_API_CONFIG.getIndividualCommPreferences(ind_key, lng_key)
    return axios.get(route)
  },
  getMemberCommitteeAndRoles(ind_key, lng_key) {
    const route = MEMBER_API_CONFIG.getMemberCommitteeAndRoles(ind_key, lng_key)
    return axios.get(route)
  },
  getMemberPopup(ind_key) {
    const route = MEMBER_API_CONFIG.getMemberPopup(ind_key)
    return axios.get(route)
  },
  loadAddressFormOptions(campKey, lng_key) {
    const route = MEMBER_API_CONFIG.getAddressFormOptions(campKey, lng_key)
    return axios.get(route)
  },
  addUpdateAddress(addressObject) {
    const route = MEMBER_API_CONFIG.saveAddress()
    return axios.post(route, addressObject)
  },
  loadPhoneFormOptions(campKey, lng_key) {
    const route = MEMBER_API_CONFIG.getPhoneFormOptions(campKey, lng_key)
    return axios.get(route)
  },
  addUpdatePhone(object) {
    const route = MEMBER_API_CONFIG.savePhone()
    return axios.post(route, object)
  },
  addUpdateFax(object) {
    const route = MEMBER_API_CONFIG.saveFax()
    return axios.post(route, object)
  },
  loadEmailFormOptions(lng_key) {
    const route = MEMBER_API_CONFIG.getEmailFormOptions(lng_key)
    return axios.get(route)
  },
  loadIndividualEditFormOptions(ind_key, lng_key) {
    const route = MEMBER_API_CONFIG.getIndividualEditFormOptions(ind_key, lng_key)
    return axios.get(route)
  },
  loadSpouseFormOptions(lng_key) {
    const route = MEMBER_API_CONFIG.getSpouseFormOptions(lng_key)
    return axios.get(route)
  },
  loadProspectFormOptions(cmp_key, lng_key) {
    const route = MEMBER_API_CONFIG.getProspectFormOptions(cmp_key, lng_key)
    return axios.get(route)
  },
  loadPreferredLanguages() {
    const route = MEMBER_API_CONFIG.getPreferredLanguages()
    return axios.get(route)
  },
  addUpdateEmail(object) {
    const route = MEMBER_API_CONFIG.saveEmail()
    return axios.post(route, object)
  },
  addUpdatePreferredLanguage(object) {
    const route = MEMBER_API_CONFIG.saveLanguage()
    return axios.post(route, object)
  },
  addUpdateLoginEmail(object) {
    const route = MEMBER_API_CONFIG.saveLoginEmail()
    return axios.post(route, object)
  },
  getProspectNotes(entity_key, lng_key) {
    const route = MEMBER_API_CONFIG.getProspectNotes(entity_key, lng_key)
    return axios.get(route)
  },
  loadProspectNoteOptions(note_type, entity_key, lng_key) {
    const route = MEMBER_API_CONFIG.getProspectNotesOptions(note_type, entity_key, lng_key)
    return axios.get(route)
  },
  addUpdateProspectNote(object) {
    const route = MEMBER_API_CONFIG.saveProspectNote()
    return axios.post(route, object)
  },
  deleteProspect(object) {
    const route = MEMBER_API_CONFIG.deleteProspect()
    return axios.post(route, object)
  },
  getMemberPaymentInfo(ind_key) {
    const route = MEMBER_API_CONFIG.getMemberPaymentInfo(ind_key)
    return axios.get(route)
  },
  getUsStates() {
    const route = MEMBER_API_CONFIG.getUsStates()
    return axios.get(route)
  },
  removeMemberPopup(pop_key, ind_key) {
    const route = MEMBER_API_CONFIG.removeMemberPopup(pop_key, ind_key)
    return axios.get(route)
  },
  saveAutopay(object) {
    const route = MEMBER_API_CONFIG.saveAutopay()
    return axios.post(route, object)
  },
  saveDues(object) {
    const route = MEMBER_API_CONFIG.saveDues()
    return axios.post(route, object)
  },
  saveIODues(object) {
    const route = MEMBER_API_CONFIG.saveIODues()
    return axios.post(route, object)
  },
  savePhoto(photoInfo) {
    let formData = new FormData()
    for (var itm in photoInfo) {
      formData.append(itm, photoInfo[itm])
    }
    const route = MEMBER_API_CONFIG.upsertPhoto(photoInfo.iim_ind_key)
    return axios.post(route, formData)
  },
  saveIndividualDetails(object) {
    const route = MEMBER_API_CONFIG.saveIndividualDetails()
    return axios.post(route, object)
  },
  saveNonMember(object) {
    const route = MEMBER_API_CONFIG.saveNonMember()
    return axios.post(route, object)
  },
  saveSpouse(object) {
    const route = MEMBER_API_CONFIG.saveSpouse()
    return axios.post(route, object)
  },
  saveAddProspect(object) {
    const route = MEMBER_API_CONFIG.saveAddProspect()
    return axios.post(route, object)
  },
  getAuxiliaryMembersNew(campKey, languageKey) {
    const route = API_CONFIG.getAuxiliaryMembersNew(campKey, languageKey)
    return axios.get(route)
  },
  getAuxiliaryMembersAll(campKey, languageKey) {
    const route = API_CONFIG.getAuxiliaryMembersAll(campKey, languageKey)
    return axios.get(route)
  },
  getMemberAvatar(ind_key) {
    const route = MEMBER_API_CONFIG.getMemberAvatar(ind_key)
    return axios.get(route)
  },
  turnOffAnnualMembershipAutoPay(payload) {
    const route = MEMBER_API_CONFIG.turnOffAnnualMembershipAutoPay(payload.cst_key)
    return axios.post(route, payload)
  }
}

const reportCalls = {
  getMemberListReportAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.membersListReport(camp_key, lng_key, 'excel')
    return route
  },
  getFriendsReportAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.friendsReport(camp_key, lng_key, 'excel')
    return route
  },
  getFriendsReportAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.friendsReport(camp_key, lng_key, 'pdf')
    return route
  },
  getMemberListReportAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.membersListReport(camp_key, lng_key, 'pdf')
    return route
  },
  getNewMemberListReportAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.newMembersListReport(camp_key, lng_key, 'excel')
    return route
  },
  getNewMemberListReportAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.newMembersListReport(camp_key, lng_key, 'pdf')
    return route
  },
  getOfficerListReportAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.officersListReport(camp_key, lng_key, 'excel')
    return route
  },
  getOfficerListReportAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.officersListReport(camp_key, lng_key, 'pdf')
    return route
  },
  getProspectListReportAsCSVUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.prospectsListReport(camp_key, lng_key, 'excel')
    return route
  },
  getProspectListReportAsPDFUrl(camp_key, lng_key) {
    const route = REPORT_CONFIG.prospectsListReport(camp_key, lng_key, 'pdf')
    return route
  },
  getMemberProfileReportAsPDF(ind_key, lng_key) {
    const route = REPORT_CONFIG.memberDetailReport(ind_key, lng_key, 'pdf')
    return route
  },
  getNonMemberProfileReportAsPDF(camp_key, lng_key) {
    const route = REPORT_CONFIG.placeholder()
    return route
  },
  getProspectProfileReportAsPDF(ind_key, lng_key) {
    const route = REPORT_CONFIG.candidateDetailReport(ind_key, lng_key, 'pdf')
    return route
  }
}

export const membership = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
