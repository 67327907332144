/* eslint-disable */
import axios from 'axios'

const API_ACTIVE_ROUTE = process.env.VUE_APP_MEMBERSHIP_APP
const CANDIDATE_MEMBER_API_CONFIG = {
    getCandidate: (cadKey) => `${API_ACTIVE_ROUTE}/DigitalMemberCandidate/candidate/${cadKey}`,
    getCandidates: (statusCode, campKey) => `${API_ACTIVE_ROUTE}/DigitalMemberCandidate/candidates/${campKey}/${statusCode}`,
    changeCandidateStatus: () => `${API_ACTIVE_ROUTE}/DigitalMemberCandidate/status/`
}

export const SET_CANDIDATE = 'SET_CANDIDATE'
export const SET_CANDIDATES = 'SET_CANDIDATES'
export const SET_VALIDATE_MEMBER_POPUP = 'SET_VALIDATE_MEMBER_POPUP'
export const SET_MEMBERSHIP_CANDIDATE_KEY = 'SET_MEMBERSHIP_CANDIDATE_KEY'
export const STATUS_CODES = {
    candidate_ended_registration: 3,
    candidate_started_registration: 2,
    candidate_in_progress_registration: 7,
    candidate_sponsor_initiation: 1,
    candidate_denied_cabinet: 5,
    candidate_ready_membership: 6,
    candidate_approved_cabinet: 4
}

const apiCalls = {
    createCandidateInfo(data) {
        // const route = CANDIDATE_API_CONFIG.createCandidateInfo(data)
        // return axios.post(route, data)
    },
    getCandidates(statusCode, campKey) {
        const route = CANDIDATE_MEMBER_API_CONFIG.getCandidates(statusCode, campKey)
        return axios.get(route)
    },
    getCandidate(cadKey) {
        const route = CANDIDATE_MEMBER_API_CONFIG.getCandidate(cadKey)
        return axios.get(route)
    },
    changeCandidateStatus(data) {
        const route = CANDIDATE_MEMBER_API_CONFIG.changeCandidateStatus()
        return axios.post(route, data)
    }
}

const actions = {
    async createCandidateInfo({ }) {
        try {
            const response = await apiCalls.getCandidates()
            if (response && response.status === 200) {
                return response.data
            }
            throw 'bad response for createCandidateInfo'
        } catch (error) {
            console.error(error)
            return false
        }
    },
    async getCandidates({ commit, rootGetters }) {
        let orgKey = rootGetters['user/userCampKey']
        const response = await apiCalls.getCandidates(STATUS_CODES.candidate_ended_registration, orgKey)
        if (response && response.status === 200) {
            await commit('SET_CANDIDATES', response.data)
        }
    },
    async getCandidate({ commit, rootGetters }, cadKey) {
        const response = await apiCalls.getCandidate(cadKey)
        if (response && response.status === 200) {
            await commit('SET_CANDIDATE', response.data)
        }
    },
    async setValidateMemberPopup({ commit }, data) {
        await commit('SET_VALIDATE_MEMBER_POPUP', data)
    },
    async approveCandidate({ }, candidate) {
        let data = {
            cad_key: candidate.cad_key,
            cad_status_code: STATUS_CODES.candidate_approved_cabinet,
            cmi_key: candidate.cmi_key,
            cmi_note: candidate.cmi_notes
        }
        const response = await apiCalls.changeCandidateStatus(data)
        return (response && response.status === 200)
    },
    async denyCandidate({ }, candidate) {
        let data = {
            cad_key: candidate.cad_key,
            cad_status_code: STATUS_CODES.candidate_denied_cabinet,
            cmi_key: candidate.cmi_key,
            cmi_note: candidate.cmi_notes
        }
        const response = await apiCalls.changeCandidateStatus(data)
        return (response && response.status === 200)
    },
    async setMemberCandidate({ commit }, cadKey) {
        await commit('SET_MEMBERSHIP_CANDIDATE_KEY', cadKey)
    }
}

export const state = () => ({
    candidate: {},
    candidates: [],
    validatedCandidate: [],
    membershipCandidateKey: null,
    pdfFile: {},
    status: {},
    httpMessage: {},
    token: '',
    validateMemberPopup: false,
    CandidateAll: {}
})

export const getters = {
    candidate: state => {
        return state.candidate
    },
    candidates: state => {
        return state.candidates
    },
    validateMemberPopup: state => {
        return state.validateMemberPopup
    },
    membershipCandidateKey: state => {
        return state.membershipCandidateKey
    }
}
export const mutations = {
    [SET_CANDIDATE](state, data) {
        state.candidate = data
    },
    [SET_CANDIDATES](state, data) {
        state.candidates = data
    },
    [SET_VALIDATE_MEMBER_POPUP](state, data) {
        state.validateMemberPopup = data
    },
    [SET_MEMBERSHIP_CANDIDATE_KEY](state, data) {
        state.membershipCandidateKey = data
    }
}

export const digitalCandidateMember = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
}
