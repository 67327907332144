/* eslint-disable */
import axios from 'axios'
import { CONVERSATIONS_API_ACTIVE_ROUTE, CONVERSATIONS_API_CONFIG } from '../config/conversations.api.config'
import constantData from '@/json/data.json'
import dateData from '@/json/date.json'
import numericMixin from '@/mixins/numericMixin.js'
import { REPORT_CONFIG } from '../config/report.config.js'

export const state = () => ({
  conversation: {},
  conversation_empty: {
    attendeesMembers: [],
    attendeesMembers_Remove: [],
    attendeesNonmembers: [],
    attendeesNonmembers_Remove: [],
    conversationKey: null,
    distributionSelected: null,
    documents: [],
    editMode: false,
    educationalUnitKey: null,
    educationalUnit: null,
    endTime: null,
    eventTitle: '',
    eventType: null,
    instructorKey: null,
    instructor: null,
    instructorDeclineHistory: [],
    localContact: null,
    location: null,
    meeting_key: null,
    memberTypeSelected: null,
    org_key: null,
    selectedAreas: [],
    startEndDate: { startDate: null, endDate: null },
    startTime: null,
    state_key: null,
    virtualLocation: null,
    meeting_status: constantData.MeetingStatuses.Pending,
    selectedStateCountry: {
      value: null,
      text: null
    }
  },
  conversations_list: [],
  educational_units: [],
  educational_unit_history: [],
  exportConversationHistoryRoute: null,
  exportConversationDetailRoute: null,
  individual_history: [],
  instructors: [],
  instructor_history: [],
  location: {
    location_key: null,
    name: null
  },
  location_virtual: {
    location_virtual_key: null,
    name: null
  },
  meeting_types: [],
  otfbCountry: {},
  state_members: [],
  selected_educational_unit_key: null,
  selected_instructor_key: null,
  widget_data: {
    conversationCounts: 0,
    attendeeCounts: 0,
    upcomingConversation: [],
    pastConversation: []
  }
})

export const getters = {
  conversation: state => {
    return state.conversation
  },
  conversations_list: state => {
    return state.conversations_list
  },
  conversations_list_past: state => {
    if (state.conversations_list.length > 0) {
      var pList = state.conversations_list.filter(
        conversation => {
          return new Date(conversation.mtg_start_date) <= new Date()
        }
      )
      return pList
    } else {
      return []
    }
  },
  conversations_list_upcoming: state => {
    if (state.conversations_list.length > 0) {
      var fList = state.conversations_list.filter(
        conversation => {
          return new Date(conversation.mtg_start_date) > new Date()
        }
      )
      return fList
    } else {
      return []
    }
  },
  exportConversationHistoryRoute: state => {
    return state.exportConversationHistoryRoute
  },
  exportConversationDetailRoute: state => {
    return state.exportConversationDetailRoute
  },
  instructors: state => {
    return state.instructors
  },
  instructors_and_eus: state => {

    let iaeu = state.educational_units.concat(state.instructors)
      let uniqueInstructors = iaeu.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            t =>
              t.ind_key === thing.ind_key &&
              t.name === thing.name &&
              t.sort_name === thing.sort_name &&
              t.ind_key === thing.ind_key
          )
      )
      let sortedList = uniqueInstructors.sort((a, b) => {
        if (a.sort_name < b.sort_name) return -1
        if (a.sort_name > b.sort_name) return 1
        return 0
      })
      return sortedList
  },
  individual_history: state => {
    return state.individual_history
  },
  instructor_history: state => {
    return state.instructor_history
  },
  educational_units: state => {
    return state.educational_units
  },
  educational_unit_history: state => {
    return state.educational_unit_history
  },
  meeting_types: state => {
    return state.meeting_types
  },
  otfbCountry: state => {
    return state.otfbCountry
  },
  selected_educational_unit_key: state => {
    return state.selected_educational_unit_key
  },
  selected_instructor_key: state => {
    return state.selected_instructor_key
  },
  state_members: state => {
    return state.state_members
  },
  widget_data: state => {
    return state.widget_data
  }
}

export const SAVE_CONVERSATION = 'SAVE_CONVERSATION'
export const SET_ATTENDEES = 'SET_ATTENDEES'
export const SET_CONVERSATION_CLEAR = 'SET_CONVERSATION_CLEAR'
export const SET_CONVERSATION_DETAILS = 'SET_CONVERSATION_DETAILS'
export const SET_CONVERSATIONS_LIST = 'SET_CONVERSATIONS_LIST'
export const SET_EDUCATIONAL_UNITS = 'SET_EDUCATIONAL_UNITS'
export const SET_EDUCATIONAL_UNIT_HISTORY = 'SET_EDUCATIONAL_UNIT_HISTORY'
export const SET_EVENT_DETAILS = 'SET_EVENT_DETAILS'
export const SET_EXPORT_CONVERSATION_HISTORY_ROUTE = 'SET_EXPORT_CONVERSATION_HISTORY_ROUTE'
export const SET_EXPORT_CONVERSATION_DETAIL_ROUTE = 'SET_EXPORT_CONVERSATION_DETAIL_ROUTE'
export const SET_GEO_DIST = 'SET_GEO_DIST'
export const SET_INDIVIDUAL_HISTORY = 'SET_INDIVIDUAL_HISTORY'
export const SET_INSTRUCTOR_HISTORY = 'SET_INSTRUCTOR_HISTORY'
export const SET_INSTRUCTORS = 'SET_INSTRUCTORS'
export const SET_MEETING_TYPE = 'SET_MEETING_TYPE'
export const SET_OFTB_COUNTRY = 'SET_OFTB_COUNTRY'
export const SET_STATE_MEMBERS = 'SET_STATE_MEMBERS'
export const SET_SELECTED_EDUCATIONAL_UNIT_KEY = 'SET_SELECTED_EDUCATIONAL_UNIT_KEY'
export const SET_SELECTED_INSTRUCTOR_KEY = 'SET_SELECTED_INSTRUCTOR_KEY'
export const SET_WIDGET_DATA = 'SET_WIDGET_DATA'
export const SET_CONVERSATION_STATECOUNTRY = 'SET_CONVERSATION_STATECOUNTRY'

export const mutations = {
  [SET_ATTENDEES](state, data) {
    state.conversation.attendeesMembers =
      !!data.members.length > 0 ? JSON.parse(JSON.stringify(data.members)) : []
    state.conversation.attendeesMembers_Remove =
      !!data.members_remove.length > 0 ? JSON.parse(JSON.stringify(data.members_remove)) : []
    state.conversation.attendeesNonmembers =
      !!data.nonmembers.length > 0 ? JSON.parse(JSON.stringify(data.nonmembers)) : []
    state.conversation.attendeesNonmembers_Remove =
      !!data.nonmembers_remove.length > 0 ? JSON.parse(JSON.stringify(data.nonmembers_remove)) : []
  },
  [SET_CONVERSATION_CLEAR](state, data) {
    state.conversation = JSON.parse(JSON.stringify(state.conversation_empty))
  },
  [SET_CONVERSATION_DETAILS](state, data) {
    //clear the conversation
    state.conversation = JSON.parse(JSON.stringify(state.conversation_empty))
    state.conversation.editMode = true
    state.conversation.conversationKey = data.details.cvs_key
    state.conversation.meeting_key = data.details.cvs_mtg_key
    state.conversation.meeting_status = data.details.mtg_mts_key
    state.conversation.educationalUnitKey = data.details.cvs_educationalunit_ind_key
    state.conversation.educationalUnit = {
      text: `${data.details.eu_first_name} ${data.details.eu_last_name}`,
      value: data.details.cvs_educationalunit_ind_key,
      email: data.details.eu_email,
      phone: data.details.eu_phone,
      formal_name: data.details.eu_formal_name,
      formal_sort_name: data.details.eu_formal_sort_name
    },
      state.conversation.instructor = {
        text: `${data.details.in_first_name} ${data.details.in_last_name}`,
        value: data.details.cvs_instructor_ind_key,
        email: data.details.in_email,
        phone: data.details.in_phone,
        formal_name: data.details.in_formal_name,
        formal_sort_name: data.details.in_formal_sort_name
      },
    state.conversation.endTime = numericMixin.methods.formatTimeToMilitary(data.details.mtg_end_time)
    state.conversation.eventType = data.details.mtg_mtt_key
    state.conversation.eventTitle = data.details.mtg_title
    state.conversation.instructorKey = data.details.cvs_instructor_ind_key
    state.conversation.localContact = {
      text: `${data.details.lc_first_name} ${data.details.lc_last_name}`,
      value: data.details.cvs_localcontact_ind_key,
      email: data.details.lc_email,
      phone: data.details.lc_phone,
      formal_name: data.details.lc_formal_name,
      formal_sort_name: data.details.lc_formal_sort_name
    }
    state.conversation.location = {
      org_key: data.details.mtg_location_org_key,
      org_name: data.details.location_name,
      org_adr_key: data.details.location_adr_key,
      adr_line1: data.details.location_adr_line1,
      adr_line2: data.details.location_adr_line2,
      adr_city: data.details.location_adr_city,
      adr_state: data.details.location_adr_state,
      adr_postal_code: data.details.location_adr_postal_code,
      adr_cnt_key: data.details.location_adr_cnt_key
    }
    state.conversation.virtualLocation = data.details.mtg_virtual_location_org_key ? {
        org_key: data.details.mtg_virtual_location_org_key
      } : null
    state.conversation.memberTypeSelected = data.details.mtg_mpt_key
    state.conversation.startEndDate = {
      startDate: data.details.mtg_start_date.substring(0, 10),
      endDate: data.details.mtg_end_date.substring(0, 10)
    }
    state.conversation.startTime = numericMixin.methods.formatTimeToMilitary(data.details.mtg_start_time)
    state.conversation.selectedStateCountry = {
      "value": data.details.host_org_key,
      "text": data.details.host_org_name
    }
    state.conversation.attendeesMembers = data.attendees.filter(attendee => attendee.cva_member_flag == true).map(attendee => ({
      text: `${attendee.vip_formal_name}`,
      value: attendee.cva_ind_key,
      email: attendee.vip_email,
      phone: attendee.vip_phone,
      formal_name: attendee.vip_formal_name,
      formal_sort_name: attendee.vip_formal_sort_name
    }))
    state.conversation.attendeesNonmembers = data.attendees.filter(attendee => attendee.cva_member_flag == false).map(attendee => ({
      text: `${attendee.vip_formal_name}`,
      value: attendee.cva_ind_key,
      email: attendee.vip_email,
      phone: attendee.vip_phone,
      formal_name: attendee.vip_formal_name,
      formal_sort_name: attendee.vip_formal_sort_name
    }))

    //geo distribution
    if (data.geodistribution.length === 1 && data.geodistribution[0].mgd_children_org_key === data.geodistribution[0].mgd_parent_org_key) {
      //Full State
      state.conversation.distributionSelected = {
        "text": "Full State",
        "selected": true,
        "key": "full"
      }
    } else {
      // Areas
      state.conversation.distributionSelected = {
        "text": "Selected Areas",
        "selected": true,
        "key": "areas"
      }
    }
    state.conversation.selectedAreas = data.geodistribution.map(area => (
      {
        "org_key": area.mgd_children_org_key,
        "org_name": area.child_org_name,
        "org_ogt_key": area.child_org_ogt_key,
        "region_key": area.mgd_parent_org_key,
        "region_name": area.parent_org_name
      }
    ))
  },
  [SET_CONVERSATIONS_LIST](state, data) {
    state.conversations_list = !!data.conversations.length > 0 ? JSON.parse(JSON.stringify(data.conversations)) : []
  },
  [SET_CONVERSATION_STATECOUNTRY](state, data) {
    state.conversation.selectedStateCountry = data
  },
  [SET_EXPORT_CONVERSATION_HISTORY_ROUTE](state, data) {
    state.exportConversationHistoryRoute = data
  },
  [SET_EXPORT_CONVERSATION_DETAIL_ROUTE](state, data) {
    state.exportConversationDetailRoute = data
  },
  [SET_INSTRUCTORS](state, data) {
    state.instructors = data
  },
  [SET_INDIVIDUAL_HISTORY](state, data) {
    state.individual_history = data
  },
  [SET_INSTRUCTOR_HISTORY](state, data) {
    state.instructor_history = data
  },
  [SET_EDUCATIONAL_UNITS](state, data) {
    state.educational_units = data
  },
  [SET_EDUCATIONAL_UNIT_HISTORY](state, data) {
    state.educational_unit_history = data
  },
  [SET_GEO_DIST](state, data) {
    state.conversation.distributionSelected = JSON.parse(JSON.stringify(data.distributionSelected))
    state.conversation.selectedAreas = JSON.parse(JSON.stringify(data.selectedAreas))
    state.conversation.org_key = data.org_key
    state.conversation.editMode = true
  },
  [SET_EVENT_DETAILS](state, data) {
    state.conversation.educationalUnitKey = data.educationalUnitKey
    state.conversation.endTime = data.endTime
    state.conversation.eventType = data.eventType
    state.conversation.eventTitle = data.eventTitle
    state.conversation.instructorKey = data.instructorKey
    state.conversation.localContact = data.localContact
    state.conversation.location = !!data.location ? JSON.parse(JSON.stringify(data.location)) : null
    state.conversation.virtualLocation = !!data.virtualLocation
      ? JSON.parse(JSON.stringify(data.virtualLocation))
      : null
    state.conversation.memberTypeSelected = data.memberTypeSelected
    state.conversation.startEndDate = JSON.parse(JSON.stringify(data.startEndDate))
    state.conversation.startTime = data.startTime
    state.conversation.endTime = data.endTime
  },
  [SET_MEETING_TYPE](state, data) {
    state.meeting_types = data
  },
  [SET_OFTB_COUNTRY](state, data) {
    state.otfbCountry = {}
    if (data) {
      state.otfbCountry = data
    }
  },
  [SET_SELECTED_EDUCATIONAL_UNIT_KEY](state, data) {
    state.selected_educational_unit_key = data
  },
  [SET_SELECTED_INSTRUCTOR_KEY](state, data) {
    state.selected_instructor_key = data
  },
  [SET_STATE_MEMBERS](state, data) {
    state.state_members = data
  },
  [SET_WIDGET_DATA](state, data) {
    state.widget_data.conversationCounts = data.ConversationCounts
    state.widget_data.attendeeCounts = data.AttendeeCounts
    let upcomingConversations = data.UpcomingConversation
    let pastConversations = data.PastConversation

    state.widget_data.upcomingConversation = upcomingConversations.map(conversation => {
      let conDate = new Date(conversation.mtg_start_date)
      let monthName = dateData.months.find(mi => parseInt(mi.value) === conDate.getMonth() + 1).abbr
      return {
        title: conversation.mtg_title,
        description: conversation.meeting_type,
        cvs_mtg_key: conversation.mtg_key,
        destination: constantData.meetings.TwoHourConversation.route,
        date: {
          month: monthName,
          day: conDate.getDate(),
          year: conDate.getFullYear()
        }
      }
    })
    state.widget_data.pastConversation = pastConversations.map(conversation => {
      let conDate = new Date(conversation.mtg_start_date)
      let monthName = dateData.months.find(mi => parseInt(mi.value) === conDate.getMonth() + 1).abbr
      return {
        title: conversation.mtg_title,
        description: conversation.meeting_type,
        cvs_mtg_key: conversation.mtg_key,
        destination: constantData.meetings.TwoHourConversation.route,
        date: {
          month: monthName,
          day: conDate.getDate(),
          year: conDate.getFullYear()
        }
      }
    })
  }
}

export const actions = {
  async addInstructorEU({ commit, dispatch, rootGetters }, payload) {
    try {
      let ixa = {
        ixa_key: payload.ixa_key,
        ixa_ind_key: payload.ind_key,
        ixa_acr_key: payload.ixa_acr_key,
        ixa_tst_key: null,
        ixa_start_date: payload.ixa_start_date,
        ixa_end_date: null,
        ixa_presentation_media_flag: null,
        ixa_note: payload.ixa_note,
        ixa_scripture_blitz_date: null,
        ixa_scripture_blitz_title: null,
        ixa_nag_key: null,
        ixa_add_user: rootGetters['user/userId'],
        ixa_add_date: new Date(),
        ixa_change_user: null,
        ixa_change_date: null,
        ixa_delete_flag: payload.ixa_delete_flag
      }
      let response = await apiCalls.addInstructorEU(ixa)

      if (response && response.status === 200) {
        let rtnValue = true
        if (payload.ixa_acr_key === constantData.ConversationGuids.InstructorAccreditation) {
          rtnValue = dispatch('loadInstructors', { org_key: payload.org_key, lang_key: payload.lang_key })
        } else {
          rtnValue = dispatch('loadEducationalUnits', { org_key: payload.org_key, lang_key: payload.lang_key })
        }
        return rtnValue
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addNonMember({ commit, dispatch, rootGetters }, payload) {
    try {
      let response = await apiCalls.addNonMember(payload)
      if (response && response.status === 200) {
        return response
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async clearConversation({ commit }) {
    try {
      await commit('SET_CONVERSATION_CLEAR')
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async clearEducationalUnitHistory({ commit }) {
    try {
      await commit('SET_EDUCATIONAL_UNIT_HISTORY', [])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async clearInstructorHistory({ commit }) {
    try {
      await commit('SET_INSTRUCTOR_HISTORY', [])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async clearIndividualHistory({ commit }) {
    try {
      await commit('SET_INDIVIDUAL_HISTORY', [])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deleteConversation({ }, payload) {
    try {
      let response = await apiCalls.deleteConversation(payload.cvs_key, payload.user)
      if (response && response.status === 200) {
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadCountryByOFTB({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadCountryByOFTB(payload)
      if (response && response.status === 200) {
        await commit('SET_OFTB_COUNTRY', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      await commit('SET_OFTB_COUNTRY', {})
      return false
    }
  },
  async loadConversationDetails({ commit, dispatch, state, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadConversationDetails(payload)
      if (response && response.status === 200) {
        await commit('SET_CONVERSATION_DETAILS', response.data)
        // dispatch and action in the User store module
        dispatch('user/setSelectedConversationKey', state.conversation.conversationKey, { root: true })
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      //await commit('SET_CONVERSATION_DETAILS', {})
      return false
    }
  },
  async loadConversationDetailsByMeetingKey({ commit, dispatch, state, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadConversationDetailsByMeetingKey(payload)
      if (response && response.status === 200) {
        await commit('SET_CONVERSATION_DETAILS', response.data)
        // dispatch and action in the User store module
        await dispatch('user/setSelectedConversationKey', state.conversation.conversationKey, { root: true })
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      //await commit('SET_CONVERSATION_DETAILS', {})
      return false
    }
  },
  async loadConversationsByOrgKey({ commit, dispatch, state, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadConversationByOrgKey(payload)
      if (response && response.status === 200) {
        await commit('SET_CONVERSATIONS_LIST', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      //await commit('SET_CONVERSATION_DETAILS', {})
      return false
    }
  },
  async loadConversationsHistory({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadConversationsHistory(payload)

      if (response && response.status === 200) {
        await commit(payload.instructor_or_eu === 'instructor' ? 'SET_INSTRUCTOR_HISTORY' : 'SET_EDUCATIONAL_UNIT_HISTORY', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadConversationsIndividualHistory({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadConversationsIndividualHistory(payload)

      if (response && response.status === 200) {
        await commit('SET_INDIVIDUAL_HISTORY', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadExportConversationHistoryRoute({ commit, rootGetters }, org_key) {
    try {
      let csvUrl = reportCalls.getConversationListReportUrl(org_key)
      await Promise.all([commit('SET_EXPORT_CONVERSATION_HISTORY_ROUTE', csvUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadExportConversationDetailRoute({ commit, rootGetters }, org_key) {
    try {
      let rptUrl = reportCalls.getConversationDetailUrl(org_key)
      await Promise.all([commit('SET_EXPORT_CONVERSATION_DETAIL_ROUTE', rptUrl)])
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadInstructors({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadInstructors(payload)

      if (response && response.status === 200) {
        await commit('SET_INSTRUCTORS', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadEducationalUnits({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadEducationalUnits(payload)

      if (response && response.status === 200) {
        await commit('SET_EDUCATIONAL_UNITS', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadMeetingTypes({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadMeetingTypes(payload)

      if (response && response.status === 200) {
        await commit('SET_MEETING_TYPE', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStateMembers({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadStateMembers(payload)

      if (response && response.status === 200) {
        await commit('SET_STATE_MEMBERS', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadWidgetData({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadWidgetData(payload)

      if (response && response.status === 200) {
        await commit('SET_WIDGET_DATA', response.data)
        return true
      }
      throw 'Did not return widget data'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async saveConversation({ commit, dispatch, rootGetters }, payload) {
    try {
      let response = await apiCalls.saveConversation(rootGetters['user/userId'], payload)
      if (response && response.status === 200) {
        let rtnValue = true
        return rtnValue
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async setSelectedEducationalUnitKey({ commit, dispatch, rootGetters }, payload) {
    try {
      await commit('SET_SELECTED_EDUCATIONAL_UNIT_KEY', payload)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async setSelectedInstructorKey({ commit, dispatch, rootGetters }, payload) {
    try {
      await commit('SET_SELECTED_INSTRUCTOR_KEY', payload)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async updateConversationGeoDist({ commit, dispatch, rootGetters }, payload) {
    try {
      await commit('SET_GEO_DIST', payload)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async updateConversationEventDetails({ commit, dispatch, rootGetters }, payload) {
    try {
      await commit('SET_EVENT_DETAILS', payload)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async updateConversationAttendees({ commit, dispatch, rootGetters }, payload) {
    try {
      await commit('SET_ATTENDEES', payload)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async updateConversationStateCountry({ commit, dispatch, rootGetters }, payload) {
    try {
      await commit('SET_CONVERSATION_STATECOUNTRY', payload)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

const apiCalls = {
  addInstructorEU(payload) {
    const route = CONVERSATIONS_API_CONFIG.addInstructorEU()
    return axios.post(route, payload)
  },
  addNonMember(payload) {
    const route = CONVERSATIONS_API_CONFIG.addNonMember()
    return axios.post(route, payload)
  },
  deleteConversation(cvs_key, mtg_user) {
    const route = CONVERSATIONS_API_CONFIG.deleteConversation(cvs_key, mtg_user)
    return axios.post(route)
  },
  exportConversationHistoryRoute(payload) {
    const route = CONVERSATIONS_API_CONFIG.exportConversationHistory(payload.org_key)
    return route;
  },
  loadConversationDetails(payload) {
    const route = CONVERSATIONS_API_CONFIG.getConversationDetails(payload)
    return axios.get(route)
  },
  loadConversationDetailsByMeetingKey(payload) {
    const route = CONVERSATIONS_API_CONFIG.getConversationDetailsByMeetingKey(payload)
    return axios.get(route)
  },
  loadConversationByOrgKey(payload) {
    const route = CONVERSATIONS_API_CONFIG.getConversationsList(payload.org_key, payload.start_date, payload.end_date)
    return axios.get(route)
  },
  loadConversationsHistory(payload) {
    const route = CONVERSATIONS_API_CONFIG.getConversationsHistory(payload.ind_key, payload.instructor_or_eu)
    return axios.get(route)
  },
  loadConversationsIndividualHistory(payload) {
    const route = CONVERSATIONS_API_CONFIG.getConversationsHistory(payload.ind_key)
    return axios.get(route)
  },
  loadCountryByOFTB(payload) {
    const route = CONVERSATIONS_API_CONFIG.getCountryByOFTB(payload.org_key)
    return axios.get(route)
  },
  loadEducationalUnits(payload) {
    const route = CONVERSATIONS_API_CONFIG.getEducationalUnitsByState(payload.org_key, payload.lang_key)
    return axios.get(route)
  },
  loadInstructors(payload) {
    const route = CONVERSATIONS_API_CONFIG.getInstructorsByState(payload.org_key, payload.lang_key)
    return axios.get(route)
  },
  loadMeetingTypes(payload) {
    const route = CONVERSATIONS_API_CONFIG.getMeetingTypes(payload.type, payload.lang_key)
    return axios.get(route)
  },
  loadStateMembers(payload) {
    const route = CONVERSATIONS_API_CONFIG.getMembersByStateAndType(
      payload.org_key,
      payload.lang_key,
      payload.membertype_key
    )
    return axios.get(route)
  },
  loadWidgetData(payload) {
    const route = CONVERSATIONS_API_CONFIG.getConversationWidgetData(
      payload.org_key,
      payload.row_count)
    return axios.get(route)
  },
  saveConversation(ind_key, payload) {
    const route = CONVERSATIONS_API_CONFIG.saveConversation(ind_key)
    return axios.post(route, payload)
  }
}

const reportCalls = {
  getConversationListReportUrl(org_key) {
    const route = REPORT_CONFIG.conversationHistory(org_key,'excel')
    return route
  },
  getConversationDetailUrl(org_key) {
    const route = REPORT_CONFIG.conversationDetails(org_key,'pdf')
    return route
  },

}
export const conversations = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
